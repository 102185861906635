// src/components/tasks/TaskKanban.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { 
  Search, Filter, PlusCircle, User, Calendar, 
  Tag, CheckCircle, AlertTriangle, Clock, FileText, 
  MoreVertical, Edit, Trash2, Copy, ExternalLink
} from 'lucide-react';

const TaskKanban = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  const [filterAssignee, setFilterAssignee] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState({});
  const [menuOpen, setMenuOpen] = useState(null);
  
  // Status columns for the Kanban board
  const statusColumns = [
    { id: 'not_started', title: 'Not Started', color: 'gray' },
    { id: 'in_progress', title: 'In Progress', color: 'blue' },
    { id: 'on_hold', title: 'On Hold', color: 'amber' },
    { id: 'completed', title: 'Completed', color: 'green' }
  ];
  
  // Mock data for categories and assignees
  const categories = ['all', 'Scheduled', 'Ad-hoc', 'Recurring'];
  const assignees = [
    { id: 'all', name: 'All Assignees' },
    { id: 'sarah', name: 'Sarah Wilson' },
    { id: 'alex', name: 'Alex Johnson' },
    { id: 'maria', name: 'Maria Garcia' },
    { id: 'david', name: 'David Chen' },
    { id: 'ahmed', name: 'Ahmed Khan' },
    { id: 'olivia', name: 'Olivia Williams' }
  ];

  // Fetch tasks and organize them into columns
  useEffect(() => {
    const fetchTasks = async () => {
      setIsLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(r => setTimeout(r, 800));
        
        // Mock task data
        const mockTasks = [
          {
            id: '1',
            description: 'Finalize Q2 marketing campaign materials',
            category: 'Scheduled',
            assignee: 'Sarah Wilson',
            assigneeId: 'sarah',
            dueDate: '2025-04-10',
            status: 'in_progress',
            priority: 'High',
            taskRef: {
              type: 'Plan',
              id: '2',
              title: 'Q2 Marketing Campaign'
            }
          },
          {
            id: '2',
            description: 'Conduct weekly team meeting',
            category: 'Recurring',
            assignee: 'Alex Johnson',
            assigneeId: 'alex',
            dueDate: '2025-04-02',
            status: 'not_started',
            priority: 'Medium',
            taskRef: {
              type: 'Meeting',
              id: 'm1',
              title: 'Weekly Team Standup'
            }
          },
          {
            id: '3',
            description: 'Prepare monthly financial report',
            category: 'Scheduled',
            assignee: 'David Chen',
            assigneeId: 'david',
            dueDate: '2025-04-15',
            status: 'not_started',
            priority: 'Critical',
            taskRef: {
              type: 'Project',
              id: 'p5',
              title: 'Financial Planning 2025'
            }
          },
          {
            id: '4',
            description: 'Follow up with client leads from trade show',
            category: 'Ad-hoc',
            assignee: 'Maria Garcia',
            assigneeId: 'maria',
            dueDate: '2025-04-02',
            status: 'completed',
            priority: 'High',
            taskRef: null
          },
          {
            id: '5',
            description: 'Update product documentation for new features',
            category: 'Scheduled',
            assignee: 'Ahmed Khan',
            assigneeId: 'ahmed',
            dueDate: '2025-04-15',
            status: 'in_progress',
            priority: 'Medium',
            taskRef: {
              type: 'Project',
              id: 'p3',
              title: 'New Product Launch'
            }
          },
          {
            id: '6',
            description: 'Coordinate office supplies inventory check',
            category: 'Ad-hoc',
            assignee: 'Olivia Williams',
            assigneeId: 'olivia',
            dueDate: '2025-04-09',
            status: 'on_hold',
            priority: 'Low',
            taskRef: null
          },
          {
            id: '7',
            description: 'Schedule Q2 performance reviews',
            category: 'Scheduled',
            assignee: 'Alex Johnson',
            assigneeId: 'alex',
            dueDate: '2025-04-20',
            status: 'not_started',
            priority: 'Medium',
            taskRef: null
          },
          {
            id: '8',
            description: 'Develop sales training materials',
            category: 'Scheduled',
            assignee: 'Sarah Wilson',
            assigneeId: 'sarah',
            dueDate: '2025-04-18',
            status: 'in_progress',
            priority: 'High',
            taskRef: {
              type: 'Plan',
              id: '1',
              title: 'Q1 Sales Strategy'
            }
          },
          {
            id: '9',
            description: 'Review and approve vendor invoices',
            category: 'Recurring',
            assignee: 'David Chen',
            assigneeId: 'david',
            dueDate: '2025-04-05',
            status: 'completed',
            priority: 'Medium',
            taskRef: null
          },
          {
            id: '10',
            description: 'Prepare for client presentation',
            category: 'Ad-hoc',
            assignee: 'Maria Garcia',
            assigneeId: 'maria',
            dueDate: '2025-04-12',
            status: 'in_progress',
            priority: 'Critical',
            taskRef: {
              type: 'Meeting',
              id: 'm2',
              title: 'Client Presentation: XYZ Corp'
            }
          }
        ];
        
        // Organize tasks into columns by status
        const initialColumns = {};
        statusColumns.forEach(column => {
          initialColumns[column.id] = {
            id: column.id,
            title: column.title,
            color: column.color,
            tasks: mockTasks.filter(task => task.status === column.id)
          };
        });
        
        setColumns(initialColumns);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchTasks();
  }, []);

  // Apply filters to tasks
  const getFilteredTasks = (tasks) => {
    if (!tasks) return [];
    
    return tasks.filter(task => {
      // Filter by search term
      const matchesSearch = searchTerm === '' || 
        task.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (task.taskRef && task.taskRef.title.toLowerCase().includes(searchTerm.toLowerCase()));
      
      // Filter by category
      const matchesCategory = filterCategory === 'all' || task.category === filterCategory;
      
      // Filter by assignee
      const matchesAssignee = filterAssignee === 'all' || task.assigneeId === filterAssignee;
      
      return matchesSearch && matchesCategory && matchesAssignee;
    });
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  // Get priority style
  const getPriorityStyle = (priority) => {
    switch (priority) {
      case 'Low':
        return 'bg-green-100 text-green-700';
      case 'Medium':
        return 'bg-blue-100 text-blue-700';
      case 'High':
        return 'bg-orange-100 text-orange-700';
      case 'Critical':
        return 'bg-red-100 text-red-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  };
  
  // Get column header style
  const getColumnHeaderStyle = (color) => {
    switch (color) {
      case 'green':
        return 'bg-green-100 text-green-800 border-green-200';
      case 'blue':
        return 'bg-blue-100 text-blue-800 border-blue-200';
      case 'amber':
        return 'bg-amber-100 text-amber-800 border-amber-200';
      case 'gray':
      default:
        return 'bg-gray-100 text-gray-800 border-gray-200';
    }
  };

  // Handle drag and drop
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    
    // If no destination or dropped in the same place
    if (!destination || 
        (destination.droppableId === source.droppableId && 
         destination.index === source.index)) {
      return;
    }
    
    // Find the task that was dragged
    const sourceColumn = columns[source.droppableId];
    const draggedTask = sourceColumn.tasks.find(task => task.id === draggableId);
    
    // Clone columns for immutability
    const newColumns = {...columns};
    
    // Remove from source column
    newColumns[source.droppableId] = {
      ...sourceColumn,
      tasks: sourceColumn.tasks.filter(task => task.id !== draggableId)
    };
    
    // Add to destination column
    const destColumn = columns[destination.droppableId];
    const newTasks = Array.from(destColumn.tasks);
    
    // Update task's status to match new column
    const updatedTask = {
      ...draggedTask,
      status: destination.droppableId
    };
    
    // Insert at the right position
    newTasks.splice(destination.index, 0, updatedTask);
    
    newColumns[destination.droppableId] = {
      ...destColumn,
      tasks: newTasks
    };
    
    setColumns(newColumns);
    
    // In a real app, you would call an API to update the task's status
    console.log(`Task ${draggableId} moved from ${source.droppableId} to ${destination.droppableId}`);
  };
  
  // Toggle task action menu
  const toggleTaskMenu = (taskId) => {
    setMenuOpen(menuOpen === taskId ? null : taskId);
  };

  return (
    <div>
      {/* Search and Actions Bar */}
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search tasks..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3 flex-wrap lg:flex-nowrap">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
          >
            <option value="all">All Categories</option>
            {categories.filter(c => c !== 'all').map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
          
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterAssignee}
            onChange={(e) => setFilterAssignee(e.target.value)}
          >
            {assignees.map(person => (
              <option key={person.id} value={person.id}>{person.name}</option>
            ))}
          </select>
          
          <Link
            to="/tasks/new"
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5]"
          >
            <PlusCircle size={18} className="mr-2" />
            Add Task
          </Link>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 pb-6 overflow-x-auto">
            {statusColumns.map(column => (
              <div key={column.id} className="min-w-[280px]">
                <div className={`px-3 py-2 rounded-t-lg border-b ${getColumnHeaderStyle(column.color)}`}>
                  <div className="flex justify-between items-center">
                    <h3 className="font-medium">{column.title}</h3>
                    <div className="flex items-center justify-center w-6 h-6 rounded-full bg-white text-xs font-medium">
                      {getFilteredTasks(columns[column.id]?.tasks).length}
                    </div>
                  </div>
                </div>
                
                <Droppable droppableId={column.id}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="bg-gray-50 rounded-b-lg p-2 min-h-[500px]"
                    >
                      {getFilteredTasks(columns[column.id]?.tasks).length === 0 ? (
                        <div className="flex flex-col items-center justify-center py-8 px-4 border-2 border-dashed border-gray-200 rounded-lg mt-2 h-28">
                          <p className="text-gray-500 text-sm text-center">No tasks in this column</p>
                          <Link
                            to="/tasks/new"
                            className="mt-2 text-[#190192] text-sm hover:underline flex items-center"
                          >
                            <PlusCircle size={14} className="mr-1" />
                            Add Task
                          </Link>
                        </div>
                      ) : (
                        getFilteredTasks(columns[column.id]?.tasks).map((task, index) => (
                          <Draggable key={task.id} draggableId={task.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`bg-white rounded-lg shadow-sm p-3 mb-2 ${
                                  snapshot.isDragging ? 'shadow-md' : ''
                                }`}
                              >
                                <div className="relative">
                                  <div className="flex justify-between mb-2">
                                    <span className={`px-2 py-0.5 text-xs rounded-full ${getPriorityStyle(task.priority)}`}>
                                      {task.priority}
                                    </span>
                                    <button 
                                      onClick={() => toggleTaskMenu(task.id)}
                                      className="text-gray-400 hover:text-gray-600"
                                    >
                                      <MoreVertical size={16} />
                                    </button>
                                    
                                    {/* Task action menu */}
                                    {menuOpen === task.id && (
                                      <div className="absolute right-0 top-6 w-40 bg-white rounded-lg shadow-lg z-10 border border-gray-200">
                                        <Link 
                                          to={`/tasks/${task.id}`}
                                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                        >
                                          <ExternalLink size={14} className="mr-2" />
                                          View Details
                                        </Link>
                                        <Link 
                                          to={`/tasks/edit/${task.id}`}
                                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                        >
                                          <Edit size={14} className="mr-2" />
                                          Edit
                                        </Link>
                                        <button className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                                          <Copy size={14} className="mr-2" />
                                          Duplicate
                                        </button>
                                        <button className="flex items-center px-4 py-2 text-sm text-red-600 hover:bg-red-50 w-full text-left">
                                          <Trash2 size={14} className="mr-2" />
                                          Delete
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                  
                                  <p className="text-gray-900 font-medium mb-3 line-clamp-2">
                                    {task.description}
                                  </p>
                                  
                                  {task.taskRef && (
                                    <div className="mb-3 text-xs text-gray-500 flex items-center">
                                      <FileText size={12} className="mr-1" />
                                      <span className="truncate">{task.taskRef.type}: {task.taskRef.title}</span>
                                    </div>
                                  )}
                                  
                                  <div className="flex items-center justify-between text-xs text-gray-500">
                                    <div className="flex items-center">
                                      <Calendar size={12} className="mr-1" />
                                      <span>{formatDate(task.dueDate)}</span>
                                    </div>
                                    
                                    {task.assignee && (
                                      <div className="flex items-center">
                                        <div className="w-5 h-5 rounded-full bg-indigo-100 flex items-center justify-center text-indigo-600 text-xs font-medium mr-1">
                                          {task.assignee.charAt(0)}
                                        </div>
                                        <span className="truncate max-w-[80px]">{task.assignee}</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
        </DragDropContext>
      )}
    </div>
  );
};

export default TaskKanban;