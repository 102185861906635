// src/models/StockItem.js
export const StockItemTypes = {
    PHYSICAL: 'physical',
    DIGITAL: 'digital',
    RAW_MATERIAL: 'raw_material',
    SERVICE: 'service'
  };
  
  export const StockItemCategories = [
    'Apparel',
    'Electronics',
    'Furniture',
    'Gifts',
    'Office Supplies',
    'Raw Materials',
    'Digital Products',
    'Services'
  ];
  
  // Sample stock item structure
  export const stockItemTemplate = {
    id: '',
    sku: '',
    name: '',
    description: '',
    type: StockItemTypes.PHYSICAL,
    category: '',
    tags: [],
    imageUrl: null,
    
    // Pricing
    costPrice: 0,
    sellingPrice: 0,
    taxRate: 0,
    
    // Inventory tracking
    trackInventory: true,
    minStockLevel: 0,
    reorderPoint: 0,
    reorderQuantity: 0,
    
    // Digital/service specific
    isDigitalDelivery: false,
    serviceDeliveryMethod: '',
    
    // Locations and quantities
    locations: {
      // locationId: quantity
    },
    
    createdAt: null,
    updatedAt: null
  };