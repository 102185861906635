// src/components/tasks/TaskList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, PlusCircle, ChevronRight, CheckCircle, 
  Calendar, Clock, User, Tag, ChevronDown, ChevronUp, 
  AlertTriangle, FileText, X
} from 'lucide-react';

const TaskList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [expandedTasks, setExpandedTasks] = useState({});

  // Fetch tasks
  useEffect(() => {
    const fetchTasks = async () => {
      setIsLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(r => setTimeout(r, 800));
        
        // Mock task data
        const mockData = [
          {
            id: '1',
            description: 'Finalize Q2 marketing campaign materials',
            category: 'Scheduled',
            assignee: 'Sarah Wilson',
            startDate: '2025-04-01',
            endDate: '2025-04-10',
            status: 'In Progress',
            priority: 'High',
            progress: 60,
            taskRef: {
              type: 'Plan',
              id: '2',
              title: 'Q2 Marketing Campaign'
            },
            objectives: [
              { text: 'Design social media graphics' },
              { text: 'Write email copy' },
              { text: 'Create landing page content' }
            ],
            createdAt: '2025-03-15'
          },
          {
            id: '2',
            description: 'Conduct weekly team meeting',
            category: 'Recurring',
            assignee: 'Alex Johnson',
            startDate: '2025-04-02',
            endDate: '2025-04-02',
            status: 'Not Started',
            priority: 'Medium',
            progress: 0,
            taskRef: {
              type: 'Meeting',
              id: 'm1',
              title: 'Weekly Team Standup'
            },
            objectives: [
              { text: 'Review sprint progress' },
              { text: 'Address blockers' },
              { text: 'Plan next sprint tasks' }
            ],
            createdAt: '2025-03-20'
          },
          {
            id: '3',
            description: 'Prepare monthly financial report',
            category: 'Scheduled',
            assignee: 'David Chen',
            startDate: '2025-04-05',
            endDate: '2025-04-15',
            status: 'Not Started',
            priority: 'Critical',
            progress: 0,
            taskRef: {
              type: 'Project',
              id: 'p5',
              title: 'Financial Planning 2025'
            },
            objectives: [
              { text: 'Collect department expense data' },
              { text: 'Analyze revenue streams' },
              { text: 'Create executive summary' }
            ],
            createdAt: '2025-03-22'
          },
          {
            id: '4',
            description: 'Follow up with client leads from trade show',
            category: 'Ad-hoc',
            assignee: 'Maria Garcia',
            startDate: '2025-03-25',
            endDate: '2025-04-02',
            status: 'Completed',
            priority: 'High',
            progress: 100,
            taskRef: null,
            objectives: [
              { text: 'Email all collected contacts' },
              { text: 'Set up demo meetings with interested prospects' }
            ],
            createdAt: '2025-03-24'
          },
          {
            id: '5',
            description: 'Update product documentation for new features',
            category: 'Scheduled',
            assignee: 'Ahmed Khan',
            startDate: '2025-03-28',
            endDate: '2025-04-15',
            status: 'In Progress',
            priority: 'Medium',
            progress: 35,
            taskRef: {
              type: 'Project',
              id: 'p3',
              title: 'New Product Launch'
            },
            objectives: [
              { text: 'Review feature changes' },
              { text: 'Update user guides' },
              { text: 'Create tutorial videos' }
            ],
            createdAt: '2025-03-25'
          },
          {
            id: '6',
            description: 'Coordinate office supplies inventory check',
            category: 'Ad-hoc',
            assignee: 'Olivia Williams',
            startDate: '2025-04-08',
            endDate: '2025-04-09',
            status: 'Not Started',
            priority: 'Low',
            progress: 0,
            taskRef: null,
            objectives: [
              { text: 'Audit current inventory' },
              { text: 'Create order list for missing items' }
            ],
            createdAt: '2025-03-27'
          }
        ];
        
        setTasks(mockData);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchTasks();
  }, []);

  // Toggle expanded task
  const toggleExpandTask = (taskId) => {
    setExpandedTasks(prev => ({
      ...prev,
      [taskId]: !prev[taskId]
    }));
  };

  // Get filtered tasks
  const getFilteredTasks = () => {
    let filtered = tasks;
    
    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(t => 
        t.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (t.assignee && t.assignee.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (t.taskRef && t.taskRef.title.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    
    // Filter by category
    if (filterCategory !== 'all') {
      filtered = filtered.filter(t => t.category === filterCategory);
    }
    
    // Filter by status
    if (filterStatus !== 'all') {
      filtered = filtered.filter(t => t.status === filterStatus);
    }
    
    return filtered;
  };

  // Get status style
  const getStatusStyle = (status) => {
    switch (status) {
      case 'In Progress':
        return 'bg-blue-100 text-blue-700';
      case 'Not Started':
        return 'bg-gray-100 text-gray-700';
      case 'Completed':
        return 'bg-green-100 text-green-700';
      case 'On Hold':
        return 'bg-amber-100 text-amber-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  };
  
  // Get priority style
  const getPriorityStyle = (priority) => {
    switch (priority) {
      case 'Low':
        return 'bg-green-100 text-green-700';
      case 'Medium':
        return 'bg-blue-100 text-blue-700';
      case 'High':
        return 'bg-orange-100 text-orange-700';
      case 'Critical':
        return 'bg-red-100 text-red-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  };
  
  // Get progress bar style
  const getProgressBarStyle = (progress) => {
    if (progress >= 100) return 'bg-green-500';
    if (progress >= 70) return 'bg-emerald-500';
    if (progress >= 40) return 'bg-blue-500';
    if (progress >= 20) return 'bg-yellow-500';
    return 'bg-orange-500';
  };

  // Get unique categories from tasks
  const categories = ['all', ...new Set(tasks.map(task => task.category))];
  
  // Get unique statuses from tasks
  const statuses = ['all', ...new Set(tasks.map(task => task.status))];

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return 'Not set';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div>
      {/* Search and Actions Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search tasks by description, assignee, or reference..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3 flex-wrap md:flex-nowrap">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
          >
            <option value="all">All Categories</option>
            {categories.filter(c => c !== 'all').map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
          
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Statuses</option>
            {statuses.filter(s => s !== 'all').map(status => (
              <option key={status} value={status}>{status}</option>
            ))}
          </select>
          
          <Link
            to="/tasks/new"
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5] whitespace-nowrap"
          >
            <PlusCircle size={18} className="mr-2" />
            Add Task
          </Link>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {tasks.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <CheckCircle size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No tasks found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You haven't added any tasks yet. Click the 'Add Task' button to get started.
              </p>
              <Link
                to="/tasks/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                <PlusCircle size={16} className="mr-2" />
                Create Your First Task
              </Link>
            </div>
          ) : getFilteredTasks().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <CheckCircle size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching tasks</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any tasks matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterCategory('all'); setFilterStatus('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Task List */
            <div className="space-y-4">
              {getFilteredTasks().map(task => (
                <div 
                  key={task.id} 
                  className="bg-white rounded-xl border border-gray-200 shadow-sm hover:shadow-md transition-shadow overflow-hidden"
                >
                  <div className="p-4">
                    <div className="flex flex-col md:flex-row md:items-start md:justify-between gap-4 mb-3">
                      <div className="flex items-start">
                        <div className="w-10 h-10 rounded-lg bg-indigo-100 flex items-center justify-center text-[#190192] mr-3 flex-shrink-0">
                          <CheckCircle size={20} />
                        </div>
                        <div>
                          <h3 className="font-medium text-gray-900 mb-1">{task.description}</h3>
                          <div className="flex flex-wrap gap-2">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(task.status)}`}>
                              {task.status}
                            </span>
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getPriorityStyle(task.priority)}`}>
                              {task.priority} Priority
                            </span>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                              {task.category}
                            </span>
                          </div>
                        </div>
                      </div>
                      
                      <div className="flex items-center space-x-2">
                        <Link
                          to={`/tasks/${task.id}`}
                          className="px-3 py-1.5 text-sm text-[#190192] bg-indigo-50 rounded-lg hover:bg-indigo-100"
                        >
                          View
                        </Link>
                        <Link
                          to={`/tasks/edit/${task.id}`}
                          className="px-3 py-1.5 text-sm text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200"
                        >
                          Edit
                        </Link>
                        <button 
                          onClick={() => toggleExpandTask(task.id)} 
                          className="p-1.5 rounded-lg text-gray-500 hover:bg-gray-100"
                        >
                          {expandedTasks[task.id] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </button>
                      </div>
                    </div>
                    
                    {/* Progress Bar */}
                    <div className="mb-4">
                      <div className="flex items-center justify-between mb-1">
                        <span className="text-xs font-medium text-gray-500">Progress</span>
                        <span className="text-xs font-medium text-gray-500">{task.progress}%</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div 
                          className={`${getProgressBarStyle(task.progress)} h-2 rounded-full`} 
                          style={{ width: `${task.progress}%` }}
                        ></div>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-sm text-gray-600">
                      <div className="flex items-center">
                        <Calendar size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                        <span>
                          {formatDate(task.startDate)} - {formatDate(task.endDate)}
                        </span>
                      </div>
                      
                      {task.assignee && (
                        <div className="flex items-center">
                          <User size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                          <span>{task.assignee}</span>
                        </div>
                      )}
                      
                      {task.taskRef && (
                        <div className="flex items-center">
                          <FileText size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                          <span>{task.taskRef.type}: {task.taskRef.title}</span>
                        </div>
                      )}
                    </div>
                    
                    {/* Expanded View */}
                    {expandedTasks[task.id] && (
                      <div className="mt-4 pt-4 border-t border-gray-100">
                        {task.objectives && task.objectives.length > 0 && (
                          <div className="mb-4">
                            <h4 className="text-sm font-medium text-gray-700 mb-2">Objectives</h4>
                            <ul className="pl-6 list-disc text-sm text-gray-600 space-y-1">
                              {task.objectives.map((obj, index) => (
                                <li key={index}>{obj.text}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        
                        <div className="flex items-center text-xs text-gray-500">
                          <Clock size={14} className="mr-1" />
                          <span>Created on {formatDate(task.createdAt)}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TaskList;