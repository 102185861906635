// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBFU1ieVKAv9cd3vbS-zf3kKLEg2n2R32M",
  authDomain: "sawubona-project.firebaseapp.com",
  projectId: "sawubona-project",
  storageBucket: "sawubona-project.appspot.com",
  messagingSenderId: "646776098773",
  appId: "1:646776098773:web:5d190c63aac996cf38f75c"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };

