// src/components/Plans/PlanForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Save, ChevronLeft, Plus, X, Calendar, 
  Target, Clock, User, Briefcase, FileText,
  DollarSign, Users, CheckCircle, AlertCircle,
  Tag, Check
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';

const PlanForm = ({ isEditing = false }) => {
  const { PlanId } = useParams();
  const navigate = useNavigate();
  const { isDemoMode } = useAuth();

  // Form state
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: '',
    objectives: [{ text: '' }],
    policyRef: '',
    startDate: '',
    endDate: '',
    PlanLead: '',
    department: '',
    budget: '',
    priority: 'Medium',
    kpis: [{ text: '' }]
  });

  // State for form validation errors
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  // Categories for dropdown
  const categories = [
    'Sales', 
    'Marketing', 
    'Operations', 
    'Product Development', 
    'Finance', 
    'HR',
    'IT',
    'Customer Service'
  ];
  
  // Mock data for departments and business policies
  const departmentOptions = [
    'Executive Team', 
    'Sales Team', 
    'Marketing Department', 
    'Operations',
    'Product Development',
    'Customer Service',
    'Human Resources',
    'Finance & Accounting',
    'Information Technology'
  ];
  
  const policyOptions = [
    'Expense Policy',
    'Procurement Policy',
    'Remote Work Policy',
    'Security Protocol',
    'Quality Standards',
    'Client Engagement Protocol',
    'Compliance Guidelines',
    'Data Protection Policy'
  ];
  
  // Priority options
  const priorityOptions = ['Low', 'Medium', 'High', 'Critical'];
  
  // Load existing Plan data if editing
  useEffect(() => {
    if (isEditing && PlanId) {
      setIsLoading(true);
      // In a real app, fetch the Plan data from API
      // For now we'll just simulate it with a timeout
      const timer = setTimeout(() => {
        // This would be your API data in a real app
        setFormData({
          title: 'Q2 Marketing Campaign',
          description: 'Comprehensive marketing campaign for our new product line launching in Q2',
          category: 'Marketing',
          objectives: [
            { text: 'Increase brand awareness by 15%' },
            { text: 'Generate 2,000 qualified leads' },
            { text: 'Achieve 5% conversion rate on landing page' }
          ],
          policyRef: 'Client Engagement Protocol',
          startDate: '2025-04-01',
          endDate: '2025-06-30',
          PlanLead: 'Sarah Johnson',
          department: 'Marketing Department',
          budget: '25000',
          priority: 'High',
          kpis: [
            { text: '15% increase in website traffic' },
            { text: '2,000 new leads generated' },
            { text: '250 sales attributable to campaign' }
          ]
        });
        setIsLoading(false);
      }, 800);
      
      return () => clearTimeout(timer);
    }
  }, [isEditing, PlanId]);

  // Handle text input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  // Handle objective actions
  const addObjective = () => {
    setFormData(prev => ({
      ...prev,
      objectives: [...prev.objectives, { text: '' }]
    }));
  };
  
  const updateObjective = (index, value) => {
    const updatedObjectives = [...formData.objectives];
    updatedObjectives[index] = { text: value };
    setFormData(prev => ({
      ...prev,
      objectives: updatedObjectives
    }));
    
    // Clear error
    if (formErrors.objectives) {
      setFormErrors(prev => ({
        ...prev,
        objectives: ''
      }));
    }
  };
  
  const removeObjective = (index) => {
    if (formData.objectives.length > 1) {
      const updatedObjectives = [...formData.objectives];
      updatedObjectives.splice(index, 1);
      setFormData(prev => ({
        ...prev,
        objectives: updatedObjectives
      }));
    }
  };
  
  // Handle KPI actions
  const addKPI = () => {
    setFormData(prev => ({
      ...prev,
      kpis: [...prev.kpis, { text: '' }]
    }));
  };
  
  const updateKPI = (index, value) => {
    const updatedKPIs = [...formData.kpis];
    updatedKPIs[index] = { text: value };
    setFormData(prev => ({
      ...prev,
      kpis: updatedKPIs
    }));
  };
  
  const removeKPI = (index) => {
    if (formData.kpis.length > 1) {
      const updatedKPIs = [...formData.kpis];
      updatedKPIs.splice(index, 1);
      setFormData(prev => ({
        ...prev,
        kpis: updatedKPIs
      }));
    }
  };

  // Handle radio button selection (priority)
  const handleRadioChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Validate the form
  const validateForm = () => {
    const errors = {};
    
    // Required fields
    if (!formData.title.trim()) errors.title = 'Plan title is required';
    if (!formData.category) errors.category = 'Plan category is required';
    
    // Validate objectives
    const validObjectives = formData.objectives.filter(obj => obj.text.trim());
    if (validObjectives.length === 0) {
      errors.objectives = 'At least one objective is required';
    }
    
    // Validate dates if both are provided
    if (formData.startDate && formData.endDate) {
      const start = new Date(formData.startDate);
      const end = new Date(formData.endDate);
      
      if (start > end) {
        errors.endDate = 'End date must be after start date';
      }
    }
    
    // Validate budget if provided
    if (formData.budget && isNaN(parseFloat(formData.budget))) {
      errors.budget = 'Budget must be a valid number';
    }
    
    return errors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isDemoMode) {
      alert("Demo Mode Restriction:\nIn demo mode, data modifications are not saved. Sign up for a free account to use all features.");
      return;
    }
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      // Scroll to first error
      const firstError = document.querySelector('.text-red-600');
      if (firstError) {
        firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
    
    setIsLoading(true);
    
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Prepare cleaned data
      const PlanData = {
        ...formData,
        objectives: formData.objectives.filter(obj => obj.text.trim()),
        kpis: formData.kpis.filter(kpi => kpi.text.trim()),
        budget: formData.budget ? parseFloat(formData.budget) : null,
        status: isEditing ? 'Updated' : 'Planning',
      };
      
      console.log('Submitting Plan data:', PlanData);
      
      // In a real app, you'd post to an API here
      
      // Navigate back to the main Plans page
      navigate('/Plans');
    } catch (error) {
      console.error('Error saving Plan:', error);
      // Handle API error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-xs shadow-md overflow-hidden">
      <div className="bg-white shadow-md px-6 py-4 text-gray-800">
        <h2 className="text-lg font-medium">
          {isEditing ? 'Edit Plan' : 'Create New Plan'}
        </h2>
        <p className="text-purple-500 text-sm">
          {isEditing 
            ? 'Update Plan information in the form below' 
            : 'Fill out the form below to create a new Plan'}
        </p>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        <div className="mb-6">
          <button 
            type="button"
            onClick={() => navigate('/Plans')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Plans
          </button>
        </div>
        
        {/* Basic Information */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
          
          {/* Plan Title */}
          <div className="mb-4">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
              Plan Title <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Briefcase size={18} className="text-gray-400" />
              </div>
              <input
                id="title"
                name="title"
                type="text"
                value={formData.title}
                onChange={handleInputChange}
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.title ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                placeholder="Enter Plan title"
              />
            </div>
            {formErrors.title && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.title}
              </p>
            )}
          </div>
          
          {/* Category & Priority */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            {/* Category */}
            <div>
              <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                Category <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Tag size={18} className="text-gray-400" />
                </div>
                <select
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.category ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                >
                  <option value="">Select a category</option>
                  {categories.map((cat) => (
                    <option key={cat} value={cat}>{cat}</option>
                  ))}
                </select>
              </div>
              {formErrors.category && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.category}
                </p>
              )}
            </div>
            
            {/* Budget */}
            <div>
              <label htmlFor="budget" className="block text-sm font-medium text-gray-700 mb-1">
                Budget
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <DollarSign size={18} className="text-gray-400" />
                </div>
                <input
                  id="budget"
                  name="budget"
                  type="text"
                  value={formData.budget}
                  onChange={handleInputChange}
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.budget ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="Enter Plan budget"
                />
              </div>
              {formErrors.budget && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.budget}
                </p>
              )}
            </div>
          </div>
          
          {/* Description */}
          <div className="mb-4">
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              rows="3"
              value={formData.description}
              onChange={handleInputChange}
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              placeholder="Describe the Plan purpose and scope"
            />
          </div>
          
          {/* Priority */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Priority
            </label>
            <div className="flex flex-wrap gap-3">
              {priorityOptions.map((priority) => (
                <div 
                  key={priority}
                  onClick={() => handleRadioChange('priority', priority)}
                  className={`border rounded-lg p-3 cursor-pointer transition-colors ${
                    formData.priority === priority 
                      ? 'bg-purple-50 border-[#190192] text-[#190192]' 
                      : 'border-gray-200 hover:border-[#190192] hover:bg-purple-50'
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <span className="font-medium">{priority}</span>
                    {formData.priority === priority && (
                      <Check size={16} className="text-[#190192] ml-2" />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {/* Objectives */}
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Objectives</h3>
            <button
              type="button"
              className="text-[#190192] hover:text-[#2C0DB5] text-sm flex items-center"
              onClick={addObjective}
            >
              <Plus size={14} className="mr-1" />
              Add Objective
            </button>
          </div>
          
          <div className="space-y-4">
            {formData.objectives.map((objective, index) => (
              <div key={index} className="flex items-center">
                <div className="flex-shrink-0 mr-2">
                  <div className="w-6 h-6 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 text-xs font-medium">
                    {index + 1}
                  </div>
                </div>
                <input
                  type="text"
                  className={`flex-grow px-3 py-2 border ${
                    formErrors.objectives ? 'border-red-300' : 'border-gray-300'
                  } rounded-md focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  value={objective.text}
                  onChange={(e) => updateObjective(index, e.target.value)}
                  placeholder="Enter Plan objective..."
                />
                <button
                  type="button"
                  className="ml-2 text-gray-400 hover:text-red-500"
                  onClick={() => removeObjective(index)}
                  disabled={formData.objectives.length <= 1}
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
          {formErrors.objectives && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.objectives}
            </p>
          )}
        </div>
        
        {/* Timeframe */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Plan Timeline</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Calendar size={18} className="text-gray-400" />
                </div>
                <input
                  id="startDate"
                  name="startDate"
                  type="date"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                />
              </div>
            </div>
            
            <div>
              <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                End Date
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Calendar size={18} className="text-gray-400" />
                </div>
                <input
                  id="endDate"
                  name="endDate"
                  type="date"
                  value={formData.endDate}
                  onChange={handleInputChange}
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.endDate ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                />
              </div>
              {formErrors.endDate && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.endDate}
                </p>
              )}
            </div>
          </div>
        </div>
        
        {/* Team & Organization */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Team & Organization</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            {/* Plan Lead */}
            <div>
              <label htmlFor="PlanLead" className="block text-sm font-medium text-gray-700 mb-1">
                Plan Lead
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User size={18} className="text-gray-400" />
                </div>
                <input
                  id="PlanLead"
                  name="PlanLead"
                  type="text"
                  value={formData.PlanLead}
                  onChange={handleInputChange}
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  placeholder="Person responsible for this Plan"
                />
              </div>
            </div>
            
            {/* Department */}
            <div>
              <label htmlFor="department" className="block text-sm font-medium text-gray-700 mb-1">
                Department
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Users size={18} className="text-gray-400" />
                </div>
                <select
                  id="department"
                  name="department"
                  value={formData.department}
                  onChange={handleInputChange}
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                >
                  <option value="">Select a department</option>
                  {departmentOptions.map((dept) => (
                    <option key={dept} value={dept}>{dept}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          
          {/* Policy Reference */}
          <div>
            <label htmlFor="policyRef" className="block text-sm font-medium text-gray-700 mb-1">
              Related Business Policy
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FileText size={18} className="text-gray-400" />
              </div>
              <select
                id="policyRef"
                name="policyRef"
                value={formData.policyRef}
                onChange={handleInputChange}
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              >
                <option value="">Select a policy</option>
                {policyOptions.map((policy) => (
                  <option key={policy} value={policy}>{policy}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        
        {/* KPIs / Measurable Outcomes */}
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Key Performance Indicators (KPIs)</h3>
            <button
              type="button"
              className="text-[#190192] hover:text-[#2C0DB5] text-sm flex items-center"
              onClick={addKPI}
            >
              <Plus size={14} className="mr-1" />
              Add KPI
            </button>
          </div>
          
          <div className="space-y-4">
            {formData.kpis.map((kpi, index) => (
              <div key={index} className="flex items-center">
                <div className="flex-shrink-0 mr-2">
                  <div className="w-6 h-6 bg-green-100 rounded-full flex items-center justify-center text-green-600 text-xs font-medium">
                    {index + 1}
                  </div>
                </div>
                <input
                  type="text"
                  className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={kpi.text}
                  onChange={(e) => updateKPI(index, e.target.value)}
                  placeholder="Enter a measurable outcome or KPI..."
                />
                <button
                  type="button"
                  className="ml-2 text-gray-400 hover:text-red-500"
                  onClick={() => removeKPI(index)}
                  disabled={formData.kpis.length <= 1}
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-4 mt-10 pt-6 border-t border-gray-200">
          <button
            type="button"
            onClick={() => navigate('/Plans')}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
          >
            Cancel
          </button>
          
          <button
            type="submit"
            disabled={isLoading}
            className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
              isLoading ? 'bg-purple-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
            }`}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <Check size={18} className="mr-2" />
                {isEditing ? 'Update Plan' : 'Create Plan'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PlanForm;