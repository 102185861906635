// src/components/business/BusinessDetail.js
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  Building, Building2, MapPin, Globe, Phone, Mail, Users, ChevronLeft, Edit, Download,
  Trash2, Facebook, Youtube, Linkedin, Instagram, Twitter, ExternalLink, DollarSign,
  PlusCircle, ChevronRight, Clock, Briefcase, ArrowLeft, ArrowRight, Map, X, AlertCircle
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { doc, getDoc, deleteDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';

const BusinessDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [business, setBusiness] = useState(null);
  const [parentBusiness, setParentBusiness] = useState(null);
  const [childBusinesses, setChildBusinesses] = useState([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Fetch business and related data
  useEffect(() => {
    const fetchBusinessDetails = async () => {
      if (!currentUser) return;
      
      setLoading(true);
      setError(null);
      
      try {
        // Fetch the business document
        const businessRef = doc(db, 'businesses', id);
        const businessDoc = await getDoc(businessRef);
        
        if (businessDoc.exists()) {
          const businessData = {
            id: businessDoc.id,
            ...businessDoc.data()
          };
          
          setBusiness(businessData);
          
          // If this business has a parent, fetch the parent business
          if (businessData.parentBusinessId) {
            try {
              const parentRef = doc(db, 'businesses', businessData.parentBusinessId);
              const parentDoc = await getDoc(parentRef);
              
              if (parentDoc.exists()) {
                setParentBusiness({
                  id: parentDoc.id,
                  ...parentDoc.data()
                });
              }
            } catch (error) {
              console.error('Error fetching parent business:', error);
              // Don't fail the whole operation if we can't fetch the parent
            }
          }
          
          // Fetch child businesses
          try {
            const childrenQuery = query(
              collection(db, 'businesses'),
              where('parentBusinessId', '==', id)
            );
            
            const childrenSnapshot = await getDocs(childrenQuery);
            const childrenData = [];
            
            childrenSnapshot.forEach(doc => {
              childrenData.push({
                id: doc.id,
                ...doc.data()
              });
            });
            
            setChildBusinesses(childrenData);
          } catch (error) {
            console.error('Error fetching child businesses:', error);
            // Don't fail the whole operation if we can't fetch the children
          }
        } else {
          setError('Business not found');
        }
      } catch (error) {
        console.error('Error fetching business details:', error);
        setError('Failed to load business details. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessDetails();
  }, [id, currentUser]);

  // Handle delete
  const handleDelete = async () => {
    try {
      setLoading(true);
      
      // Check for child businesses before deleting
      if (childBusinesses.length > 0) {
        // You might want to either block deletion or cascade delete
        // For simplicity, we'll show a different alert message
        alert('Please delete all sub-entities before deleting this business.');
        setDeleteModalOpen(false);
        setLoading(false);
        return;
      }
      
      // Delete the business document
      await deleteDoc(doc(db, 'businesses', id));
      
      // Navigate back to the business list
      navigate('/business');
    } catch (error) {
      console.error('Error deleting business:', error);
      alert('Failed to delete business. Please try again.');
    } finally {
      setLoading(false);
      setDeleteModalOpen(false);
    }
  };

  // Get social icon component by name
  const getSocialIcon = (name) => {
    switch (name) {
      case 'facebook': return Facebook;
      case 'youtube': return Youtube;
      case 'linkedin': return Linkedin;
      case 'instagram': return Instagram;
      case 'twitter': return Twitter;
      default: return ExternalLink;
    }
  };
  
  // Get level icon
  const getLevelIcon = (level) => {
    switch (level) {
      case 'Headquarters': return Building;
      case 'Subsidiary': return Building2;
      case 'Branch': return Map;
      case 'Department': return Briefcase;
      default: return Building;
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (error || !business) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Business not found</h3>
          <p className="text-gray-500 mb-6">The business you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/business')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Businesses
          </button>
        </div>
      </div>
    );
  }

  // Determine level style
  const getLevelStyle = (level) => {
    switch (level) {
      case 'Headquarters':
        return 'bg-purple-100 text-[#190192]';
      case 'Subsidiary':
        return 'bg-blue-100 text-blue-700';
      case 'Branch':
        return 'bg-green-100 text-green-700';
      case 'Department':
        return 'bg-amber-100 text-amber-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  };
  
  // Icon component for the level
  const LevelIcon = getLevelIcon(business.level);

  // Format date
  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    try {
      // Convert Firestore timestamp to JS Date
      const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'N/A';
    }
  };

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/business"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Businesses
          </Link>
          
          <div className="flex space-x-3">
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
              <Download size={20} />
            </button>
            <Link
              to={`/business/edit/${id}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Business Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className={`w-16 h-16 rounded-lg flex items-center justify-center flex-shrink-0 ${getLevelStyle(business.level)}`}>
              {business.businessLogoUrl ? (
                <img src={business.businessLogoUrl} alt={business.name} className="w-full h-full object-contain rounded-lg" />
              ) : (
                <LevelIcon size={32} />
              )}
            </div>
            
            <div>
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">{business.name}</h1>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getLevelStyle(business.level)}`}>
                  {business.level}
                </span>
              </div>
              
              <p className="text-gray-600">{business.industry} • {business.category} • {business.type}</p>
              
              {/* Parent Link (if not headquarters) */}
              {business.level !== 'Headquarters' && parentBusiness && (
                <div className="mt-2 flex items-center text-[#190192]">
                  <ArrowLeft size={14} className="mr-1" />
                  <Link 
                    to={`/business/${parentBusiness.id}`}
                    className="text-sm hover:underline"
                  >
                    Part of {parentBusiness.name}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="flex px-6 -mb-px">
            <button
              onClick={() => setActiveTab('overview')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'overview'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('subentities')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'subentities'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Sub-entities ({childBusinesses.length})
            </button>
          </nav>
        </div>
        
        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'overview' ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left Column: Basic Info */}
              <div className="lg:col-span-2 space-y-8">
                {/* Contact Information */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Contact Information</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {business.tel && (
                      <div className="flex items-start">
                        <Phone size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Telephone</p>
                          <p className="text-gray-900">{business.tel}</p>
                        </div>
                      </div>
                    )}
                    
                    {business.mobile && (
                      <div className="flex items-start">
                        <Phone size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Mobile</p>
                          <p className="text-gray-900">{business.mobile}</p>
                        </div>
                      </div>
                    )}
                    
                    {business.email && (
                      <div className="flex items-start">
                        <Mail size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Email</p>
                          <a href={`mailto:${business.email}`} className="text-[#190192] hover:underline">
                            {business.email}
                          </a>
                        </div>
                      </div>
                    )}
                    
                    {business.website && (
                      <div className="flex items-start">
                        <Globe size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Website</p>
                          <a 
                            href={business.website.startsWith('http') ? business.website : `https://${business.website}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-[#190192] hover:underline flex items-center"
                          >
                            {business.website} <ExternalLink size={12} className="ml-1" />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  
                  {business.address && (
                    <div className="mt-4 flex items-start">
                      <MapPin size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Address</p>
                        <p className="text-gray-900">{business.address}</p>
                        <p className="text-gray-900">{business.country}</p>
                      </div>
                    </div>
                  )}
                  
                  {business.currency && (
                    <div className="mt-4 flex items-start">
                      <DollarSign size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Currency</p>
                        <p className="text-gray-900">{business.currency}</p>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* Social Media Links */}
                {business.socialLinks && business.socialLinks.length > 0 && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Social Media</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {business.socialLinks.map(platform => {
                        const url = business.socialLinkUrls?.[platform];
                        if (!url) return null;
                        
                        const SocialIcon = getSocialIcon(platform);
                        return (
                          <div key={platform} className="flex items-start">
                            <div className={`w-8 h-8 rounded-full flex items-center justify-center mr-3 flex-shrink-0 
                              ${platform === 'facebook' ? 'bg-blue-100 text-blue-600' : 
                                platform === 'twitter' ? 'bg-sky-100 text-sky-500' :
                                platform === 'linkedin' ? 'bg-blue-100 text-blue-700' :
                                platform === 'youtube' ? 'bg-red-100 text-red-600' :
                                platform === 'instagram' ? 'bg-pink-100 text-pink-600' : 
                                'bg-gray-100 text-gray-600'
                              }`}
                            >
                              <SocialIcon size={16} />
                            </div>
                            <div>
                              <p className="text-sm font-medium text-gray-500 capitalize">{platform}</p>
                              <a 
                                href={url.startsWith('http') ? url : `https://${url}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="text-[#190192] hover:underline flex items-center"
                              >
                                {url} <ExternalLink size={12} className="ml-1" />
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                
                {/* Branding Materials */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Branding Materials</h3>
                  {(!business.letterhead && !business.emailHeader && !business.emailSignature) ? (
                    <p className="text-gray-500 italic">No branding materials have been uploaded yet.</p>
                  ) : (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      {/* Would display images here if available */}
                      {business.letterhead && (
                        <div className="border rounded-lg p-4">
                          <p className="text-sm font-medium text-gray-900 mb-2">Letterhead</p>
                          <div className="h-32 bg-gray-100 rounded flex items-center justify-center">
                            <img src={business.letterhead} alt="Letterhead" className="max-h-full max-w-full" />
                          </div>
                        </div>
                      )}
                      
                      {business.emailHeader && (
                        <div className="border rounded-lg p-4">
                          <p className="text-sm font-medium text-gray-900 mb-2">Email Header</p>
                          <div className="h-32 bg-gray-100 rounded flex items-center justify-center">
                            <img src={business.emailHeader} alt="Email Header" className="max-h-full max-w-full" />
                          </div>
                        </div>
                      )}
                      
                      {business.emailSignature && (
                        <div className="border rounded-lg p-4">
                          <p className="text-sm font-medium text-gray-900 mb-2">Email Signature</p>
                          <div className="h-32 bg-gray-100 rounded flex items-center justify-center">
                            <img src={business.emailSignature} alt="Email Signature" className="max-h-full max-w-full" />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              
              {/* Right Column: Sidebar */}
              <div className="lg:col-span-1">
                {/* Sub-entities Summary */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Sub-entities</h3>
                  
                  {childBusinesses.length === 0 ? (
                    <p className="text-gray-500 italic">No sub-entities available.</p>
                  ) : (
                    <>
                      <div className="space-y-1 mb-3">
                        {childBusinesses.slice(0, 3).map(entity => (
                          <Link
                            key={entity.id}
                            to={`/business/${entity.id}`}
                            className="block px-3 py-2 hover:bg-gray-100 rounded-lg"
                          >
                            <div className="flex items-center">
                              <div className={`w-6 h-6 rounded-md flex items-center justify-center mr-2 ${getLevelStyle(entity.level)}`}>
                                {entity.logoUrl ? (
                                  <img src={entity.logoUrl} alt="" className="w-full h-full object-contain rounded-md" />
                                ) : (
                                  React.createElement(getLevelIcon(entity.level), { size: 14 })
                                )}
                              </div>
                              <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900 truncate">{entity.name}</p>
                                <p className="text-xs text-gray-500 truncate">{entity.level}</p>
                              </div>
                              <ChevronRight size={16} className="text-gray-400" />
                            </div>
                          </Link>
                        ))}
                      </div>
                      
                      {childBusinesses.length > 3 && (
                        <button
                          onClick={() => setActiveTab('subentities')}
                          className="text-sm text-[#190192] hover:text-[#2C0DB5] font-medium flex items-center"
                        >
                          View all {childBusinesses.length} sub-entities <ChevronRight size={14} className="ml-1" />
                        </button>
                      )}
                    </>
                  )}
                  
                  <Link
                    to={`/business/new?parentId=${id}`}
                    className="mt-4 w-full py-2 border border-gray-300 text-[#190192] rounded-lg hover:bg-purple-50 flex items-center justify-center font-medium"
                  >
                    <PlusCircle size={16} className="mr-2" />
                    Add Sub-entity
                  </Link>
                </div>
                
                {/* Meta Information */}
                <div className="bg-gray-50 rounded-xl p-5">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Information</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Created</span>
                      <span className="text-sm text-gray-900 flex items-center">
                        <Clock size={14} className="mr-1.5" />
                        {formatDate(business.createdAt)}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Last Updated</span>
                      <span className="text-sm text-gray-900 flex items-center">
                        <Clock size={14} className="mr-1.5" />
                        {formatDate(business.updatedAt)}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">ID</span>
                      <span className="text-sm text-gray-900">{business.id}</span>
                    </div>
                    
                    {business.createdBy && (
                      <div className="flex justify-between">
                        <span className="text-sm text-gray-500">Created By</span>
                        <span className="text-sm text-gray-900">{business.createdBy}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            /* Sub-entities Tab */
            <div>
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-xl font-medium text-gray-900">Sub-entities</h3>
                <Link
                  to={`/business/new?parentId=${id}`}
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Add Sub-entity
                </Link>
              </div>
              
              {childBusinesses.length === 0 ? (
                <div className="bg-gray-50 rounded-xl py-12 px-6 text-center">
                  <Building2 size={48} className="mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No sub-entities yet</h3>
                  <p className="text-gray-500 mb-6 max-w-md mx-auto">
                    This business doesn't have any subsidiaries, branches, or departments yet.
                  </p>
                  <Link
                    to={`/business/new?parentId=${id}`}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                  >
                    <PlusCircle size={16} className="mr-2" />
                    Add First Sub-entity
                  </Link>
                </div>
              ) : (
                <div className="space-y-4">
                  {childBusinesses.map(entity => (
                    <div 
                      key={entity.id} 
                      className="bg-white border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-shadow overflow-hidden"
                    >
                      <div className="p-4">
                        <div className="flex items-start">
                          <div className={`w-10 h-10 rounded-lg flex items-center justify-center mr-3 ${getLevelStyle(entity.level)}`}>
                            {entity.logoUrl ? (
                              <img src={entity.logoUrl} alt="" className="w-full h-full object-contain rounded-lg" />
                            ) : (
                              React.createElement(getLevelIcon(entity.level), { size: 20 })
                            )}
                          </div>
                          <div className="flex-1">
                            <div className="flex items-start justify-between">
                              <div>
                                <h3 className="font-medium text-gray-900">{entity.name}</h3>
                                <p className="text-sm text-gray-500">{entity.level} • {entity.industry}</p>
                              </div>
                              <Link
                                to={`/business/${entity.id}`}
                                className="text-[#190192] hover:text-[#2C0DB5] text-sm font-medium flex items-center"
                              >
                                View Details <ChevronRight size={16} className="ml-1" />
                              </Link>
                            </div>
                            
                            <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                              {entity.address && (
                                <div className="flex items-center text-sm text-gray-600">
                                  <MapPin size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                                  <span>{entity.address}</span>
                                </div>
                              )}
                              
                              <div className="flex items-center text-sm text-gray-600">
                                <Users size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                                <span>{entity.employees || 0} employees</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Business</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete <span className="font-medium">{business.name}</span>? This action cannot be undone.
              {childBusinesses.length > 0 && (
                <span className="block mt-2 text-red-600 font-medium">
                  Warning: This business has {childBusinesses.length} sub-entities that will also be deleted.
                </span>
              )}
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BusinessDetail;