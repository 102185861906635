// src/components/inventory/receiving/ReceivingDetail.js
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Download, X, Truck, Mail, Phone,
  Building, Calendar, Package, CheckCircle, 
  ShoppingBag, FileText, User, ClipboardCheck,
  ExternalLink, AlertCircle, Printer
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { useCurrency } from '../../../utils/currencyUtils';
import { db } from '../../../firebase/config';

const ReceivingDetail = () => {
  const { receivingId } = useParams();
  const navigate = useNavigate();
  const { formatAmount } = useCurrency();
  
  const [loading, setLoading] = useState(true);
  const [receiving, setReceiving] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [purchaseOrder, setPurchaseOrder] = useState(null);

  // Fetch receiving data and related information
  useEffect(() => {
    const fetchReceivingData = async () => {
      setLoading(true);
      
      try {
        // In a real app, these would be API calls
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Mock receiving data
        const mockReceiving = {
          id: receivingId,
          receiptNumber: 'GRN-2025-001',
          poNumber: 'PO-2025-002',
          poId: '2',
          supplierId: '2',
          receiveDate: '2025-03-15',
          status: 'complete',
          receivedBy: 'John Doe',
          notes: 'All items received in good condition',
          items: [
            {
              id: '1',
              sku: 'KM-303',
              name: 'Ergonomic Keyboard',
              description: 'Wireless ergonomic keyboard with wrist rest',
              orderedQuantity: 10,
              receivedQuantity: 10,
              acceptedQuantity: 10,
              damagedQuantity: 0,
              unitPrice: 45.99,
              location: 'main-store'
            },
            {
              id: '2',
              sku: 'PJ-001',
              name: 'Portable Projector',
              description: 'HD Mini Projector with WiFi and Bluetooth',
              orderedQuantity: 5,
              receivedQuantity: 4,
              acceptedQuantity: 4,
              damagedQuantity: 0,
              unitPrice: 349.99,
              location: 'main-store'
            },
            {
              id: '3',
              sku: 'OS-101',
              name: 'Premium Notebook',
              description: 'Leather-bound premium notebook, 200 pages',
              orderedQuantity: 20,
              receivedQuantity: 20,
              acceptedQuantity: 18,
              damagedQuantity: 2,
              unitPrice: 12.50,
              location: 'warehouse'
            }
          ],
          attachments: [
            {
              id: '1',
              name: 'delivery_note.pdf',
              type: 'document',
              url: '#',
              uploadedAt: '2025-03-15'
            },
            {
              id: '2',
              name: 'received_items.jpg',
              type: 'image',
              url: '#',
              uploadedAt: '2025-03-15'
            }
          ],
          createdAt: '2025-03-15',
          updatedAt: '2025-03-15'
        };
        
        // Mock supplier data
        const mockSupplier = {
          id: '2',
          name: 'Tech Components Ltd',
          contactPerson: 'Jane Smith',
          email: 'sales@techcomponents.com',
          phone: '+1 (555) 123-4567',
          address: '456 Tech Park, San Francisco, CA 94107',
          country: 'United States'
        };
        
        // Mock purchase order data
        const mockPurchaseOrder = {
          id: '2',
          poNumber: 'PO-2025-002',
          orderDate: '2025-03-10',
          status: 'received'
        };
        
        setReceiving(mockReceiving);
        setSupplier(mockSupplier);
        setPurchaseOrder(mockPurchaseOrder);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching receiving data:', error);
        setLoading(false);
      }
    };
    
    fetchReceivingData();
  }, [receivingId]);

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Calculate totals
  const calculateTotals = () => {
    if (!receiving) return { ordered: 0, received: 0, accepted: 0, damaged: 0 };
    
    return receiving.items.reduce((totals, item) => {
      return {
        ordered: totals.ordered + item.orderedQuantity,
        received: totals.received + item.receivedQuantity,
        accepted: totals.accepted + item.acceptedQuantity,
        damaged: totals.damaged + item.damagedQuantity
      };
    }, { ordered: 0, received: 0, accepted: 0, damaged: 0 });
  };
  
  // Handle print receiving document
  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!receiving) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Receiving document not found</h3>
          <p className="text-gray-500 mb-6">The receiving document you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/inventory/receiving')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Receiving
          </button>
        </div>
      </div>
    );
  }

  const totals = calculateTotals();

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      {/* Header Actions */}
      <div className="px-6 pt-6 pb-4 flex justify-between items-start">
        <Link
          to="/inventory/receiving"
          className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
        >
          <ChevronLeft size={16} className="mr-1" />
          Back to Receiving
        </Link>
        
        <div className="flex space-x-3">
          <button
            onClick={handlePrint}
            className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 flex items-center"
          >
            <Printer size={16} className="mr-2" />
            Print
          </button>
          <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
            <Download size={20} />
          </button>
        </div>
      </div>
      
      {/* Receiving Header */}
      <div className="px-6 pb-6 border-b border-gray-200">
        <div className="flex flex-col sm:flex-row sm:items-center gap-6">
          <div className="w-16 h-16 rounded-lg bg-green-100 flex items-center justify-center flex-shrink-0 text-green-700">
            <Truck size={32} />
          </div>
          
          <div className="flex-1">
            <div className="flex flex-wrap items-center gap-2 mb-1">
              <h1 className="text-2xl font-bold text-gray-900">{receiving.receiptNumber}</h1>
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                <CheckCircle size={14} className="mr-1" />
                {receiving.status.charAt(0).toUpperCase() + receiving.status.slice(1)}
              </span>
            </div>
            
            <div className="flex flex-wrap gap-4 text-gray-600">
              <div className="flex items-center">
                <ShoppingBag size={16} className="mr-1 text-gray-400" />
                <span>PO: {receiving.poNumber}</span>
              </div>
              <div className="flex items-center">
                <Calendar size={16} className="mr-1 text-gray-400" />
                <span>Received: {formatDate(receiving.receiveDate)}</span>
              </div>
              <div className="flex items-center">
                <Building size={16} className="mr-1 text-gray-400" />
                <span>{supplier?.name}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div className="p-6">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column: Items and Details */}
          <div className="lg:col-span-2 space-y-8">
            {/* Received Items */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Received Items</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 border rounded-lg">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Item
                      </th>
                      <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Ordered
                      </th>
                      <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Received
                      </th>
                      <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Accepted
                      </th>
                      <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Damaged
                      </th>
                      <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Unit Price
                      </th>
                      <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Location
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {receiving.items.map((item, index) => (
                      <tr key={item.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4">
                          <div className="text-sm font-medium text-gray-900">{item.name}</div>
                          <div className="text-xs text-gray-500">SKU: {item.sku}</div>
                          {item.description && (
                            <div className="text-xs text-gray-500 mt-1">{item.description}</div>
                          )}
                        </td>
                        <td className="px-4 py-4 text-sm text-gray-500 text-center">
                          {item.orderedQuantity}
                        </td>
                        <td className="px-4 py-4 text-sm text-center">
                          <span className={`font-medium ${
                            item.receivedQuantity < item.orderedQuantity ? 'text-yellow-600' : 'text-green-600'
                          }`}>
                            {item.receivedQuantity}
                          </span>
                        </td>
                        <td className="px-4 py-4 text-sm text-green-600 text-center font-medium">
                          {item.acceptedQuantity}
                        </td>
                        <td className="px-4 py-4 text-sm text-center">
                          <span className={`font-medium ${
                            item.damagedQuantity > 0 ? 'text-red-600' : 'text-gray-500'
                          }`}>
                            {item.damagedQuantity}
                          </span>
                        </td>
                        <td className="px-4 py-4 text-sm text-gray-900 text-right">
                          {formatAmount(item.unitPrice)}
                        </td>
                        <td className="px-4 py-4 text-sm text-gray-500 text-center">
                          {item.location === 'main-store' ? 'Main Store' : 
                           item.location === 'warehouse' ? 'Warehouse' : 
                           item.location}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="bg-gray-50">
                    <tr>
                      <td className="px-6 py-3 text-right text-sm font-medium text-gray-900" colSpan="1">
                        Totals:
                      </td>
                      <td className="px-4 py-3 text-center text-sm font-medium text-gray-900">
                        {totals.ordered}
                      </td>
                      <td className="px-4 py-3 text-center text-sm font-medium text-green-600">
                        {totals.received}
                      </td>
                      <td className="px-4 py-3 text-center text-sm font-medium text-green-600">
                        {totals.accepted}
                      </td>
                      <td className="px-4 py-3 text-center text-sm font-medium text-red-600">
                        {totals.damaged}
                      </td>
                      <td colSpan="2"></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            
            {/* Notes */}
            {receiving.notes && (
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Notes</h3>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-gray-700">{receiving.notes}</p>
                </div>
              </div>
            )}
            
            {/* Attachments */}
            {receiving.attachments && receiving.attachments.length > 0 && (
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Attachments</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {receiving.attachments.map(attachment => (
                    <div key={attachment.id} className="border rounded-lg overflow-hidden">
                      <div className="p-3 border-b bg-gray-50 flex justify-between items-center">
                        <span className="text-sm font-medium truncate flex-1">{attachment.name}</span>
                        <button className="text-gray-500 hover:text-gray-700 p-1">
                          <Download size={16} />
                        </button>
                      </div>
                      
                      <div className="h-32 bg-gray-100 flex items-center justify-center">
                        {attachment.type === 'image' ? (
                          <img 
                            src={attachment.url} 
                            alt={attachment.name}
                            className="h-full w-full object-contain"
                          />
                        ) : (
                          <FileText size={32} className="text-gray-400" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          
          {/* Right Column: Sidebar */}
          <div className="lg:col-span-1">
            {/* Receipt Information */}
            <div className="bg-gray-50 rounded-xl p-5 mb-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3">Receipt Information</h3>
              
              <div className="space-y-4">
                <div className="flex items-start">
                  <ClipboardCheck size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                  <div>
                    <p className="text-sm font-medium text-gray-500">Receipt Number</p>
                    <p className="text-gray-900">{receiving.receiptNumber}</p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <Calendar size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                  <div>
                    <p className="text-sm font-medium text-gray-500">Receive Date</p>
                    <p className="text-gray-900">{formatDate(receiving.receiveDate)}</p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <User size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                  <div>
                    <p className="text-sm font-medium text-gray-500">Received By</p>
                    <p className="text-gray-900">{receiving.receivedBy}</p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <ShoppingBag size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                  <div>
                    <p className="text-sm font-medium text-gray-500">Purchase Order</p>
                    <Link 
                      to={`/inventory/buying/${receiving.poId}`}
                      className="text-[#190192] hover:underline flex items-center"
                    >
                      {receiving.poNumber}
                      <ExternalLink size={12} className="ml-1" />
                    </Link>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <AlertCircle size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                  <div>
                    <p className="text-sm font-medium text-gray-500">Status</p>
                    <p className="font-medium text-green-600">
                      {receiving.status.charAt(0).toUpperCase() + receiving.status.slice(1)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Supplier Information */}
            <div className="bg-gray-50 rounded-xl p-5 mb-6">
              <h3 className="text-lg font-medium text-gray-900 mb-3">Supplier Information</h3>
              
              {supplier && (
                <div className="space-y-4">
                  <div className="flex items-start">
                    <Building size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                    <div>
                      <p className="text-sm font-medium text-gray-500">Supplier</p>
                      <Link 
                        to={`/inventory/suppliers/${supplier.id}`}
                        className="text-[#190192] hover:underline flex items-center"
                      >
                        {supplier.name}
                        <ExternalLink size={12} className="ml-1" />
                      </Link>
                    </div>
                  </div>
                  
                  {supplier.contactPerson && (
                    <div className="flex items-start">
                      <User size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Contact Person</p>
                        <p className="text-gray-900">{supplier.contactPerson}</p>
                      </div>
                    </div>
                  )}
                  
                  <div className="flex items-start">
                    <Mail size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                    <div>
                      <p className="text-sm font-medium text-gray-500">Email</p>
                      <a href={`mailto:${supplier.email}`} className="text-[#190192] hover:underline">
                        {supplier.email}
                      </a>
                    </div>
                  </div>
                  
                  <div className="flex items-start">
                    <Phone size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                    <div>
                      <p className="text-sm font-medium text-gray-500">Phone</p>
                      <p className="text-gray-900">{supplier.phone}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            {/* Meta Information */}
            <div className="bg-gray-50 rounded-xl p-5">
              <h3 className="text-lg font-medium text-gray-900 mb-3">Information</h3>
              
              <div className="space-y-3">
                <div className="flex justify-between">
                  <span className="text-sm text-gray-500">Created</span>
                  <span className="text-sm text-gray-900">{formatDate(receiving.createdAt)}</span>
                </div>
                
                <div className="flex justify-between">
                  <span className="text-sm text-gray-500">Last Updated</span>
                  <span className="text-sm text-gray-900">{formatDate(receiving.updatedAt)}</span>
                </div>
                
                <div className="flex justify-between">
                  <span className="text-sm text-gray-500">ID</span>
                  <span className="text-sm text-gray-900">{receiving.id}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceivingDetail;