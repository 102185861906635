// src/components/stock/StockItemForm.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  ChevronLeft, Check, AlertCircle, Package, 
  DollarSign, Tag, Image, X, Upload, Truck,
  Ruler, Briefcase, Barcode, QrCode, FileText
} from 'lucide-react';
import { StockItemTypes, StockItemCategories } from '../../../models/StockItem';
import { useAuth } from '../../../context/AuthContext';
import { useCurrency } from '../../../utils/currencyUtils';
import { db, storage } from '../../../firebase/config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const StockItemForm = ({ isEditing = false }) => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const { getCurrencySymbol } = useCurrency();
  const { currentUser, isDemoMode } = useAuth();
  
  // Refs for file inputs
  const imageInputRef = useRef(null);
  const barcodeInputRef = useRef(null);
  
  // Get currency symbol for display
  const currencySymbol = getCurrencySymbol();
  
  // Form state
  const [formData, setFormData] = useState({
    sku: '',
    name: '',
    description: '',
    type: StockItemTypes.PHYSICAL,
    category: '',
    brand: '',
    supplier: '',
    imageUrl: null,
    image: null, // For file upload
    costPrice: '',
    sellingPrice: '',
    taxRate: '0',
    trackInventory: true,
    minStockLevel: '0',
    reorderPoint: '0',
    reorderQuantity: '0',
    packaging: 'units', // New field
    unitType: '', // New field
    size: '', // New field
    barcode: '', // New field
    isDigitalDelivery: false,
    serviceDeliveryMethod: '',
    // Initial location for a new item
    initialLocation: '',
    initialQuantity: '0'
  });

  // Image preview
  const [imagePreview, setImagePreview] = useState(null);
  
  // State for form validation errors
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  
  // Mock locations for the form
  const locations = [
    { id: 'main-store', name: 'Main Store' },
    { id: 'warehouse', name: 'Warehouse' }
  ];
  
  // Mock suppliers for the form
  const suppliers = [
    { id: 'supplier1', name: 'Global Supplies Inc.' },
    { id: 'supplier2', name: 'Best Wholesale Ltd.' },
    { id: 'supplier3', name: 'Quality Products Co.' }
  ];
  
  // Packaging options
  const packagingOptions = [
    { value: 'units', label: 'Units' },
    { value: 'packs', label: 'Packs' },
    { value: 'boxes', label: 'Boxes' },
    { value: 'pallets', label: 'Pallets' },
    { value: 'cases', label: 'Cases' },
    { value: 'bundles', label: 'Bundles' }
  ];
  
  // Unit type options
  const unitTypeOptions = [
    { value: '', label: 'None' },
    { value: 'kg', label: 'Kilograms (kg)' },
    { value: 'g', label: 'Grams (g)' },
    { value: 'lb', label: 'Pounds (lb)' },
    { value: 'oz', label: 'Ounces (oz)' },
    { value: 'l', label: 'Liters (l)' },
    { value: 'ml', label: 'Milliliters (ml)' },
    { value: 'm', label: 'Meters (m)' },
    { value: 'cm', label: 'Centimeters (cm)' },
    { value: 'ton', label: 'Tons' }
  ];

  // Generate SKU function
  const generateSKU = () => {
    // Get first 2-3 characters of category or product name
    const prefix = formData.category 
      ? formData.category.substring(0, 2).toUpperCase() 
      : formData.name.substring(0, 2).toUpperCase();
    
    // Generate random 4-digit number
    const randomNum = Math.floor(1000 + Math.random() * 9000);
    
    // Combine with current date for uniqueness
    const timestamp = new Date().getTime().toString().substring(10, 13);
    
    const newSKU = `${prefix}-${randomNum}${timestamp}`;
    
    setFormData(prev => ({
      ...prev,
      sku: newSKU
    }));
  };

  // Fetch item data if editing
  useEffect(() => {
    if (isEditing && itemId) {
      setIsLoading(true);
      
      // In a real app, this would be an API call
      setTimeout(() => {
        // Mock item data
        const mockItem = {
          id: itemId,
          sku: 'TS-001',
          name: 'T-Shirt Large',
          description: 'High-quality cotton t-shirt, large size. Available in black color.',
          type: 'physical',
          category: 'Apparel',
          brand: 'Fashion Brand',
          supplier: 'supplier1',
          imageUrl: null,
          costPrice: 8.50,
          sellingPrice: 19.99,
          taxRate: 0.0725,
          trackInventory: true,
          minStockLevel: 10,
          reorderPoint: 15,
          reorderQuantity: 50,
          packaging: 'units',
          unitType: '',
          size: 'Large',
          barcode: '9876543210123'
        };
        
        setFormData({
          ...mockItem,
          costPrice: mockItem.costPrice.toString(),
          sellingPrice: mockItem.sellingPrice.toString(),
          taxRate: (mockItem.taxRate * 100).toString(),
          minStockLevel: mockItem.minStockLevel.toString(),
          reorderPoint: mockItem.reorderPoint.toString(),
          reorderQuantity: mockItem.reorderQuantity.toString(),
          initialLocation: '',
          initialQuantity: '0'
        });
        
        // Set image preview if URL exists
        if (mockItem.imageUrl) {
          setImagePreview(mockItem.imageUrl);
        }
        
        setIsLoading(false);
      }, 800);
    }
  }, [isEditing, itemId]);

  // Handle text input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Use the checked value for checkboxes
    const newValue = type === 'checkbox' ? checked : value;
    
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Handle file upload for product image
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Validate file size and type
    if (file.size > 5 * 1024 * 1024) {
      setFormErrors(prev => ({
        ...prev,
        image: 'Image size should be less than 5MB'
      }));
      return;
    }
    
    if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
      setFormErrors(prev => ({
        ...prev,
        image: 'Only JPG, JPEG, and PNG formats are supported'
      }));
      return;
    }
    
    // Update form data
    setFormData(prev => ({
      ...prev,
      image: file
    }));
    
    // Create preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
    
    // Clear error
    if (formErrors.image) {
      setFormErrors(prev => ({
        ...prev,
        image: ''
      }));
    }
  };
  
  // Remove image
  const handleRemoveImage = () => {
    setFormData(prev => ({
      ...prev,
      image: null
    }));
    setImagePreview(null);
    
    if (imageInputRef.current) {
      imageInputRef.current.value = '';
    }
  };
  
  // Handle barcode scan (this would integrate with a barcode scanner in a real app)
  const handleBarcodeScan = () => {
    // Simulate a barcode scan with a randomly generated number
    const randomBarcode = Math.floor(Math.random() * 9000000000000) + 1000000000000;
    setFormData(prev => ({
      ...prev,
      barcode: randomBarcode.toString()
    }));
  };
  
  // Upload file to Firebase Storage
  const uploadFileToStorage = async (file) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `stockItems/${currentUser.uid}/${Date.now()}_${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      uploadTask.on('state_changed', 
        (snapshot) => {
          // Track upload progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error('Upload error:', error);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };
  
  // Validate the form
  const validateForm = () => {
    const errors = {};
    
    // Required fields
    if (!formData.sku.trim()) errors.sku = 'SKU is required';
    if (!formData.name.trim()) errors.name = 'Item name is required';
    if (!formData.category) errors.category = 'Category is required';
    
    // Numeric fields
    if (formData.costPrice && isNaN(parseFloat(formData.costPrice))) {
      errors.costPrice = 'Cost price must be a valid number';
    }
    
    if (formData.sellingPrice && isNaN(parseFloat(formData.sellingPrice))) {
      errors.sellingPrice = 'Selling price must be a valid number';
    }
    
    if (formData.taxRate && isNaN(parseFloat(formData.taxRate))) {
      errors.taxRate = 'Tax rate must be a valid number';
    }
    
    if (formData.minStockLevel && isNaN(parseInt(formData.minStockLevel))) {
      errors.minStockLevel = 'Minimum stock level must be a valid number';
    }
    
    if (formData.reorderPoint && isNaN(parseInt(formData.reorderPoint))) {
      errors.reorderPoint = 'Reorder point must be a valid number';
    }
    
    if (formData.reorderQuantity && isNaN(parseInt(formData.reorderQuantity))) {
      errors.reorderQuantity = 'Reorder quantity must be a valid number';
    }
    
    // For new items with inventory
    if (!isEditing && formData.trackInventory && formData.type === StockItemTypes.PHYSICAL) {
      if (!formData.initialLocation) {
        errors.initialLocation = 'Initial location is required';
      }
      
      if (formData.initialQuantity && isNaN(parseInt(formData.initialQuantity))) {
        errors.initialQuantity = 'Initial quantity must be a valid number';
      }
    }
    
    return errors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isDemoMode) {
      alert("Demo Mode Restriction:\nIn demo mode, data modifications are not saved. Sign up for a free account to use all features.");
      return;
    }
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      // Scroll to first error
      const firstError = document.querySelector('.text-red-600');
      if (firstError) {
        firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
    
    setIsLoading(true);
    
    try {
      // Upload image if present
      let imageUrl = formData.imageUrl;
      if (formData.image) {
        imageUrl = await uploadFileToStorage(formData.image);
      }
      
      // Prepare data for submission
      const itemData = {
        ...formData,
        imageUrl,
        costPrice: parseFloat(formData.costPrice || 0),
        sellingPrice: parseFloat(formData.sellingPrice || 0),
        taxRate: parseFloat(formData.taxRate || 0) / 100,
        minStockLevel: parseInt(formData.minStockLevel || 0),
        reorderPoint: parseInt(formData.reorderPoint || 0),
        reorderQuantity: parseInt(formData.reorderQuantity || 0)
      };
      
      // Remove temporary image file from data
      delete itemData.image;
      
      console.log('Submitting item data:', itemData);
      
      // In a real app, you'd post to an API here
      
      // Wait a bit to simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Navigate back to the items list or detail page
      navigate(isEditing ? `/stock/items/${itemId}` : '/stock/items');
    } catch (error) {
      console.error('Error saving item:', error);
      // Handle API error
      setFormErrors(prev => ({
        ...prev,
        submit: 'An error occurred while saving. Please try again.'
      }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-xs shadow-md overflow-hidden">
      <div className="bg-white shadow-md px-6 py-4 text-gray-800">
        <h2 className="text-lg font-medium">
          {isEditing ? 'Edit Stock Item' : 'Add New Stock Item'}
        </h2>
        <p className="text-purple-500 text-sm">
          {isEditing 
            ? 'Update item information in the form below' 
            : 'Fill out the form below to add a new stock item'}
        </p>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        <div className="mb-6">
          <button 
            type="button"
            onClick={() => navigate(isEditing ? `/stock/items/${itemId}` : '/inventory/items')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            {isEditing ? 'Back to Item Details' : 'Back to Inventory'}
          </button>
        </div>
        
        {/* Product Image Upload */}
        <div className="mb-8">
          <div className="flex flex-col sm:flex-row items-start gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Product Image</label>
              <div className="relative">
                {imagePreview ? (
                  <div className="relative">
                    <img 
                      src={imagePreview} 
                      alt="Product preview" 
                      className="w-40 h-40 object-contain border rounded-lg"
                    />
                    <button 
                      type="button" 
                      onClick={handleRemoveImage}
                      className="absolute -top-2 -right-2 bg-red-100 rounded-full p-1 text-red-600 hover:bg-red-200"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ) : (
                  <div 
                    onClick={() => imageInputRef.current?.click()} 
                    className="w-40 h-40 border-2 border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-[#190192]"
                  >
                    <Upload size={24} className="text-gray-400 mb-1" />
                    <span className="text-xs text-gray-500">Upload Image</span>
                  </div>
                )}
                <input 
                  type="file" 
                  ref={imageInputRef}
                  className="hidden" 
                  accept="image/png, image/jpeg, image/jpg" 
                  onChange={handleFileUpload}
                  disabled={isLoading}
                />
              </div>
              {formErrors.image && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.image}
                </p>
              )}
              <p className="text-xs text-gray-500 mt-2">Recommended: 800x800px. Max 5MB.</p>
              
              {/* Upload progress bar */}
              {uploadProgress > 0 && uploadProgress < 100 && (
                <div className="mt-2">
                  <div className="h-1.5 w-full bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-green-500 rounded-full" 
                      style={{ width: `${uploadProgress}%` }}
                    ></div>
                  </div>
                  <p className="text-xs text-gray-500 mt-1">{Math.round(uploadProgress)}% uploaded</p>
                </div>
              )}
            </div>
            
            <div className="flex-1 w-full">
              {/* SKU with Generate Button */}
              <div className="mb-4">
                <label htmlFor="sku" className="block text-sm font-medium text-gray-700 mb-1">
                  SKU <span className="text-red-500">*</span>
                </label>
                <div className="flex">
                  <div className="relative flex-1">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Tag size={16} className="text-gray-400" />
                    </div>
                    <input
                      id="sku"
                      name="sku"
                      type="text"
                      className={`pl-10 block w-full rounded-l-lg border ${
                        formErrors.sku ? 'border-red-300' : 'border-gray-300'
                      } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                      value={formData.sku}
                      onChange={handleInputChange}
                      placeholder="Stock Keeping Unit"
                      disabled={isLoading}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={generateSKU}
                    className="px-4 py-2 bg-gray-100 border border-gray-300 rounded-r-lg text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-[#190192]"
                    disabled={isLoading}
                  >
                    Generate
                  </button>
                </div>
                {formErrors.sku && (
                  <p className="mt-1 text-red-600 text-xs flex items-center">
                    <AlertCircle size={12} className="mr-1" /> {formErrors.sku}
                  </p>
                )}
              </div>
              
              {/* Item Name */}
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                  Item Name <span className="text-red-500">*</span>
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className={`block w-full rounded-lg border ${
                    formErrors.name ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  value={formData.name}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
                {formErrors.name && (
                  <p className="mt-1 text-red-600 text-xs flex items-center">
                    <AlertCircle size={12} className="mr-1" /> {formErrors.name}
                  </p>
                )}
              </div>
              
              {/* Barcode with Scan Button */}
              <div>
                <label htmlFor="barcode" className="block text-sm font-medium text-gray-700 mb-1">
                  Barcode
                </label>
                <div className="flex">
                  <div className="relative flex-1">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Barcode size={16} className="text-gray-400" />
                    </div>
                    <input
                      id="barcode"
                      name="barcode"
                      type="text"
                      className="pl-10 block w-full rounded-l-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                      value={formData.barcode}
                      onChange={handleInputChange}
                      placeholder="EAN, UPC, etc."
                      disabled={isLoading}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handleBarcodeScan}
                    className="px-4 py-2 bg-gray-100 border border-gray-300 rounded-r-lg text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-[#190192]"
                    disabled={isLoading}
                  >
                    <QrCode size={16} className="mr-2 inline" />
                    Scan
                  </button>
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  Enter manually or scan with a barcode scanner
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Basic Information */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
          
          {/* Description */}
          <div className="mb-6">
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              rows="3"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.description}
              onChange={handleInputChange}
              disabled={isLoading}
            />
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            {/* Item Type */}
            <div>
              <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
                Item Type <span className="text-red-500">*</span>
              </label>
              <select
                id="type"
                name="type"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.type}
                onChange={handleInputChange}
                disabled={isLoading || isEditing} // Can't change type when editing
              >
                <option value={StockItemTypes.PHYSICAL}>Physical Product</option>
                <option value={StockItemTypes.DIGITAL}>Digital Product</option>
                <option value={StockItemTypes.RAW_MATERIAL}>Raw Material</option>
                <option value={StockItemTypes.SERVICE}>Service</option>
              </select>
            </div>
            
            {/* Category */}
            <div>
              <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                Category <span className="text-red-500">*</span>
              </label>
              <select
                id="category"
                name="category"
                className={`block w-full rounded-lg border ${
                  formErrors.category ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.category}
                onChange={handleInputChange}
                disabled={isLoading}
              >
                <option value="">Select a category</option>
                {StockItemCategories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
              {formErrors.category && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.category}
                </p>
              )}
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            {/* Brand */}
            <div>
              <label htmlFor="brand" className="block text-sm font-medium text-gray-700 mb-1">
                Brand
              </label>
              <input
                id="brand"
                name="brand"
                type="text"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.brand}
                onChange={handleInputChange}
                placeholder="Brand name"
                disabled={isLoading}
              />
            </div>
            
            {/* Supplier */}
            <div>
              <label htmlFor="supplier" className="block text-sm font-medium text-gray-700 mb-1">
                Supplier
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Truck size={16} className="text-gray-400" />
                </div>
                <select
                  id="supplier"
                  name="supplier"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.supplier}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  <option value="">Select a supplier</option>
                  {suppliers.map(supplier => (
                    <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                  ))}
                </select>
              </div>
            </div>
            
            {/* Size */}
            <div>
              <label htmlFor="size" className="block text-sm font-medium text-gray-700 mb-1">
                Size
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Ruler size={16} className="text-gray-400" />
                </div>
                <input
                  id="size"
                  name="size"
                  type="text"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.size}
                  onChange={handleInputChange}
                  placeholder="S, M, L, XL, 10mm, etc."
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
          
          {/* Packaging and Unit Type */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Packaging */}
            <div>
              <label htmlFor="packaging" className="block text-sm font-medium text-gray-700 mb-1">
                Packaging
              </label>
              <select
                id="packaging"
                name="packaging"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.packaging}
                onChange={handleInputChange}
                disabled={isLoading}
              >
                {packagingOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            
            {/* Unit Type */}
            <div>
              <label htmlFor="unitType" className="block text-sm font-medium text-gray-700 mb-1">
                Unit Type
              </label>
              <select
                id="unitType"
                name="unitType"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.unitType}
                onChange={handleInputChange}
                disabled={isLoading}
              >
                {unitTypeOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        
        {/* Pricing Information */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Pricing Information</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Cost Price */}
            <div>
              <label htmlFor="costPrice" className="block text-sm font-medium text-gray-700 mb-1">
                Cost Price
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-400">{currencySymbol}</span>
                </div>
                <input
                  id="costPrice"
                  name="costPrice"
                  type="text"
                  className={`pl-8 block w-full rounded-lg border ${
                    formErrors.costPrice ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  value={formData.costPrice}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {formErrors.costPrice && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.costPrice}
                </p>
              )}
            </div>
            
            {/* Selling Price */}
            <div>
              <label htmlFor="sellingPrice" className="block text-sm font-medium text-gray-700 mb-1">
                Selling Price
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-400">{currencySymbol}</span>
                </div>
                <input
                  id="sellingPrice"
                  name="sellingPrice"
                  type="text"
                  className={`pl-8 block w-full rounded-lg border ${
                    formErrors.sellingPrice ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  value={formData.sellingPrice}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {formErrors.sellingPrice && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.sellingPrice}
                </p>
              )}
            </div>
            
            {/* Tax Rate */}
            <div>
              <label htmlFor="taxRate" className="block text-sm font-medium text-gray-700 mb-1">
                Tax Rate (%)
              </label>
              <div className="relative">
                <input
                  id="taxRate"
                  name="taxRate"
                  type="text"
                  className={`block w-full rounded-lg border ${
                    formErrors.taxRate ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  value={formData.taxRate}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-400">%</span>
                </div>
              </div>
              {formErrors.taxRate && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.taxRate}
                </p>
              )}
            </div>
          </div>
          
          {/* Profit Margin Calculation (read-only) */}
          {formData.costPrice && formData.sellingPrice && parseFloat(formData.costPrice) > 0 && (
            <div className="mt-2 p-3 bg-gray-50 rounded-lg border border-gray-200">
              <div className="flex justify-between items-center">
                <span className="text-sm text-gray-700">Profit Margin:</span>
                <span className="font-medium">
                  {Math.round(((parseFloat(formData.sellingPrice) - parseFloat(formData.costPrice)) / parseFloat(formData.sellingPrice)) * 100)}%
                </span>
              </div>
            </div>
          )}
        </div>
        
        {/* Inventory Settings */}
        {(formData.type === StockItemTypes.PHYSICAL || formData.type === StockItemTypes.RAW_MATERIAL) && (
          <div className="mb-8">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium text-gray-900">Inventory Settings</h3>
              
              <div className="flex items-center">
                <input
                  id="trackInventory"
                  name="trackInventory"
                  type="checkbox"
                  className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
                  checked={formData.trackInventory}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
                <label htmlFor="trackInventory" className="ml-2 block text-sm text-gray-900">
                  Track Inventory
                </label>
              </div>
            </div>
            
            {formData.trackInventory && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                  {/* Minimum Stock Level */}
                  <div>
                    <label htmlFor="minStockLevel" className="block text-sm font-medium text-gray-700 mb-1">
                      Minimum Stock Level
                    </label>
                    <input
                      id="minStockLevel"
                      name="minStockLevel"
                      type="text"
                      className={`block w-full rounded-lg border ${
                        formErrors.minStockLevel ? 'border-red-300' : 'border-gray-300'
                      } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                      value={formData.minStockLevel}
                      onChange={handleInputChange}
                      disabled={isLoading}
                    />
                    {formErrors.minStockLevel && (
                      <p className="mt-1 text-red-600 text-xs flex items-center">
                        <AlertCircle size={12} className="mr-1" /> {formErrors.minStockLevel}
                      </p>
                    )}
                  </div>
                  
                  {/* Reorder Point */}
                  <div>
                    <label htmlFor="reorderPoint" className="block text-sm font-medium text-gray-700 mb-1">
                      Reorder Point
                    </label>
                    <input
                      id="reorderPoint"
                      name="reorderPoint"
                      type="text"
                      className={`block w-full rounded-lg border ${
                        formErrors.reorderPoint ? 'border-red-300' : 'border-gray-300'
                      } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                      value={formData.reorderPoint}
                      onChange={handleInputChange}
                      disabled={isLoading}
                    />
                    {formErrors.reorderPoint && (
                      <p className="mt-1 text-red-600 text-xs flex items-center">
                        <AlertCircle size={12} className="mr-1" /> {formErrors.reorderPoint}
                      </p>
                    )}
                  </div>
                  
                  {/* Reorder Quantity */}
                  <div>
                    <label htmlFor="reorderQuantity" className="block text-sm font-medium text-gray-700 mb-1">
                      Reorder Quantity
                    </label>
                    <input
                      id="reorderQuantity"
                      name="reorderQuantity"
                      type="text"
                      className={`block w-full rounded-lg border ${
                        formErrors.reorderQuantity ? 'border-red-300' : 'border-gray-300'
                      } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                      value={formData.reorderQuantity}
                      onChange={handleInputChange}
                      disabled={isLoading}
                    />
                    {formErrors.reorderQuantity && (
                      <p className="mt-1 text-red-600 text-xs flex items-center">
                        <AlertCircle size={12} className="mr-1" /> {formErrors.reorderQuantity}
                      </p>
                    )}
                  </div>
                </div>
                
                {/* Initial Inventory (for new items only) */}
                {!isEditing && (
                  <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
                    <h4 className="text-sm font-medium text-blue-900 mb-3">Initial Inventory</h4>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {/* Initial Location */}
                      <div>
                        <label htmlFor="initialLocation" className="block text-sm font-medium text-gray-700 mb-1">
                          Location <span className="text-red-500">*</span>
                        </label>
                        <select
                          id="initialLocation"
                          name="initialLocation"
                          className={`block w-full rounded-lg border ${
                            formErrors.initialLocation ? 'border-red-300' : 'border-gray-300'
                          } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                          value={formData.initialLocation}
                          onChange={handleInputChange}
                          disabled={isLoading}
                        >
                          <option value="">Select a location</option>
                          {locations.map(location => (
                            <option key={location.id} value={location.id}>{location.name}</option>
                          ))}
                        </select>
                        {formErrors.initialLocation && (
                          <p className="mt-1 text-red-600 text-xs flex items-center">
                            <AlertCircle size={12} className="mr-1" /> {formErrors.initialLocation}
                          </p>
                        )}
                      </div>
                      
                      {/* Initial Quantity */}
                      <div>
                        <label htmlFor="initialQuantity" className="block text-sm font-medium text-gray-700 mb-1">
                          Quantity
                        </label>
                        <input
                          id="initialQuantity"
                          name="initialQuantity"
                          type="text"
                          className={`block w-full rounded-lg border ${
                            formErrors.initialQuantity ? 'border-red-300' : 'border-gray-300'
                          } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                          value={formData.initialQuantity}
                          onChange={handleInputChange}
                          disabled={isLoading}
                        />
                        {formErrors.initialQuantity && (
                          <p className="mt-1 text-red-600 text-xs flex items-center">
                            <AlertCircle size={12} className="mr-1" /> {formErrors.initialQuantity}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        
        {/* Digital Delivery (for digital items) */}
        {formData.type === StockItemTypes.DIGITAL && (
          <div className="mb-8">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Digital Delivery</h3>
            
            <div className="mb-4">
              <div className="flex items-center">
                <input
                  id="isDigitalDelivery"
                  name="isDigitalDelivery"
                  type="checkbox"
                  className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
                  checked={formData.isDigitalDelivery}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
                <label htmlFor="isDigitalDelivery" className="ml-2 block text-sm text-gray-900">
                  Automatic Digital Delivery
                </label>
              </div>
              <p className="mt-1 text-xs text-gray-500">
                Enable this option if the item should be delivered automatically upon purchase.
              </p>
            </div>
          </div>
        )}
        
        {/* Service Settings (for services) */}
        {formData.type === StockItemTypes.SERVICE && (
          <div className="mb-8">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Service Settings</h3>
            
            <div>
              <label htmlFor="serviceDeliveryMethod" className="block text-sm font-medium text-gray-700 mb-1">
                Delivery Method
              </label>
              <select
                id="serviceDeliveryMethod"
                name="serviceDeliveryMethod"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.serviceDeliveryMethod}
                onChange={handleInputChange}
                disabled={isLoading}
              >
                <option value="">Select a delivery method</option>
                <option value="online">Online</option>
                <option value="in-person">In-Person</option>
                <option value="phone">Phone</option>
                <option value="email">Email</option>
              </select>
            </div>
          </div>
        )}
        
        {/* Form submission error message */}
        {formErrors.submit && (
          <div className="mb-6 p-4 bg-red-50 rounded-lg">
            <p className="text-red-600 text-sm flex items-center">
              <AlertCircle size={16} className="mr-2" /> {formErrors.submit}
            </p>
          </div>
        )}
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-4 mt-10 pt-6 border-t border-gray-200">
          <button
            type="button"
            onClick={() => navigate(isEditing ? `/stock/items/${itemId}` : '/stock/items')}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
            disabled={isLoading}
          >
            Cancel
          </button>
          
          <button
            type="submit"
            disabled={isLoading}
            className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
              isLoading ? 'bg-purple-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
            }`}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <Check size={18} className="mr-2" />
                {isEditing ? 'Update Item' : 'Create Item'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default StockItemForm;