// src/components/records/RecordForm.js
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { 
  ChevronLeft, Check, AlertCircle, Calendar, Clock, Users,
  DollarSign, Tag, Image, X, Upload, FileText, Film, Plus,
  Paperclip, Trash, User, Briefcase, Building, Package, Wrench,
  AlertTriangle, CheckCircle, MessageSquare, ExternalLink 
} from 'lucide-react';
import { db, storage } from '../../firebase/config';
import { 
  collection, addDoc, updateDoc, doc, getDoc, 
  serverTimestamp, runTransaction, query, where, getDocs 
} from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../../context/AuthContext';

// Record categories
const RECORD_CATEGORIES = [
  'Administration', 
  'Operations', 
  'HR', 
  'Marketing', 
  'Finance', 
  'Legal', 
  'Policies'
];

// Record target types
const TARGET_TYPES = [
  { id: 'employee', label: 'Employee', icon: User },
  { id: 'customer', label: 'Customer', icon: Users },
  { id: 'supplier', label: 'Supplier', icon: Briefcase },
  { id: 'structure', label: 'Structure', icon: Building },
  { id: 'asset', label: 'Asset', icon: Wrench },
  { id: 'stock', label: 'Stock Item', icon: Package },
];

// Measurement types
const MEASUREMENT_TYPES = [
  { id: 'time', label: 'Time' },
  { id: 'date', label: 'Date' },
  { id: 'duration', label: 'Duration' },
  { id: 'quantity', label: 'Quantity' },
  { id: 'amount', label: 'Amount' }
];

// Status options
const STATUS_OPTIONS = [
  { id: 'green', label: 'Green', color: 'bg-green-500' },
  { id: 'orange', label: 'Orange', color: 'bg-orange-500' },
  { id: 'red', label: 'Red', color: 'bg-red-500' }
];

const RecordForm = ({ isEditing = false }) => {
  const { recordId } = useParams();
  const navigate = useNavigate();
  const { currentUser, isDemoMode } = useAuth();
  
  // Refs for file inputs
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const documentInputRef = useRef(null);
  
  // State for associated task
  const [associatedTask, setAssociatedTask] = useState(null);
  
  // State for target reference selection
  const [targetRefs, setTargetRefs] = useState([]);
  const [isLoadingTargetRefs, setIsLoadingTargetRefs] = useState(false);
  const [showTargetRefSelector, setShowTargetRefSelector] = useState(false);
  
  // State for media files
  const [mediaFiles, setMediaFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [selectedMediaType, setSelectedMediaType] = useState(null);
  
  // State for businesses (for business selection)
  const [businesses, setBusinesses] = useState([]);
  const [isLoadingBusinesses, setIsLoadingBusinesses] = useState(false);
  
  // Form state
  const [formData, setFormData] = useState({
    recordId: '',
    userId: currentUser?.uid || '',
    businessId: '',
    date: new Date().toISOString().split('T')[0],
    category: '',
    targetType: '',
    targetRef: '',
    type: '',
    message: '',
    mediaUrls: [],
    measurements: [],
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    quantity: '',
    amount: '',
    status: 'green',
    requireAction: false,
    taskId: null
  });

  // State for form validation errors
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  // Fetch record data if editing
  useEffect(() => {
    if (isEditing && recordId) {
      setIsLoading(true);
      
      const fetchRecord = async () => {
        try {
          const docRef = doc(db, 'records', recordId);
          const docSnap = await getDoc(docRef);
          
          if (docSnap.exists()) {
            const recordData = docSnap.data();
            
            setFormData({
              ...recordData,
              date: recordData.date ? new Date(recordData.date.seconds * 1000).toISOString().split('T')[0] : '',
              startDate: recordData.startDate ? new Date(recordData.startDate.seconds * 1000).toISOString().split('T')[0] : '',
              endDate: recordData.endDate ? new Date(recordData.endDate.seconds * 1000).toISOString().split('T')[0] : '',
              startTime: recordData.startTime || '',
              endTime: recordData.endTime || '',
              quantity: recordData.quantity?.toString() || '',
              amount: recordData.amount?.toString() || ''
            });
            
            // Set measurements
            if (recordData.measurements && recordData.measurements.length > 0) {
              handleMultiSelectMeasurements(recordData.measurements);
            }
            
            // Set media files if they exist
            if (recordData.mediaUrls && recordData.mediaUrls.length > 0) {
              setMediaFiles(recordData.mediaUrls.map(media => ({
                id: uuidv4(),
                url: media.url,
                type: media.type,
                name: media.name || media.url.split('/').pop().split('?')[0],
                isUploaded: true
              })));
            }
            
            // Fetch associated task if exists
            if (recordData.taskId) {
              const taskRef = doc(db, 'tasks', recordData.taskId);
              const taskSnap = await getDoc(taskRef);
              
              if (taskSnap.exists()) {
                setAssociatedTask(taskSnap.data());
              }
            }
            
            setIsLoading(false);
          } else {
            console.error('Record not found');
            navigate('/records');
          }
        } catch (error) {
          console.error('Error fetching record:', error);
          setIsLoading(false);
        }
      };
      
      fetchRecord();
    }
  }, [isEditing, recordId, navigate]);
  
  // Fetch businesses
  useEffect(() => {
    const fetchBusinesses = async () => {
      setIsLoadingBusinesses(true);
      
      try {
        const businessesQuery = query(
          collection(db, 'businesses'),
          where('createdBy', '==', currentUser.uid)
        );
        
        const querySnapshot = await getDocs(businessesQuery);
        const businessesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setBusinesses(businessesData);
      } catch (error) {
        console.error('Error fetching businesses:', error);
      } finally {
        setIsLoadingBusinesses(false);
      }
    };
    
    if (currentUser) {
      fetchBusinesses();
    }
  }, [currentUser]);
  
  // Fetch target references based on target type
  useEffect(() => {
    const fetchTargetRefs = async () => {
      if (!formData.targetType || !formData.businessId) return;
      
      setIsLoadingTargetRefs(true);
      setTargetRefs([]);
      
      try {
        let collectionName;
        
        switch (formData.targetType) {
          case 'employee':
            collectionName = 'employees';
            break;
          case 'customer':
            collectionName = 'customers';
            break;
          case 'supplier':
            collectionName = 'suppliers';
            break;
          case 'structure':
            collectionName = 'structures';
            break;
          case 'asset':
            collectionName = 'assets';
            break;
          case 'stock':
            collectionName = 'stockItems';
            break;
          default:
            setIsLoadingTargetRefs(false);
            return;
        }
        
        const targetQuery = query(
          collection(db, collectionName),
          where('businessId', '==', formData.businessId)
        );
        
        const querySnapshot = await getDocs(targetQuery);
        const targetData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name || doc.data().fullName || `${doc.data().firstName || ''} ${doc.data().lastName || ''}`.trim(),
          ...doc.data()
        }));
        
        setTargetRefs(targetData);
      } catch (error) {
        console.error(`Error fetching ${formData.targetType} references:`, error);
      } finally {
        setIsLoadingTargetRefs(false);
      }
    };
    
    fetchTargetRefs();
  }, [formData.targetType, formData.businessId]);
  
  // Handle text input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Use the checked value for checkboxes
    const newValue = type === 'checkbox' ? checked : value;
    
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Handle target type selection
  const handleTargetTypeSelect = (targetType) => {
    setFormData(prev => ({
      ...prev,
      targetType,
      targetRef: '' // Reset target reference when type changes
    }));
    
    setShowTargetRefSelector(true);
  };
  
  // Handle measurement type selection (multi-select)
  const handleMultiSelectMeasurements = (measurements) => {
    setFormData(prev => ({
      ...prev,
      measurements
    }));
  };
  
  // Toggle a measurement type
  const toggleMeasurement = (measurementId) => {
    const measurements = [...formData.measurements];
    
    if (measurements.includes(measurementId)) {
      // Remove if already selected
      const updatedMeasurements = measurements.filter(id => id !== measurementId);
      handleMultiSelectMeasurements(updatedMeasurements);
    } else {
      // Add if not selected
      handleMultiSelectMeasurements([...measurements, measurementId]);
    }
  };
  
  // Check if a measurement is selected
  const isMeasurementSelected = (measurementId) => {
    return formData.measurements.includes(measurementId);
  };
  
  // Show measurement fields based on selected measurements
  const shouldShowMeasurement = (measurementId) => {
    return formData.measurements.includes(measurementId);
  };
  
  // Handle media type selection
  const handleMediaTypeSelect = (type) => {
    setSelectedMediaType(type);
    
    // Open the appropriate file input
    if (type === 'image') {
      imageInputRef.current?.click();
    } else if (type === 'video') {
      videoInputRef.current?.click();
    } else if (type === 'document') {
      documentInputRef.current?.click();
    }
  };
  
  // Handle file selection
  const handleFileSelect = (e, type) => {
    const files = Array.from(e.target.files);
    
    if (files.length === 0) return;
    
    // Validate files
    const validFiles = files.filter(file => {
      // Check file size (10MB limit)
      if (file.size > 10 * 1024 * 1024) {
        setFormErrors(prev => ({
          ...prev,
          mediaFiles: `File ${file.name} exceeds the 10MB size limit`
        }));
        return false;
      }
      
      // Check file type
      if (type === 'image' && !file.type.startsWith('image/')) {
        setFormErrors(prev => ({
          ...prev,
          mediaFiles: `File ${file.name} is not a valid image`
        }));
        return false;
      }
      
      if (type === 'video' && !file.type.startsWith('video/')) {
        setFormErrors(prev => ({
          ...prev,
          mediaFiles: `File ${file.name} is not a valid video`
        }));
        return false;
      }
      
      if (type === 'document' && 
          !['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
            'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/plain'].includes(file.type)) {
        setFormErrors(prev => ({
          ...prev,
          mediaFiles: `File ${file.name} is not a valid document type`
        }));
        return false;
      }
      
      return true;
    });
    
    // Add valid files to the media files array
    const newMediaFiles = validFiles.map(file => ({
      id: uuidv4(),
      file,
      name: file.name,
      type,
      isUploaded: false,
      previewUrl: type === 'image' ? URL.createObjectURL(file) : null
    }));
    
    setMediaFiles(prev => [...prev, ...newMediaFiles]);
    
    // Clear the input
    e.target.value = null;
    
    // Reset selected media type
    setSelectedMediaType(null);
    
    // Clear any error
    if (formErrors.mediaFiles) {
      setFormErrors(prev => ({ ...prev, mediaFiles: '' }));
    }
  };
  
  // Remove a media file
  const handleRemoveFile = async (fileId) => {
    const fileToRemove = mediaFiles.find(file => file.id === fileId);
    
    // If the file has already been uploaded to Firebase Storage, delete it
    if (fileToRemove && fileToRemove.isUploaded && fileToRemove.url) {
      try {
        // Create a reference to the file in Firebase Storage
        const fileRef = ref(storage, fileToRemove.url);
        
        // Delete the file
        await deleteObject(fileRef);
      } catch (error) {
        console.error('Error deleting file from storage:', error);
      }
    }
    
    // If the file has a preview URL, revoke it
    if (fileToRemove && fileToRemove.previewUrl) {
      URL.revokeObjectURL(fileToRemove.previewUrl);
    }
    
    // Remove the file from the state
    setMediaFiles(prev => prev.filter(file => file.id !== fileId));
  };
  
  // Upload a file to Firebase Storage
  const uploadFileToStorage = async (file) => {
    return new Promise((resolve, reject) => {
      // Create a storage reference
      const storageRef = ref(storage, `records/${currentUser.uid}/${Date.now()}_${file.name}`);
      
      // Create the upload task
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      // Register three observers:
      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(prev => ({
            ...prev,
            [file.name]: progress
          }));
        }, 
        (error) => {
          // Handle unsuccessful uploads
          console.error('Upload error:', error);
          reject(error);
        }, 
        async () => {
          // Handle successful uploads on complete
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };
  
  // Navigate to task creation page
  const handleCreateTask = () => {
    // Save current record state to sessionStorage
    sessionStorage.setItem('pendingRecord', JSON.stringify(formData));
    
    // Navigate to task creation page with record context
    navigate('/tasks/new?fromRecord=true');
  };
  
  // Validate the form
  const validateForm = () => {
    const errors = {};
    
    // Required fields
    if (!formData.businessId) errors.businessId = 'Business is required';
    if (!formData.category) errors.category = 'Category is required';
    if (!formData.message.trim()) errors.message = 'Message is required';
    
    // If target type is selected, target reference is required
    if (formData.targetType && !formData.targetRef) {
      errors.targetRef = 'Target reference is required';
    }
    
    // Validate measurements
    if (shouldShowMeasurement('quantity') && formData.quantity && isNaN(parseFloat(formData.quantity))) {
      errors.quantity = 'Quantity must be a valid number';
    }
    
    if (shouldShowMeasurement('amount') && formData.amount && isNaN(parseFloat(formData.amount))) {
      errors.amount = 'Amount must be a valid number';
    }
    
    // Validate date ranges if duration is selected
    if (shouldShowMeasurement('duration') && shouldShowMeasurement('date')) {
      if (formData.startDate && formData.endDate) {
        const start = new Date(formData.startDate);
        const end = new Date(formData.endDate);
        
        if (end < start) {
          errors.endDate = 'End date cannot be before start date';
        }
      }
    }
    
    // Validate time ranges if duration and time are selected
    if (shouldShowMeasurement('duration') && shouldShowMeasurement('time')) {
      if (formData.startDate === formData.endDate && formData.startTime && formData.endTime) {
        if (formData.endTime < formData.startTime) {
          errors.endTime = 'End time cannot be before start time on the same day';
        }
      }
    }
    
    return errors;
  };
  
  // Update stock quantity if needed
  const updateStockQuantity = async (stockItemId, quantity) => {
    if (!stockItemId || !quantity) return;
    
    try {
      const stockRef = doc(db, 'stockItems', stockItemId);
      
      await runTransaction(db, async (transaction) => {
        const stockDoc = await transaction.get(stockRef);
        
        if (!stockDoc.exists()) {
          throw new Error('Stock item does not exist');
        }
        
        const stockData = stockDoc.data();
        
        // Get current location quantities or initialize if none
        const locations = stockData.locations || {};
        
        // We'll update the main store location for this example
        // In a real app, you'd probably specify which location to update
        const locationId = 'main-store';
        const currentQty = locations[locationId] || 0;
        const newQty = currentQty + parseFloat(quantity);
        
        // Update the location quantity
        transaction.update(stockRef, {
          [`locations.${locationId}`]: newQty,
          updatedAt: serverTimestamp()
        });
        
        // Create a stock transaction record
        const transactionData = {
          itemId: stockItemId,
          recordId: recordId || null, // Link to this record
          type: quantity > 0 ? 'Received' : 'Adjustment',
          quantity: parseFloat(quantity),
          location: locationId,
          date: serverTimestamp(),
          createdBy: currentUser.uid,
          reference: recordId || 'manual-adjustment'
        };
        
        const newTransactionRef = doc(collection(db, 'stockTransactions'));
        transaction.set(newTransactionRef, transactionData);
      });
      
      console.log('Stock quantity updated successfully');
    } catch (error) {
      console.error('Error updating stock quantity:', error);
      throw error;
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isDemoMode) {
      alert("Demo Mode Restriction:\nIn demo mode, data modifications are not saved. Sign up for a free account to use all features.");
      return;
    }
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      // Scroll to first error
      const firstError = document.querySelector('.text-red-600');
      if (firstError) {
        firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
    
    setIsLoading(true);
    
    try {
      // 1. Upload any new media files to Firebase Storage
      const mediaUploadPromises = mediaFiles
        .filter(media => !media.isUploaded) // Only upload new files
        .map(async (media) => {
          if (media.file) {
            const url = await uploadFileToStorage(media.file);
            return {
              id: media.id,
              url,
              type: media.type,
              name: media.name
            };
          }
          return null;
        });
      
      const uploadedMedia = (await Promise.all(mediaUploadPromises))
        .filter(Boolean); // Filter out any nulls
      
      // Combine with existing media
      const existingMedia = mediaFiles
        .filter(media => media.isUploaded)
        .map(media => ({
          id: media.id,
          url: media.url,
          type: media.type,
          name: media.name
        }));
      
      const allMedia = [...existingMedia, ...uploadedMedia];
      
      // 2. Prepare data for submission
      const recordData = {
        userId: currentUser.uid,
        businessId: formData.businessId,
        date: formData.date ? new Date(formData.date) : new Date(),
        category: formData.category,
        targetType: formData.targetType || null,
        targetRef: formData.targetRef || null,
        type: formData.type || null,
        message: formData.message,
        mediaUrls: allMedia,
        measurements: formData.measurements,
        status: formData.status,
        requireAction: formData.requireAction,
        taskId: formData.taskId || null,
        updatedAt: serverTimestamp()
      };
      
      // Add measurement specific fields if they're used
      if (shouldShowMeasurement('date')) {
        recordData.startDate = formData.startDate ? new Date(formData.startDate) : null;
      }
      
      if (shouldShowMeasurement('time')) {
        recordData.startTime = formData.startTime || null;
      }
      
      if (shouldShowMeasurement('duration')) {
        if (shouldShowMeasurement('date')) {
          recordData.endDate = formData.endDate ? new Date(formData.endDate) : null;
        }
        
        if (shouldShowMeasurement('time')) {
          recordData.endTime = formData.endTime || null;
        }
      }
      
      if (shouldShowMeasurement('quantity')) {
        recordData.quantity = formData.quantity ? parseFloat(formData.quantity) : null;
      }
      
      if (shouldShowMeasurement('amount')) {
        recordData.amount = formData.amount ? parseFloat(formData.amount) : null;
      }
      
      // 3. Save to Firestore
      let docRef;
      
      if (isEditing) {
        // Update existing document
        docRef = doc(db, 'records', recordId);
        await updateDoc(docRef, recordData);
      } else {
        // Create new document
        recordData.createdAt = serverTimestamp();
        recordData.createdBy = currentUser.uid;
        
        // Generate a recordId if not editing
        recordData.recordId = `REC-${Date.now().toString().slice(-6)}`;
        
        docRef = await addDoc(collection(db, 'records'), recordData);
      }
      
      // 4. Update stock quantity if this is a stock record
      if (formData.targetType === 'stock' && 
          formData.targetRef && 
          shouldShowMeasurement('quantity') && 
          formData.quantity) {
        await updateStockQuantity(formData.targetRef, parseFloat(formData.quantity));
      }
      
      // 5. Navigate back
      navigate('/records');
    } catch (error) {
      console.error('Error saving record:', error);
      setFormErrors(prev => ({
        ...prev,
        submit: 'An error occurred while saving the record. Please try again.'
      }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-xs shadow-md overflow-hidden">
      <div className="bg-white shadow-md px-6 py-4 text-gray-800">
        <h2 className="text-lg font-medium">
          {isEditing ? 'Edit Record' : 'Create New Record'}
        </h2>
        <p className="text-purple-500 text-sm">
          {isEditing 
            ? 'Update record information in the form below' 
            : 'Record activities, incidents, and non-financial transactions'}
        </p>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        <div className="mb-6">
          <button 
            type="button"
            onClick={() => navigate('/records')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Records
          </button>
        </div>
        
        {/* Basic Information */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            {/* Business Selection */}
            <div>
              <label htmlFor="businessId" className="block text-sm font-medium text-gray-700 mb-1">
                Business <span className="text-red-500">*</span>
              </label>
              <select
                id="businessId"
                name="businessId"
                className={`block w-full rounded-lg border ${
                  formErrors.businessId ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.businessId}
                onChange={handleInputChange}
                disabled={isLoading || isLoadingBusinesses}
              >
                <option value="">Select a business</option>
                {businesses.map(business => (
                  <option key={business.id} value={business.id}>{business.name}</option>
                ))}
              </select>
              {formErrors.businessId && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.businessId}
                </p>
              )}
            </div>
            
            {/* Date */}
            <div>
              <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
                Record Date
              </label>
              <input
                id="date"
                name="date"
                type="date"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.date}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </div>
          </div>
          
          {/* Category */}
          <div className="mb-6">
            <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
              Category <span className="text-red-500">*</span>
            </label>
            <select
              id="category"
              name="category"
              className={`block w-full rounded-lg border ${
                formErrors.category ? 'border-red-300' : 'border-gray-300'
              } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
              value={formData.category}
              onChange={handleInputChange}
              disabled={isLoading}
            >
              <option value="">Select a category</option>
              {RECORD_CATEGORIES.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
            {formErrors.category && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.category}
              </p>
            )}
          </div>
          
          {/* Type (free text field) */}
          <div className="mb-6">
            <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
              Record Type
            </label>
            <input
              id="type"
              name="type"
              type="text"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.type}
              onChange={handleInputChange}
              placeholder="e.g., Incident, Meeting, Delivery, Maintenance"
              disabled={isLoading}
            />
          </div>
        </div>
        
        {/* Target Information */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Target Information</h3>
          
          {/* Target Type */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Target Type
            </label>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-2">
              {TARGET_TYPES.map((targetType) => (
                <div 
                  key={targetType.id}
                  onClick={() => handleTargetTypeSelect(targetType.id)}
                  className={`border rounded-lg p-3 flex flex-col items-center justify-center cursor-pointer transition-colors ${
                    formData.targetType === targetType.id
                     ? 'bg-purple-50 border-[#190192] text-[#190192]' 
                     : 'border-gray-200 hover:border-gray-300'
                 }`}
               >
                 <targetType.icon size={24} className={formData.targetType === targetType.id ? 'text-[#190192]' : 'text-gray-500'} />
                 <span className="mt-2 text-sm">{targetType.label}</span>
               </div>
             ))}
           </div>
         </div>
         
         {/* Target Reference */}
         {formData.targetType && formData.businessId && (
           <div className="mb-4">
             <label htmlFor="targetRef" className="block text-sm font-medium text-gray-700 mb-1">
               Select {TARGET_TYPES.find(t => t.id === formData.targetType)?.label}
             </label>
             
             {isLoadingTargetRefs ? (
               <div className="flex items-center text-gray-500 text-sm">
                 <div className="animate-spin h-4 w-4 border-2 border-[#190192] rounded-full border-t-transparent mr-2"></div>
                 Loading options...
               </div>
             ) : targetRefs.length === 0 ? (
               <p className="text-amber-600 text-sm flex items-center">
                 <AlertTriangle size={16} className="mr-2" />
                 No {formData.targetType}s found for this business
               </p>
             ) : (
               <select
                 id="targetRef"
                 name="targetRef"
                 className={`block w-full rounded-lg border ${
                   formErrors.targetRef ? 'border-red-300' : 'border-gray-300'
                 } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                 value={formData.targetRef}
                 onChange={handleInputChange}
                 disabled={isLoading}
               >
                 <option value="">Select a {formData.targetType}</option>
                 {targetRefs.map(ref => (
                   <option key={ref.id} value={ref.id}>{ref.name}</option>
                 ))}
               </select>
             )}
             
             {formErrors.targetRef && (
               <p className="mt-1 text-red-600 text-xs flex items-center">
                 <AlertCircle size={12} className="mr-1" /> {formErrors.targetRef}
               </p>
             )}
           </div>
         )}
       </div>
       
       {/* Record Content */}
       <div className="mb-8">
         <h3 className="text-lg font-medium text-gray-900 mb-4">Record Content</h3>
         
         {/* Message */}
         <div className="mb-6">
           <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
             Record Message <span className="text-red-500">*</span>
           </label>
           <textarea
             id="message"
             name="message"
             rows="4"
             className={`block w-full rounded-lg border ${
               formErrors.message ? 'border-red-300' : 'border-gray-300'
             } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
             value={formData.message}
             onChange={handleInputChange}
             placeholder="Enter the details of this record..."
             disabled={isLoading}
           />
           {formErrors.message && (
             <p className="mt-1 text-red-600 text-xs flex items-center">
               <AlertCircle size={12} className="mr-1" /> {formErrors.message}
             </p>
           )}
         </div>
         
         {/* Media Upload */}
         <div className="mb-6">
           <label className="block text-sm font-medium text-gray-700 mb-2">
             Add Media
           </label>
           
           <div className="flex flex-wrap gap-2 mb-3">
             <button
               type="button"
               onClick={() => handleMediaTypeSelect('image')}
               className={`inline-flex items-center px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 ${
                 selectedMediaType === 'image' ? 'bg-purple-50 border-[#190192] text-[#190192]' : ''
               }`}
               disabled={isLoading}
             >
               <Image size={16} className="mr-2" />
               Image
             </button>
             
             <button
               type="button"
               onClick={() => handleMediaTypeSelect('video')}
               className={`inline-flex items-center px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 ${
                 selectedMediaType === 'video' ? 'bg-purple-50 border-[#190192] text-[#190192]' : ''
               }`}
               disabled={isLoading}
             >
               <Film size={16} className="mr-2" />
               Video
             </button>
             
             <button
               type="button"
               onClick={() => handleMediaTypeSelect('document')}
               className={`inline-flex items-center px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 ${
                 selectedMediaType === 'document' ? 'bg-purple-50 border-[#190192] text-[#190192]' : ''
               }`}
               disabled={isLoading}
             >
               <FileText size={16} className="mr-2" />
               Document
             </button>
             
             {/* Hidden file inputs */}
             <input
               type="file"
               ref={imageInputRef}
               className="hidden"
               accept="image/*"
               onChange={(e) => handleFileSelect(e, 'image')}
               multiple
             />
             
             <input
               type="file"
               ref={videoInputRef}
               className="hidden"
               accept="video/*"
               onChange={(e) => handleFileSelect(e, 'video')}
               multiple
             />
             
             <input
               type="file"
               ref={documentInputRef}
               className="hidden"
               accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
               onChange={(e) => handleFileSelect(e, 'document')}
               multiple
             />
           </div>
           
           {formErrors.mediaFiles && (
             <p className="mt-1 text-red-600 text-xs flex items-center">
               <AlertCircle size={12} className="mr-1" /> {formErrors.mediaFiles}
             </p>
           )}
           
           {/* Preview of uploaded files */}
           {mediaFiles.length > 0 && (
             <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
               {mediaFiles.map(mediaFile => (
                 <div key={mediaFile.id} className="relative border rounded-lg p-2">
                   <div className="flex items-center mb-2">
                     {mediaFile.type === 'image' && <Image size={16} className="mr-2 text-blue-500" />}
                     {mediaFile.type === 'video' && <Film size={16} className="mr-2 text-purple-500" />}
                     {mediaFile.type === 'document' && <FileText size={16} className="mr-2 text-green-500" />}
                     <span className="text-sm text-gray-700 truncate flex-1">{mediaFile.name}</span>
                     <button
                       type="button"
                       onClick={() => handleRemoveFile(mediaFile.id)}
                       className="p-1 ml-2 text-red-600 hover:text-red-800 rounded"
                       disabled={isLoading}
                     >
                       <Trash size={14} />
                     </button>
                   </div>
                   
                   {/* Preview for images */}
                   {mediaFile.type === 'image' && (
                     <div className="h-32 bg-gray-100 rounded flex items-center justify-center overflow-hidden">
                       {mediaFile.previewUrl || mediaFile.url ? (
                         <img 
                           src={mediaFile.previewUrl || mediaFile.url} 
                           alt={mediaFile.name}
                           className="h-full w-full object-contain"
                         />
                       ) : (
                         <p className="text-gray-400 text-sm">No preview</p>
                       )}
                     </div>
                   )}
                   
                   {/* Placeholder for videos and documents */}
                   {(mediaFile.type === 'video' || mediaFile.type === 'document') && (
                     <div className="h-32 bg-gray-100 rounded flex flex-col items-center justify-center">
                       {mediaFile.type === 'video' && <Film size={32} className="text-gray-400 mb-2" />}
                       {mediaFile.type === 'document' && <FileText size={32} className="text-gray-400 mb-2" />}
                       <p className="text-gray-500 text-xs">{mediaFile.type.charAt(0).toUpperCase() + mediaFile.type.slice(1)} file</p>
                     </div>
                   )}
                   
                   {/* Upload progress */}
                   {!mediaFile.isUploaded && uploadProgress[mediaFile.name] > 0 && (
                     <div className="mt-2">
                       <div className="w-full bg-gray-200 rounded-full h-2">
                         <div
                           className="bg-[#190192] h-2 rounded-full"
                           style={{ width: `${uploadProgress[mediaFile.name]}%` }}
                         ></div>
                       </div>
                       <p className="text-xs text-right mt-1 text-gray-500">
                         {Math.round(uploadProgress[mediaFile.name])}%
                       </p>
                     </div>
                   )}
                 </div>
               ))}
             </div>
           )}
         </div>
       </div>
       
       {/* Measurements */}
       <div className="mb-8">
         <h3 className="text-lg font-medium text-gray-900 mb-4">Measurements</h3>
         
         {/* Measurement Types */}
         <div className="mb-6">
           <label className="block text-sm font-medium text-gray-700 mb-2">
             Select Measurements (Optional)
           </label>
           <div className="flex flex-wrap gap-2">
             {MEASUREMENT_TYPES.map((measurement) => (
               <button
                 key={measurement.id}
                 type="button"
                 onClick={() => toggleMeasurement(measurement.id)}
                 className={`inline-flex items-center px-3 py-2 border rounded-md text-sm font-medium ${
                   isMeasurementSelected(measurement.id)
                     ? 'bg-purple-50 border-[#190192] text-[#190192]'
                     : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'
                 }`}
                 disabled={isLoading}
               >
                 {measurement.label}
                 {isMeasurementSelected(measurement.id) && (
                   <Check size={16} className="ml-2" />
                 )}
               </button>
             ))}
           </div>
         </div>
         
         {/* Date and Time Fields */}
         {(shouldShowMeasurement('date') || shouldShowMeasurement('time') || shouldShowMeasurement('duration')) && (
           <div className="bg-gray-50 p-4 rounded-lg mb-6">
             <h4 className="text-md font-medium text-gray-900 mb-3">Date & Time Information</h4>
             
             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
               {/* Start Date */}
               {shouldShowMeasurement('date') && (
                 <div>
                   <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                     {shouldShowMeasurement('duration') ? 'Start Date' : 'Date'}
                   </label>
                   <div className="relative">
                     <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                       <Calendar size={16} className="text-gray-400" />
                     </div>
                     <input
                       id="startDate"
                       name="startDate"
                       type="date"
                       className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                       value={formData.startDate}
                       onChange={handleInputChange}
                       disabled={isLoading}
                     />
                   </div>
                 </div>
               )}
               
               {/* Start Time */}
               {shouldShowMeasurement('time') && (
                 <div>
                   <label htmlFor="startTime" className="block text-sm font-medium text-gray-700 mb-1">
                     {shouldShowMeasurement('duration') ? 'Start Time' : 'Time'}
                   </label>
                   <div className="relative">
                     <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                       <Clock size={16} className="text-gray-400" />
                     </div>
                     <input
                       id="startTime"
                       name="startTime"
                       type="time"
                       className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                       value={formData.startTime}
                       onChange={handleInputChange}
                       disabled={isLoading}
                     />
                   </div>
                 </div>
               )}
               
               {/* End Date (if duration is selected) */}
               {shouldShowMeasurement('duration') && shouldShowMeasurement('date') && (
                 <div>
                   <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                     End Date
                   </label>
                   <div className="relative">
                     <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                       <Calendar size={16} className="text-gray-400" />
                     </div>
                     <input
                       id="endDate"
                       name="endDate"
                       type="date"
                       className={`pl-10 block w-full rounded-lg border ${
                         formErrors.endDate ? 'border-red-300' : 'border-gray-300'
                       } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                       value={formData.endDate}
                       onChange={handleInputChange}
                       disabled={isLoading}
                     />
                   </div>
                   {formErrors.endDate && (
                     <p className="mt-1 text-red-600 text-xs flex items-center">
                       <AlertCircle size={12} className="mr-1" /> {formErrors.endDate}
                     </p>
                   )}
                 </div>
               )}
               
               {/* End Time (if duration and time are selected) */}
               {shouldShowMeasurement('duration') && shouldShowMeasurement('time') && (
                 <div>
                   <label htmlFor="endTime" className="block text-sm font-medium text-gray-700 mb-1">
                     End Time
                   </label>
                   <div className="relative">
                     <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                       <Clock size={16} className="text-gray-400" />
                     </div>
                     <input
                       id="endTime"
                       name="endTime"
                       type="time"
                       className={`pl-10 block w-full rounded-lg border ${
                         formErrors.endTime ? 'border-red-300' : 'border-gray-300'
                       } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                       value={formData.endTime}
                       onChange={handleInputChange}
                       disabled={isLoading}
                     />
                   </div>
                   {formErrors.endTime && (
                     <p className="mt-1 text-red-600 text-xs flex items-center">
                       <AlertCircle size={12} className="mr-1" /> {formErrors.endTime}
                     </p>
                   )}
                 </div>
               )}
             </div>
           </div>
         )}
         
         {/* Quantity and Amount Fields */}
         <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
           {/* Quantity */}
           {shouldShowMeasurement('quantity') && (
             <div>
               <label htmlFor="quantity" className="block text-sm font-medium text-gray-700 mb-1">
                 Quantity
               </label>
               <input
                 id="quantity"
                 name="quantity"
                 type="text"
                 className={`block w-full rounded-lg border ${
                   formErrors.quantity ? 'border-red-300' : 'border-gray-300'
                 } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                 value={formData.quantity}
                 onChange={handleInputChange}
                 placeholder="Enter quantity"
                 disabled={isLoading}
               />
               {formErrors.quantity && (
                 <p className="mt-1 text-red-600 text-xs flex items-center">
                   <AlertCircle size={12} className="mr-1" /> {formErrors.quantity}
                 </p>
               )}
             </div>
           )}
           
           {/* Amount */}
           {shouldShowMeasurement('amount') && (
             <div>
               <label htmlFor="amount" className="block text-sm font-medium text-gray-700 mb-1">
                 Amount
               </label>
               <div className="relative">
                 <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                   <DollarSign size={16} className="text-gray-400" />
                 </div>
                 <input
                   id="amount"
                   name="amount"
                   type="text"
                   className={`pl-10 block w-full rounded-lg border ${
                     formErrors.amount ? 'border-red-300' : 'border-gray-300'
                   } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                   value={formData.amount}
                   onChange={handleInputChange}
                   placeholder="Enter amount"
                   disabled={isLoading}
                 />
               </div>
               {formErrors.amount && (
                 <p className="mt-1 text-red-600 text-xs flex items-center">
                   <AlertCircle size={12} className="mr-1" /> {formErrors.amount}
                 </p>
               )}
             </div>
           )}
         </div>
       </div>
       
       {/* Status and Action Section */}
       <div className="mb-8">
         <h3 className="text-lg font-medium text-gray-900 mb-4">Status & Actions</h3>
         
         {/* Status Selection */}
         <div className="mb-6">
           <label className="block text-sm font-medium text-gray-700 mb-2">
             Status
           </label>
           <div className="flex space-x-3">
             {STATUS_OPTIONS.map((status) => (
               <button
                 key={status.id}
                 type="button"
                 onClick={() => setFormData(prev => ({ ...prev, status: status.id }))}
                 className={`flex items-center px-4 py-2 rounded-lg border ${
                   formData.status === status.id
                     ? 'border-gray-700 ring-2 ring-offset-2 ring-gray-500'
                     : 'border-gray-300'
                 }`}
                 disabled={isLoading}
               >
                 <div className={`w-4 h-4 rounded-full ${status.color} mr-2`}></div>
                 <span>{status.label}</span>
               </button>
             ))}
           </div>
         </div>
         
         {/* Require Action Toggle */}
         <div className="mb-6">
           <div className="flex items-center mb-2">
             <input
               id="requireAction"
               name="requireAction"
               type="checkbox"
               className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
               checked={formData.requireAction}
               onChange={handleInputChange}
               disabled={isLoading}
             />
             <label htmlFor="requireAction" className="ml-2 block text-sm text-gray-700">
               This record requires action
             </label>
           </div>
           
           {/* Create Task Button */}
           {formData.requireAction && !formData.taskId && !associatedTask && (
             <button
               type="button"
               onClick={handleCreateTask}
               className="mt-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
               disabled={isLoading}
             >
               <Plus size={16} className="mr-2" />
               Create Task from Record
             </button>
           )}
           
           {/* Show Associated Task */}
           {(formData.taskId || associatedTask) && (
             <div className="mt-2 p-4 bg-blue-50 rounded-lg border border-blue-100">
               <div className="flex items-start">
                 <CheckCircle size={20} className="text-blue-600 mr-2 flex-shrink-0 mt-0.5" />
                 <div>
                   <p className="text-blue-800 font-medium">Associated Task</p>
                   <p className="text-sm text-blue-600">
                     {associatedTask?.title || 'Task is linked to this record'}
                   </p>
                   <Link
                     to={`/tasks/${formData.taskId}`}
                     className="text-[#190192] hover:text-[#2C0DB5] text-sm font-medium inline-flex items-center mt-1"
                   >
                     View Task <ExternalLink size={14} className="ml-1" />
                   </Link>
                 </div>
               </div>
             </div>
           )}
         </div>
       </div>
       
       {/* Form submit error */}
       {formErrors.submit && (
         <div className="mb-6 p-4 bg-red-50 rounded-lg border border-red-100">
           <p className="text-red-600 text-sm flex items-center">
             <AlertCircle size={16} className="mr-2" /> {formErrors.submit}
           </p>
         </div>
       )}
       
       {/* Form Actions */}
       <div className="flex justify-end space-x-4 mt-10 pt-6 border-t border-gray-200">
         <button
           type="button"
           onClick={() => navigate('/records')}
           className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
           disabled={isLoading}
         >
           Cancel
         </button>
         
         <button
           type="submit"
           disabled={isLoading}
           className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
             isLoading ? 'bg-purple-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
           }`}
         >
           {isLoading ? (
             <>
               <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                 <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                 <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
               </svg>
               Saving...
             </>
           ) : (
             <>
               <Check size={18} className="mr-2" />
               {isEditing ? 'Update Record' : 'Create Record'}
             </>
           )}
         </button>
       </div>
     </form>
   </div>
 );
};

export default RecordForm;