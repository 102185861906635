// src/components/finance/TransactForm.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  ChevronLeft, Check, AlertCircle, Calendar, 
  DollarSign, Tag, FileText, X, Upload, 
  CreditCard, Users, Building, ArrowUpDown, ArrowUpRight, 
  PlusCircle, Trash, BadgePercent, Copy,
  BarChart2, Clock, CalendarRange, Repeat,
  Calculator, Receipt, ShoppingCart
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../firebase/config';
import { 
  collection, addDoc, updateDoc, doc, getDoc, 
  serverTimestamp, query, where, getDocs
} from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

// Transaction types
const TRANSACTION_TYPES = [
  { id: 'simple', label: 'Transaction', icon: ArrowUpDown, description: 'Record income or expense' },
  { id: 'invoice', label: 'Invoice', icon: Receipt, description: 'Create customer invoice' },
  { id: 'bill', label: 'Bill', icon: BadgePercent, description: 'Record supplier bill' },
  { id: 'budget', label: 'Budget', icon: BarChart2, description: 'Add budget entry' }
];

// Transaction categories (these would normally come from the database)
const TRANSACTION_CATEGORIES = {
  income: [
    { id: 'sales', label: 'Sales' },
    { id: 'service', label: 'Service Revenue' },
    { id: 'interest', label: 'Interest' },
    { id: 'other_income', label: 'Other Income' }
  ],
  expense: [
    { id: 'rent', label: 'Rent' },
    { id: 'utilities', label: 'Utilities' },
    { id: 'salaries', label: 'Salaries & Wages' },
    { id: 'supplies', label: 'Supplies' },
    { id: 'marketing', label: 'Marketing' },
    { id: 'travel', label: 'Travel & Entertainment' },
    { id: 'insurance', label: 'Insurance' },
    { id: 'taxes', label: 'Taxes' },
    { id: 'other_expense', label: 'Other Expense' }
  ]
};

const TransactForm = ({ isEditing = false }) => {
  const { transactionId } = useParams();
  const navigate = useNavigate();
  const { currentUser, isDemoMode } = useAuth();
  const fileInputRef = useRef(null);
  
  // Basic state
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [transactionType, setTransactionType] = useState('simple');
  const [directionType, setDirectionType] = useState('expense'); // 'income' or 'expense'
  
  // Reference data
  const [businesses, setBusinesses] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [products, setProducts] = useState([]);
  const [taxRates, setTaxRates] = useState([]);
  
  // Attachment handling
  const [attachments, setAttachments] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  
  // Main form data
  const [formData, setFormData] = useState({
    businessId: '',
    accountId: '',
    
    // Common fields
    date: new Date().toISOString().split('T')[0],
    amount: '',
    description: '',
    notes: '',
    
    // Categorization
    category: '',
    subcategory: '',
    
    // Transaction specific
    reference: '',
    paymentMethod: 'bank_transfer',
    
    // Invoice/Bill specific
    documentNumber: '',
    counterpartyId: '',
    counterpartyType: '',
    issueDate: new Date().toISOString().split('T')[0],
    dueDate: '',
    items: [],
    subtotal: 0,
    taxAmount: 0,
    totalAmount: 0,
    paidAmount: 0,
    balanceDue: 0,
    status: 'draft',
    
    // Recurring information
    isRecurring: false,
    recurrenceFrequency: 'monthly',
    recurrenceInterval: 1,
    recurrenceEndDate: '',
    recurrenceEndOccurrences: '',
    
    // Budget related
    budgetCategoryId: '',
    fiscalPeriod: '',
    plannedAmount: ''
  });
  
  // Fetch businesses on component mount
  useEffect(() => {
    const fetchBusinesses = async () => {
      if (!currentUser) return;
      
      try {
        const businessQuery = query(
          collection(db, 'businesses'),
          where('createdBy', '==', currentUser.uid)
        );
        
        const querySnapshot = await getDocs(businessQuery);
        const businessesData = [];
        
        querySnapshot.forEach((doc) => {
          businessesData.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setBusinesses(businessesData);
        
        // Set default business if available
        if (businessesData.length > 0 && !formData.businessId) {
          setFormData(prev => ({
            ...prev,
            businessId: businessesData[0].id
          }));
        }
      } catch (error) {
        console.error('Error fetching businesses:', error);
      }
    };
    
    fetchBusinesses();
  }, [currentUser]);
  
  // Fetch accounts when business changes
  useEffect(() => {
    const fetchAccounts = async () => {
      if (!formData.businessId) return;
      
      try {
        const accountsQuery = query(
          collection(db, 'accounts'),
          where('businessId', '==', formData.businessId)
        );
        
        const querySnapshot = await getDocs(accountsQuery);
        const accountsData = [];
        
        querySnapshot.forEach((doc) => {
          accountsData.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setAccounts(accountsData);
        
        // Set default account if available
        if (accountsData.length > 0 && !formData.accountId) {
          setFormData(prev => ({
            ...prev,
            accountId: accountsData[0].id
          }));
        }
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };
    
    fetchAccounts();
  }, [formData.businessId]);
  
  // Fetch transaction data if editing
  useEffect(() => {
    const fetchTransactionData = async () => {
      if (!isEditing || !transactionId) return;
      
      setIsLoading(true);
      
      try {
        const transactionDoc = await getDoc(doc(db, 'financialTransactions', transactionId));
        
        if (transactionDoc.exists()) {
          const data = transactionDoc.data();
          
          // Set transaction type
          setTransactionType(data.type || 'simple');
          
          // Set direction for simple transactions
          if (data.type === 'simple') {
            setDirectionType(data.amount >= 0 ? 'income' : 'expense');
          }
          
          // Format dates
          const formattedData = {
            ...data,
            date: data.date?.toDate().toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
            issueDate: data.issueDate?.toDate().toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
            dueDate: data.dueDate?.toDate().toISOString().split('T')[0] || '',
            recurrenceEndDate: data.recurrenceEndDate?.toDate().toISOString().split('T')[0] || '',
            // Ensure amount is positive for display
            amount: Math.abs(data.amount || 0).toString()
          };
          
          setFormData(formattedData);
          
          // Load attachments
          if (data.attachments && data.attachments.length > 0) {
            setAttachments(data.attachments.map(att => ({
              ...att,
              id: att.id || uuidv4(),
              isUploaded: true
            })));
          }
          
          // Fetch related data based on transaction type
          if (data.type === 'invoice' && data.counterpartyId) {
            fetchCustomers();
          } else if (data.type === 'bill' && data.counterpartyId) {
            fetchSuppliers();
          }
          
          // Fetch products if we have line items
          if ((data.type === 'invoice' || data.type === 'bill') && data.items && data.items.length > 0) {
            fetchProducts();
          }
        } else {
          navigate('/finance');
        }
      } catch (error) {
        console.error('Error fetching transaction:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchTransactionData();
  }, [isEditing, transactionId, navigate]);
  
  // Fetch customers for invoices
  const fetchCustomers = async () => {
    if (!formData.businessId) return;
    
    try {
      const customersQuery = query(
        collection(db, 'customers'),
        where('businessId', '==', formData.businessId)
      );
      
      const querySnapshot = await getDocs(customersQuery);
      const customersData = [];
      
      querySnapshot.forEach((doc) => {
        customersData.push({
          id: doc.id,
          ...doc.data()
        });
      });
      
      setCustomers(customersData);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };
  
  // Fetch suppliers for bills
  const fetchSuppliers = async () => {
    if (!formData.businessId) return;
    
    try {
      const suppliersQuery = query(
        collection(db, 'suppliers'),
        where('businessId', '==', formData.businessId)
      );
      
      const querySnapshot = await getDocs(suppliersQuery);
      const suppliersData = [];
      
      querySnapshot.forEach((doc) => {
        suppliersData.push({
          id: doc.id,
          ...doc.data()
        });
      });
      
      setSuppliers(suppliersData);
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    }
  };
  
  // Fetch products for invoices and bills
  const fetchProducts = async () => {
    if (!formData.businessId) return;
    
    try {
      const productsQuery = query(
        collection(db, 'stockItems'),
        where('businessId', '==', formData.businessId)
      );
      
      const querySnapshot = await getDocs(productsQuery);
      const productsData = [];
      
      querySnapshot.forEach((doc) => {
        productsData.push({
          id: doc.id,
          ...doc.data()
        });
      });
      
      setProducts(productsData);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };
  
  // Handle transaction type change
  const handleTransactionTypeChange = (type) => {
    setTransactionType(type);
    
    // Load necessary data based on type
    if (type === 'invoice') {
      fetchCustomers();
      fetchProducts();
      setFormData(prev => ({
        ...prev,
        counterpartyType: 'customer',
        status: 'draft',
        documentNumber: generateDocumentNumber('INV')
      }));
    } else if (type === 'bill') {
      fetchSuppliers();
      fetchProducts();
      setFormData(prev => ({
        ...prev,
        counterpartyType: 'supplier',
        status: 'pending',
        documentNumber: generateDocumentNumber('BILL')
      }));
    }
  };
  
  // Generate document number
  const generateDocumentNumber = (prefix) => {
    const date = new Date();
    const year = date.getFullYear().toString().substr(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
    return `${prefix}-${year}${month}-${random}`;
  };
  
  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear errors for this field
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Handle direction change (income/expense)
  const handleDirectionChange = (direction) => {
    setDirectionType(direction);
    setFormData(prev => ({
      ...prev,
      category: '' // Reset category when direction changes
    }));
  };
  
  // Handle file upload
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    
    if (files.length === 0) return;
    
    // Validate files
    const validFiles = files.filter(file => {
      // Check file size (10MB limit)
      if (file.size > 10 * 1024 * 1024) {
        setFormErrors(prev => ({
          ...prev,
          attachments: `File ${file.name} exceeds the 10MB size limit`
        }));
        return false;
      }
      
      // Validate file type
      const validTypes = [
        'image/jpeg', 'image/png', 'image/jpg',
        'application/pdf', 
        'application/msword', 
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];
      
      if (!validTypes.includes(file.type)) {
        setFormErrors(prev => ({
          ...prev,
          attachments: `File ${file.name} has an unsupported format`
        }));
        return false;
      }
      
      return true;
    });
    
    // Add valid files to attachments
    const newAttachments = validFiles.map(file => ({
      id: uuidv4(),
      file,
      name: file.name,
      type: file.type.startsWith('image/') ? 'image' : 'document',
      isUploaded: false,
      previewUrl: file.type.startsWith('image/') ? URL.createObjectURL(file) : null
    }));
    
    setAttachments(prev => [...prev, ...newAttachments]);
    
    // Clear input
    e.target.value = null;
    
    // Clear errors
    if (formErrors.attachments) {
      setFormErrors(prev => ({ ...prev, attachments: '' }));
    }
  };
  
  // Remove attachment
  const handleRemoveAttachment = async (attachmentId) => {
    const attachmentToRemove = attachments.find(att => att.id === attachmentId);
    
    // If file is already uploaded to Firebase Storage
    if (attachmentToRemove && attachmentToRemove.isUploaded && attachmentToRemove.url) {
      try {
        const fileRef = ref(storage, attachmentToRemove.url);
        await deleteObject(fileRef);
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    }
    
    // If file has a preview URL
    if (attachmentToRemove && attachmentToRemove.previewUrl) {
      URL.revokeObjectURL(attachmentToRemove.previewUrl);
    }
    
    // Remove from state
    setAttachments(prev => prev.filter(att => att.id !== attachmentId));
  };
  
  // Handle line item changes
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...formData.items];
    updatedItems[index][field] = value;
    
    // Recalculate amounts
    if (field === 'quantity' || field === 'price' || field === 'taxRate') {
      const item = updatedItems[index];
      const quantity = parseFloat(item.quantity) || 0;
      const price = parseFloat(item.price) || 0;
      const taxRate = parseFloat(item.taxRate) || 0;
      
      item.amount = quantity * price;
      item.taxAmount = item.amount * (taxRate / 100);
      item.totalAmount = item.amount + item.taxAmount;
    }
    
    // Update form data and recalculate totals
    setFormData(prev => {
      const newData = {
        ...prev,
        items: updatedItems
      };
      
      // Recalculate totals
      calculateTotals(newData);
      
      return newData;
    });
  };
  
  // Add new line item
  const handleAddLineItem = () => {
    const newItem = {
      id: uuidv4(),
      productId: '',
      description: '',
      quantity: '1',
      price: '0',
      taxRate: '0',
      amount: 0,
      taxAmount: 0,
      totalAmount: 0
    };
    
    setFormData(prev => ({
      ...prev,
      items: [...prev.items, newItem]
    }));
  };
  
  // Remove line item
  const handleRemoveLineItem = (index) => {
    const updatedItems = [...formData.items];
    updatedItems.splice(index, 1);
    
    setFormData(prev => {
      const newData = {
        ...prev,
        items: updatedItems
      };
      
      // Recalculate totals
      calculateTotals(newData);
      
      return newData;
    });
  };
  
  // Calculate invoice/bill totals
  const calculateTotals = (data) => {
    const items = data.items || [];
    
    let subtotal = 0;
    let taxAmount = 0;
    
    items.forEach(item => {
      subtotal += parseFloat(item.amount) || 0;
      taxAmount += parseFloat(item.taxAmount) || 0;
    });
    
    const totalAmount = subtotal + taxAmount;
    const balanceDue = totalAmount - (parseFloat(data.paidAmount) || 0);
    
    data.subtotal = subtotal;
    data.taxAmount = taxAmount;
    data.totalAmount = totalAmount;
    data.balanceDue = balanceDue;
    
    return data;
  };
  
  // Upload file to Firebase Storage
  const uploadFileToStorage = async (file) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `finance/${currentUser.uid}/${Date.now()}_${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      uploadTask.on('state_changed', 
        (snapshot) => {
          // Track upload progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(prev => ({
            ...prev,
            [file.name]: progress
          }));
        },
        (error) => {
          console.error('Upload error:', error);
          reject(error);
        },
        async () => {
          // Get download URL on completion
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };
  
  // Form validation
  const validateForm = () => {
    const errors = {};
    
    // Required fields for all transaction types
    if (!formData.businessId) errors.businessId = 'Business is required';
    if (!formData.accountId) errors.accountId = 'Account is required';
    if (!formData.date) errors.date = 'Date is required';
    if (!formData.description.trim()) errors.description = 'Description is required';
    
    // Validate amount for simple transactions
    if (transactionType === 'simple' && (!formData.amount || isNaN(parseFloat(formData.amount)))) {
      errors.amount = 'Valid amount is required';
    }
    
    // Validate category
    if (transactionType === 'simple' && !formData.category) {
      errors.category = 'Category is required';
    }
    
    // Validate counterparty for invoices/bills
    if ((transactionType === 'invoice' || transactionType === 'bill') && !formData.counterpartyId) {
      errors.counterpartyId = `${transactionType === 'invoice' ? 'Customer' : 'Supplier'} is required`;
    }
    
    // Validate due date for invoices/bills
    if ((transactionType === 'invoice' || transactionType === 'bill') && !formData.dueDate) {
      errors.dueDate = 'Due date is required';
    }
    
    // Validate line items for invoices/bills
    if ((transactionType === 'invoice' || transactionType === 'bill')) {
      if (!formData.items || formData.items.length === 0) {
        errors.items = 'At least one line item is required';
      } else {
        // Check each line item
        const itemErrors = [];
        formData.items.forEach((item, index) => {
          const itemError = {};
          
          if (!item.description.trim()) itemError.description = 'Description is required';
          if (!item.quantity || isNaN(parseFloat(item.quantity)) || parseFloat(item.quantity) <= 0) {
            itemError.quantity = 'Valid quantity is required';
          }
          if (!item.price || isNaN(parseFloat(item.price))) {
            itemError.price = 'Valid price is required';
          }
          
          if (Object.keys(itemError).length > 0) {
            itemErrors[index] = itemError;
          }
        });
        
        if (itemErrors.length > 0) {
          errors.lineItems = itemErrors;
        }
      }
    }
    
    // Validate budget fields
    if (transactionType === 'budget') {
      if (!formData.fiscalPeriod) errors.fiscalPeriod = 'Fiscal period is required';
      if (!formData.plannedAmount || isNaN(parseFloat(formData.plannedAmount))) {
        errors.plannedAmount = 'Valid planned amount is required';
      }
    }
    
    // Validate recurring transaction
    if (formData.isRecurring) {
      if (!formData.recurrenceFrequency) errors.recurrenceFrequency = 'Frequency is required';
      if (!formData.recurrenceInterval || isNaN(parseInt(formData.recurrenceInterval)) || parseInt(formData.recurrenceInterval) <= 0) {
        errors.recurrenceInterval = 'Valid interval is required';
      }
      if (!formData.recurrenceEndDate && !formData.recurrenceEndOccurrences) {
        errors.recurrenceEnd = 'End date or number of occurrences is required';
      }
    }
    
    return errors;
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isDemoMode) {
      alert("Demo Mode Restriction:\nIn demo mode, data modifications are not saved. Sign up for a free account to use all features.");
      return;
    }
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      
      // Scroll to first error
      const firstErrorElement = document.querySelector('.text-red-600');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      return;
    }
    
    setIsLoading(true);
    
    try {
      // 1. Upload attachments
      const attachmentPromises = attachments
        .filter(att => !att.isUploaded)
        .map(async (attachment) => {
          if (attachment.file) {
            const url = await uploadFileToStorage(attachment.file);
            return {
              id: attachment.id,
              name: attachment.name,
              type: attachment.type,
              url,
              uploadedAt: new Date()
            };
          }
          return null;
        });
      
      const uploadedAttachments = (await Promise.all(attachmentPromises)).filter(Boolean);
      
      // Combine with existing attachments
      const existingAttachments = attachments
        .filter(att => att.isUploaded)
        .map(({ id, name, type, url, uploadedAt }) => ({ id, name, type, url, uploadedAt }));
      
      const allAttachments = [...existingAttachments, ...uploadedAttachments];
      
      // 2. Prepare transaction data
      const transactionData = {
        businessId: formData.businessId,
        accountId: formData.accountId,
        type: transactionType,
        
        // Common fields
        description: formData.description,
        notes: formData.notes,
        attachments: allAttachments,
        
        // Type-specific fields
        ...(transactionType === 'simple' && {
          date: new Date(formData.date),
          amount: directionType === 'expense' ? -Math.abs(parseFloat(formData.amount)) : Math.abs(parseFloat(formData.amount)),
          category: formData.category,
          subcategory: formData.subcategory,
          reference: formData.reference,
          paymentMethod: formData.paymentMethod
        }),
        
        ...(transactionType === 'invoice' && {
          documentNumber: formData.documentNumber,
          counterpartyId: formData.counterpartyId,
          counterpartyType: 'customer',
          issueDate: new Date(formData.issueDate),
          dueDate: new Date(formData.dueDate),
          items: formData.items,
          subtotal: formData.subtotal,
          taxAmount: formData.taxAmount,
          totalAmount: formData.totalAmount,
          paidAmount: formData.paidAmount || 0,
          balanceDue: formData.balanceDue,
          status: formData.status
        }),
        
        ...(transactionType === 'bill' && {
          documentNumber: formData.documentNumber,
          counterpartyId: formData.counterpartyId,
          counterpartyType: 'supplier',
          issueDate: new Date(formData.issueDate),
          dueDate: new Date(formData.dueDate),
          items: formData.items,
          subtotal: formData.subtotal,
          taxAmount: formData.taxAmount,
          totalAmount: formData.totalAmount,
          paidAmount: formData.paidAmount || 0,
          balanceDue: formData.balanceDue,
          status: formData.status
        }),
        
        ...(transactionType === 'budget' && {
          date: new Date(formData.date),
          fiscalPeriod: formData.fiscalPeriod,
          plannedAmount: parseFloat(formData.plannedAmount),
          category: formData.category,
          budgetCategoryId: formData.budgetCategoryId
        }),
        
        // Recurring information if applicable
        isRecurring: formData.isRecurring,
        ...(formData.isRecurring && {
          recurrencePattern: {
            frequency: formData.recurrenceFrequency,
            interval: parseInt(formData.recurrenceInterval),
            endDate: formData.recurrenceEndDate ? new Date(formData.recurrenceEndDate) : null,
            endAfterOccurrences: formData.recurrenceEndOccurrences ? parseInt(formData.recurrenceEndOccurrences) : null
          }
        }),
        
        // Metadata
        updatedAt: serverTimestamp()
      };
      
      // 3. Save to Firestore
      if (isEditing) {
        await updateDoc(doc(db, 'financialTransactions', transactionId), transactionData);
      } else {
        transactionData.createdAt = serverTimestamp();
        transactionData.createdBy = currentUser.uid;
        
        await addDoc(collection(db, 'financialTransactions'), transactionData);
      }
      
      // 4. Navigate back to finance list
      navigate('/finance');
      
    } catch (error) {
      console.error('Error saving transaction:', error);
      setFormErrors(prev => ({
        ...prev,
        submit: 'An error occurred. Please try again.'
      }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div className="bg-white shadow-md px-6 py-4 text-gray-800">
        <h2 className="text-lg font-medium">
          {isEditing ? 'Edit Financial Transaction' : 'New Financial Transaction'}
        </h2>
        <p className="text-purple-500 text-sm">
          {isEditing 
            ? 'Update transaction details below' 
            : 'Record a new financial transaction for your business'}
        </p>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        <div className="mb-6">
          <button 
            type="button"
            onClick={() => navigate('/finance')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Finance
          </button>
        </div>
        
        {/* Transaction Type Selector */}
        <div className="mb-8">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Transaction Type
          </label>
          <div className="flex overflow-x-auto pb-2 space-x-3 hide-scrollbar">
            {TRANSACTION_TYPES.map(type => (
              <div
                key={type.id}
                onClick={() => !isEditing && handleTransactionTypeChange(type.id)}
                className={`border rounded-lg p-4 flex-shrink-0 w-full sm:w-auto flex flex-col cursor-pointer transition-colors ${
                  transactionType === type.id
                    ? 'bg-purple-50 border-[#190192] text-[#190192]'
                    : 'border-gray-200 hover:border-gray-300'
                } ${isEditing && transactionType !== type.id ? 'opacity-50 cursor-not-allowed' : ''}`}
             >
               <div className="flex items-center justify-between mb-2">
                 <div className="flex items-center">
                   <type.icon size={20} className={transactionType === type.id ? 'text-[#190192]' : 'text-gray-500'} />
                   <span className="ml-2 font-medium">{type.label}</span>
                 </div>
                 {transactionType === type.id && <Check size={16} />}
               </div>
               <p className="text-xs text-gray-500">{type.description}</p>
             </div>
           ))}
         </div>
       </div>
       
       {/* Basic Information */}
       <div className="mb-8">
         <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
         
         <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
           {/* Business Selection */}
           <div>
             <label htmlFor="businessId" className="block text-sm font-medium text-gray-700 mb-1">
               Business <span className="text-red-500">*</span>
             </label>
             <select
               id="businessId"
               name="businessId"
               className={`block w-full rounded-lg border ${
                 formErrors.businessId ? 'border-red-300' : 'border-gray-300'
               } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
               value={formData.businessId}
               onChange={handleInputChange}
               disabled={isLoading}
             >
               <option value="">Select a business</option>
               {businesses.map(business => (
                 <option key={business.id} value={business.id}>{business.name}</option>
               ))}
             </select>
             {formErrors.businessId && (
               <p className="mt-1 text-red-600 text-xs flex items-center">
                 <AlertCircle size={12} className="mr-1" /> {formErrors.businessId}
               </p>
             )}
           </div>
           
           {/* Account Selection */}
           <div>
             <label htmlFor="accountId" className="block text-sm font-medium text-gray-700 mb-1">
               Account <span className="text-red-500">*</span>
             </label>
             <select
               id="accountId"
               name="accountId"
               className={`block w-full rounded-lg border ${
                 formErrors.accountId ? 'border-red-300' : 'border-gray-300'
               } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
               value={formData.accountId}
               onChange={handleInputChange}
               disabled={isLoading}
             >
               <option value="">Select an account</option>
               {accounts.map(account => (
                 <option key={account.id} value={account.id}>
                   {account.accountName} ({account.accountType})
                 </option>
               ))}
             </select>
             {formErrors.accountId && (
               <p className="mt-1 text-red-600 text-xs flex items-center">
                 <AlertCircle size={12} className="mr-1" /> {formErrors.accountId}
               </p>
             )}
             {accounts.length === 0 && (
               <p className="mt-1 text-amber-600 text-xs flex items-center">
                 <AlertCircle size={12} className="mr-1" /> No accounts found. Please create an account first.
               </p>
             )}
           </div>
         </div>
         
         <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
           {/* Date */}
           <div>
             <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
               Date <span className="text-red-500">*</span>
             </label>
             <div className="relative">
               <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                 <Calendar size={16} className="text-gray-400" />
               </div>
               <input
                 id="date"
                 name="date"
                 type="date"
                 className={`pl-10 block w-full rounded-lg border ${
                   formErrors.date ? 'border-red-300' : 'border-gray-300'
                 } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                 value={formData.date}
                 onChange={handleInputChange}
                 disabled={isLoading}
               />
             </div>
             {formErrors.date && (
               <p className="mt-1 text-red-600 text-xs flex items-center">
                 <AlertCircle size={12} className="mr-1" /> {formErrors.date}
               </p>
             )}
           </div>
           
           {/* Amount for simple transaction */}
           {transactionType === 'simple' && (
             <div>
               <label htmlFor="amount" className="block text-sm font-medium text-gray-700 mb-1">
                 Amount <span className="text-red-500">*</span>
               </label>
               <div className="relative">
                 <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                   <DollarSign size={16} className="text-gray-400" />
                 </div>
                 <input
                   id="amount"
                   name="amount"
                   type="text"
                   className={`pl-10 block w-full rounded-lg border ${
                     formErrors.amount ? 'border-red-300' : 'border-gray-300'
                   } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                   placeholder="0.00"
                   value={formData.amount}
                   onChange={handleInputChange}
                   disabled={isLoading}
                 />
               </div>
               {formErrors.amount && (
                 <p className="mt-1 text-red-600 text-xs flex items-center">
                   <AlertCircle size={12} className="mr-1" /> {formErrors.amount}
                 </p>
               )}
             </div>
           )}
           
           {/* Document Number for invoice/bill */}
           {(transactionType === 'invoice' || transactionType === 'bill') && (
             <div>
               <label htmlFor="documentNumber" className="block text-sm font-medium text-gray-700 mb-1">
                 {transactionType === 'invoice' ? 'Invoice Number' : 'Bill Number'}
               </label>
               <input
                 id="documentNumber"
                 name="documentNumber"
                 type="text"
                 className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                 value={formData.documentNumber}
                 onChange={handleInputChange}
                 disabled={isLoading}
               />
             </div>
           )}
         </div>
         
         {/* Description */}
         <div className="mb-4">
           <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
             Description <span className="text-red-500">*</span>
           </label>
           <input
             id="description"
             name="description"
             type="text"
             className={`block w-full rounded-lg border ${
               formErrors.description ? 'border-red-300' : 'border-gray-300'
             } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
             placeholder="Enter a clear description"
             value={formData.description}
             onChange={handleInputChange}
             disabled={isLoading}
           />
           {formErrors.description && (
             <p className="mt-1 text-red-600 text-xs flex items-center">
               <AlertCircle size={12} className="mr-1" /> {formErrors.description}
             </p>
           )}
         </div>
         
         {/* Notes */}
         <div>
           <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
             Notes (Optional)
           </label>
           <textarea
             id="notes"
             name="notes"
             rows="3"
             className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
             placeholder="Add any additional details"
             value={formData.notes}
             onChange={handleInputChange}
             disabled={isLoading}
           />
         </div>
       </div>
       
       {/* Transaction-specific sections */}
       {transactionType === 'simple' && (
         <div className="mb-8">
           <h3 className="text-lg font-medium text-gray-900 mb-4">Transaction Details</h3>
           
           {/* Income/Expense Selection */}
           <div className="mb-4">
             <label className="block text-sm font-medium text-gray-700 mb-2">
               Transaction Type <span className="text-red-500">*</span>
             </label>
             <div className="flex space-x-4">
               <button
                 type="button"
                 onClick={() => handleDirectionChange('income')}
                 className={`flex-1 py-2 px-4 rounded-lg border flex items-center justify-center ${
                   directionType === 'income'
                     ? 'bg-green-50 border-green-500 text-green-700'
                     : 'border-gray-300 text-gray-700'
                 }`}
               >
                 <ArrowUpRight size={18} className={`mr-2 ${directionType === 'income' ? 'text-green-600' : 'text-gray-500'}`} />
                 Income
               </button>
               <button
                 type="button"
                 onClick={() => handleDirectionChange('expense')}
                 className={`flex-1 py-2 px-4 rounded-lg border flex items-center justify-center ${
                   directionType === 'expense'
                     ? 'bg-red-50 border-red-500 text-red-700'
                     : 'border-gray-300 text-gray-700'
                 }`}
               >
                 <ArrowUpRight size={18} className={`mr-2 transform rotate-180 ${directionType === 'expense' ? 'text-red-600' : 'text-gray-500'}`} />
                 Expense
               </button>
             </div>
           </div>
           
           <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
             {/* Category */}
             <div>
               <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                 Category <span className="text-red-500">*</span>
               </label>
               <div className="relative">
                 <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                   <Tag size={16} className="text-gray-400" />
                 </div>
                 <select
                   id="category"
                   name="category"
                   className={`pl-10 block w-full rounded-lg border ${
                     formErrors.category ? 'border-red-300' : 'border-gray-300'
                   } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                   value={formData.category}
                   onChange={handleInputChange}
                   disabled={isLoading}
                 >
                   <option value="">Select a category</option>
                   {TRANSACTION_CATEGORIES[directionType].map(category => (
                     <option key={category.id} value={category.id}>{category.label}</option>
                   ))}
                 </select>
               </div>
               {formErrors.category && (
                 <p className="mt-1 text-red-600 text-xs flex items-center">
                   <AlertCircle size={12} className="mr-1" /> {formErrors.category}
                 </p>
               )}
             </div>
             
             {/* Reference Number */}
             <div>
               <label htmlFor="reference" className="block text-sm font-medium text-gray-700 mb-1">
                 Reference Number (Optional)
               </label>
               <input
                 id="reference"
                 name="reference"
                 type="text"
                 className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                 placeholder="e.g., Receipt #, Check #"
                 value={formData.reference}
                 onChange={handleInputChange}
                 disabled={isLoading}
               />
             </div>
           </div>
           
           {/* Payment Method */}
           <div>
             <label htmlFor="paymentMethod" className="block text-sm font-medium text-gray-700 mb-1">
               Payment Method
             </label>
             <select
               id="paymentMethod"
               name="paymentMethod"
               className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
               value={formData.paymentMethod}
               onChange={handleInputChange}
               disabled={isLoading}
             >
               <option value="bank_transfer">Bank Transfer</option>
               <option value="cash">Cash</option>
               <option value="credit_card">Credit Card</option>
               <option value="check">Check</option>
               <option value="other">Other</option>
             </select>
           </div>
         </div>
       )}
       
       {/* Invoice or Bill specific section */}
       {(transactionType === 'invoice' || transactionType === 'bill') && (
         <div className="mb-8">
           <h3 className="text-lg font-medium text-gray-900 mb-4">
             {transactionType === 'invoice' ? 'Invoice Details' : 'Bill Details'}
           </h3>
           
           <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
             {/* Customer or Supplier */}
             <div>
               <label htmlFor="counterpartyId" className="block text-sm font-medium text-gray-700 mb-1">
                 {transactionType === 'invoice' ? 'Customer' : 'Supplier'} <span className="text-red-500">*</span>
               </label>
               <div className="relative">
                 <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                   {transactionType === 'invoice' ? 
                     <Users size={16} className="text-gray-400" /> : 
                     <Building size={16} className="text-gray-400" />
                   }
                 </div>
                 <select
                   id="counterpartyId"
                   name="counterpartyId"
                   className={`pl-10 block w-full rounded-lg border ${
                     formErrors.counterpartyId ? 'border-red-300' : 'border-gray-300'
                   } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                   value={formData.counterpartyId}
                   onChange={handleInputChange}
                   disabled={isLoading}
                 >
                   <option value="">Select a {transactionType === 'invoice' ? 'customer' : 'supplier'}</option>
                   {(transactionType === 'invoice' ? customers : suppliers).map(entity => (
                     <option key={entity.id} value={entity.id}>
                       {entity.name || entity.businessName || `${entity.firstName || ''} ${entity.lastName || ''}`.trim()}
                     </option>
                   ))}
                 </select>
               </div>
               {formErrors.counterpartyId && (
                 <p className="mt-1 text-red-600 text-xs flex items-center">
                   <AlertCircle size={12} className="mr-1" /> {formErrors.counterpartyId}
                 </p>
               )}
               {((transactionType === 'invoice' && customers.length === 0) || 
                 (transactionType === 'bill' && suppliers.length === 0)) && (
                 <div className="mt-1 flex justify-between items-center">
                   <p className="text-amber-600 text-xs flex items-center">
                     <AlertCircle size={12} className="mr-1" /> 
                     No {transactionType === 'invoice' ? 'customers' : 'suppliers'} found
                   </p>
                   <button
                     type="button"
                     className="text-xs text-[#190192] hover:text-[#2C0DB5] flex items-center"
                     onClick={() => navigate(`/${transactionType === 'invoice' ? 'customers' : 'suppliers'}/new`)}
                   >
                     <PlusCircle size={12} className="mr-1" />
                     Add new
                   </button>
                 </div>
               )}
             </div>
             
             {/* Due Date */}
             <div>
               <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700 mb-1">
                 Due Date <span className="text-red-500">*</span>
               </label>
               <div className="relative">
                 <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                   <Calendar size={16} className="text-gray-400" />
                 </div>
                 <input
                   id="dueDate"
                   name="dueDate"
                   type="date"
                   className={`pl-10 block w-full rounded-lg border ${
                     formErrors.dueDate ? 'border-red-300' : 'border-gray-300'
                   } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                   value={formData.dueDate}
                   onChange={handleInputChange}
                   disabled={isLoading}
                 />
               </div>
               {formErrors.dueDate && (
                 <p className="mt-1 text-red-600 text-xs flex items-center">
                   <AlertCircle size={12} className="mr-1" /> {formErrors.dueDate}
                 </p>
               )}
             </div>
           </div>
           
           {/* Line Items */}
           <div className="mb-4">
             <div className="flex justify-between items-center mb-2">
               <label className="block text-sm font-medium text-gray-700">
                 Line Items <span className="text-red-500">*</span>
               </label>
               <button
                 type="button"
                 onClick={handleAddLineItem}
                 className="text-[#190192] hover:text-[#2C0DB5] text-sm font-medium flex items-center"
               >
                 <PlusCircle size={16} className="mr-1" />
                 Add Item
               </button>
             </div>
             
             {formErrors.items && (
               <p className="mb-2 text-red-600 text-xs flex items-center">
                 <AlertCircle size={12} className="mr-1" /> {formErrors.items}
               </p>
             )}
             
             <div className="border rounded-lg overflow-hidden">
               {/* Table Header */}
               <div className="grid grid-cols-12 gap-2 bg-gray-50 p-3 border-b text-xs font-medium text-gray-700">
                 <div className="col-span-4">Description</div>
                 <div className="col-span-2 text-center">Quantity</div>
                 <div className="col-span-2 text-center">Price</div>
                 <div className="col-span-1 text-center">Tax %</div>
                 <div className="col-span-2 text-right">Amount</div>
                 <div className="col-span-1 text-center">Action</div>
               </div>
               
               {/* Line Items */}
               {formData.items.length === 0 ? (
                 <div className="p-4 text-center text-gray-500">
                   No items added yet. Click "Add Item" to begin.
                 </div>
               ) : (
                 <div className="divide-y">
                   {formData.items.map((item, index) => (
                     <div key={item.id} className="grid grid-cols-12 gap-2 p-3 items-center">
                       {/* Description */}
                       <div className="col-span-4">
                         <input
                           type="text"
                           className={`block w-full rounded border ${
                             formErrors.lineItems && formErrors.lineItems[index]?.description 
                               ? 'border-red-300' : 'border-gray-300'
                           } py-1.5 px-2 text-sm focus:outline-none focus:ring-1 focus:ring-[#190192] focus:border-[#190192]`}
                           placeholder="Item description"
                           value={item.description}
                           onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                           disabled={isLoading}
                         />
                         {formErrors.lineItems && formErrors.lineItems[index]?.description && (
                           <p className="mt-1 text-red-600 text-xs">
                             {formErrors.lineItems[index].description}
                           </p>
                         )}
                       </div>
                       
                       {/* Quantity */}
                       <div className="col-span-2">
                         <input
                           type="text"
                           className={`block w-full rounded border ${
                             formErrors.lineItems && formErrors.lineItems[index]?.quantity 
                               ? 'border-red-300' : 'border-gray-300'
                           } py-1.5 px-2 text-sm focus:outline-none focus:ring-1 focus:ring-[#190192] focus:border-[#190192] text-center`}
                           placeholder="0"
                           value={item.quantity}
                           onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                           disabled={isLoading}
                         />
                         {formErrors.lineItems && formErrors.lineItems[index]?.quantity && (
                           <p className="mt-1 text-red-600 text-xs text-center">
                             {formErrors.lineItems[index].quantity}
                           </p>
                         )}
                       </div>
                       
                       {/* Price */}
                       <div className="col-span-2">
                         <div className="relative">
                           <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                             <DollarSign size={12} className="text-gray-400" />
                           </div>
                           <input
                             type="text"
                             className={`block w-full rounded border ${
                               formErrors.lineItems && formErrors.lineItems[index]?.price 
                                 ? 'border-red-300' : 'border-gray-300'
                             } py-1.5 pl-6 pr-2 text-sm focus:outline-none focus:ring-1 focus:ring-[#190192] focus:border-[#190192] text-right`}
                             placeholder="0.00"
                             value={item.price}
                             onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                             disabled={isLoading}
                           />
                         </div>
                         {formErrors.lineItems && formErrors.lineItems[index]?.price && (
                           <p className="mt-1 text-red-600 text-xs text-center">
                             {formErrors.lineItems[index].price}
                           </p>
                         )}
                       </div>
                       
                       {/* Tax Rate */}
                       <div className="col-span-1">
                         <div className="relative">
                           <input
                             type="text"
                             className="block w-full rounded border border-gray-300 py-1.5 pr-4 text-sm focus:outline-none focus:ring-1 focus:ring-[#190192] focus:border-[#190192] text-center"
                             placeholder="0"
                             value={item.taxRate}
                             onChange={(e) => handleItemChange(index, 'taxRate', e.target.value)}
                             disabled={isLoading}
                           />
                           <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
                             <span className="text-gray-400 text-xs">%</span>
                           </div>
                         </div>
                       </div>
                       
                       {/* Amount */}
                       <div className="col-span-2 text-right font-medium">
                         ${(parseFloat(item.amount) || 0).toFixed(2)}
                       </div>
                       
                       {/* Remove Button */}
                       <div className="col-span-1 text-center">
                         <button
                           type="button"
                           onClick={() => handleRemoveLineItem(index)}
                           className="text-red-500 hover:text-red-700"
                           disabled={isLoading}
                         >
                           <Trash size={16} />
                         </button>
                       </div>
                     </div>
                   ))}
                 </div>
               )}
               
               {/* Totals */}
               <div className="bg-gray-50 p-3 border-t">
                 <div className="grid grid-cols-12 gap-2">
                   <div className="col-span-9 text-right font-medium text-sm text-gray-700">Subtotal:</div>
                   <div className="col-span-2 text-right font-medium text-sm">${formData.subtotal.toFixed(2)}</div>
                   <div className="col-span-1"></div>
                 </div>
                 <div className="grid grid-cols-12 gap-2 mt-1">
                   <div className="col-span-9 text-right font-medium text-sm text-gray-700">Tax:</div>
                   <div className="col-span-2 text-right font-medium text-sm">${formData.taxAmount.toFixed(2)}</div>
                   <div className="col-span-1"></div>
                 </div>
                 <div className="grid grid-cols-12 gap-2 mt-2 pt-2 border-t border-gray-200">
                   <div className="col-span-9 text-right font-medium text-gray-900">Total:</div>
                   <div className="col-span-2 text-right font-bold text-[#190192]">${formData.totalAmount.toFixed(2)}</div>
                   <div className="col-span-1"></div>
                 </div>
               </div>
             </div>
           </div>
           
           {/* Status Selection */}
           <div className="mb-4">
             <label className="block text-sm font-medium text-gray-700 mb-2">
               Status
             </label>
             <div className="overflow-x-auto pb-2">
              <div className="flex space-x-2 min-w-max">
                {transactionType === 'invoice' ? (
                  <>
                    <button
                      type="button"
                      onClick={() => setFormData(prev => ({ ...prev, status: 'draft' }))}
                      className={`px-3 py-1.5 rounded-lg border ${
                        formData.status === 'draft' 
                          ? 'bg-gray-100 border-gray-400 text-gray-800' 
                          : 'border-gray-300 text-gray-600'
                      }`}
                    >
                     Draft
                   </button>
                   <button
                     type="button"
                     onClick={() => setFormData(prev => ({ ...prev, status: 'sent' }))}
                     className={`px-3 py-1.5 rounded-lg border ${
                       formData.status === 'sent' 
                         ? 'bg-blue-100 border-blue-400 text-blue-800' 
                         : 'border-gray-300 text-gray-600'
                     }`}
                   >
                     Sent
                   </button>
                   <button
                     type="button"
                     onClick={() => setFormData(prev => ({ ...prev, status: 'paid' }))}
                     className={`px-3 py-1.5 rounded-lg border ${
                       formData.status === 'paid' 
                         ? 'bg-green-100 border-green-400 text-green-800' 
                         : 'border-gray-300 text-gray-600'
                     }`}
                   >
                     Paid
                   </button>
                   <button
                     type="button"
                     onClick={() => setFormData(prev => ({ ...prev, status: 'overdue' }))}
                     className={`px-3 py-1.5 rounded-lg border ${
                       formData.status === 'overdue' 
                         ? 'bg-red-100 border-red-400 text-red-800' 
                         : 'border-gray-300 text-gray-600'
                     }`}
                   >
                     Overdue
                   </button>
                 </>
               ) : (
                 <>
                   <button
                     type="button"
                     onClick={() => setFormData(prev => ({ ...prev, status: 'pending' }))}
                     className={`px-3 py-1.5 rounded-lg border ${
                       formData.status === 'pending' 
                         ? 'bg-amber-100 border-amber-400 text-amber-800' 
                         : 'border-gray-300 text-gray-600'
                     }`}
                   >
                     Pending
                   </button>
                   <button
                     type="button"
                     onClick={() => setFormData(prev => ({ ...prev, status: 'paid' }))}
                     className={`px-3 py-1.5 rounded-lg border ${
                       formData.status === 'paid' 
                         ? 'bg-green-100 border-green-400 text-green-800' 
                         : 'border-gray-300 text-gray-600'
                     }`}
                   >
                     Paid
                   </button>
                   <button
                     type="button"
                     onClick={() => setFormData(prev => ({ ...prev, status: 'overdue' }))}
                     className={`px-3 py-1.5 rounded-lg border ${
                       formData.status === 'overdue' 
                         ? 'bg-red-100 border-red-400 text-red-800' 
                         : 'border-gray-300 text-gray-600'
                     }`}
                   >
                     Overdue
                   </button>
                 </>
               )}
               </div>
             </div>
           </div>
         </div>
       )}
       
       {/* Budget specific section */}
       {transactionType === 'budget' && (
         <div className="mb-8">
           <h3 className="text-lg font-medium text-gray-900 mb-4">Budget Entry</h3>
           
           <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
             {/* Fiscal Period */}
             <div>
               <label htmlFor="fiscalPeriod" className="block text-sm font-medium text-gray-700 mb-1">
                 Fiscal Period <span className="text-red-500">*</span>
               </label>
               <select
                 id="fiscalPeriod"
                 name="fiscalPeriod"
                 className={`block w-full rounded-lg border ${
                   formErrors.fiscalPeriod ? 'border-red-300' : 'border-gray-300'
                 } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                 value={formData.fiscalPeriod}
                 onChange={handleInputChange}
                 disabled={isLoading}
               >
                 <option value="">Select a period</option>
                 <option value="Q1">Q1</option>
                 <option value="Q2">Q2</option>
                 <option value="Q3">Q3</option>
                 <option value="Q4">Q4</option>
                 <option value="annual">Annual</option>
               </select>
               {formErrors.fiscalPeriod && (
                 <p className="mt-1 text-red-600 text-xs flex items-center">
                   <AlertCircle size={12} className="mr-1" /> {formErrors.fiscalPeriod}
                 </p>
               )}
             </div>
             
             {/* Category */}
             <div>
               <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                 Budget Category <span className="text-red-500">*</span>
               </label>
               <div className="relative">
                 <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                   <Tag size={16} className="text-gray-400" />
                 </div>
                 <select
                   id="category"
                   name="category"
                   className={`pl-10 block w-full rounded-lg border ${
                     formErrors.category ? 'border-red-300' : 'border-gray-300'
                   } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                   value={formData.category}
                   onChange={handleInputChange}
                   disabled={isLoading}
                 >
                   <option value="">Select a category</option>
                   {[...TRANSACTION_CATEGORIES.income, ...TRANSACTION_CATEGORIES.expense].map(category => (
                     <option key={category.id} value={category.id}>{category.label}</option>
                   ))}
                 </select>
               </div>
               {formErrors.category && (
                 <p className="mt-1 text-red-600 text-xs flex items-center">
                   <AlertCircle size={12} className="mr-1" /> {formErrors.category}
                 </p>
               )}
             </div>
           </div>
           
           {/* Planned Amount */}
           <div>
             <label htmlFor="plannedAmount" className="block text-sm font-medium text-gray-700 mb-1">
               Planned Amount <span className="text-red-500">*</span>
             </label>
             <div className="relative">
               <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                 <DollarSign size={16} className="text-gray-400" />
               </div>
               <input
                 id="plannedAmount"
                 name="plannedAmount"
                 type="text"
                 className={`pl-10 block w-full rounded-lg border ${
                   formErrors.plannedAmount ? 'border-red-300' : 'border-gray-300'
                 } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                 placeholder="0.00"
                 value={formData.plannedAmount}
                 onChange={handleInputChange}
                 disabled={isLoading}
               />
             </div>
             {formErrors.plannedAmount && (
               <p className="mt-1 text-red-600 text-xs flex items-center">
                 <AlertCircle size={12} className="mr-1" /> {formErrors.plannedAmount}
               </p>
             )}
           </div>
         </div>
       )}
       
       {/* Recurring Settings */}
       <div className="mb-8">
         <div className="flex items-center mb-4">
           <input
             id="isRecurring"
             name="isRecurring"
             type="checkbox"
             className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
             checked={formData.isRecurring}
             onChange={handleInputChange}
             disabled={isLoading}
           />
           <label htmlFor="isRecurring" className="ml-2 block text-sm font-medium text-gray-700">
             This is a recurring transaction
           </label>
         </div>
         
         {formData.isRecurring && (
           <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
             <h4 className="font-medium text-blue-800 mb-3">Recurrence Pattern</h4>
             
             <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
               {/* Frequency */}
               <div>
                 <label htmlFor="recurrenceFrequency" className="block text-sm font-medium text-gray-700 mb-1">
                   Frequency <span className="text-red-500">*</span>
                 </label>
                 <div className="relative">
                   <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                     <Repeat size={16} className="text-gray-400" />
                   </div>
                   <select
                     id="recurrenceFrequency"
                     name="recurrenceFrequency"
                     className={`pl-10 block w-full rounded-lg border ${
                       formErrors.recurrenceFrequency ? 'border-red-300' : 'border-gray-300'
                     } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                     value={formData.recurrenceFrequency}
                     onChange={handleInputChange}
                     disabled={isLoading}
                   >
                     <option value="daily">Daily</option>
                     <option value="weekly">Weekly</option>
                     <option value="monthly">Monthly</option>
                     <option value="yearly">Yearly</option>
                   </select>
                 </div>
                 {formErrors.recurrenceFrequency && (
                   <p className="mt-1 text-red-600 text-xs flex items-center">
                     <AlertCircle size={12} className="mr-1" /> {formErrors.recurrenceFrequency}
                   </p>
                 )}
               </div>
               
               {/* Interval */}
               <div>
                 <label htmlFor="recurrenceInterval" className="block text-sm font-medium text-gray-700 mb-1">
                   Interval <span className="text-red-500">*</span>
                 </label>
                 <div className="relative">
                   <input
                     id="recurrenceInterval"
                     name="recurrenceInterval"
                     type="number"
                     min="1"
                     className={`block w-full rounded-lg border ${
                       formErrors.recurrenceInterval ? 'border-red-300' : 'border-gray-300'
                     } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                     value={formData.recurrenceInterval}
                     onChange={handleInputChange}
                     disabled={isLoading}
                   />
                   <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                     <span className="text-gray-500 text-sm">
                       {formData.recurrenceFrequency === 'daily' ? 'days' :
                        formData.recurrenceFrequency === 'weekly' ? 'weeks' :
                        formData.recurrenceFrequency === 'monthly' ? 'months' : 'years'}
                     </span>
                   </div>
                 </div>
                 {formErrors.recurrenceInterval && (
                   <p className="mt-1 text-red-600 text-xs flex items-center">
                     <AlertCircle size={12} className="mr-1" /> {formErrors.recurrenceInterval}
                   </p>
                 )}
               </div>
             </div>
             
             <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
               {/* End Date */}
               <div>
                 <label htmlFor="recurrenceEndDate" className="block text-sm font-medium text-gray-700 mb-1">
                   End Date (Optional)
                 </label>
                 <div className="relative">
                   <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                     <CalendarRange size={16} className="text-gray-400" />
                   </div>
                   <input
                     id="recurrenceEndDate"
                     name="recurrenceEndDate"
                     type="date"
                     className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                     value={formData.recurrenceEndDate}
                     onChange={handleInputChange}
                     disabled={isLoading}
                   />
                 </div>
               </div>
               
               {/* End After Occurrences */}
               <div>
                 <label htmlFor="recurrenceEndOccurrences" className="block text-sm font-medium text-gray-700 mb-1">
                   End After (Optional)
                 </label>
                 <div className="relative">
                   <input
                     id="recurrenceEndOccurrences"
                     name="recurrenceEndOccurrences"
                     type="number"
                     min="1"
                     className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                     value={formData.recurrenceEndOccurrences}
                     onChange={handleInputChange}
                     disabled={isLoading}
                   />
                   <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                     <span className="text-gray-500 text-sm">occurrences</span>
                   </div>
                 </div>
               </div>
             </div>
             
             {formErrors.recurrenceEnd && (
               <p className="mt-3 text-red-600 text-xs flex items-center">
                 <AlertCircle size={12} className="mr-1" /> {formErrors.recurrenceEnd}
               </p>
             )}
             
             <p className="mt-3 text-xs text-blue-600">
               <Clock size={12} className="inline mr-1" />
               This transaction will repeat every {formData.recurrenceInterval || '1'} {
                 formData.recurrenceFrequency === 'daily' ? 'day(s)' :
                 formData.recurrenceFrequency === 'weekly' ? 'week(s)' :
                 formData.recurrenceFrequency === 'monthly' ? 'month(s)' : 'year(s)'
               }.
             </p>
           </div>
         )}
       </div>
       
       {/* Attachments */}
       <div className="mb-8">
         <h3 className="text-lg font-medium text-gray-900 mb-4">Attachments</h3>
         
         <div className="mb-4">
           <button
             type="button"
             onClick={() => fileInputRef.current?.click()}
             className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 flex items-center"
             disabled={isLoading}
           >
             <Upload size={16} className="mr-2" />
             Upload Files
           </button>
           <input
             type="file"
             ref={fileInputRef}
             className="hidden"
             onChange={handleFileUpload}
             multiple
             accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
           />
           <p className="mt-1 text-xs text-gray-500">
             Attach receipts, invoices, or other relevant documents (max 10MB per file)
           </p>
         </div>
         
         {formErrors.attachments && (
           <p className="mt-1 mb-2 text-red-600 text-xs flex items-center">
             <AlertCircle size={12} className="mr-1" /> {formErrors.attachments}
           </p>
         )}
         
         {/* Display attachments */}
         {attachments.length > 0 && (
           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
             {attachments.map(attachment => (
               <div key={attachment.id} className="border rounded-lg overflow-hidden">
                 <div className="p-3 border-b bg-gray-50 flex justify-between items-center">
                   <span className="text-sm font-medium truncate flex-1">{attachment.name}</span>
                   <button
                     type="button"
                     onClick={() => handleRemoveAttachment(attachment.id)}
                     className="text-red-500 hover:text-red-700 p-1"
                     disabled={isLoading}
                   >
                     <X size={16} />
                   </button>
                 </div>
                 
                 {/* Preview area */}
                 <div className="h-32 bg-gray-100 flex items-center justify-center">
                   {attachment.type === 'image' && (attachment.previewUrl || attachment.url) ? (
                     <img 
                       src={attachment.previewUrl || attachment.url} 
                       alt={attachment.name}
                       className="h-full w-full object-contain"
                     />
                   ) : (
                     <FileText size={32} className="text-gray-400" />
                   )}
                 </div>
                 
                 {/* Upload progress */}
                 {!attachment.isUploaded && uploadProgress[attachment.name] > 0 && (
                   <div className="p-2">
                     <div className="w-full bg-gray-200 rounded-full h-2">
                       <div
                         className="bg-[#190192] h-2 rounded-full"
                         style={{ width: `${uploadProgress[attachment.name]}%` }}
                       ></div>
                     </div>
                     <p className="text-xs text-right mt-1 text-gray-500">
                       {Math.round(uploadProgress[attachment.name])}%
                     </p>
                   </div>
                 )}
               </div>
             ))}
           </div>
         )}
       </div>
       
       {/* Form Submission Error */}
       {formErrors.submit && (
         <div className="mb-6 p-4 bg-red-50 rounded-lg">
           <p className="text-red-600 text-sm flex items-center">
             <AlertCircle size={16} className="mr-2" /> {formErrors.submit}
           </p>
         </div>
       )}
       
       {/* Form Actions */}
       <div className="flex justify-end space-x-4 pt-6 border-t border-gray-200">
         <button
           type="button"
           onClick={() => navigate('/finance')}
           className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
           disabled={isLoading}
         >
           Cancel
         </button>
         
         <button
           type="submit"
           className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
             isLoading ? 'bg-purple-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
           }`}
           disabled={isLoading}
         >
           {isLoading ? (
             <>
               <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                 <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                 <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
               </svg>
               Saving...
             </>
           ) : (
             <>
               <Check size={18} className="mr-2" />
               {isEditing ? 'Update Transaction' : 'Save Transaction'}
             </>
           )}
         </button>
       </div>
     </form>
   </div>
 );
};

export default TransactForm;