// src/components/finance/reports/ReportFilters.jsx
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { 
  Calendar, Download, Printer, FileText, 
  ChevronDown, Filter, ArrowDownToLine
} from 'lucide-react';

const ReportFilters = ({ 
  onFilterChange, 
  availableFilters = ['dateRange', 'account', 'category'],
  defaultDateRange = 'month'
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  
  // Initialize filters from URL or default values
  const [filters, setFilters] = useState({
    dateRange: searchParams.get('period') || defaultDateRange,
    startDate: searchParams.get('startDate') || '',
    endDate: searchParams.get('endDate') || '',
    account: searchParams.get('account') || '',
    category: searchParams.get('category') || '',
    compareWith: searchParams.get('compare') || 'none',
    includeSubitems: searchParams.get('includeSubitems') === 'true',
    status: searchParams.get('status') || 'all',
  });
  
  // Track if this is the initial render
  const [isInitialMount, setIsInitialMount] = useState(true);
  
  // Update URL with current filters and notify parent
  useEffect(() => {
    // Skip the first render to avoid double-setting params
    if (isInitialMount) {
      setIsInitialMount(false);
      // Notify parent of initial filters
      if (onFilterChange) {
        onFilterChange(filters);
      }
      return;
    }
    
    // Build URL parameters
    const params = {};
    if (filters.dateRange) params.period = filters.dateRange;
    if (filters.startDate) params.startDate = filters.startDate;
    if (filters.endDate) params.endDate = filters.endDate;
    if (filters.account) params.account = filters.account;
    if (filters.category) params.category = filters.category;
    if (filters.compareWith !== 'none') params.compare = filters.compareWith;
    if (filters.includeSubitems) params.includeSubitems = 'true';
    if (filters.status !== 'all') params.status = filters.status;
    
    // Update URL
    setSearchParams(params);
    
    // Notify parent component of filter changes
    if (onFilterChange) {
      onFilterChange(filters);
    }
  }, [filters, isInitialMount]);
  
  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  
  // Handle date range preset selection
  const handleDateRangeChange = (range) => {
    let startDate = '';
    let endDate = '';
    
    const today = new Date();
    
    // Set appropriate date range
    switch (range) {
      case 'today':
        startDate = today.toISOString().split('T')[0];
        endDate = startDate;
        break;
      case 'week':
        // Start from last Sunday
        const lastSunday = new Date(today);
        lastSunday.setDate(today.getDate() - today.getDay());
        startDate = lastSunday.toISOString().split('T')[0];
        endDate = today.toISOString().split('T')[0];
        break;
      case 'month':
        // Start from 1st of current month
        const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        startDate = firstOfMonth.toISOString().split('T')[0];
        endDate = today.toISOString().split('T')[0];
        break;
      case 'quarter':
        // Start from beginning of current quarter
        const quarterMonth = Math.floor(today.getMonth() / 3) * 3;
        const firstOfQuarter = new Date(today.getFullYear(), quarterMonth, 1);
        startDate = firstOfQuarter.toISOString().split('T')[0];
        endDate = today.toISOString().split('T')[0];
        break;
      case 'year':
        // Start from January 1 of current year
        const firstOfYear = new Date(today.getFullYear(), 0, 1);
        startDate = firstOfYear.toISOString().split('T')[0];
        endDate = today.toISOString().split('T')[0];
        break;
      case 'custom':
        // Keep existing custom dates
        startDate = filters.startDate;
        endDate = filters.endDate;
        break;
      default:
        // Default to current month
        const defaultFirst = new Date(today.getFullYear(), today.getMonth(), 1);
        startDate = defaultFirst.toISOString().split('T')[0];
        endDate = today.toISOString().split('T')[0];
    }
    
    setFilters(prev => ({
      ...prev,
      dateRange: range,
      startDate,
      endDate
    }));
  };
  
  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 mb-6">
      {/* Quick Filter Bar */}
      <div className="p-4 flex flex-col md:flex-row md:items-center md:justify-between gap-4">
        <div className="flex items-center gap-2">
          {/* Date Range Selector */}
          <div className="relative">
            <button 
              className="px-4 py-2 border border-gray-300 rounded-lg flex items-center bg-white text-sm font-medium text-gray-700"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <Calendar size={16} className="mr-2 text-gray-500" />
              {filters.dateRange === 'today' ? 'Today' :
               filters.dateRange === 'week' ? 'This Week' :
               filters.dateRange === 'month' ? 'This Month' :
               filters.dateRange === 'quarter' ? 'This Quarter' :
               filters.dateRange === 'year' ? 'This Year' :
               filters.dateRange === 'custom' ? 'Custom Range' : 'Select Period'}
              <ChevronDown size={16} className="ml-2 text-gray-500" />
            </button>
            
            {isFilterOpen && (
              <div className="absolute z-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1">
                  <button
                    className={`block px-4 py-2 text-sm w-full text-left ${
                      filters.dateRange === 'today' ? 'bg-purple-50 text-[#190192] font-medium' : 'text-gray-700 hover:bg-gray-100'
                    }`}
                    onClick={() => { handleDateRangeChange('today'); setIsFilterOpen(false); }}
                  >
                    Today
                  </button>
                  <button
                    className={`block px-4 py-2 text-sm w-full text-left ${
                      filters.dateRange === 'week' ? 'bg-purple-50 text-[#190192] font-medium' : 'text-gray-700 hover:bg-gray-100'
                    }`}
                    onClick={() => { handleDateRangeChange('week'); setIsFilterOpen(false); }}
                  >
                    This Week
                  </button>
                  <button
                    className={`block px-4 py-2 text-sm w-full text-left ${
                      filters.dateRange === 'month' ? 'bg-purple-50 text-[#190192] font-medium' : 'text-gray-700 hover:bg-gray-100'
                    }`}
                    onClick={() => { handleDateRangeChange('month'); setIsFilterOpen(false); }}
                  >
                    This Month
                  </button>
                  <button
                    className={`block px-4 py-2 text-sm w-full text-left ${
                      filters.dateRange === 'quarter' ? 'bg-purple-50 text-[#190192] font-medium' : 'text-gray-700 hover:bg-gray-100'
                    }`}
                    onClick={() => { handleDateRangeChange('quarter'); setIsFilterOpen(false); }}
                  >
                    This Quarter
                  </button>
                  <button
                    className={`block px-4 py-2 text-sm w-full text-left ${
                      filters.dateRange === 'year' ? 'bg-purple-50 text-[#190192] font-medium' : 'text-gray-700 hover:bg-gray-100'
                    }`}
                    onClick={() => { handleDateRangeChange('year'); setIsFilterOpen(false); }}
                  >
                    This Year
                  </button>
                  <button
                    className={`block px-4 py-2 text-sm w-full text-left ${
                      filters.dateRange === 'custom' ? 'bg-purple-50 text-[#190192] font-medium' : 'text-gray-700 hover:bg-gray-100'
                    }`}
                    onClick={() => { 
                      setFilters(prev => ({ ...prev, dateRange: 'custom' }));
                      setIsFilterOpen(false);
                    }}
                  >
                    Custom Range
                  </button>
                </div>
              </div>
            )}
          </div>
          
          {/* Custom Date Inputs (show only if custom range is selected) */}
          {filters.dateRange === 'custom' && (
            <div className="flex items-center gap-2">
              <input
                type="date"
                name="startDate"
                className="border border-gray-300 rounded-lg px-3 py-2 text-sm"
                value={filters.startDate}
                onChange={handleFilterChange}
              />
              <span className="text-gray-500">to</span>
              <input
                type="date"
                name="endDate"
                className="border border-gray-300 rounded-lg px-3 py-2 text-sm"
                value={filters.endDate}
                onChange={handleFilterChange}
              />
            </div>
          )}
          
          {/* More Filters Button */}
          <button
            className="px-4 py-2 border border-gray-300 rounded-lg flex items-center bg-white text-sm font-medium text-gray-700"
            onClick={() => document.getElementById('advancedFilters').classList.toggle('hidden')}
          >
            <Filter size={16} className="mr-2 text-gray-500" />
            More Filters
          </button>
        </div>
        
        <div className="flex items-center gap-2">
          {/* Export Options */}
          <div className="relative group">
            <button className="px-4 py-2 border border-gray-300 rounded-lg flex items-center bg-white text-sm font-medium text-gray-700">
              <Download size={16} className="mr-2 text-gray-500" />
              Export
              <ChevronDown size={16} className="ml-2 text-gray-500" />
            </button>
            
            <div className="hidden group-hover:block absolute z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 right-0">
              <div className="py-1">
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left flex items-center">
                  <FileText size={16} className="mr-2 text-gray-500" />
                  Export as PDF
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left flex items-center">
                  <ArrowDownToLine size={16} className="mr-2 text-gray-500" />
                  Export as CSV
                </button>
                <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left flex items-center">
                  <Printer size={16} className="mr-2 text-gray-500" />
                  Print Report
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Advanced Filters */}
      <div id="advancedFilters" className="hidden border-t border-gray-200 p-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {availableFilters.includes('account') && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Account</label>
              <select
                name="account"
                className="block w-full border border-gray-300 rounded-lg px-3 py-2 text-sm"
                value={filters.account}
                onChange={handleFilterChange}
              >
                <option value="">All Accounts</option>
                <option value="checking">Checking Account</option>
                <option value="savings">Savings Account</option>
                <option value="credit-card">Credit Card</option>
              </select>
            </div>
          )}
          
          {availableFilters.includes('category') && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Category</label>
              <select
                name="category"
                className="block w-full border border-gray-300 rounded-lg px-3 py-2 text-sm"
                value={filters.category}
                onChange={handleFilterChange}
              >
                <option value="">All Categories</option>
                <option value="sales">Sales</option>
                <option value="services">Services</option>
                <option value="rent">Rent & Utilities</option>
                <option value="payroll">Payroll</option>
                <option value="supplies">Supplies</option>
              </select>
            </div>
          )}
          
          {availableFilters.includes('status') && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
              <select
                name="status"
                className="block w-full border border-gray-300 rounded-lg px-3 py-2 text-sm"
                value={filters.status}
                onChange={handleFilterChange}
              >
                <option value="all">All Statuses</option>
                <option value="paid">Paid</option>
                <option value="unpaid">Unpaid</option>
                <option value="overdue">Overdue</option>
              </select>
            </div>
          )}
          
          {availableFilters.includes('compare') && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Compare With</label>
              <select
                name="compareWith"
                className="block w-full border border-gray-300 rounded-lg px-3 py-2 text-sm"
                value={filters.compareWith}
                onChange={handleFilterChange}
              >
                <option value="none">No Comparison</option>
                <option value="previous">Previous Period</option>
                <option value="year">Same Period Last Year</option>
                <option value="budget">Budget</option>
              </select>
            </div>
          )}
          
          {availableFilters.includes('includeSubitems') && (
            <div className="flex items-center mt-6">
              <input
                type="checkbox"
                id="includeSubitems"
                name="includeSubitems"
                className="h-4 w-4 text-[#190192] rounded border-gray-300 focus:ring-[#190192]"
                checked={filters.includeSubitems}
                onChange={handleFilterChange}
              />
              <label htmlFor="includeSubitems" className="ml-2 text-sm text-gray-700">
                Include sub-categories and accounts
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportFilters;