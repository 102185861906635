// src/pages/About.js
import React from 'react';
import { Link } from 'react-router-dom';
import { 
  BarChart2, ShoppingCart, Package, Truck, DollarSign, 
  Users, CheckCircle, Calendar, MessageSquare, FileText,
  Shield, Zap, Layers, Settings
} from 'lucide-react';
import dashboard from '../assets/images/dashboard.png'; 

const About = () => {
  const features = [
    {
      icon: <BarChart2 size={24} className="text-purple-800" />,
      title: "Business Dashboard",
      description: "Get a comprehensive overview of your business with real-time metrics, charts, and insights."
    },
    {
      icon: <ShoppingCart size={24} className="text-purple-800" />,
      title: "Sales Management",
      description: "Track orders, process transactions, and manage your entire sales pipeline in one place."
    },
    {
      icon: <Package size={24} className="text-purple-800" />,
      title: "Inventory Control",
      description: "Monitor stock levels, track product information, and automate reordering processes."
    },
    {
      icon: <Truck size={24} className="text-purple-800" />,
      title: "Order Processing",
      description: "Streamline order fulfillment from receipt to delivery with automated workflows."
    },
    {
      icon: <DollarSign size={24} className="text-purple-800" />,
      title: "Financial Overview",
      description: "Track revenue, expenses, and profitability with detailed financial reporting."
    },
    {
      icon: <Users size={24} className="text-purple-800" />,
      title: "Customer Management",
      description: "Build stronger relationships with a complete view of customer information and history."
    },
    {
      icon: <CheckCircle size={24} className="text-purple-800" />,
      title: "Task Management",
      description: "Organize, assign, and track business tasks to ensure nothing falls through the cracks."
    },
    {
      icon: <Calendar size={24} className="text-purple-800" />,
      title: "Calendar & Scheduling",
      description: "Manage appointments, events, and important dates in a centralized calendar."
    },
    {
      icon: <MessageSquare size={24} className="text-purple-800" />,
      title: "Team Communication",
      description: "Keep your team connected with integrated messaging and collaboration tools."
    },
    {
      icon: <FileText size={24} className="text-purple-800" />,
      title: "Document Management",
      description: "Store, organize, and share business documents securely in the cloud."
    }
  ];

  const benefits = [
    {
      title: "Streamlined Operations",
      description: "Automate repetitive tasks and centralize your business processes to save time and reduce errors."
    },
    {
      title: "Data-Driven Decisions",
      description: "Make informed decisions with actionable insights based on real-time business data."
    },
    {
      title: "Enhanced Collaboration",
      description: "Improve team coordination with shared access to information and communication tools."
    },
    {
      title: "Increased Productivity",
      description: "Focus on growing your business instead of managing paperwork and manual processes."
    },
    {
      title: "Better Customer Service",
      description: "Provide faster, more personalized service with complete customer information at your fingertips."
    },
    {
      title: "Scalable Solution",
      description: "Easily adapt to your growing business needs with a flexible, modular platform."
    }
  ];

  // Custom primary color styles
  const primaryColor = "#190192";
  const primaryColorLight = "#2C0DB5";
  const primaryColorDark = "#0D0163";
  const primaryColorText = "#B9A7FF";

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        {/* Background Image Layer */}
        <div className="absolute inset-0">
         <img
            className="h-full w-full object-cover" 
            src={dashboard}
            alt="Dashboard"
          />
          {/* Optional Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-br from-[#190192] to-[#3B0DB5] opacity-60"></div>
        </div>
        {/* Content Layer */}
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 text-center">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl md:text-6xl">
            <span className="block">Giving Small Businesses</span>
            <span className="block text-purple-200">Data Superpowers</span>
          </h1>
          <p className="mt-6 max-w-xl mx-auto text-xl text-purple-100">
            Chat to your business data and get the insights for better decisions.
          </p>
          <div className="mt-10 flex justify-center">
            <Link to="/register" className="px-8 py-3 text-base font-medium rounded-xl text-[#190192] bg-white hover:bg-gray-50 md:py-4 md:text-lg">
              Get Started
            </Link>
            <Link to="/login" className="ml-4 px-8 py-3 text-base font-medium rounded-xl text-white bg-[#190192] bg-opacity-30 hover:bg-opacity-40 md:py-4 md:text-lg">
              Sign In
            </Link>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-16 sm:py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base font-semibold text-[#190192] tracking-wide uppercase">Features</h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight">
              Everything you need to run your business
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              BizCentral combines all essential business tools in one integrated platform.
            </p>
          </div>

          <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {features.map((feature, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow">
                <div className="w-12 h-12 bg-purple-100 rounded-xl flex items-center justify-center mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-lg font-medium text-gray-900">{feature.title}</h3>
                <p className="mt-2 text-base text-gray-500">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="py-16 sm:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base font-semibold text-[#190192] tracking-wide uppercase">Benefits</h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight">
              Transform the way you work
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              See how BizCentral can help your business grow and thrive.
            </p>
          </div>

          <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {benefits.map((benefit, index) => (
              <div key={index} className="bg-white p-6 rounded-xl border border-gray-200">
                <h3 className="text-lg font-medium text-gray-900">{benefit.title}</h3>
                <p className="mt-2 text-base text-gray-500">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Core Values Section */}
      <div className="py-16 sm:py-24 bg-gradient-to-br from-[#190192] to-[#3B0DB5] text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base font-semibold text-purple-200 tracking-wide uppercase">Our Values</h2>
            <p className="mt-1 text-4xl font-extrabold sm:text-5xl sm:tracking-tight">
              Built with your business in mind
            </p>
          </div>

          <div className="mt-16 grid gap-8 md:grid-cols-3">
            <div className="text-center">
              <div className="mx-auto w-16 h-16 bg-white bg-opacity-20 rounded-xl flex items-center justify-center mb-4">
                <Shield size={30} className="text-white" />
              </div>
              <h3 className="text-xl font-medium">Security First</h3>
              <p className="mt-2 text-purple-200">Your business data is protected with enterprise-grade security and encryption.</p>
            </div>
            <div className="text-center">
              <div className="mx-auto w-16 h-16 bg-white bg-opacity-20 rounded-xl flex items-center justify-center mb-4">
                <Zap size={30} className="text-white" />
              </div>
              <h3 className="text-xl font-medium">Performance</h3>
              <p className="mt-2 text-purple-200">Lightning-fast application designed for efficiency and responsiveness.</p>
            </div>
            <div className="text-center">
              <div className="mx-auto w-16 h-16 bg-white bg-opacity-20 rounded-xl flex items-center justify-center mb-4">
                <Layers size={30} className="text-white" />
              </div>
              <h3 className="text-xl font-medium">Flexibility</h3>
              <p className="mt-2 text-purple-200">Modular design lets you use only the features you need for your business.</p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-16 sm:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-purple-50 rounded-2xl overflow-hidden shadow-xl">
            <div className="px-6 py-12 md:px-12 md:py-16 lg:flex lg:items-center lg:justify-between">
              <div>
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                  <span className="block">Ready to streamline your business?</span>
                  <span className="block text-[#190192]">Start your free trial today.</span>
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  No credit card required. Get full access to all features for 14 days.
                </p>
              </div>
              <div className="mt-8 lg:mt-0 lg:flex-shrink-0">
                <div className="inline-flex rounded-md shadow">
                  <Link to="/register" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#190192] hover:bg-[#2C0DB5]">
                    Create an Account
                  </Link>
                </div>
                <div className="inline-flex ml-3 rounded-md shadow">
                  <Link to="/login" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-[#190192] bg-white hover:bg-gray-50">
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials Section (Placeholder) */}
      <div className="py-16 sm:py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base font-semibold text-[#190192] tracking-wide uppercase">Testimonials</h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight">
              Trusted by businesses like yours
            </p>
          </div>

          <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {/* Placeholder for testimonials */}
            <div className="bg-white p-6 rounded-xl shadow">
              <p className="text-gray-600 italic">"BizCentral has transformed how we manage our small retail business. The inventory management and sales tracking features have saved us countless hours."</p>
              <div className="mt-4 flex items-center">
                <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center font-medium text-[#190192]">JD</div>
                <div className="ml-3">
                  <p className="text-gray-900 font-medium">Jane Doe</p>
                  <p className="text-gray-500">Retail Shop Owner</p>
                </div>
              </div>
            </div>
            <div className="bg-white p-6 rounded-xl shadow">
              <p className="text-gray-600 italic">"The financial reporting has given us insights we never had before. We're making better decisions and seeing real growth as a result."</p>
              <div className="mt-4 flex items-center">
                <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center font-medium text-[#190192]">RS</div>
                <div className="ml-3">
                  <p className="text-gray-900 font-medium">Robert Smith</p>
                  <p className="text-gray-500">Service Business Owner</p>
                </div>
              </div>
            </div>
            <div className="bg-white p-6 rounded-xl shadow">
              <p className="text-gray-600 italic">"Customer management has never been easier. We can track all interactions and provide much better service with all the information at our fingertips."</p>
              <div className="mt-4 flex items-center">
                <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center font-medium text-[#190192]">AL</div>
                <div className="ml-3">
                  <p className="text-gray-900 font-medium">Amy Lee</p>
                  <p className="text-gray-500">Consulting Firm Partner</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-sm font-semibold uppercase tracking-wider">Product</h3>
              <ul className="mt-4 space-y-2">
                <li><a href="#features" className="text-gray-300 hover:text-white">Features</a></li>
                <li><a href="#pricing" className="text-gray-300 hover:text-white">Pricing</a></li>
                <li><a href="#testimonials" className="text-gray-300 hover:text-white">Testimonials</a></li>
                <li><a href="#faq" className="text-gray-300 hover:text-white">FAQ</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold uppercase tracking-wider">Support</h3>
              <ul className="mt-4 space-y-2">
                <li><a href="#help" className="text-gray-300 hover:text-white">Help Center</a></li>
                <li><a href="#contact" className="text-gray-300 hover:text-white">Contact Us</a></li>
                <li><a href="#docs" className="text-gray-300 hover:text-white">Documentation</a></li>
                <li><a href="#status" className="text-gray-300 hover:text-white">System Status</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold uppercase tracking-wider">Company</h3>
              <ul className="mt-4 space-y-2">
                <li><a href="#about" className="text-gray-300 hover:text-white">About Us</a></li>
                <li><a href="#careers" className="text-gray-300 hover:text-white">Careers</a></li>
                <li><a href="#blog" className="text-gray-300 hover:text-white">Blog</a></li>
                <li><a href="#press" className="text-gray-300 hover:text-white">Press</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold uppercase tracking-wider">Legal</h3>
              <ul className="mt-4 space-y-2">
                <li><a href="#privacy" className="text-gray-300 hover:text-white">Privacy Policy</a></li>
                <li><a href="#terms" className="text-gray-300 hover:text-white">Terms of Service</a></li>
                <li><a href="#security" className="text-gray-300 hover:text-white">Security</a></li>
                <li><a href="#compliance" className="text-gray-300 hover:text-white">Compliance</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center">
              <div className="w-10 h-10 rounded-xl bg-[#190192] flex items-center justify-center">
                <Settings size={20} className="text-white" />
              </div>
              <p className="ml-3 text-xl font-bold">BizCentral</p>
            </div>
            <p className="mt-4 md:mt-0 text-gray-400 text-sm">
              &copy; 2025 BizCentral, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default About;