// src/components/plans/PlanList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, PlusCircle, ChevronRight, FileText, Calendar, 
  Clock, User, Tag, ChevronDown, ChevronUp, CheckCircle, Briefcase,
  AlertTriangle, X
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';

const PlanList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [expandedPlans, setExpandedPlans] = useState({});

  // Fetch plans
  useEffect(() => {
    const fetchPlans = async () => {
      setIsLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(r => setTimeout(r, 800));
        
        // Mock plan data
        const mockData = [
          {
            id: '1',
            title: 'Q1 Sales Strategy',
            category: 'Sales',
            description: 'Strategic sales plan for the first quarter focusing on new product launches',
            status: 'In Progress',
            priority: 'High',
            startDate: '2025-01-01',
            endDate: '2025-03-31',
            projectLead: 'Mark Wilson',
            department: 'Sales Team',
            progress: 65,
            createdAt: '2024-12-10'
          },
          {
            id: '2',
            title: 'Customer Retention Program',
            category: 'Marketing',
            description: 'Initiative to improve customer retention rates through targeted engagement',
            status: 'Planning',
            priority: 'Medium',
            startDate: '2025-02-15',
            endDate: '2025-08-15',
            projectLead: 'Sarah Johnson',
            department: 'Marketing Department',
            progress: 20,
            createdAt: '2024-12-15'
          },
          {
            id: '3',
            title: 'IT Infrastructure Upgrade',
            category: 'IT',
            description: 'Comprehensive upgrade of company servers and network infrastructure',
            status: 'Not Started',
            priority: 'Critical',
            startDate: '2025-04-01',
            endDate: '2025-06-30',
            projectLead: 'Robert Chen',
            department: 'Information Technology',
            progress: 0,
            createdAt: '2025-01-05'
          },
          {
            id: '4',
            title: 'Employee Training Program',
            category: 'HR',
            description: 'Development and implementation of new employee training modules',
            status: 'Completed',
            priority: 'Medium',
            startDate: '2025-01-15',
            endDate: '2025-03-15',
            projectLead: 'Jessica Myers',
            department: 'Human Resources',
            progress: 100,
            createdAt: '2024-12-20'
          },
          {
            id: '5',
            title: 'Cost Reduction Initiative',
            category: 'Finance',
            description: 'Strategic initiative to reduce operational costs by 15%',
            status: 'In Progress',
            priority: 'High',
            startDate: '2025-01-01',
            endDate: '2025-06-30',
            projectLead: 'David Parker',
            department: 'Finance & Accounting',
            progress: 40,
            createdAt: '2024-12-18'
          },
          {
            id: '6',
            title: 'New Product Launch',
            category: 'Product Development',
            description: 'Launch plan for our new flagship product line',
            status: 'In Progress',
            priority: 'Critical',
            startDate: '2025-03-01',
            endDate: '2025-05-15',
            projectLead: 'Michael Thompson',
            department: 'Product Development',
            progress: 50,
            createdAt: '2025-01-10'
          },
          {
            id: '7',
            title: 'Website Redesign',
            category: 'Marketing',
            description: 'Complete overhaul of company website with improved UX',
            status: 'Planning',
            priority: 'Medium',
            startDate: '2025-04-01',
            endDate: '2025-07-31',
            projectLead: 'Emma Rodriguez',
            department: 'Marketing Department',
            progress: 15,
            createdAt: '2025-01-20'
          }
        ];
        
        setPlans(mockData);
      } catch (error) {
        console.error('Error fetching plans:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchPlans();
  }, []);

  // Toggle expanded plan
  const toggleExpandPlan = (planId) => {
    setExpandedPlans(prev => ({
      ...prev,
      [planId]: !prev[planId]
    }));
  };

  // Get filtered plans
  const getFilteredPlans = () => {
    let filtered = plans;
    
    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(p => 
        p.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        p.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        p.projectLead.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Filter by category
    if (filterCategory !== 'all') {
      filtered = filtered.filter(p => p.category === filterCategory);
    }
    
    // Filter by status
    if (filterStatus !== 'all') {
      filtered = filtered.filter(p => p.status === filterStatus);
    }
    
    return filtered;
  };

  // Get status style
  const getStatusStyle = (status) => {
    switch (status) {
      case 'In Progress':
        return 'bg-blue-100 text-blue-700';
      case 'Planning':
        return 'bg-purple-100 text-purple-700';
      case 'Not Started':
        return 'bg-gray-100 text-gray-700';
      case 'Completed':
        return 'bg-green-100 text-green-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  };
  
  // Get priority style
  const getPriorityStyle = (priority) => {
    switch (priority) {
      case 'Low':
        return 'bg-green-100 text-green-700';
      case 'Medium':
        return 'bg-blue-100 text-blue-700';
      case 'High':
        return 'bg-orange-100 text-orange-700';
      case 'Critical':
        return 'bg-red-100 text-red-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  };
  
  // Get progress bar style
  const getProgressBarStyle = (progress) => {
    if (progress >= 100) return 'bg-green-500';
    if (progress >= 70) return 'bg-emerald-500';
    if (progress >= 40) return 'bg-blue-500';
    if (progress >= 20) return 'bg-yellow-500';
    return 'bg-orange-500';
  };

  // Get unique categories from plans
  const categories = ['all', ...new Set(plans.map(plan => plan.category))];
  
  // Get unique statuses from plans
  const statuses = ['all', ...new Set(plans.map(plan => plan.status))];

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return 'Not set';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div>
      {/* Search and Actions Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search plans by title, description, or lead..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3 flex-wrap md:flex-nowrap">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
          >
            <option value="all">All Categories</option>
            {categories.filter(c => c !== 'all').map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
          
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Statuses</option>
            {statuses.filter(s => s !== 'all').map(status => (
              <option key={status} value={status}>{status}</option>
            ))}
          </select>
          
          <Link
            to="/plans/new"
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5] whitespace-nowrap"
          >
            <PlusCircle size={18} className="mr-2" />
            Add Plan
          </Link>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {plans.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <FileText size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No plans found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You haven't added any business plans yet. Click the 'Add Plan' button to get started.
              </p>
              <Link
                to="/plans/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                <PlusCircle size={16} className="mr-2" />
                Create Your First Plan
              </Link>
            </div>
          ) : getFilteredPlans().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <FileText size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching plans</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any plans matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterCategory('all'); setFilterStatus('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Plan List */
            <div className="space-y-4">
              {getFilteredPlans().map(plan => (
                <div 
                  key={plan.id} 
                  className="bg-white rounded-xl border border-gray-200 shadow-sm hover:shadow-md transition-shadow overflow-hidden"
                >
                  <div className="p-4">
                    <div className="flex flex-col md:flex-row md:items-start md:justify-between gap-4 mb-3">
                      <div className="flex items-start">
                        <div className="w-10 h-10 rounded-lg bg-indigo-100 flex items-center justify-center text-[#190192] mr-3 flex-shrink-0">
                          <FileText size={20} />
                        </div>
                        <div>
                          <h3 className="font-medium text-gray-900 mb-1">{plan.title}</h3>
                          <div className="flex flex-wrap gap-2">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(plan.status)}`}>
                              {plan.status}
                            </span>
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getPriorityStyle(plan.priority)}`}>
                              {plan.priority} Priority
                            </span>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                              {plan.category}
                            </span>
                          </div>
                        </div>
                      </div>
                      
                      <div className="flex items-center space-x-2">
                        <Link
                          to={`/plans/${plan.id}`}
                          className="px-3 py-1.5 text-sm text-[#190192] bg-indigo-50 rounded-lg hover:bg-indigo-100"
                        >
                          View
                        </Link>
                        <Link
                          to={`/plans/edit/${plan.id}`}
                          className="px-3 py-1.5 text-sm text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200"
                        >
                          Edit
                        </Link>
                        <button 
                          onClick={() => toggleExpandPlan(plan.id)} 
                          className="p-1.5 rounded-lg text-gray-500 hover:bg-gray-100"
                        >
                          {expandedPlans[plan.id] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </button>
                      </div>
                    </div>
                    
                    {/* Progress Bar */}
                    <div className="mb-4">
                      <div className="flex items-center justify-between mb-1">
                        <span className="text-xs font-medium text-gray-500">Progress</span>
                        <span className="text-xs font-medium text-gray-500">{plan.progress}%</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div 
                          className={`${getProgressBarStyle(plan.progress)} h-2 rounded-full`} 
                          style={{ width: `${plan.progress}%` }}
                        ></div>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 text-sm text-gray-600">
                      <div className="flex items-center">
                        <Calendar size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                        <span>
                          {formatDate(plan.startDate)} - {formatDate(plan.endDate)}
                        </span>
                      </div>
                      
                      <div className="flex items-center">
                        <User size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                        <span>{plan.projectLead}</span>
                      </div>
                      
                      <div className="flex items-center">
                        <Briefcase size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                        <span>{plan.department}</span>
                      </div>
                    </div>
                    
                    {/* Expanded View */}
                    {expandedPlans[plan.id] && (
                      <div className="mt-4 pt-4 border-t border-gray-100">
                        <p className="text-gray-600 mb-4">{plan.description}</p>
                        
                        <div className="flex items-center text-xs text-gray-500">
                          <Clock size={14} className="mr-1" />
                          <span>Created on {formatDate(plan.createdAt)}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PlanList;