// src/components/communication/EmojiPicker.js
import React, { useEffect, useRef } from 'react';
import { X, Search } from 'lucide-react';

const EmojiPicker = ({ onSelect, onClickOutside }) => {
  const pickerRef = useRef(null);
  
  // Common emoji categories
  const categories = [
    {
      name: 'Smileys & Emotion',
      emojis: ['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '🙂', '🙃', '😉', '😊', 
              '😇', '😍', '🥰', '😘', '😗', '😚', '😙', '😋', '😛', '😜', '😝', '🤑']
    },
    {
      name: 'Gestures & People',
      emojis: ['👍', '👎', '👌', '✌️', '🤞', '🤟', '🤘', '🤙', '👈', '👉', '👆', '👇',
              '☝️', '👋', '🤚', '🖐️', '✋', '🖖', '👏', '🙌', '👐', '🤲', '🙏', '🤝']
    },
    {
      name: 'Objects',
      emojis: ['💯', '💢', '💬', '👁️‍🗨️', '🗨️', '🗯️', '💭', '💤', '💮', '♨️', '💈',
              '🛑', '🕛', '🕧', '🕐', '🕜', '🕑', '🕝', '🕒', '🕞', '🕓', '🕟', '🕔']
    }
  ];
  
  // Handle click outside to close the picker
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        onClickOutside();
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside]);
  
  return (
    <div 
      ref={pickerRef}
      className="absolute bottom-full right-0 mb-2 bg-white border border-gray-200 rounded-lg shadow-lg z-50 w-72"
    >
      <div className="p-2 border-b flex justify-between items-center">
        <h3 className="text-sm font-medium text-gray-700">Emoji Picker</h3>
        <button 
          className="text-gray-400 hover:text-gray-600"
          onClick={onClickOutside}
        >
          <X size={16} />
        </button>
      </div>
      
      <div className="max-h-72 overflow-y-auto">
        {categories.map((category) => (
          <div key={category.name} className="p-2">
            <h4 className="text-xs font-medium text-gray-500 mb-1">{category.name}</h4>
            <div className="grid grid-cols-8 gap-1">
              {category.emojis.map((emoji, index) => (
                <button
                  key={index}
                  className="hover:bg-gray-100 rounded p-1 text-xl"
                  onClick={() => onSelect(emoji)}
                >
                  {emoji}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmojiPicker;