// src/components/finance/reports/ReportsList.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { 
  PieChart, DollarSign, TrendingUp, Clock, 
  FileText, CreditCard, AlertTriangle, BarChart, Calendar
} from 'lucide-react';
import { useCurrency } from '../../../utils/currencyUtils';


const ReportsList = () => {
  const { formatAmount } = useCurrency();
  
  const reports = [
    {
      id: 'profit-loss',
      name: 'Profit & Loss',
      description: 'Review your revenue, expenses, and overall profitability',
      icon: <PieChart size={24} className="text-blue-600" />,
      path: '/finance/reports/profit-loss',
      stats: {
        value: 24850,
        label: 'Net Income',
        change: '+8.2%'
      }
    },
    {
      id: 'balance-sheet',
      name: 'Balance Sheet',
      description: 'View your assets, liabilities, and equity',
      icon: <DollarSign size={24} className="text-green-600" />,
      path: '/finance/reports/balance-sheet',
      stats: {
        value: 127450,
        label: 'Total Assets',
        change: '+3.5%'
      }
    },
    {
      id: 'cash-flow',
      name: 'Cash Flow',
      description: 'Track how money moves in and out of your business',
      icon: <TrendingUp size={24} className="text-purple-600" />,
      path: '/finance/reports/cash-flow',
      stats: {
        value: 15280,
        label: 'Net Cash Flow',
        change: '+12.3%'
      }
    },
    {
      id: 'receivables-aging',
      name: 'Accounts Receivable',
      description: 'Monitor outstanding customer payments',
      icon: <Clock size={24} className="text-amber-600" />,
      path: '/finance/reports/receivables',
      stats: {
        value: 18650,
        label: 'Outstanding',
        change: '-2.1%'
      }
    },
    {
      id: 'payables-aging',
      name: 'Accounts Payable',
      description: 'Track bills and amounts owed to vendors',
      icon: <CreditCard size={24} className="text-red-600" />,
      path: '/finance/reports/payables',
      stats: {
        value: 8920,
        label: 'Outstanding',
        change: '-5.7%'
      }
    },
    {
      id: 'tax-summary',
      name: 'Tax Summary',
      description: 'Review collected and paid taxes',
      icon: <FileText size={24} className="text-gray-600" />,
      path: '/finance/reports/tax',
      stats: {
        value: 4320,
        label: 'Tax Liability',
        change: '+0.8%'
      }
    },
    {
      id: 'expense-report',
      name: 'Expense Analysis',
      description: 'Analyze your business expenses by category',
      icon: <BarChart size={24} className="text-indigo-600" />,
      path: '/finance/reports/expenses',
      stats: {
        value: 12450,
        label: 'Total Expenses',
        change: '+2.3%'
      }
    }
  ];

  return (
    <div className="bg-white rounded-xl shadow-md p-6">
      <h2 className="text-xl font-bold text-gray-900 mb-6">Available Reports</h2>
      
      <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {reports.map(report => (
          <Link 
            key={report.id}
            to={report.path}
            className="block border border-gray-200 rounded-xl hover:shadow-md transition-shadow overflow-hidden"
          >
            <div className="p-6">
              <div className="flex items-start justify-between mb-4">
                <div className="w-12 h-12 rounded-lg flex items-center justify-center bg-gray-100">
                  {report.icon}
                </div>
                <div className={`px-2.5 py-1 rounded text-xs font-medium ${
                  report.stats.change.startsWith('+') 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-red-100 text-red-800'
                }`}>
                  {report.stats.change}
                </div>
              </div>
              
              <h3 className="text-lg font-medium text-gray-900 mb-1">{report.name}</h3>
              <p className="text-sm text-gray-500 mb-4">{report.description}</p>
              
              <div className="flex items-end justify-between border-t border-gray-100 pt-4">
                <div>
                  <p className="text-xs text-gray-500">{report.stats.label}</p>
                  <p className="text-lg font-bold text-gray-900">
                    {formatAmount(report.stats.value)}
                  </p>
                </div>
                <span className="text-[#190192] text-sm font-medium">View Report</span>
              </div>
            </div>
          </Link>
        ))}
      </div>
      
      {/* Date Range Presets */}
      <div className="mt-8 pt-6 border-t border-gray-200">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Quick Reports</h3>
        
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <Link 
            to="/finance/reports/profit-loss?period=month"
            className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-50"
          >
            <Calendar size={18} className="text-[#190192] mr-3" />
            <span className="font-medium text-gray-700">This Month's P&L</span>
          </Link>
          
          <Link 
            to="/finance/reports/profit-loss?period=quarter"
            className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-50"
          >
            <Calendar size={18} className="text-[#190192] mr-3" />
            <span className="font-medium text-gray-700">Quarterly P&L</span>
          </Link>
          
          <Link 
            to="/finance/reports/receivables?overdue=true"
            className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-50"
          >
            <AlertTriangle size={18} className="text-amber-500 mr-3" />
            <span className="font-medium text-gray-700">Overdue Invoices</span>
          </Link>
          
          <Link 
            to="/finance/reports/tax?period=year"
            className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-50"
          >
            <FileText size={18} className="text-[#190192] mr-3" />
            <span className="font-medium text-gray-700">Annual Tax Summary</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ReportsList;