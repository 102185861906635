// src/components/communication/ExternalMessaging.js
import React, { useState } from 'react';
import { 
  Mail, 
  MessageSquare, 
  Facebook, 
  Instagram, 
  Linkedin, 
  Send, 
  FileText, 
  Clock, 
  Copy, 
  Edit, 
  Trash2,
  Users, 
  Filter, 
  ChevronDown, 
  Search, 
  ExternalLink, 
  CheckCircle, 
  Globe,
  Plus,
  File,
  PlusCircle
} from 'lucide-react';

// Custom Template icon component
const Template = ({ size = 24, className = "" }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={size} 
    height={size} 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    className={className}
  >
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="3" y1="9" x2="21" y2="9"></line>
    <line x1="9" y1="21" x2="9" y2="9"></line>
  </svg>
);

const ExternalMessaging = () => {
  const [activeTab, setActiveTab] = useState('email');
  const [templates, setTemplates] = useState([
    { id: '1', name: 'Welcome Email', type: 'email', subject: 'Welcome to our business!', lastUsed: '2025-03-12', status: 'active' },
    { id: '2', name: 'Follow-up', type: 'email', subject: 'Following up on our conversation', lastUsed: '2025-03-10', status: 'active' },
    { id: '3', name: 'Monthly Newsletter', type: 'email', subject: 'Your Monthly Business Update', lastUsed: '2025-03-01', status: 'active' },
    { id: '4', name: 'Appointment Reminder', type: 'sms', content: 'Reminder: You have an appointment tomorrow at 2 PM', lastUsed: '2025-03-05', status: 'active' },
    { id: '5', name: 'Payment Confirmation', type: 'whatsapp', content: "We've received your payment. Thank you!", lastUsed: '2025-03-08', status: 'active' },
    { id: '6', name: 'New Product Announcement', type: 'social', content: "Exciting news! We've just launched our new product line.", lastUsed: '2025-02-28', status: 'active' }
  ]);
  
  const [campaigns, setCampaigns] = useState([
    { id: '1', name: 'March Newsletter', type: 'email', recipients: 245, sent: 230, opened: 125, clicked: 68, status: 'completed', date: '2025-03-05' },
    { id: '2', name: 'Product Promotion', type: 'email', recipients: 350, sent: 350, opened: 180, clicked: 79, status: 'completed', date: '2025-02-20' },
    { id: '3', name: 'Customer Survey', type: 'email', recipients: 420, sent: 0, opened: 0, clicked: 0, status: 'scheduled', date: '2025-04-15' },
    { id: '4', name: 'Service Reminder', type: 'sms', recipients: 156, sent: 156, opened: null, clicked: null, status: 'completed', date: '2025-03-12' },
    { id: '5', name: 'Holiday Greetings', type: 'email', recipients: 600, sent: 0, opened: 0, clicked: 0, status: 'draft', date: null }
  ]);
  
  // Functions to render different tabs
  const renderEmailTab = () => {
    return (
      <div className="space-y-6">
        {/* Templates Section */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Email Templates</h3>
            <button className="px-4 py-2 bg-[#190192] text-white rounded-lg flex items-center hover:bg-[#2C0DB5]">
              <PlusCircle size={16} className="mr-2" />
              Create Template
            </button>
          </div>
          
          <div className="bg-white rounded-xl shadow-md overflow-hidden">
            <div className="flex items-center justify-between p-4 border-b">
              <div className="relative w-64">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                <input
                  type="text"
                  className="pl-10 pr-4 py-2 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
                  placeholder="Search templates..."
                />
              </div>
              
              <div className="flex items-center space-x-3">
                <div className="relative">
                  <button className="px-3 py-2 rounded-lg border border-gray-200 text-gray-600 flex items-center hover:bg-gray-50">
                    <Filter size={16} className="mr-2" />
                    Filter
                    <ChevronDown size={16} className="ml-2" />
                  </button>
                </div>
              </div>
            </div>
            
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Template Name</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Subject</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Used</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {templates
                    .filter(template => template.type === 'email')
                    .map((template) => (
                    <tr key={template.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <Template size={18} className="text-gray-400 mr-2" />
                          <span className="text-sm font-medium text-gray-900">{template.name}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{template.subject}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{template.lastUsed}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button className="text-[#190192] hover:text-[#2C0DB5] mr-3">
                          <Edit size={16} />
                        </button>
                        <button className="text-gray-400 hover:text-red-600 mr-3">
                          <Trash2 size={16} />
                        </button>
                        <button className="text-gray-400 hover:text-gray-600">
                          <Copy size={16} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        {/* Campaigns Section */}
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Email Campaigns</h3>
            <button className="px-4 py-2 bg-[#190192] text-white rounded-lg flex items-center hover:bg-[#2C0DB5]">
              <PlusCircle size={16} className="mr-2" />
              Create Campaign
            </button>
          </div>
          
          <div className="bg-white rounded-xl shadow-md overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Campaign Name</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Recipients</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Performance</th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {campaigns
                    .filter(campaign => campaign.type === 'email')
                    .map((campaign) => (
                    <tr key={campaign.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm font-medium text-gray-900">{campaign.name}</span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {campaign.recipients}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          campaign.status === 'completed' ? 'bg-green-100 text-green-800' :
                          campaign.status === 'scheduled' ? 'bg-blue-100 text-blue-800' :
                          'bg-gray-100 text-gray-800'
                        }`}>
                          {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {campaign.date || '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {campaign.sent > 0 ? (
                          <div>
                            <div className="flex items-center text-xs">
                              <span className="w-16 text-gray-500">Opened:</span>
                              <div className="w-32 bg-gray-200 rounded-full h-2 ml-2">
                                <div className="bg-blue-500 h-2 rounded-full" style={{ width: `${(campaign.opened / campaign.sent) * 100}%` }}></div>
                              </div>
                              <span className="ml-2 text-gray-700">{Math.round((campaign.opened / campaign.sent) * 100)}%</span>
                            </div>
                            <div className="flex items-center text-xs mt-1">
                              <span className="w-16 text-gray-500">Clicked:</span>
                              <div className="w-32 bg-gray-200 rounded-full h-2 ml-2">
                                <div className="bg-green-500 h-2 rounded-full" style={{ width: `${(campaign.clicked / campaign.sent) * 100}%` }}></div>
                              </div>
                              <span className="ml-2 text-gray-700">{Math.round((campaign.clicked / campaign.sent) * 100)}%</span>
                            </div>
                          </div>
                        ) : (
                          <span className="text-sm text-gray-500">Not sent yet</span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button className="text-[#190192] hover:text-[#2C0DB5] mr-3">
                          <Edit size={16} />
                        </button>
                        <button className="text-gray-400 hover:text-red-600">
                          <Trash2 size={16} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const renderSMSTab = () => {
    return (
      <div className="space-y-6">
        <div className="bg-white rounded-xl shadow-md p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-medium text-gray-900">SMS Messaging</h3>
            <button className="px-4 py-2 bg-[#190192] text-white rounded-lg flex items-center hover:bg-[#2C0DB5]">
              <PlusCircle size={16} className="mr-2" />
              New SMS Campaign
            </button>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <div className="bg-blue-50 border border-blue-100 rounded-lg p-4">
              <div className="flex items-start">
                <div className="bg-blue-100 rounded-lg p-2 mr-4">
                  <MessageSquare className="h-6 w-6 text-blue-600" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-900">SMS Templates</h4>
                  <p className="text-sm text-gray-600 mt-1">
                    {templates.filter(t => t.type === 'sms').length} templates available
                  </p>
                  <button className="mt-2 text-blue-600 text-sm hover:text-blue-800">View Templates</button>
                </div>
              </div>
            </div>
            
            <div className="bg-green-50 border border-green-100 rounded-lg p-4">
              <div className="flex items-start">
                <div className="bg-green-100 rounded-lg p-2 mr-4">
                  <Users className="h-6 w-6 text-green-600" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-900">Recipients</h4>
                  <p className="text-sm text-gray-600 mt-1">
                    428 contacts with phone numbers
                  </p>
                  <button className="mt-2 text-green-600 text-sm hover:text-green-800">Manage Contacts</button>
                </div>
              </div>
            </div>
            
            <div className="bg-purple-50 border border-purple-100 rounded-lg p-4">
              <div className="flex items-start">
                <div className="bg-purple-100 rounded-lg p-2 mr-4">
                  <Clock className="h-6 w-6 text-purple-600" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-900">Scheduled</h4>
                  <p className="text-sm text-gray-600 mt-1">
                    1 SMS campaign scheduled
                  </p>
                  <button className="mt-2 text-purple-600 text-sm hover:text-purple-800">View Schedule</button>
                </div>
              </div>
            </div>
          </div>
          
          <div>
            <h4 className="text-md font-medium text-gray-900 mb-4">Recent SMS Campaigns</h4>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Campaign</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Recipients</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sent</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {campaigns
                    .filter(campaign => campaign.type === 'sms')
                    .map((campaign) => (
                    <tr key={campaign.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{campaign.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{campaign.recipients}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{campaign.sent} SMS</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{campaign.date || '-'}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          campaign.status === 'completed' ? 'bg-green-100 text-green-800' :
                          campaign.status === 'scheduled' ? 'bg-blue-100 text-blue-800' :
                          'bg-gray-100 text-gray-800'
                        }`}>
                          {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const renderWhatsAppTab = () => {
    return (
      <div className="space-y-6">
        <div className="bg-white rounded-xl shadow-md p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-medium text-gray-900">WhatsApp Business Integration</h3>
            <button className="px-4 py-2 bg-[#190192] text-white rounded-lg flex items-center hover:bg-[#2C0DB5]">
              <PlusCircle size={16} className="mr-2" />
              New Message
            </button>
          </div>
          
          <div className="bg-green-50 border border-green-200 rounded-xl p-6 mb-8">
            <div className="flex items-start">
              <div className="bg-white rounded-lg p-3 mr-6 shadow-sm">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-10 w-10 text-green-600">
                  <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"/>
                </svg>
              </div>
              <div>
                <h4 className="text-lg font-medium text-gray-900">Connect Your WhatsApp Business Account</h4>
                <p className="text-gray-600 mt-2">
                  Integrate with WhatsApp Business API to send notifications, updates, and marketing messages to your customers.
                </p>
                <div className="mt-4">
                  <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <ExternalLink size={16} className="mr-2" />
                    Connect WhatsApp
                  </button>
                </div>
              </div>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h4 className="text-md font-medium text-gray-900 mb-4">WhatsApp Templates</h4>
              <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                <div className="p-4 border-b">
                  <p className="text-sm text-gray-500">Approved Templates</p>
                </div>
                <div className="p-6 text-center">
                  <MessageSquare className="h-12 w-12 text-gray-300 mx-auto mb-3" />
                  <p className="text-gray-600">No approved templates yet</p>
                  <button className="mt-4 text-sm text-[#190192] hover:text-[#2C0DB5]">
                    Create Template
                  </button>
                </div>
              </div>
            </div>
            
            <div>
              <h4 className="text-md font-medium text-gray-900 mb-4">Message Statistics</h4>
              <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                <div className="p-4 border-b">
                  <p className="text-sm text-gray-500">Last 30 Days</p>
                </div>
                <div className="p-6 text-center">
                  <p className="text-gray-600">Connect WhatsApp Business to view statistics</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const renderSocialTab = () => {
    return (
      <div className="space-y-6">
        <div className="bg-white rounded-xl shadow-md p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-medium text-gray-900">Social Media Management</h3>
            <button className="px-4 py-2 bg-[#190192] text-white rounded-lg flex items-center hover:bg-[#2C0DB5]">
              <PlusCircle size={16} className="mr-2" />
              Create Post
            </button>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
              <div className="flex items-center">
                <Facebook className="h-8 w-8 text-blue-600 mr-3" />
                <div>
                  <h4 className="font-medium text-gray-900">Facebook</h4>
                  <p className="text-xs text-gray-500 mt-1">Not connected</p>
                </div>
              </div>
              <button className="mt-4 w-full py-1.5 text-sm bg-white border border-gray-300 text-gray-700 rounded hover:bg-gray-50">
                Connect Page
              </button>
            </div>
            
            <div className="bg-pink-50 border border-pink-200 rounded-lg p-4">
              <div className="flex items-center">
                <Instagram className="h-8 w-8 text-pink-600 mr-3" />
                <div>
                  <h4 className="font-medium text-gray-900">Instagram</h4>
                  <p className="text-xs text-gray-500 mt-1">Not connected</p>
                </div>
              </div>
              <button className="mt-4 w-full py-1.5 text-sm bg-white border border-gray-300 text-gray-700 rounded hover:bg-gray-50">
                Connect Account
              </button>
            </div>
            
            <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
              <div className="flex items-center">
                <Linkedin className="h-8 w-8 text-blue-800 mr-3" />
                <div>
                  <h4 className="font-medium text-gray-900">LinkedIn</h4>
                  <p className="text-xs text-gray-500 mt-1">Not connected</p>
                </div>
              </div>
              <button className="mt-4 w-full py-1.5 text-sm bg-white border border-gray-300 text-gray-700 rounded hover:bg-gray-50">
                Connect Profile
              </button>
            </div>
          </div>
          
          <div className="bg-gray-50 rounded-lg p-6 text-center">
            <FileText className="h-12 w-12 text-gray-400 mx-auto mb-3" />
            <h4 className="text-lg font-medium text-gray-900">Manage All Your Social Media in One Place</h4>
            <p className="text-gray-600 mt-2 max-w-md mx-auto">
              Connect your social media accounts to create, schedule, and analyze posts across multiple platforms.
            </p>
            <div className="mt-6 flex justify-center">
              <button className="px-4 py-2 bg-[#190192] text-white rounded-lg flex items-center hover:bg-[#2C0DB5]">
                Get Started with Social Media
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <div className="p-6">
      {/* External Messaging Tabs */}
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          <button
            onClick={() => setActiveTab('email')}
            className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm ${
              activeTab === 'email'
                ? 'border-[#190192] text-[#190192]'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            <Mail className="h-5 w-5 mr-2" />
            Email
          </button>
          <button
              onClick={() => setActiveTab('sms')}
              className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm ${
                activeTab === 'sms'
                  ? 'border-[#190192] text-[#190192]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <MessageSquare className="h-5 w-5 mr-2" />
              SMS
            </button>
            <button
              onClick={() => setActiveTab('whatsapp')}
              className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm ${
                activeTab === 'whatsapp'
                  ? 'border-[#190192] text-[#190192]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              {/* WhatsApp icon would typically go here */}
              WhatsApp
            </button>
            <button
              onClick={() => setActiveTab('social')}
              className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm ${
                activeTab === 'social'
                  ? 'border-[#190192] text-[#190192]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <Globe className="h-5 w-5 mr-2" />
              Social Media
            </button>
          </nav>
        </div>

        {/* Content for active tab */}
        <div className="py-6">
          {activeTab === 'email' && renderEmailTab()}
          {activeTab === 'sms' && renderSMSTab()}
          {activeTab === 'whatsapp' && renderWhatsAppTab()}
          {activeTab === 'social' && renderSocialTab()}
        </div>
      </div>
    );
};

export default ExternalMessaging;