// src/components/finance/reports/CashFlowReport.jsx
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { 
  ChevronRight, ChevronDown, TrendingUp, TrendingDown, ArrowDown, ArrowUp
} from 'lucide-react';
import ReportFilters from './ReportFilters';
import { useCurrency } from '../../../utils/currencyUtils';

const CashFlowReport = () => {
  const [searchParams] = useSearchParams();
  const { formatAmount } = useCurrency();
  const [filters, setFilters] = useState({
    dateRange: searchParams.get('period') || 'month',
    startDate: searchParams.get('startDate') || '',
    endDate: searchParams.get('endDate') || '',
    compareWith: searchParams.get('compare') || 'none'
  });
  
  const [expandedCategories, setExpandedCategories] = useState({
    operating: true,
    investing: true,
    financing: true
  });
  
  const [reportData, setReportData] = useState({
    period: 'March 2025',
    comparisonPeriod: 'February 2025',
    overview: {
      startingBalance: 30000,
      previousStartingBalance: 28200,
      endingBalance: 32500,
      previousEndingBalance: 30000,
      netCashFlow: 2500,
      previousNetCashFlow: 1800,
      netCashFlowChange: 38.9
    },
    operatingActivities: {
      total: 9250,
      previousTotal: 7500,
      change: 23.3,
      items: [
        { name: 'Net Income', amount: 10400, previousAmount: 9000, change: 15.6 },
        { name: 'Depreciation and Amortization', amount: 1200, previousAmount: 1200, change: 0 },
        { name: 'Increase in Accounts Receivable', amount: -550, previousAmount: -1200, change: -54.2 },
        { name: 'Decrease in Inventory', amount: -700, previousAmount: -300, change: 133.3 },
        { name: 'Decrease in Accounts Payable', amount: -530, previousAmount: -850, change: -37.6 },
        { name: 'Increase in Accrued Expenses', amount: -570, previousAmount: -350, change: 62.9 }
      ]
    },
    investingActivities: {
      total: -4800,
      previousTotal: -3500,
      change: 37.1,
      items: [
        { name: 'Purchase of Equipment', amount: -5000, previousAmount: -3500, change: 42.9 },
        { name: 'Sale of Investments', amount: 200, previousAmount: 0, change: null }
      ]
    },
    financingActivities: {
      total: -1950,
      previousTotal: -2200,
      change: -11.4,
      items: [
        { name: 'Repayment of Loans', amount: -2500, previousAmount: -2500, change: 0 },
        { name: 'Dividends Paid', amount: -450, previousAmount: -700, change: -35.7 },
        { name: 'Capital Contribution', amount: 1000, previousAmount: 1000, change: 0 }
      ]
    }
  });
  
  // Toggle category expansion
  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };
  
  // Handle filter changes
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    
    // In a real app, you would fetch new data based on filters
    // For now, we'll just update the period labels
    if (newFilters.dateRange === 'month') {
      setReportData(prev => ({
        ...prev,
        period: 'March 2025',
        comparisonPeriod: 'February 2025'
      }));
    } else if (newFilters.dateRange === 'quarter') {
      setReportData(prev => ({
        ...prev,
        period: 'Q1 2025',
        comparisonPeriod: 'Q4 2024'
      }));
    } else if (newFilters.dateRange === 'year') {
      setReportData(prev => ({
        ...prev,
        period: '2025',
        comparisonPeriod: '2024'
      }));
    }
  };
  
  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">Cash Flow Statement</h2>
      
      <ReportFilters 
        onFilterChange={handleFilterChange}
        availableFilters={['dateRange', 'compare', 'includeSubitems']}
        defaultDateRange={filters.dateRange}
      />
      
      {/* Summary */}
      <div className="bg-white rounded-xl shadow-md p-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-medium text-gray-900">Cash Flow Summary</h3>
          <div className="text-sm font-medium text-gray-500">
            {reportData.period} {filters.compareWith !== 'none' && `vs ${reportData.comparisonPeriod}`}
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Starting Balance */}
          <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-gray-700">Beginning Balance</h4>
            </div>
            <p className="text-xl font-bold text-gray-900">{formatAmount(reportData.overview.startingBalance)}</p>
            {filters.compareWith !== 'none' && (
              <p className="text-sm text-gray-500 mt-1">vs {formatAmount(reportData.overview.previousStartingBalance)}</p>
            )}
          </div>
          
          {/* Net Cash Flow */}
          <div className={`rounded-lg p-4 border ${
            reportData.overview.netCashFlow >= 0 
              ? 'bg-green-50 border-green-100' 
              : 'bg-red-50 border-red-100'
          }`}>
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-gray-700">Net Cash Flow</h4>
              {filters.compareWith !== 'none' && (
                <span className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full ${
                  reportData.overview.netCashFlowChange >= 0 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-red-100 text-red-800'
                }`}>
                  {reportData.overview.netCashFlowChange >= 0 ? 
                    `+${reportData.overview.netCashFlowChange}%` : 
                    `${reportData.overview.netCashFlowChange}%`}
                </span>
              )}
            </div>
            <p className="text-xl font-bold text-gray-900">
              {reportData.overview.netCashFlow >= 0 ? '+' : ''}
              {formatAmount(reportData.overview.netCashFlow)}
            </p>
            {filters.compareWith !== 'none' && (
              <p className="text-sm text-gray-500 mt-1">
                vs {reportData.overview.previousNetCashFlow >= 0 ? '+' : ''}
                {formatAmount(reportData.overview.previousNetCashFlow)}
              </p>
            )}
          </div>
          
          {/* Ending Balance */}
          <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-blue-900">Ending Balance</h4>
            </div>
            <p className="text-xl font-bold text-gray-900">{formatAmount(reportData.overview.endingBalance)}</p>
            {filters.compareWith !== 'none' && (
              <p className="text-sm text-gray-500 mt-1">vs {formatAmount(reportData.overview.previousEndingBalance)}</p>
            )}
          </div>
          
          {/* Cash Flow by Category */}
          <div className="bg-indigo-50 rounded-lg p-4 border border-indigo-100">
            <h4 className="text-sm font-medium text-indigo-900 mb-3">Cash Flow by Activity</h4>
            
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <span className="text-xs text-gray-600">Operating</span>
                <span className={`text-xs font-medium ${
                  reportData.operatingActivities.total >= 0 ? 'text-green-600' : 'text-red-600'
                }`}>
                  {reportData.operatingActivities.total >= 0 ? '+' : ''}
                  {formatAmount(reportData.operatingActivities.total)}
                </span>
              </div>
              
              <div className="flex justify-between items-center">
                <span className="text-xs text-gray-600">Investing</span>
                <span className={`text-xs font-medium ${
                  reportData.investingActivities.total >= 0 ? 'text-green-600' : 'text-red-600'
                }`}>
                  {reportData.investingActivities.total >= 0 ? '+' : ''}
                  {formatAmount(reportData.investingActivities.total)}
                </span>
              </div>
              
              <div className="flex justify-between items-center">
                <span className="text-xs text-gray-600">Financing</span>
                <span className={`text-xs font-medium ${
                  reportData.financingActivities.total >= 0 ? 'text-green-600' : 'text-red-600'
                }`}>
                  {reportData.financingActivities.total >= 0 ? '+' : ''}
                  {formatAmount(reportData.financingActivities.total)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Detailed Report */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="flex items-center justify-between p-6 border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-900">Cash Flow Details</h3>
          <p className="text-sm font-medium text-gray-500">
            {reportData.period}
          </p>
        </div>
        
        <div className="p-6">
          {/* Starting Balance */}
          <div className="mb-6 pb-6 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <div className="text-lg font-medium text-gray-700">Beginning Cash Balance</div>
              <div className="text-lg font-bold text-gray-900">{formatAmount(reportData.overview.startingBalance)}</div>
            </div>
          </div>
          
          {/* Operating Activities */}
          <div className="mb-8">
            <button
              onClick={() => toggleCategory('operating')}
              className="flex items-center justify-between w-full text-left mb-3"
            >
              <div className="flex items-center">
                {expandedCategories.operating ? (
                  <ChevronDown size={18} className="text-gray-500 mr-2" />
                ) : (
                  <ChevronRight size={18} className="text-gray-500 mr-2" />
                )}
                <span className="text-lg font-medium text-indigo-900">Operating Activities</span>
              </div>
              <div className={`text-lg font-bold ${
                reportData.operatingActivities.total >= 0 ? 'text-green-600' : 'text-red-600'
              }`}>
                {reportData.operatingActivities.total >= 0 ? '+' : ''}
                {formatAmount(reportData.operatingActivities.total)}
              </div>
            </button>
            
            {expandedCategories.operating && (
              <div className="pl-6">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description
                      </th>
                      {filters.compareWith !== 'none' && (
                        <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Previous
                        </th>
                      )}
                      <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      {filters.compareWith !== 'none' && (
                        <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Change
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {reportData.operatingActivities.items.map((item, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-3 py-4 text-sm text-gray-900">{item.name}</td>
                        {filters.compareWith !== 'none' && (
                          <td className="px-3 py-4 text-sm text-right">
                            <span className={item.previousAmount >= 0 ? 'text-green-600' : 'text-red-600'}>
                              {item.previousAmount >= 0 ? '+' : ''}
                              {formatAmount(item.previousAmount)}
                            </span>
                          </td>
                        )}
                        <td className="px-3 py-4 text-sm font-medium text-right">
                          <span className={item.amount >= 0 ? 'text-green-600' : 'text-red-600'}>
                            {item.amount >= 0 ? '+' : ''}
                            {formatAmount(item.amount)}
                          </span>
                        </td>
                        {filters.compareWith !== 'none' && item.change !== null && (
                          <td className="px-3 py-4 text-right">
                            <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                              (item.amount >= 0 && item.change >= 0) || (item.amount < 0 && item.change < 0)
                                ? 'bg-green-100 text-green-800' 
                                : 'bg-red-100 text-red-800'
                            }`}>
                              {item.change >= 0 ? (
                                <>
                                  <ArrowUp size={12} className="mr-1" />
                                  +{item.change}%
                                </>
                              ) : (
                                <>
                                  <ArrowDown size={12} className="mr-1" />
                                  {item.change}%
                                </>
                              )}
                            </span>
                          </td>
                        )}
                        {filters.compareWith !== 'none' && item.change === null && (
                          <td className="px-3 py-4 text-right">
                            <span className="text-gray-500">—</span>
                          </td>
                        )}
                      </tr>
                    ))}
                    <tr className="bg-indigo-50">
                      <td className="px-3 py-4 text-sm font-bold text-gray-900">Net Cash from Operating Activities</td>
                      {filters.compareWith !== 'none' && (
                        <td className="px-3 py-4 text-sm text-right font-bold">
                          <span className={reportData.operatingActivities.previousTotal >= 0 ? 'text-green-600' : 'text-red-600'}>
                            {reportData.operatingActivities.previousTotal >= 0 ? '+' : ''}
                            {formatAmount(reportData.operatingActivities.previousTotal)}
                          </span>
                        </td>
                      )}
                      <td className="px-3 py-4 text-sm text-right font-bold">
                        <span className={reportData.operatingActivities.total >= 0 ? 'text-green-600' : 'text-red-600'}>
                          {reportData.operatingActivities.total >= 0 ? '+' : ''}
                          {formatAmount(reportData.operatingActivities.total)}
                        </span>
                      </td>
                      {filters.compareWith !== 'none' && (
                        <td className="px-3 py-4 text-right">
                          <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                            reportData.operatingActivities.change >= 0 
                              ? 'bg-green-100 text-green-800' 
                              : 'bg-red-100 text-red-800'
                          }`}>
                            {reportData.operatingActivities.change >= 0 ? (
                              <>
                                <TrendingUp size={12} className="mr-1" />
                                +{reportData.operatingActivities.change}%
                              </>
                            ) : (
                              <>
                                <TrendingDown size={12} className="mr-1" />
                                {reportData.operatingActivities.change}%
                              </>
                            )}
                          </span>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          
          {/* Investing Activities */}
          <div className="mb-8">
            <button
              onClick={() => toggleCategory('investing')}
              className="flex items-center justify-between w-full text-left mb-3"
            >
              <div className="flex items-center">
                {expandedCategories.investing ? (
                  <ChevronDown size={18} className="text-gray-500 mr-2" />
                ) : (
                  <ChevronRight size={18} className="text-gray-500 mr-2" />
                )}
                <span className="text-lg font-medium text-indigo-900">Investing Activities</span>
              </div>
              <div className={`text-lg font-bold ${
                reportData.investingActivities.total >= 0 ? 'text-green-600' : 'text-red-600'
              }`}>
                {reportData.investingActivities.total >= 0 ? '+' : ''}
                {formatAmount(reportData.investingActivities.total)}
              </div>
            </button>
            
            {expandedCategories.investing && (
              <div className="pl-6">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description
                      </th>
                      {filters.compareWith !== 'none' && (
                        <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Previous
                        </th>
                      )}
                      <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      {filters.compareWith !== 'none' && (
                        <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Change
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {reportData.investingActivities.items.map((item, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-3 py-4 text-sm text-gray-900">{item.name}</td>
                        {filters.compareWith !== 'none' && (
                          <td className="px-3 py-4 text-sm text-right">
                            <span className={item.previousAmount >= 0 ? 'text-green-600' : 'text-red-600'}>
                              {item.previousAmount >= 0 ? '+' : ''}
                              {formatAmount(item.previousAmount)}
                            </span>
                          </td>
                        )}
                        <td className="px-3 py-4 text-sm font-medium text-right">
                          <span className={item.amount >= 0 ? 'text-green-600' : 'text-red-600'}>
                            {item.amount >= 0 ? '+' : ''}
                            {formatAmount(item.amount)}
                          </span>
                        </td>
                        {filters.compareWith !== 'none' && item.change !== null && (
                          <td className="px-3 py-4 text-right">
                            <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                              (item.amount >= 0 && item.change >= 0) || (item.amount < 0 && item.change < 0)
                                ? 'bg-green-100 text-green-800' 
                                : 'bg-red-100 text-red-800'
                            }`}>
                              {item.change >= 0 ? (
                                <>
                                  <ArrowUp size={12} className="mr-1" />
                                  +{item.change}%
                                </>
                              ) : (
                                <>
                                  <ArrowDown size={12} className="mr-1" />
                                  {item.change}%
                                </>
                              )}
                            </span>
                          </td>
                        )}
                        {filters.compareWith !== 'none' && item.change === null && (
                          <td className="px-3 py-4 text-right">
                            <span className="text-gray-500">—</span>
                          </td>
                        )}
                      </tr>
                    ))}
                    <tr className="bg-indigo-50">
                      <td className="px-3 py-4 text-sm font-bold text-gray-900">Net Cash from Investing Activities</td>
                      {filters.compareWith !== 'none' && (
                        <td className="px-3 py-4 text-sm text-right font-bold">
                          <span className={reportData.investingActivities.previousTotal >= 0 ? 'text-green-600' : 'text-red-600'}>
                            {reportData.investingActivities.previousTotal >= 0 ? '+' : ''}
                            {formatAmount(reportData.investingActivities.previousTotal)}
                          </span>
                        </td>
                      )}
                      <td className="px-3 py-4 text-sm text-right font-bold">
                        <span className={reportData.investingActivities.total >= 0 ? 'text-green-600' : 'text-red-600'}>
                          {reportData.investingActivities.total >= 0 ? '+' : ''}
                          {formatAmount(reportData.investingActivities.total)}
                        </span>
                      </td>
                      {filters.compareWith !== 'none' && (
                        <td className="px-3 py-4 text-right">
                          <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                            reportData.investingActivities.change >= 0 
                              ? 'bg-red-100 text-red-800' 
                              : 'bg-green-100 text-green-800'
                          }`}>
                            {reportData.investingActivities.change >= 0 ? (
                              <>
                                <ArrowUp size={12} className="mr-1" />
                                +{reportData.investingActivities.change}%
                              </>
                            ) : (
                              <>
                                <ArrowDown size={12} className="mr-1" />
                                {reportData.investingActivities.change}%
                              </>
                            )}
                          </span>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          
          {/* Financing Activities */}
          <div className="mb-8">
            <button
              onClick={() => toggleCategory('financing')}
              className="flex items-center justify-between w-full text-left mb-3"
            >
              <div className="flex items-center">
                {expandedCategories.financing ? (
                  <ChevronDown size={18} className="text-gray-500 mr-2" />
                ) : (
                  <ChevronRight size={18} className="text-gray-500 mr-2" />
                )}
                <span className="text-lg font-medium text-indigo-900">Financing Activities</span>
              </div>
              <div className={`text-lg font-bold ${
                reportData.financingActivities.total >= 0 ? 'text-green-600' : 'text-red-600'
              }`}>
                {reportData.financingActivities.total >= 0 ? '+' : ''}
                {formatAmount(reportData.financingActivities.total)}
              </div>
            </button>
            
            {expandedCategories.financing && (
              <div className="pl-6">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description
                      </th>
                      {filters.compareWith !== 'none' && (
                        <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Previous
                        </th>
                      )}
                      <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      {filters.compareWith !== 'none' && (
                        <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Change
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {reportData.financingActivities.items.map((item, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-3 py-4 text-sm text-gray-900">{item.name}</td>
                        {filters.compareWith !== 'none' && (
                          <td className="px-3 py-4 text-sm text-right">
                            <span className={item.previousAmount >= 0 ? 'text-green-600' : 'text-red-600'}>
                            {item.previousAmount >= 0 ? '+' : ''}
                             {formatAmount(item.previousAmount)}
                           </span>
                         </td>
                       )}
                       <td className="px-3 py-4 text-sm font-medium text-right">
                         <span className={item.amount >= 0 ? 'text-green-600' : 'text-red-600'}>
                           {item.amount >= 0 ? '+' : ''}
                           {formatAmount(item.amount)}
                         </span>
                       </td>
                       {filters.compareWith !== 'none' && item.change !== null && (
                         <td className="px-3 py-4 text-right">
                           <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                             (item.amount >= 0 && item.change >= 0) || (item.amount < 0 && item.change < 0)
                               ? 'bg-green-100 text-green-800' 
                               : 'bg-red-100 text-red-800'
                           }`}>
                             {item.change >= 0 ? (
                               <>
                                 <ArrowUp size={12} className="mr-1" />
                                 +{item.change}%
                               </>
                             ) : (
                               <>
                                 <ArrowDown size={12} className="mr-1" />
                                 {item.change}%
                               </>
                             )}
                           </span>
                         </td>
                       )}
                       {filters.compareWith !== 'none' && item.change === null && (
                         <td className="px-3 py-4 text-right">
                           <span className="text-gray-500">—</span>
                         </td>
                       )}
                     </tr>
                   ))}
                   <tr className="bg-indigo-50">
                     <td className="px-3 py-4 text-sm font-bold text-gray-900">Net Cash from Financing Activities</td>
                     {filters.compareWith !== 'none' && (
                       <td className="px-3 py-4 text-sm text-right font-bold">
                         <span className={reportData.financingActivities.previousTotal >= 0 ? 'text-green-600' : 'text-red-600'}>
                           {reportData.financingActivities.previousTotal >= 0 ? '+' : ''}
                           {formatAmount(reportData.financingActivities.previousTotal)}
                         </span>
                       </td>
                     )}
                     <td className="px-3 py-4 text-sm text-right font-bold">
                       <span className={reportData.financingActivities.total >= 0 ? 'text-green-600' : 'text-red-600'}>
                         {reportData.financingActivities.total >= 0 ? '+' : ''}
                         {formatAmount(reportData.financingActivities.total)}
                       </span>
                     </td>
                     {filters.compareWith !== 'none' && (
                       <td className="px-3 py-4 text-right">
                         <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                           reportData.financingActivities.change >= 0 
                             ? 'bg-red-100 text-red-800' 
                             : 'bg-green-100 text-green-800'
                         }`}>
                           {reportData.financingActivities.change >= 0 ? (
                             <>
                               <ArrowUp size={12} className="mr-1" />
                               +{reportData.financingActivities.change}%
                             </>
                           ) : (
                             <>
                               <ArrowDown size={12} className="mr-1" />
                               {reportData.financingActivities.change}%
                             </>
                           )}
                         </span>
                       </td>
                     )}
                   </tr>
                 </tbody>
               </table>
             </div>
           )}
         </div>
         
         {/* Net Cash Flow and Ending Balance */}
         <div className="mt-8 border-t border-gray-200 pt-6">
           <div className="mb-6">
             <div className="flex items-center justify-between mb-2">
               <div className="text-lg font-medium text-gray-900">Net Increase/Decrease in Cash</div>
               <div className={`text-lg font-bold ${
                 reportData.overview.netCashFlow >= 0 ? 'text-green-600' : 'text-red-600'
               }`}>
                 {reportData.overview.netCashFlow >= 0 ? '+' : ''}
                 {formatAmount(reportData.overview.netCashFlow)}
               </div>
             </div>
             {filters.compareWith !== 'none' && (
               <div className="flex justify-end">
                 <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium ${
                   reportData.overview.netCashFlowChange >= 0 
                     ? 'bg-green-100 text-green-800' 
                     : 'bg-red-100 text-red-800'
                 }`}>
                   {reportData.overview.netCashFlowChange >= 0 ? (
                     <>
                       <TrendingUp size={14} className="mr-1" />
                       +{reportData.overview.netCashFlowChange}%
                     </>
                   ) : (
                     <>
                       <TrendingDown size={14} className="mr-1" />
                       {reportData.overview.netCashFlowChange}%
                     </>
                   )}
                 </span>
               </div>
             )}
           </div>
           
           <div className="mt-4 p-6 bg-blue-50 rounded-lg">
             <div className="flex items-center justify-between mb-1">
               <div className="text-lg font-medium text-blue-900">Cash Balance at End of Period</div>
               <div className="text-2xl font-bold text-gray-900">{formatAmount(reportData.overview.endingBalance)}</div>
             </div>
             <div className="text-sm text-blue-800">
               Beginning Balance + Net Cash Flow = Ending Balance
             </div>
             <div className="text-sm text-blue-800">
               {formatAmount(reportData.overview.startingBalance)} {reportData.overview.netCashFlow >= 0 ? '+' : ''}
               {formatAmount(reportData.overview.netCashFlow)} = {formatAmount(reportData.overview.endingBalance)}
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 );
};

export default CashFlowReport;