// src/components/finance/AccountDetail.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Edit, Download, Trash2, ArrowUpRight, ArrowDownRight,
  Building, DollarSign, CreditCard, Briefcase, FileText, Clock,
  Wallet, Landmark, TrendingUp, AlertCircle, PlusCircle, X
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { doc, getDoc, collection, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { formatCurrency } from '../../utils/currencyUtils';

const AccountDetail = () => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const [business, setBusiness] = useState(null);
  const [recentTransactions, setRecentTransactions] = useState([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Fetch account details
  useEffect(() => {
    const fetchAccountDetails = async () => {
      if (!accountId) return;
      
      setLoading(true);
      
      try {
        // Get account data
        const accountDoc = await getDoc(doc(db, 'accounts', accountId));
        
        if (accountDoc.exists()) {
          const accountData = {
            id: accountId,
            ...accountDoc.data()
          };
          
          setAccount(accountData);
          
          // Get the business this account belongs to
          if (accountData.businessId) {
            const businessDoc = await getDoc(doc(db, 'businesses', accountData.businessId));
            if (businessDoc.exists()) {
              setBusiness({
                id: accountData.businessId,
                ...businessDoc.data()
              });
            }
          }
          
          // Get recent transactions for this account
          const transactionsQuery = query(
            collection(db, 'financialTransactions'),
            where('accountId', '==', accountId),
            // Add a limit and order by here in a real app
          );
          
          const transactionsSnapshot = await getDocs(transactionsQuery);
          const transactionsData = [];
          
          transactionsSnapshot.forEach((doc) => {
            transactionsData.push({
              id: doc.id,
              ...doc.data()
            });
          });
          
          setRecentTransactions(transactionsData);
        } else {
          // Account not found
          navigate('/finance/accounts');
        }
      } catch (error) {
        console.error('Error fetching account details:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchAccountDetails();
  }, [accountId, navigate]);

  // Handle account deletion
  const handleDelete = async () => {
    if (!accountId) return;
    
    try {
      // Check if account has transactions
      if (recentTransactions.length > 0) {
        alert('Cannot delete account with existing transactions. Consider making it inactive instead.');
        setDeleteModalOpen(false);
        return;
      }
      
      await deleteDoc(doc(db, 'accounts', accountId));
      navigate('/finance/accounts');
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  // Get account type icon and color
  const getAccountTypeInfo = (type) => {
    switch (type) {
      case 'bank':
        return { icon: Building, color: 'blue' };
      case 'cash':
        return { icon: Wallet, color: 'green' };
      case 'credit_card':
        return { icon: CreditCard, color: 'purple' };
      case 'investment':
        return { icon: TrendingUp, color: 'amber' };
      case 'loan':
        return { icon: FileText, color: 'red' };
      default:
        return { icon: Briefcase, color: 'gray' };
    }
  };

  // Format date for display
  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!account) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Account not found</h3>
          <p className="text-gray-500 mb-6">The account you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/finance/accounts')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Accounts
          </button>
        </div>
      </div>
    );
  }

  const { icon: TypeIcon, color: typeColor } = getAccountTypeInfo(account.accountType);
  const formattedCurrency = formatCurrency(account.currentBalance, account.currency);
  const isNegative = account.currentBalance < 0;

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/finance/accounts"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Accounts
          </Link>
          
          <div className="flex space-x-3">
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
              <Download size={20} />
            </button>
            <Link
              to={`/finance/accounts/edit/${accountId}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Account Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className={`w-16 h-16 rounded-lg bg-${typeColor}-100 flex items-center justify-center flex-shrink-0 text-${typeColor}-600`}>
              <TypeIcon size={32} />
            </div>
            
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">{account.accountName}</h1>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-${account.isActive ? 'green' : 'gray'}-100 text-${account.isActive ? 'green' : 'gray'}-800`}>
                  {account.isActive ? 'Active' : 'Inactive'}
                </span>
              </div>
              
              <p className="text-gray-600">
                {account.accountType.charAt(0).toUpperCase() + account.accountType.slice(1).replace('_', ' ')}
                {account.institution && ` • ${account.institution}`}
                {account.accountNumber && ` • ${account.accountType === 'credit_card' ? 
                  `•••• ${account.accountNumber.slice(-4)}` : 
                  account.accountNumber}`}
              </p>
              
              {business && (
                <div className="mt-2">
                  <span className="text-sm text-gray-500">Business: </span>
                  <Link 
                    to={`/business/${business.id}`}
                    className="text-sm text-[#190192] hover:underline"
                  >
                    {business.name}
                  </Link>
                </div>
              )}
            </div>
            
            <div className="text-right">
              <p className="text-sm text-gray-500 mb-1">Current Balance</p>
              <p className={`text-2xl font-bold ${isNegative ? 'text-red-600' : 'text-gray-900'}`}>
                {formattedCurrency}
              </p>
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="flex px-6 -mb-px">
            <button
              onClick={() => setActiveTab('overview')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'overview'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('transactions')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'transactions'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Transactions
            </button>
          </nav>
        </div>
        
        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'overview' ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left Column: Basic Info */}
              <div className="lg:col-span-2 space-y-8">
                {/* Account Information */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Account Information</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {account.institution && (
                      <div className="flex items-start">
                        <Landmark size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Institution</p>
                          <p className="text-gray-900">{account.institution}</p>
                        </div>
                      </div>
                    )}
                    
                    {account.accountNumber && (
                      <div className="flex items-start">
                        <CreditCard size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            {account.accountType === 'credit_card' ? 'Card Number' : 'Account Number'}
                          </p>
                          <p className="text-gray-900">
                            {account.accountType === 'credit_card' 
                              ? `•••• •••• •••• ${account.accountNumber.slice(-4)}` 
                              : account.accountNumber}
                          </p>
                        </div>
                      </div>
                    )}
                    
                    {account.routingNumber && (
                      <div className="flex items-start">
                        <Building size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Routing Number</p>
                          <p className="text-gray-900">{account.routingNumber}</p>
                        </div>
                      </div>
                    )}
                    
                    <div className="flex items-start">
                      <DollarSign size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Currency</p>
                        <p className="text-gray-900">{account.currency}</p>
                      </div>
                    </div>
                  </div>
                  
                  {account.description && (
                    <div className="mt-4 flex items-start">
                      <FileText size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Description</p>
                        <p className="text-gray-900">{account.description}</p>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* Recent Activity */}
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium text-gray-900">Recent Activity</h3>
                    <Link
                      to={`/finance/transactions/new?accountId=${accountId}`}
                      className="text-[#190192] hover:text-[#2C0DB5] text-sm font-medium flex items-center"
                    >
                      <PlusCircle size={16} className="mr-1" />
                      New Transaction
                    </Link>
                  </div>
                  
                  {recentTransactions.length === 0 ? (
                    <div className="bg-gray-50 rounded-lg py-6 px-4 text-center">
                      <p className="text-gray-500">No transactions found for this account.</p>
                      <Link
                        to={`/finance/transactions/new?accountId=${accountId}`}
                        className="inline-flex items-center mt-2 text-[#190192] hover:text-[#2C0DB5] text-sm font-medium"
                      >
                        <PlusCircle size={16} className="mr-1" />
                        Record First Transaction
                      </Link>
                    </div>
                  ) : (
                    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                      <div className="flow-root">
                        <ul className="divide-y divide-gray-200">
                          {recentTransactions.slice(0, 5).map((transaction) => (
                            <li key={transaction.id} className="px-4 py-4 sm:px-6 hover:bg-gray-50">
                              <div className="flex items-center justify-between">
                                <div className="flex items-center min-w-0 flex-1">
                                  {transaction.amount >= 0 ? (
                                    <ArrowDownRight className="flex-shrink-0 h-5 w-5 text-green-500" />
                                  ) : (
                                    <ArrowUpRight className="flex-shrink-0 h-5 w-5 text-red-500" />
                                  )}
                                  <div className="min-w-0 flex-1 px-4">
                                    <p className="text-sm font-medium text-gray-900 truncate">
                                      {transaction.description}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate">
                                      {transaction.category} • {formatDate(transaction.date)}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex-shrink-0 text-right">
                                  <p className={`text-sm font-medium ${
                                    transaction.amount >= 0 ? 'text-green-600' : 'text-red-600'
                                  }`}>
                                    {formatCurrency(transaction.amount, account.currency)}
                                  </p>
                                  <p className="text-xs text-gray-500">
                                    {transaction.reference || '-'}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 text-right">
                        <Link
                          to={`/finance/accounts/${accountId}/transactions`}
                          className="text-sm font-medium text-[#190192] hover:text-[#2C0DB5]"
                        >
                          View all transactions
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Right Column: Sidebar */}
              <div className="lg:col-span-1">
                {/* Account Balance */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Balance Summary</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Opening Balance</span>
                      <span className="text-sm font-medium text-gray-900">
                        {formatCurrency(account.openingBalance, account.currency)}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Current Balance</span>
                      <span className={`text-sm font-medium ${isNegative ? 'text-red-600' : 'text-gray-900'}`}>
                        {formattedCurrency}
                      </span>
                    </div>
                    
                    <div className="border-t border-gray-200 pt-3 mt-3">
                      <div className="flex justify-between">
                        <span className="text-sm text-gray-500">Net Change</span>
                        <span className={`text-sm font-medium ${
                          account.currentBalance - account.openingBalance < 0 
                            ? 'text-red-600' 
                            : 'text-green-600'
                        }`}>
                          {formatCurrency(account.currentBalance - account.openingBalance, account.currency)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Quick Actions */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Quick Actions</h3>
                  
                  <div className="space-y-2">
                    <Link
                      to={`/finance/transactions/new?accountId=${accountId}&type=income`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Record Income
                    </Link>
                    
                    <Link
                      to={`/finance/transactions/new?accountId=${accountId}&type=expense`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Record Expense
                    </Link>
                    
                    <Link
                      to={`/finance/transactions/new?accountId=${accountId}&type=transfer`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Transfer Funds
                    </Link>
                  </div>
                </div>
                
                {/* Account Info */}
                <div className="bg-gray-50 rounded-xl p-5">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Information</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Opened On</span>
                      <span className="text-sm text-gray-900 flex items-center">
                        <Clock size={14} className="mr-1.5" />
                        {formatDate(account.openingDate)}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Last Updated</span>
                      <span className="text-sm text-gray-900 flex items-center">
                        <Clock size={14} className="mr-1.5" />
                        {formatDate(account.updatedAt)}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">ID</span>
                      <span className="text-sm text-gray-900">{account.id}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            /* Transactions Tab */
            <div>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-medium text-gray-900">Transaction History</h3>
                <Link
                  to={`/finance/transactions/new?accountId=${accountId}`}
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <PlusCircle size={16} className="mr-2" />
                  New Transaction
                </Link>
              </div>
              
              {recentTransactions.length === 0 ? (
                <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
                  <FileText size={48} className="mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No transactions yet</h3>
                  <p className="text-gray-500 mb-6 max-w-md mx-auto">
                    This account doesn't have any recorded transactions yet.
                  </p>
                  <Link
                    to={`/finance/transactions/new?accountId=${accountId}`}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                  >
                    <PlusCircle size={16} className="mr-2" />
                    Record First Transaction
                  </Link>
                </div>
              ) : (
                <div className="bg-white rounded-lg shadow border border-gray-200 overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Description
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Category
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Reference
                        </th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {recentTransactions.map((transaction) => (
                        <tr key={transaction.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {formatDate(transaction.date)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <Link 
                              to={`/finance/transactions/${transaction.id}`}
                              className="text-sm font-medium text-[#190192] hover:underline"
                            >
                              {transaction.description}
                            </Link>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {transaction.category}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {transaction.reference || '-'}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-right font-medium">
                            <span className={transaction.amount >= 0 ? 'text-green-600' : 'text-red-600'}>
                              {formatCurrency(transaction.amount, account.currency)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Account</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete <span className="font-medium">{account.accountName}</span>? This action cannot be undone.
              {recentTransactions.length > 0 && (
                <span className="block mt-2 text-red-600 font-medium">
                  Warning: This account has {recentTransactions.length} transactions. You cannot delete an account with transactions.
                </span>
              )}
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className={`px-4 py-2 bg-red-600 text-white rounded-lg font-medium ${
                  recentTransactions.length > 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-700'
                }`}
                disabled={recentTransactions.length > 0}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountDetail;