// src/components/inventory/suppliers/SupplierDetail.js
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Edit, Download, Trash2, 
  Building, MapPin, Globe, Phone, Mail, 
  FileText, DollarSign, CreditCard, Clock,
  ShoppingBag, BarChart2, Tag, User, X
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { useCurrency } from '../../../utils/currencyUtils';

const SupplierDetail = () => {
  const { supplierId } = useParams();
  const navigate = useNavigate();
  const { formatAmount } = useCurrency();
  
  const [loading, setLoading] = useState(true);
  const [supplier, setSupplier] = useState(null);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Fetch supplier data and related purchase orders
  useEffect(() => {
    const fetchSupplierData = async () => {
      setLoading(true);
      
      try {
        // In a real app, these would be API calls
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Mock supplier data
        const mockSupplier = {
          id: supplierId,
          name: 'Global Textiles Inc.',
          contactPerson: 'John Smith',
          email: 'orders@globaltextiles.com',
          phone: '+1 (555) 987-6543',
          mobile: '+1 (555) 123-7890',
          website: 'https://www.globaltextiles.com',
          address: '123 Manufacturing Blvd',
          city: 'New York',
          state: 'NY',
          zipCode: '10001',
          country: 'United States',
          category: 'Raw Materials',
          taxId: 'TX-12345678',
          paymentTerms: 'net30',
          creditLimit: '25000',
          currency: 'USD',
          notes: 'Reliable supplier for all fabric materials.',
          status: 'active',
          createdAt: '2025-01-15',
          updatedAt: '2025-03-20'
        };
        
        // Mock purchase orders from this supplier
        const mockPurchaseOrders = [
          {
            id: '1',
            poNumber: 'PO-2025-001',
            orderDate: '2025-03-01',
            deliveryDate: '2025-03-31',
            status: 'pending',
            totalAmount: 2850.00,
            items: 5
          },
          {
            id: '4',
            poNumber: 'PO-2025-004',
            orderDate: '2025-03-22',
            deliveryDate: '2025-04-22',
            status: 'cancelled',
            totalAmount: 1500.00,
            items: 4
          }
        ];
        
        setSupplier(mockSupplier);
        setPurchaseOrders(mockPurchaseOrders);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching supplier data:', error);
        setLoading(false);
      }
    };
    
    fetchSupplierData();
  }, [supplierId]);

  // Handle delete
  const handleDelete = async () => {
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Navigate back to the suppliers list
      navigate('/inventory/suppliers');
    } catch (error) {
      console.error('Error deleting supplier:', error);
    }
  };
  
  // Format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Payment terms display
  const getPaymentTermsDisplay = (terms) => {
    switch (terms) {
      case 'immediate': return 'Immediate Payment';
      case 'net15': return 'Net 15 days';
      case 'net30': return 'Net 30 days';
      case 'net45': return 'Net 45 days';
      case 'net60': return 'Net 60 days';
      case 'eom': return 'End of Month';
      case 'custom': return 'Custom Terms';
      default: return terms;
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!supplier) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Supplier not found</h3>
          <p className="text-gray-500 mb-6">The supplier you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/inventory/suppliers')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Suppliers
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/inventory/suppliers"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Suppliers
          </Link>
          
          <div className="flex space-x-3">
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
              <Download size={20} />
            </button>
            <Link
              to={`/inventory/suppliers/edit/${supplierId}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Supplier Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className="w-16 h-16 rounded-lg bg-blue-100 flex items-center justify-center flex-shrink-0 text-blue-700">
              <Building size={32} />
            </div>
            
            <div>
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">{supplier.name}</h1>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                  {supplier.category}
                </span>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  supplier.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}>
                  {supplier.status === 'active' ? 'Active' : 'Inactive'}
                </span>
              </div>
              
              {supplier.contactPerson && (
                <p className="text-gray-600">Contact: {supplier.contactPerson}</p>
              )}
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="flex px-6 -mb-px">
            <button
              onClick={() => setActiveTab('overview')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'overview'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('orders')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'orders'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Purchase Orders ({purchaseOrders.length})
            </button>
          </nav>
        </div>
        
        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'overview' ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left Column: Basic Info */}
              <div className="lg:col-span-2 space-y-8">
                {/* Contact Information */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Contact Information</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {supplier.phone && (
                      <div className="flex items-start">
                        <Phone size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Telephone</p>
                          <p className="text-gray-900">{supplier.phone}</p>
                        </div>
                      </div>
                    )}
                    
                    {supplier.mobile && (
                      <div className="flex items-start">
                        <Phone size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Mobile</p>
                          <p className="text-gray-900">{supplier.mobile}</p>
                        </div>
                      </div>
                    )}
                    
                    {supplier.email && (
                      <div className="flex items-start">
                        <Mail size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Email</p>
                          <a href={`mailto:${supplier.email}`} className="text-[#190192] hover:underline">
                            {supplier.email}
                          </a>
                        </div>
                      </div>
                    )}
                    
                    {supplier.website && (
                      <div className="flex items-start">
                        <Globe size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Website</p>
                          <a 
                            href={supplier.website.startsWith('http') ? supplier.website : `https://${supplier.website}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="text-[#190192] hover:underline"
                          >
                            {supplier.website}
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  
                  {supplier.address && (
                    <div className="mt-4 flex items-start">
                      <MapPin size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Address</p>
                        <div>
                          <p className="text-gray-900">{supplier.address}</p>
                          {supplier.city && supplier.state && (
                            <p className="text-gray-900">{supplier.city}, {supplier.state} {supplier.zipCode}</p>
                          )}
                          <p className="text-gray-900">{supplier.country}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* Financial Information */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Financial Information</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {supplier.taxId && (
                      <div className="flex items-start">
                        <FileText size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Tax ID / VAT Number</p>
                          <p className="text-gray-900">{supplier.taxId}</p>
                        </div>
                      </div>
                    )}
                    
                    {supplier.paymentTerms && (
                      <div className="flex items-start">
                        <Clock size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Payment Terms</p>
                          <p className="text-gray-900">{getPaymentTermsDisplay(supplier.paymentTerms)}</p>
                        </div>
                      </div>
                    )}
                    
                    {supplier.creditLimit && (
                      <div className="flex items-start">
                        <DollarSign size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Credit Limit</p>
                          <p className="text-gray-900">{formatAmount(supplier.creditLimit)}</p>
                        </div>
                      </div>
                    )}
                    
                    {supplier.currency && (
                      <div className="flex items-start">
                        <CreditCard size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Currency</p>
                          <p className="text-gray-900">{supplier.currency}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Notes */}
                {supplier.notes && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Notes</h3>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-gray-700">{supplier.notes}</p>
                    </div>
                  </div>
                )}
              </div>
              
              {/* Right Column: Sidebar */}
              <div className="lg:col-span-1">
                {/* Purchase Orders Summary */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Purchase Orders</h3>
                  
                  {purchaseOrders.length === 0 ? (
                    <p className="text-gray-500 italic">No purchase orders with this supplier.</p>
                  ) : (
                    <>
                      <div className="space-y-3">
                        {purchaseOrders.slice(0, 3).map(order => (
                          <Link
                            key={order.id}
                            to={`/inventory/buying/${order.id}`}
                            className="block p-3 bg-white rounded-lg hover:bg-gray-100"
                          >
                            <div className="flex items-center justify-between">
                              <div>
                                <p className="font-medium text-gray-900">{order.poNumber}</p>
                                <p className="text-sm text-gray-500">{formatDate(order.orderDate)}</p>
                              </div>
                              <div className="text-right">
                                <p className="font-medium text-gray-900">{formatAmount(order.totalAmount)}</p>
                                <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs ${
                                  order.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                                  order.status === 'approved' ? 'bg-blue-100 text-blue-800' :
                                  order.status === 'received' ? 'bg-green-100 text-green-800' :
                                  'bg-red-100 text-red-800'
                                }`}>
                                  {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                                </span>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                      
                      {purchaseOrders.length > 3 && (
                        <button
                          onClick={() => setActiveTab('orders')}
                          className="mt-3 text-sm text-[#190192] hover:text-[#2C0DB5] font-medium"
                        >
                          View all {purchaseOrders.length} purchase orders
                        </button>
                      )}
                    </>
                  )}
                  
                  <Link
                    to={`/inventory/buying/new?supplier=${supplierId}`}
                    className="mt-4 w-full py-2 flex justify-center items-center text-[#190192] bg-white border border-gray-300 rounded-lg hover:bg-purple-50 font-medium"
                  >
                    <ShoppingBag size={16} className="mr-2" />
                    Create Purchase Order
                  </Link>
                </div>
                
                {/* Meta Information */}
                <div className="bg-gray-50 rounded-xl p-5">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Information</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Created</span>
                      <span className="text-sm text-gray-900">{formatDate(supplier.createdAt)}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Last Updated</span>
                      <span className="text-sm text-gray-900">{formatDate(supplier.updatedAt)}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">ID</span>
                      <span className="text-sm text-gray-900">{supplier.id}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Status</span>
                      <span className={`text-sm font-medium ${
                        supplier.status === 'active' ? 'text-green-600' : 'text-red-600'
                      }`}>
                        {supplier.status === 'active' ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            /* Purchase Orders Tab */
            <div>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-medium text-gray-900">Purchase Orders</h3>
                
                <Link
                  to={`/inventory/buying/new?supplier=${supplierId}`}
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <ShoppingBag size={16} className="mr-2" />
                  Create Purchase Order
                </Link>
              </div>
              
              {purchaseOrders.length === 0 ? (
                <div className="text-center py-12 bg-gray-50 rounded-lg">
                  <ShoppingBag size={48} className="mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No purchase orders yet</h3>
                  <p className="text-gray-500 mb-6">
                    You haven't created any purchase orders with this supplier yet.
                  </p>
                  <Link
                    to={`/inventory/buying/new?supplier=${supplierId}`}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                  >
                    <ShoppingBag size={16} className="mr-2" />
                    Create First Purchase Order
                  </Link>
                </div>
              ) : (
                <div className="bg-white rounded-lg shadow border border-gray-200 overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          PO Number
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Items
                        </th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Total
                        </th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {purchaseOrders.map((order) => (
                        <tr key={order.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {order.poNumber}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {formatDate(order.orderDate)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              order.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                              order.status === 'approved' ? 'bg-blue-100 text-blue-800' :
                              order.status === 'received' ? 'bg-green-100 text-green-800' :
                              'bg-red-100 text-red-800'
                            }`}>
                              {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                            {order.items}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
                            {formatAmount(order.totalAmount)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <Link
                              to={`/inventory/buying/${order.id}`}
                              className="text-[#190192] hover:text-[#2C0DB5]"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Supplier</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete <span className="font-medium">{supplier.name}</span>? This action cannot be undone.
              {purchaseOrders.length > 0 && (
                <span className="block mt-2 text-red-600">
                  Warning: This supplier has {purchaseOrders.length} purchase orders that will be affected.
                </span>
              )}
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SupplierDetail;