// src/components/inventory/buying/PurchaseOrderDetail.js
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Edit, Download, Trash2, ShoppingBag, 
  Building, Calendar, MapPin, Truck, CreditCard, Clock,
  FileText, DollarSign, CheckCircle, XCircle, AlertCircle,
  ExternalLink, Send, Package, X, ArrowDownCircle
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { useCurrency } from '../../../utils/currencyUtils';
import { db } from '../../../firebase/config';

const PurchaseOrderDetail = () => {
  const { poId } = useParams();
  const navigate = useNavigate();
  const { formatAmount } = useCurrency();
  
  const [loading, setLoading] = useState(true);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [supplier, setSupplier] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [receiveModalOpen, setReceiveModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');

  // Fetch purchase order and related data
  useEffect(() => {
    const fetchPurchaseOrderData = async () => {
      setLoading(true);
      
      try {
        // In a real app, these would be API calls
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Mock purchase order data
        const mockPurchaseOrder = {
          id: poId,
          poNumber: 'PO-2025-001',
          supplierId: '1',
          orderDate: '2025-03-01',
          expectedDeliveryDate: '2025-03-31',
          status: 'pending',
          shippingMethod: 'standard',
          shippingAddress: '123 Manufacturing Blvd, New York, NY 10001',
          paymentTerms: 'net30',
          currency: 'USD',
          notes: 'Please ship as soon as possible.',
          items: [
            {
              id: '1',
              sku: 'TS-001',
              name: 'T-Shirt Large',
              description: 'Large size t-shirt, black color',
              quantity: 50,
              unitPrice: 8.50,
              taxRate: 7,
              amount: 425.00,
              taxAmount: 29.75,
              totalAmount: 454.75,
              receivedQuantity: 0
            },
            {
              id: '4',
              sku: 'RM-201',
              name: 'Cotton Fabric',
              description: 'Premium cotton fabric, white, 5 yards',
              quantity: 100,
              unitPrice: 4.25,
              taxRate: 7,
              amount: 425.00,
              taxAmount: 29.75,
              totalAmount: 454.75,
              receivedQuantity: 0
            }
          ],
          subtotal: 850.00,
          taxAmount: 59.50,
          shippingCost: 25.00,
          totalAmount: 934.50,
          createdAt: '2025-03-01',
          updatedAt: '2025-03-05',
          attachments: [
            {
              id: '1',
              name: 'supplier_quote.pdf',
              type: 'document',
              url: '#',
              uploadedAt: '2025-03-01'
            }
          ],
          history: [
            {
              id: '1',
              date: '2025-03-01',
              action: 'created',
              user: 'John Doe',
              notes: 'Purchase order created'
            },
            {
              id: '2',
              date: '2025-03-05',
              action: 'updated',
              user: 'John Doe',
              notes: 'Updated shipping address'
            }
          ]
        };
        
        // Mock supplier data
        const mockSupplier = {
          id: '1',
          name: 'Global Textiles Inc.',
          contactPerson: 'John Smith',
          email: 'orders@globaltextiles.com',
          phone: '+1 (555) 987-6543',
          address: '123 Manufacturing Blvd, New York, NY 10001',
          country: 'United States'
        };
        
        setPurchaseOrder(mockPurchaseOrder);
        setSupplier(mockSupplier);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching purchase order data:', error);
        setLoading(false);
      }
    };
    
    fetchPurchaseOrderData();
  }, [poId]);

  // Handle delete
  const handleDelete = async () => {
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Navigate back to the purchase orders list
      navigate('/inventory/buying');
    } catch (error) {
      console.error('Error deleting purchase order:', error);
    }
  };
  
  // Handle send email
  const handleSendEmail = async () => {
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Close modal
      setEmailModalOpen(false);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };
  
  // Handle receive items
  const handleReceiveItems = async () => {
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Navigate to receiving page
      navigate(`/inventory/receiving/new?po=${poId}`);
    } catch (error) {
      console.error('Error initiating receive process:', error);
    }
  };
  
  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Get payment terms display
  const getPaymentTermsDisplay = (terms) => {
    switch (terms) {
      case 'immediate': return 'Immediate Payment';
      case 'net15': return 'Net 15 days';
      case 'net30': return 'Net 30 days';
      case 'net45': return 'Net 45 days';
      case 'net60': return 'Net 60 days';
      case 'eom': return 'End of Month';
      default: return terms;
    }
  };
  
  // Get shipping method display
  const getShippingMethodDisplay = (method) => {
    switch (method) {
      case 'standard': return 'Standard Shipping';
      case 'express': return 'Express Shipping';
      case 'overnight': return 'Overnight Shipping';
      case 'pickup': return 'Supplier Pickup';
      case 'freight': return 'Freight';
      default: return method;
    }
  };
  
  // Get status badge
  const getStatusBadge = (status) => {
    switch (status) {
      case 'draft':
        return { bg: 'bg-gray-100', text: 'text-gray-800', icon: FileText };
      case 'pending':
        return { bg: 'bg-yellow-100', text: 'text-yellow-800', icon: Clock };
      case 'approved':
        return { bg: 'bg-blue-100', text: 'text-blue-800', icon: CheckCircle };
      case 'received':
        return { bg: 'bg-green-100', text: 'text-green-800', icon: CheckCircle };
      case 'cancelled':
        return { bg: 'bg-red-100', text: 'text-red-800', icon: XCircle };
      default:
        return { bg: 'bg-gray-100', text: 'text-gray-800', icon: FileText };
    }
  };
  
  // Get currency symbol
  const getCurrencySymbol = (code) => {
    switch (code) {
      case 'USD': return '$';
      case 'EUR': return '€';
      case 'GBP': return '£';
      case 'ZAR': return 'R';
      case 'JPY': return '¥';
      case 'CAD': return 'CA$';
      case 'AUD': return 'A$';
      default: return '$';
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!purchaseOrder) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Purchase Order not found</h3>
          <p className="text-gray-500 mb-6">The purchase order you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/inventory/buying')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Purchase Orders
          </button>
        </div>
      </div>
    );
  }

  const statusBadge = getStatusBadge(purchaseOrder.status);
  const StatusIcon = statusBadge.icon;
  const currencySymbol = getCurrencySymbol(purchaseOrder.currency);

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/inventory/buying"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Purchase Orders
          </Link>
          
          <div className="flex space-x-3">
            <button 
              onClick={() => setEmailModalOpen(true)}
              className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 flex items-center"
            >
              <Send size={16} className="mr-2" />
              Email
            </button>
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
              <Download size={20} />
            </button>
            <Link
              to={`/inventory/buying/edit/${poId}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* PO Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className="w-16 h-16 rounded-lg bg-blue-100 flex items-center justify-center flex-shrink-0 text-blue-700">
              <ShoppingBag size={32} />
            </div>
            
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-2 mb-2">
                <h1 className="text-2xl font-bold text-gray-900">{purchaseOrder.poNumber}</h1>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${statusBadge.bg} ${statusBadge.text}`}>
                  <StatusIcon size={14} className="mr-1" />
                  {purchaseOrder.status.charAt(0).toUpperCase() + purchaseOrder.status.slice(1)}
                </span>
              </div>
              
              <div className="flex flex-wrap gap-4 text-gray-600">
                <div className="flex items-center">
                  <Building size={16} className="mr-1 text-gray-400" />
                  <span>{supplier?.name}</span>
                </div>
                <div className="flex items-center">
                  <Calendar size={16} className="mr-1 text-gray-400" />
                  <span>Ordered: {formatDate(purchaseOrder.orderDate)}</span>
                </div>
                <div className="flex items-center">
                  <DollarSign size={16} className="mr-1 text-gray-400" />
                  <span>Total: {formatAmount(purchaseOrder.totalAmount)}</span>
                </div>
              </div>
            </div>
            
            {/* Action Button based on status */}
            <div>
              {purchaseOrder.status === 'pending' || purchaseOrder.status === 'approved' ? (
                <button
                  onClick={() => setReceiveModalOpen(true)}
                  className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 flex items-center"
                >
                  <ArrowDownCircle size={16} className="mr-2" />
                  Receive Items
                </button>
              ) : purchaseOrder.status === 'draft' ? (
                <button
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center"
                >
                  <CheckCircle size={16} className="mr-2" />
                  Approve Order
                </button>
              ) : null}
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="flex px-6 -mb-px">
            <button
              onClick={() => setActiveTab('overview')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'overview'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('history')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'history'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              History
            </button>
          </nav>
        </div>
        
        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'overview' ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left Column: Items and Details */}
              <div className="lg:col-span-2 space-y-8">
                {/* Items Table */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Items</h3>
                  <div className="border rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Item
                          </th>
                          <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Quantity
                          </th>
                          <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Unit Price
                          </th>
                          <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Tax
                          </th>
                          <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {purchaseOrder.items.map((item) => (
                          <tr key={item.id} className="hover:bg-gray-50">
                            <td className="px-6 py-4">
                              <div className="text-sm font-medium text-gray-900">{item.name}</div>
                              <div className="text-sm text-gray-500">SKU: {item.sku}</div>
                              {item.description && (
                                <div className="text-sm text-gray-500 mt-1">{item.description}</div>
                              )}
                            </td>
                            <td className="px-4 py-4 text-center text-sm text-gray-500">
                              {item.quantity}
                              {item.receivedQuantity > 0 && (
                                <div className="text-xs text-green-600 mt-1">
                                  {item.receivedQuantity} received
                                </div>
                              )}
                            </td>
                            <td className="px-4 py-4 text-right text-sm text-gray-900">
                              {currencySymbol}{item.unitPrice.toFixed(2)}
                            </td>
                            <td className="px-4 py-4 text-right text-sm text-gray-500">
                              {item.taxRate}%
                            </td>
                            <td className="px-4 py-4 text-right text-sm font-medium text-gray-900">
                              {currencySymbol}{item.amount.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot className="bg-gray-50">
                        <tr>
                          <td colSpan="4" className="px-6 py-3 text-right text-sm font-medium text-gray-500">
                            Subtotal:
                          </td>
                          <td className="px-4 py-3 text-right text-sm font-medium text-gray-900">
                            {currencySymbol}{purchaseOrder.subtotal.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="px-6 py-3 text-right text-sm font-medium text-gray-500">
                            Tax:
                          </td>
                          <td className="px-4 py-3 text-right text-sm font-medium text-gray-900">
                            {currencySymbol}{purchaseOrder.taxAmount.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="px-6 py-3 text-right text-sm font-medium text-gray-500">
                            Shipping:
                          </td>
                          <td className="px-4 py-3 text-right text-sm font-medium text-gray-900">
                            {currencySymbol}{purchaseOrder.shippingCost.toFixed(2)}
                          </td>
                        </tr>
                        <tr className="border-t border-gray-200">
                          <td colSpan="4" className="px-6 py-3 text-right text-base font-bold text-gray-900">
                            Total:
                          </td>
                          <td className="px-4 py-3 text-right text-base font-bold text-[#190192]">
                            {currencySymbol}{purchaseOrder.totalAmount.toFixed(2)}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                
                {/* Shipping Information */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Shipping Information</h3>
                  <div className="bg-gray-50 rounded-lg p-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <h4 className="text-sm font-medium text-gray-500 mb-2">Shipping Address</h4>
                        <div className="flex items-start">
                          <MapPin size={18} className="text-gray-400 mt-0.5 mr-2 flex-shrink-0" />
                          <div className="text-gray-900">
                            <p>{purchaseOrder.shippingAddress}</p>
                          </div>
                        </div>
                      </div>
                      
                      <div>
                        <h4 className="text-sm font-medium text-gray-500 mb-2">Shipping Method</h4>
                        <div className="flex items-start">
                          <Truck size={18} className="text-gray-400 mt-0.5 mr-2 flex-shrink-0" />
                          <p className="text-gray-900">
                            {getShippingMethodDisplay(purchaseOrder.shippingMethod)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Supplier Information */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Supplier Information</h3>
                  <div className="bg-gray-50 rounded-lg p-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {supplier && (
                        <>
                          <div>
                            <div className="flex items-center mb-3">
                              <Building size={18} className="text-gray-400 mr-2" />
                              <h4 className="text-sm font-medium text-gray-900">{supplier.name}</h4>
                            </div>
                            
                            {supplier.contactPerson && (
                              <p className="text-sm text-gray-700 mb-1 ml-7">
                                Contact: {supplier.contactPerson}
                              </p>
                            )}
                            
                            {supplier.email && (
                              <div className="flex items-center mb-1 ml-7">
                                <a href={`mailto:${supplier.email}`} className="text-sm text-[#190192] hover:underline">
                                  {supplier.email}
                                </a>
                              </div>
                            )}
                            
                            {supplier.phone && (
                              <p className="text-sm text-gray-700 mb-1 ml-7">
                                {supplier.phone}
                              </p>
                            )}
                          </div>
                          
                          <div>
                            <div className="flex items-start mb-2">
                              <MapPin size={18} className="text-gray-400 mt-0.5 mr-2 flex-shrink-0" />
                              <p className="text-sm text-gray-700">
                                {supplier.address}<br />
                                {supplier.country}
                              </p>
                            </div>
                            
                            <div className="flex justify-end">
                              <Link 
                                to={`/inventory/suppliers/${supplier.id}`}
                                className="text-sm text-[#190192] hover:text-[#2C0DB5] flex items-center"
                              >
                                View Supplier Details <ExternalLink size={14} className="ml-1" />
                              </Link>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                
                {/* Notes */}
                {purchaseOrder.notes && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Notes</h3>
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-gray-700">{purchaseOrder.notes}</p>
                    </div>
                  </div>
                )}
                
                {/* Attachments */}
                {purchaseOrder.attachments && purchaseOrder.attachments.length > 0 && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Attachments</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                      {purchaseOrder.attachments.map(attachment => (
                        <div key={attachment.id} className="border rounded-lg overflow-hidden">
                          <div className="p-3 border-b bg-gray-50 flex justify-between items-center">
                            <span className="text-sm font-medium truncate flex-1">{attachment.name}</span>
                            <button className="text-gray-500 hover:text-gray-700 p-1">
                              <Download size={16} />
                            </button>
                          </div>
                          
                          <div className="h-32 bg-gray-100 flex items-center justify-center">
                            {attachment.type === 'image' ? (
                              <img 
                                src={attachment.url} 
                                alt={attachment.name}
                                className="h-full w-full object-contain"
                              />
                            ) : (
                              <FileText size={32} className="text-gray-400" />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              
              {/* Right Column: Sidebar */}
              <div className="lg:col-span-1">
                {/* Payment Information */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Payment Information</h3>
                  
                  <div className="space-y-4">
                    <div className="flex items-start">
                      <CreditCard size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Currency</p>
                        <p className="text-gray-900">{purchaseOrder.currency}</p>
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <Clock size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Payment Terms</p>
                        <p className="text-gray-900">{getPaymentTermsDisplay(purchaseOrder.paymentTerms)}</p>
                      </div>
                    </div>
                    
                    <div className="pt-2 border-t border-gray-200">
                      <div className="flex justify-between items-center">
                        <span className="text-gray-500 font-medium">Subtotal:</span>
                        <span className="text-gray-900">{formatAmount(purchaseOrder.subtotal)}</span>
                      </div>
                      <div className="flex justify-between items-center mt-1">
                        <span className="text-gray-500 font-medium">Tax:</span>
                        <span className="text-gray-900">{formatAmount(purchaseOrder.taxAmount)}</span>
                      </div>
                      <div className="flex justify-between items-center mt-1">
                        <span className="text-gray-500 font-medium">Shipping:</span>
                        <span className="text-gray-900">{formatAmount(purchaseOrder.shippingCost)}</span>
                      </div>
                      <div className="flex justify-between items-center mt-2 pt-2 border-t border-gray-200">
                        <span className="text-gray-900 font-bold">Total:</span>
                        <span className="text-[#190192] font-bold">{formatAmount(purchaseOrder.totalAmount)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Delivery Information */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Delivery Information</h3>
                  
                  <div className="space-y-4">
                    <div className="flex items-start">
                      <Calendar size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Order Date</p>
                        <p className="text-gray-900">{formatDate(purchaseOrder.orderDate)}</p>
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <Calendar size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Expected Delivery</p>
                        <p className="text-gray-900">{formatDate(purchaseOrder.expectedDeliveryDate)}</p>
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <Truck size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Shipping Method</p>
                        <p className="text-gray-900">{getShippingMethodDisplay(purchaseOrder.shippingMethod)}</p>
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <Package size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Items</p>
                        <p className="text-gray-900">{purchaseOrder.items.length} items</p>
                        <p className="text-sm text-gray-500">
                          {purchaseOrder.items.reduce((total, item) => total + item.quantity, 0)} units total
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Meta Information */}
                <div className="bg-gray-50 rounded-xl p-5">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Information</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Status</span>
                      <span className={`text-sm font-medium ${statusBadge.text}`}>
                        {purchaseOrder.status.charAt(0).toUpperCase() + purchaseOrder.status.slice(1)}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Created</span>
                      <span className="text-sm text-gray-900">{formatDate(purchaseOrder.createdAt)}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Last Updated</span>
                      <span className="text-sm text-gray-900">{formatDate(purchaseOrder.updatedAt)}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">ID</span>
                      <span className="text-sm text-gray-900">{purchaseOrder.id}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            /* History Tab */
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Order History</h3>
              
              {purchaseOrder.history && purchaseOrder.history.length > 0 ? (
                <div className="border-l-2 border-gray-200 ml-3">
                  {purchaseOrder.history.map((event, index) => (
                    <div key={event.id} className="relative mb-6">
                      <div className="absolute -left-3 mt-1.5 w-6 h-6 bg-white">
                        <div className={`w-4 h-4 rounded-full ${
                          event.action === 'created' ? 'bg-green-500' :
                          event.action === 'updated' ? 'bg-blue-500' :
                          event.action === 'cancelled' ? 'bg-red-500' :
                          'bg-gray-500'
                        }`}></div>
                      </div>
                      <div className="ml-6">
                        <div className="flex items-center">
                          <p className="text-sm font-medium text-gray-900">
                            {event.action.charAt(0).toUpperCase() + event.action.slice(1)}
                          </p>
                          <span className="mx-2 text-gray-500">•</span>
                          <p className="text-sm text-gray-500">{formatDate(event.date)}</p>
                        </div>
                        <p className="text-sm text-gray-600 mt-1">{event.notes}</p>
                        {event.user && (
                          <p className="text-xs text-gray-500 mt-1">by {event.user}</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="bg-gray-50 rounded-lg p-6 text-center">
                  <p className="text-gray-500">No history events recorded for this purchase order.</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Purchase Order</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete purchase order <span className="font-medium">{purchaseOrder.poNumber}</span>? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* Receive Items Modal */}
      {receiveModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Receive Items</h3>
            <p className="text-gray-600 mb-6">
              Are you ready to receive items for purchase order <span className="font-medium">{purchaseOrder.poNumber}</span>?
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setReceiveModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleReceiveItems}
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 font-medium"
              >
                Receive Now
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* Email Modal */}
      {emailModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-lg w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Email Purchase Order</h3>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                To
              </label>
              <input
                type="email"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={supplier?.email || ''}
                readOnly
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Subject
              </label>
              <input
                type="text"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={`Purchase Order: ${purchaseOrder.poNumber}`}
                readOnly
              />
            </div>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Message
              </label>
              <textarea
                rows="4"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                defaultValue={`Dear ${supplier?.contactPerson || 'Supplier'},\n\nPlease find attached purchase order ${purchaseOrder.poNumber}.\n\nKind regards,\nYour Company`}
              ></textarea>
            </div>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setEmailModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleSendEmail}
                className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] font-medium"
              >
                Send Email
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PurchaseOrderDetail;