// src/components/inventory/reports/InventoryReportDetail.js
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Download, BarChart2, Calendar, 
  Package, Archive, ArrowDown, ArrowUp, 
  DollarSign, Filter, Printer, RefreshCw, 
  ExternalLink, X, AlertTriangle, FileText,
  PieChart, TrendingUp, TrendingDown
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { useCurrency } from '../../../utils/currencyUtils';
import { db } from '../../../firebase/config';

const InventoryReportDetail = () => {
  const { reportId } = useParams();
  console.log("Report ID:", reportId);
  const navigate = useNavigate();
  const { formatAmount } = useCurrency();
  
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [filterPeriod, setFilterPeriod] = useState('all');
  const [filterCategory, setFilterCategory] = useState('all');

  // Fetch report data
  useEffect(() => {
    const fetchReportData = async () => {
      setLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Mock report data based on the report ID
        let mockReport;
        
        switch (reportId) {
          case '1': // Inventory Valuation Report
            mockReport = {
              id: reportId,
              title: 'Monthly Inventory Valuation',
              type: 'valuation',
              description: 'Summary of inventory value at the end of the month',
              generated: '2025-03-01',
              period: 'March 2025',
              summary: {
                totalItems: 245,
                totalValue: 58750.25,
                averageValue: 239.80,
                lowStockItems: 18,
                outOfStockItems: 3
              },
              categories: [
                { name: 'Apparel', count: 65, value: 18250.75 },
                { name: 'Electronics', count: 45, value: 22500.50 },
                { name: 'Home Goods', count: 85, value: 12500.00 },
                { name: 'Office Supplies', count: 50, value: 5499.00 }
              ],
              items: [
                { 
                  id: '1', 
                  sku: 'TS-001', 
                  name: 'T-Shirt Large', 
                  category: 'Apparel',
                  quantity: 125,
                  costPrice: 8.50,
                  totalValue: 1062.50,
                  lastUpdated: '2025-02-15'
                },
                { 
                  id: '2', 
                  sku: 'MG-100', 
                  name: 'Coffee Mug', 
                  category: 'Home Goods',
                  quantity: 85,
                  costPrice: 5.75,
                  totalValue: 488.75,
                  lastUpdated: '2025-02-20'
                },
                { 
                  id: '3', 
                  sku: 'DL-050', 
                  name: 'E-book Bundle', 
                  category: 'Digital Products',
                  quantity: Infinity,
                  costPrice: 10.00,
                  totalValue: 'N/A',
                  lastUpdated: '2025-01-10'
                },
                { 
                  id: '4', 
                  sku: 'LP-201', 
                  name: 'Laptop Pro', 
                  category: 'Electronics',
                  quantity: 12,
                  costPrice: 850.00,
                  totalValue: 10200.00,
                  lastUpdated: '2025-02-28'
                },
                { 
                  id: '5', 
                  sku: 'TS-002', 
                  name: 'T-Shirt Medium', 
                  category: 'Apparel',
                  quantity: 95,
                  costPrice: 8.50,
                  totalValue: 807.50,
                  lastUpdated: '2025-02-15'
                }
              ]
            };
            
            setChartData({
              labels: mockReport.categories.map(cat => cat.name),
              datasets: [
                {
                  values: mockReport.categories.map(cat => cat.value)
                }
              ]
            });
            break;
            
          case '2': // Low Stock Report
            mockReport = {
              id: reportId,
              title: 'Low Stock Items',
              type: 'stock-level',
              description: 'Items that are at or below their minimum stock level',
              generated: '2025-03-15',
              period: 'Current',
              summary: {
                totalLowStockItems: 18,
                totalOutOfStockItems: 3,
                totalItemsNeedingReorder: 21,
                estimatedReorderValue: 12550.75
              },
              items: [
                { 
                  id: '4', 
                  sku: 'LP-201', 
                  name: 'Laptop Pro', 
                  category: 'Electronics',
                  currentStock: 12,
                  minStockLevel: 15,
                  reorderPoint: 20,
                  reorderQuantity: 25,
                  estimatedCost: 21250.00,
                  status: 'low'
                },
                { 
                  id: '6', 
                  sku: 'DS-100', 
                  name: 'Desktop Scanner', 
                  category: 'Electronics',
                  currentStock: 3,
                  minStockLevel: 10,
                  reorderPoint: 15,
                  reorderQuantity: 20,
                  estimatedCost: 3990.00,
                  status: 'low'
                },
                { 
                  id: '7', 
                  sku: 'OF-220', 
                  name: 'Office Chair', 
                  category: 'Office Supplies',
                  currentStock: 0,
                  minStockLevel: 5,
                  reorderPoint: 8,
                  reorderQuantity: 10,
                  estimatedCost: 1250.00,
                  status: 'out'
                },
                { 
                  id: '8', 
                  sku: 'PH-110', 
                  name: 'Smartphone', 
                  category: 'Electronics',
                  currentStock: 5,
                  minStockLevel: 8,
                  reorderPoint: 12,
                  reorderQuantity: 15,
                  estimatedCost: 6750.00,
                  status: 'low'
                },
                { 
                  id: '9', 
                  sku: 'PJ-001', 
                  name: 'Portable Projector', 
                  category: 'Electronics',
                  currentStock: 2,
                  minStockLevel: 5,
                  reorderPoint: 8,
                  reorderQuantity: 10,
                  estimatedCost: 3500.00,
                  status: 'low'
                }
              ]
            };
            break;
            
          case '3': // Stock Movement Analysis
            mockReport = {
              id: reportId,
              title: 'Stock Movement Analysis',
              type: 'movement',
              description: 'Analysis of stock movement patterns for the past quarter',
              generated: '2025-03-20',
              period: 'Q1 2025',
              summary: {
                totalItemsReceived: 1250,
                totalItemsSold: 950,
                totalAdjustments: 35,
                netChange: 265
              },
              categories: [
                { name: 'Apparel', received: 450, sold: 380, adjusted: 10, net: 80 },
                { name: 'Electronics', received: 200, sold: 175, adjusted: 5, net: 30 },
                { name: 'Home Goods', received: 350, sold: 275, adjusted: 15, net: 90 },
                { name: 'Office Supplies', received: 250, sold: 120, adjusted: 5, net: 135 }
              ],
              topMovers: [
                { 
                  id: '1', 
                  sku: 'TS-001', 
                  name: 'T-Shirt Large', 
                  category: 'Apparel',
                  received: 150,
                  sold: 125,
                  adjusted: 0,
                  net: 25,
                  turnoverRate: 1.25
                },
                { 
                  id: '10', 
                  sku: 'HG-005', 
                  name: 'Stainless Steel Water Bottle', 
                  category: 'Home Goods',
                  received: 120,
                  sold: 95,
                  adjusted: 0,
                  net: 25,
                  turnoverRate: 0.95
                },
                { 
                  id: '4', 
                  sku: 'LP-201', 
                  name: 'Laptop Pro', 
                  category: 'Electronics',
                  received: 50,
                  sold: 40,
                  adjusted: 2,
                  net: 12,
                  turnoverRate: 0.8
                },
                { 
                  id: '11', 
                  sku: 'OS-101', 
                  name: 'Premium Notebook', 
                  category: 'Office Supplies',
                  received: 100,
                  sold: 80,
                  adjusted: 0,
                  net: 20,
                  turnoverRate: 0.8
                },
                { 
                  id: '12', 
                  sku: 'KM-303', 
                  name: 'Ergonomic Keyboard', 
                  category: 'Electronics',
                  received: 75,
                  sold: 65,
                  adjusted: 0,
                  net: 10,
                  turnoverRate: 0.87
                }
              ]
            };
            
            setChartData({
              labels: ['Jan', 'Feb', 'Mar'],
              datasets: [
                {
                  name: 'Received',
                  values: [420, 380, 450]
                },
                {
                  name: 'Sold',
                  values: [300, 350, 400]
                }
              ]
            });
            break;
            
          case '4': // Supplier Performance Report
            mockReport = {
              id: reportId,
              title: 'Supplier Performance Report',
              type: 'supplier',
              description: 'Evaluation of supplier reliability and delivery performance',
              generated: '2025-03-25',
              period: 'January - March 2025',
              summary: {
                totalSuppliers: 15,
                totalOrders: 48,
                ordersOnTime: 40,
                ordersLate: 8,
                averageLeadTime: 12.5
              },
              supplierPerformance: [
                { 
                  id: '1',
                  name: 'Global Textiles Inc.',
                  orders: 12,
                  onTime: 11,
                  late: 1,
                  avgLeadTime: 9.2,
                  quality: 95,
                  rating: 'Excellent'
                },
                { 
                  id: '2',
                  name: 'Tech Components Ltd',
                  orders: 8,
                  onTime: 5,
                  late: 3,
                  avgLeadTime: 15.7,
                  quality: 88,
                  rating: 'Good'
                },
                { 
                  id: '3',
                  name: 'Packaging Solutions',
                  orders: 10,
                  onTime: 9,
                  late: 1,
                  avgLeadTime: 10.5,
                  quality: 92,
                  rating: 'Excellent'
                },
                { 
                  id: '5',
                  name: 'Office Depot Inc.',
                  orders: 6,
                  onTime: 6,
                  late: 0,
                  avgLeadTime: 8.3,
                  quality: 97,
                  rating: 'Excellent'
                },
                { 
                  id: '6',
                  name: 'Electronics Wholesale',
                  orders: 5,
                  onTime: 3,
                  late: 2,
                  avgLeadTime: 18.2,
                  quality: 85,
                  rating: 'Average'
                }
              ]
            };
            
            setChartData({
              labels: mockReport.supplierPerformance.map(s => s.name),
              datasets: [
                {
                  name: 'On-Time Delivery %',
                  values: mockReport.supplierPerformance.map(s => (s.onTime / s.orders) * 100)
                }
              ]
            });
            break;
            
          default:
            mockReport = {
              id: reportId,
              title: 'Unknown Report',
              type: 'unknown',
              description: 'Report details not available',
              generated: new Date().toISOString().split('T')[0],
              period: 'Unknown'
            };
        }
        
        setReport(mockReport);
      } catch (error) {
        console.error('Error fetching report data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchReportData();
  }, [reportId]);

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Get report icon based on type
  const getReportIcon = (type) => {
    switch (type) {
      case 'valuation':
        return DollarSign;
      case 'stock-level':
        return AlertTriangle;
      case 'movement':
        return TrendingUp;
      case 'supplier':
        return FileText;
      default:
        return BarChart2;
    }
  };
  
  // Handle print report
  const handlePrintReport = () => {
    window.print();
  };
  
  // Handle refresh report
  const handleRefreshReport = async () => {
    setLoading(true);
    
    try {
      // Simulate API call to refresh report
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Update report with current date
      setReport(prev => ({
        ...prev,
        generated: new Date().toISOString().split('T')[0]
      }));
    } catch (error) {
      console.error('Error refreshing report:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!report) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Report not found</h3>
          <p className="text-gray-500 mb-6">The report you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/inventory/reports')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Reports
          </button>
        </div>
      </div>
    );
  }

  const ReportIcon = getReportIcon(report.type);

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      {/* Header Actions */}
      <div className="px-6 pt-6 pb-4 flex justify-between items-start">
        <Link
          to="/inventory/reports"
          className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
        >
          <ChevronLeft size={16} className="mr-1" />
          Back to Reports
        </Link>
        
        <div className="flex space-x-3">
          <button
            onClick={handleRefreshReport}
            className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 flex items-center"
            disabled={loading}
          >
            <RefreshCw size={16} className={`mr-2 ${loading ? 'animate-spin' : ''}`} />
            Refresh
          </button>
          <button
            onClick={handlePrintReport}
            className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 flex items-center"
          >
            <Printer size={16} className="mr-2" />
            Print
          </button>
          <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
            <Download size={20} />
          </button>
        </div>
      </div>
      
      {/* Report Header */}
      <div className="px-6 pb-6 border-b border-gray-200">
        <div className="flex flex-col sm:flex-row sm:items-center gap-6">
          <div className="w-16 h-16 rounded-lg bg-blue-100 flex items-center justify-center flex-shrink-0 text-blue-700">
            <ReportIcon size={32} />
          </div>
          
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{report.title}</h1>
            <p className="text-gray-600 mt-1">{report.description}</p>
            <div className="flex flex-wrap gap-4 mt-2 text-sm text-gray-500">
              <div className="flex items-center">
                <Calendar size={16} className="mr-1 text-gray-400" />
                <span>Generated: {formatDate(report.generated)}</span>
              </div>
              <div className="flex items-center">
                <Calendar size={16} className="mr-1 text-gray-400" />
                <span>Period: {report.period}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Report Content */}
      <div className="p-6">
        {/* Report Type-specific Content */}
        {report.type === 'valuation' && (
          <>
            {/* Summary Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-8">
              <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
                <h4 className="text-sm font-medium text-blue-700 mb-1">Total Items</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.totalItems}</p>
              </div>
              <div className="bg-green-50 rounded-lg p-4 border border-green-100">
                <h4 className="text-sm font-medium text-green-700 mb-1">Total Value</h4>
                <p className="text-2xl font-bold text-gray-900">{formatAmount(report.summary.totalValue)}</p>
              </div>
              <div className="bg-purple-50 rounded-lg p-4 border border-purple-100">
                <h4 className="text-sm font-medium text-purple-700 mb-1">Average Value</h4>
                <p className="text-2xl font-bold text-gray-900">{formatAmount(report.summary.averageValue)}</p>
              </div>
              <div className="bg-yellow-50 rounded-lg p-4 border border-yellow-100">
                <h4 className="text-sm font-medium text-yellow-700 mb-1">Low Stock Items</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.lowStockItems}</p>
              </div>
              <div className="bg-red-50 rounded-lg p-4 border border-red-100">
                <h4 className="text-sm font-medium text-red-700 mb-1">Out of Stock</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.outOfStockItems}</p>
              </div>
            </div>
            
            {/* Chart Placeholder */}
            <div className="mb-8">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Value by Category</h3>
              <div className="bg-gray-50 border border-gray-200 rounded-lg h-64 flex items-center justify-center">
                <div className="text-center">
                  <PieChart size={48} className="mx-auto text-gray-400 mb-3" />
                  <p className="text-gray-500">Chart visualization would appear here</p>
                </div>
              </div>
            </div>
            
            {/* Categories Table */}
            <div className="mb-8">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Category Breakdown</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 border rounded-lg">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Item Count
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total Value
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        % of Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {report.categories.map((category, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {category.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                          {category.count}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                          {formatAmount(category.value)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                          {((category.value / report.summary.totalValue) * 100).toFixed(1)}%
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            
            {/* Items Table */}
            <div>
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">Items</h3>
                <div className="flex gap-2">
                  <select 
                    className="px-3 py-1.5 rounded-lg border border-gray-300 text-sm"
                    value={filterCategory}
                    onChange={(e) => setFilterCategory(e.target.value)}
                  >
                    <option value="all">All Categories</option>
                    {report.categories.map((cat, index) => (
                      <option key={index} value={cat.name}>{cat.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 border rounded-lg">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Item
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Quantity
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Cost Price
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total Value
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Last Updated
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {report.items
                      .filter(item => filterCategory === 'all' || item.category === filterCategory)
                      .map((item) => (
                        <tr key={item.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900">{item.name}</div>
                            <div className="text-xs text-gray-500">SKU: {item.sku}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {item.category}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                            {item.quantity === Infinity ? '∞' : item.quantity}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                            {formatAmount(item.costPrice)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                            {item.totalValue === 'N/A' ? 'N/A' : formatAmount(item.totalValue)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                            {formatDate(item.lastUpdated)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        
        {report.type === 'stock-level' && (
          <>
            {/* Summary Cards */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
              <div className="bg-yellow-50 rounded-lg p-4 border border-yellow-100">
                <h4 className="text-sm font-medium text-yellow-700 mb-1">Low Stock Items</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.totalLowStockItems}</p>
              </div>
              <div className="bg-red-50 rounded-lg p-4 border border-red-100">
                <h4 className="text-sm font-medium text-red-700 mb-1">Out of Stock Items</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.totalOutOfStockItems}</p>
              </div>
              <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
                <h4 className="text-sm font-medium text-blue-700 mb-1">Items Needing Reorder</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.totalItemsNeedingReorder}</p>
              </div>
              <div className="bg-purple-50 rounded-lg p-4 border border-purple-100">
                <h4 className="text-sm font-medium text-purple-700 mb-1">Estimated Reorder Value</h4>
                <p className="text-2xl font-bold text-gray-900">{formatAmount(report.summary.estimatedReorderValue)}</p>
              </div>
            </div>
            
            {/* Items Table */}
            <div>
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">Items Needing Attention</h3>
                <div className="flex gap-2">
                  <select 
                    className="px-3 py-1.5 rounded-lg border border-gray-300 text-sm"
                    value={filterPeriod}
                    onChange={(e) => setFilterPeriod(e.target.value)}
                  >
                    <option value="all">All Items</option>
                    <option value="low">Low Stock</option>
                    <option value="out">Out of Stock</option>
                  </select>
                </div>
              </div>
              
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 border rounded-lg">
                <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Item
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Current Stock
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Min. Level
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Reorder Point
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Reorder Qty
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Est. Cost
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {report.items
                      .filter(item => filterPeriod === 'all' || item.status === filterPeriod)
                      .map((item) => (
                        <tr key={item.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900">{item.name}</div>
                            <div className="text-xs text-gray-500">SKU: {item.sku}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {item.category}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                            <span className={`font-medium ${
                              item.status === 'out' ? 'text-red-600' : 
                              item.status === 'low' ? 'text-yellow-600' : 'text-gray-900'
                            }`}>
                              {item.currentStock}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                            {item.minStockLevel}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                            {item.reorderPoint}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                            {item.reorderQuantity}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                            {formatAmount(item.estimatedCost)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-center">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              item.status === 'out' ? 'bg-red-100 text-red-800' : 'bg-yellow-100 text-yellow-800'
                            }`}>
                              {item.status === 'out' ? 'Out of Stock' : 'Low Stock'}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              
              <div className="mt-6 flex justify-end">
                <Link
                  to="/inventory/buying/new"
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <Package size={16} className="mr-2" />
                  Create Purchase Orders
                </Link>
              </div>
            </div>
          </>
        )}
        
        {report.type === 'movement' && (
          <>
            {/* Summary Cards */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
              <div className="bg-green-50 rounded-lg p-4 border border-green-100">
                <h4 className="text-sm font-medium text-green-700 mb-1">Items Received</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.totalItemsReceived}</p>
              </div>
              <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
                <h4 className="text-sm font-medium text-blue-700 mb-1">Items Sold</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.totalItemsSold}</p>
              </div>
              <div className="bg-purple-50 rounded-lg p-4 border border-purple-100">
                <h4 className="text-sm font-medium text-purple-700 mb-1">Adjustments</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.totalAdjustments}</p>
              </div>
              <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
                <h4 className="text-sm font-medium text-blue-700 mb-1">Net Change</h4>
                <div className="flex items-center">
                  <p className="text-2xl font-bold text-gray-900">{report.summary.netChange}</p>
                  {report.summary.netChange > 0 ? (
                    <ArrowUp size={18} className="ml-2 text-green-600" />
                  ) : report.summary.netChange < 0 ? (
                    <ArrowDown size={18} className="ml-2 text-red-600" />
                  ) : null}
                </div>
              </div>
            </div>
            
            {/* Chart Placeholder */}
            <div className="mb-8">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Stock Movement Trend</h3>
              <div className="bg-gray-50 border border-gray-200 rounded-lg h-64 flex items-center justify-center">
                <div className="text-center">
                  <TrendingUp size={48} className="mx-auto text-gray-400 mb-3" />
                  <p className="text-gray-500">Chart visualization would appear here</p>
                </div>
              </div>
            </div>
            
            {/* Categories Table */}
            <div className="mb-8">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Movement by Category</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 border rounded-lg">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Received
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Sold
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Adjusted
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Net Change
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {report.categories.map((category, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {category.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center">
                          <span className="text-green-600">{category.received}</span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center">
                          <span className="text-blue-600">{category.sold}</span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center">
                          <span className="text-purple-600">{category.adjusted}</span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-center">
                          <div className="flex items-center justify-center">
                            <span className={`font-medium ${category.net > 0 ? 'text-green-600' : category.net < 0 ? 'text-red-600' : 'text-gray-500'}`}>
                              {category.net > 0 ? '+' : ''}{category.net}
                            </span>
                            {category.net > 0 ? (
                              <ArrowUp size={14} className="ml-1 text-green-600" />
                            ) : category.net < 0 ? (
                              <ArrowDown size={14} className="ml-1 text-red-600" />
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            
            {/* Top Movers Table */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Top Moving Products</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 border rounded-lg">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Product
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Received
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Sold
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Adjusted
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Net Change
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Turnover Rate
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {report.topMovers.map((item) => (
                      <tr key={item.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">{item.name}</div>
                          <div className="text-xs text-gray-500">SKU: {item.sku}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.category}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-green-600">
                          {item.received}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-blue-600">
                          {item.sold}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-purple-600">
                          {item.adjusted}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-center">
                          <div className="flex items-center justify-center">
                            <span className={`font-medium ${item.net > 0 ? 'text-green-600' : item.net < 0 ? 'text-red-600' : 'text-gray-500'}`}>
                              {item.net > 0 ? '+' : ''}{item.net}
                            </span>
                            {item.net > 0 ? (
                              <ArrowUp size={14} className="ml-1 text-green-600" />
                            ) : item.net < 0 ? (
                              <ArrowDown size={14} className="ml-1 text-red-600" />
                            ) : null}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                          {item.turnoverRate.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        
        {report.type === 'supplier' && (
          <>
            {/* Summary Cards */}
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-8">
              <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
                <h4 className="text-sm font-medium text-blue-700 mb-1">Total Suppliers</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.totalSuppliers}</p>
              </div>
              <div className="bg-purple-50 rounded-lg p-4 border border-purple-100">
                <h4 className="text-sm font-medium text-purple-700 mb-1">Total Orders</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.totalOrders}</p>
              </div>
              <div className="bg-green-50 rounded-lg p-4 border border-green-100">
                <h4 className="text-sm font-medium text-green-700 mb-1">On-Time Orders</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.ordersOnTime}</p>
              </div>
              <div className="bg-red-50 rounded-lg p-4 border border-red-100">
                <h4 className="text-sm font-medium text-red-700 mb-1">Late Orders</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.ordersLate}</p>
              </div>
              <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
                <h4 className="text-sm font-medium text-blue-700 mb-1">Avg. Lead Time</h4>
                <p className="text-2xl font-bold text-gray-900">{report.summary.averageLeadTime} days</p>
              </div>
            </div>
            
            {/* Chart Placeholder */}
            <div className="mb-8">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Supplier Performance Chart</h3>
              <div className="bg-gray-50 border border-gray-200 rounded-lg h-64 flex items-center justify-center">
                <div className="text-center">
                  <BarChart2 size={48} className="mx-auto text-gray-400 mb-3" />
                  <p className="text-gray-500">Chart visualization would appear here</p>
                </div>
              </div>
            </div>
            
            {/* Supplier Performance Table */}
            <div>
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">Supplier Performance</h3>
                <div className="flex gap-2">
                  <select 
                    className="px-3 py-1.5 rounded-lg border border-gray-300 text-sm"
                    value={filterCategory}
                    onChange={(e) => setFilterCategory(e.target.value)}
                  >
                    <option value="all">All Ratings</option>
                    <option value="Excellent">Excellent</option>
                    <option value="Good">Good</option>
                    <option value="Average">Average</option>
                    <option value="Poor">Poor</option>
                  </select>
                </div>
              </div>
              
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 border rounded-lg">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Supplier
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total Orders
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        On-Time
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Late
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        On-Time %
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Avg. Lead Time
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Quality Score
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Rating
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {report.supplierPerformance
                      .filter(supplier => filterCategory === 'all' || supplier.rating === filterCategory)
                      .map((supplier) => (
                        <tr key={supplier.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap">
                            <Link 
                              to={`/inventory/suppliers/${supplier.id}`}
                              className="text-sm font-medium text-[#190192] hover:underline"
                            >
                              {supplier.name}
                            </Link>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                            {supplier.orders}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-green-600 text-center">
                            {supplier.onTime}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-red-600 text-center">
                            {supplier.late}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-center">
                            <div className="flex items-center justify-center">
                              <div className="w-16 bg-gray-200 rounded-full h-2.5">
                                <div 
                                  className={`h-2.5 rounded-full ${
                                    (supplier.onTime / supplier.orders) >= 0.9 ? 'bg-green-600' :
                                    (supplier.onTime / supplier.orders) >= 0.8 ? 'bg-yellow-400' :
                                    'bg-red-600'
                                  }`}
                                  style={{ width: `${(supplier.onTime / supplier.orders) * 100}%` }}
                                ></div>
                              </div>
                              <span className="ml-2 text-sm text-gray-600">
                                {((supplier.onTime / supplier.orders) * 100).toFixed(0)}%
                              </span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                            {supplier.avgLeadTime} days
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-center">
                            <div className="flex items-center justify-center">
                              <div className="w-16 bg-gray-200 rounded-full h-2.5">
                                <div 
                                  className={`h-2.5 rounded-full ${
                                    supplier.quality >= 90 ? 'bg-green-600' :
                                    supplier.quality >= 80 ? 'bg-yellow-400' :
                                    'bg-red-600'
                                  }`}
                                  style={{ width: `${supplier.quality}%` }}
                                ></div>
                              </div>
                              <span className="ml-2 text-sm text-gray-600">
                                {supplier.quality}%
                              </span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-center">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              supplier.rating === 'Excellent' ? 'bg-green-100 text-green-800' :
                              supplier.rating === 'Good' ? 'bg-blue-100 text-blue-800' :
                              supplier.rating === 'Average' ? 'bg-yellow-100 text-yellow-800' :
                              'bg-red-100 text-red-800'
                            }`}>
                              {supplier.rating}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        
        {/* Default Content for Unknown Report Types */}
        {report.type === 'unknown' && (
          <div className="text-center py-12">
            <X size={48} className="mx-auto text-red-500 mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">Report Format Not Supported</h3>
            <p className="text-gray-500 mb-6">The report type "{report.type}" is not supported by the current viewer.</p>
            <button
              onClick={() => navigate('/inventory/reports')}
              className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
            >
              Back to Reports
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InventoryReportDetail;