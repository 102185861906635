// src/components/finance/reports/ProfitLossReport.jsx
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { 
  ArrowUp, ArrowDown, ChevronRight, 
  ChevronDown, TrendingUp, TrendingDown 
} from 'lucide-react';
import ReportFilters from './ReportFilters';
import { useCurrency } from '../../../utils/currencyUtils';

const ProfitLossReport = () => {
  const [searchParams] = useSearchParams();
  const { formatAmount } = useCurrency();
  const [filters, setFilters] = useState({
    dateRange: searchParams.get('period') || 'month',
    startDate: searchParams.get('startDate') || '',
    endDate: searchParams.get('endDate') || '',
    compareWith: searchParams.get('compare') || 'none'
  });
  
  const [expandedCategories, setExpandedCategories] = useState({
    revenue: true,
    expenses: true
  });
  
  const [reportData, setReportData] = useState({
    period: 'March 2025',
    comparisonPeriod: 'February 2025',
    overview: {
      revenue: 35250,
      previousRevenue: 32500,
      expenses: 24850,
      previousExpenses: 23500,
      profit: 10400,
      previousProfit: 9000,
      revenueChange: 8.5,
      expensesChange: 5.7,
      profitChange: 15.6
    },
    revenue: [
      {
        category: 'Sales',
        amount: 28500,
        previousAmount: 26000,
        change: 9.6,
        items: [
          { name: 'Product Sales', amount: 21500, previousAmount: 19500, change: 10.3 },
          { name: 'Service Sales', amount: 7000, previousAmount: 6500, change: 7.7 }
        ]
      },
      {
        category: 'Other Income',
        amount: 6750,
        previousAmount: 6500,
        change: 3.8,
        items: [
          { name: 'Interest Income', amount: 250, previousAmount: 200, change: 25.0 },
          { name: 'Rental Income', amount: 6500, previousAmount: 6300, change: 3.2 }
        ]
      }
    ],
    expenses: [
      {
        category: 'Cost of Goods Sold',
        amount: 12500,
        previousAmount: 11800,
        change: 5.9,
        items: [
          { name: 'Inventory Purchases', amount: 9500, previousAmount: 9000, change: 5.6 },
          { name: 'Shipping Costs', amount: 3000, previousAmount: 2800, change: 7.1 }
        ]
      },
      {
        category: 'Operating Expenses',
        amount: 9350,
        previousAmount: 8700,
        change: 7.5,
        items: [
          { name: 'Rent', amount: 3500, previousAmount: 3500, change: 0 },
          { name: 'Utilities', amount: 850, previousAmount: 800, change: 6.3 },
          { name: 'Salaries', amount: 5000, previousAmount: 4400, change: 13.6 }
        ]
      },
      {
        category: 'Administrative Expenses',
        amount: 3000,
        previousAmount: 3000,
        change: 0,
        items: [
          { name: 'Office Supplies', amount: 500, previousAmount: 600, change: -16.7 },
          { name: 'Insurance', amount: 1200, previousAmount: 1200, change: 0 },
          { name: 'Software Subscriptions', amount: 1300, previousAmount: 1200, change: 8.3 }
        ]
      }
    ]
  });
  
  // Toggle category expansion
  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };
  
  // Handle filter changes
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    
    // In a real app, you would fetch new data based on filters
    // For now, we'll just update the period labels
    if (newFilters.dateRange === 'month') {
      setReportData(prev => ({
        ...prev,
        period: 'March 2025',
        comparisonPeriod: 'February 2025'
      }));
    } else if (newFilters.dateRange === 'quarter') {
      setReportData(prev => ({
        ...prev,
       period: 'Q1 2025',
       comparisonPeriod: 'Q4 2024'
     }));
   } else if (newFilters.dateRange === 'year') {
     setReportData(prev => ({
       ...prev,
       period: '2025',
       comparisonPeriod: '2024'
     }));
   }
 };
 
 // Calculate totals
 const totalRevenue = reportData.revenue.reduce((sum, category) => sum + category.amount, 0);
 const totalExpenses = reportData.expenses.reduce((sum, category) => sum + category.amount, 0);
 const netProfit = totalRevenue - totalExpenses;
 
 return (
   <div>
     <h2 className="text-2xl font-bold text-gray-900 mb-4">Profit & Loss Statement</h2>
     
     <ReportFilters 
       onFilterChange={handleFilterChange}
       availableFilters={['dateRange', 'compare', 'includeSubitems']}
       defaultDateRange={filters.dateRange}
     />
     
     {/* Overall Summary */}
     <div className="bg-white rounded-xl shadow-md p-6 mb-6">
       <div className="flex justify-between items-center mb-6">
         <h3 className="text-lg font-medium text-gray-900">Financial Summary</h3>
         <div className="text-sm font-medium text-gray-500">
           {reportData.period} {filters.compareWith !== 'none' && `vs ${reportData.comparisonPeriod}`}
         </div>
       </div>
       
       <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
         {/* Revenue Summary */}
         <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
           <div className="flex justify-between items-start mb-2">
             <h4 className="text-sm font-medium text-blue-900">Revenue</h4>
             {filters.compareWith !== 'none' && (
               <span className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full ${
                 reportData.overview.revenueChange >= 0 
                   ? 'bg-green-100 text-green-800' 
                   : 'bg-red-100 text-red-800'
               }`}>
                 {reportData.overview.revenueChange >= 0 ? (
                   <>
                     <ArrowUp size={12} className="mr-1" />
                     +{reportData.overview.revenueChange}%
                   </>
                 ) : (
                   <>
                     <ArrowDown size={12} className="mr-1" />
                     {reportData.overview.revenueChange}%
                   </>
                 )}
               </span>
             )}
           </div>
           <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.overview.revenue)}</p>
           {filters.compareWith !== 'none' && (
             <p className="text-sm text-gray-500 mt-1">vs {formatAmount(reportData.overview.previousRevenue)}</p>
           )}
         </div>
         
         {/* Expenses Summary */}
         <div className="bg-red-50 rounded-lg p-4 border border-red-100">
           <div className="flex justify-between items-start mb-2">
             <h4 className="text-sm font-medium text-red-900">Expenses</h4>
             {filters.compareWith !== 'none' && (
               <span className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full ${
                 reportData.overview.expensesChange >= 0 
                   ? 'bg-red-100 text-red-800' 
                   : 'bg-green-100 text-green-800'
               }`}>
                 {reportData.overview.expensesChange >= 0 ? (
                   <>
                     <ArrowUp size={12} className="mr-1" />
                     +{reportData.overview.expensesChange}%
                   </>
                 ) : (
                   <>
                     <ArrowDown size={12} className="mr-1" />
                     {reportData.overview.expensesChange}%
                   </>
                 )}
               </span>
             )}
           </div>
           <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.overview.expenses)}</p>
           {filters.compareWith !== 'none' && (
             <p className="text-sm text-gray-500 mt-1">vs {formatAmount(reportData.overview.previousExpenses)}</p>
           )}
         </div>
         
         {/* Net Profit Summary */}
         <div className="bg-green-50 rounded-lg p-4 border border-green-100">
           <div className="flex justify-between items-start mb-2">
             <h4 className="text-sm font-medium text-green-900">Net Profit</h4>
             {filters.compareWith !== 'none' && (
               <span className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full ${
                 reportData.overview.profitChange >= 0 
                   ? 'bg-green-100 text-green-800' 
                   : 'bg-red-100 text-red-800'
               }`}>
                 {reportData.overview.profitChange >= 0 ? (
                   <>
                     <ArrowUp size={12} className="mr-1" />
                     +{reportData.overview.profitChange}%
                   </>
                 ) : (
                   <>
                     <ArrowDown size={12} className="mr-1" />
                     {reportData.overview.profitChange}%
                   </>
                 )}
               </span>
             )}
           </div>
           <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.overview.profit)}</p>
           {filters.compareWith !== 'none' && (
             <p className="text-sm text-gray-500 mt-1">vs {formatAmount(reportData.overview.previousProfit)}</p>
           )}
         </div>
       </div>
     </div>
     
     {/* Detailed Report */}
     <div className="bg-white rounded-xl shadow-md overflow-hidden">
       <div className="flex items-center justify-between p-6 border-b border-gray-200">
         <h3 className="text-lg font-medium text-gray-900">Detailed Report</h3>
         <p className="text-sm font-medium text-gray-500">
           {reportData.period} {filters.compareWith !== 'none' && `vs ${reportData.comparisonPeriod}`}
         </p>
       </div>
       
       <div className="p-6">
         {/* Revenue Section */}
         <div className="mb-8">
           <button
             onClick={() => toggleCategory('revenue')}
             className="flex items-center justify-between w-full text-left mb-3"
           >
             <div className="flex items-center">
               {expandedCategories.revenue ? (
                 <ChevronDown size={18} className="text-gray-500 mr-2" />
               ) : (
                 <ChevronRight size={18} className="text-gray-500 mr-2" />
               )}
               <span className="text-lg font-medium text-blue-900">Revenue</span>
             </div>
             <div className="text-xl font-bold text-gray-900">{formatAmount(totalRevenue)}</div>
           </button>
           
           {expandedCategories.revenue && (
             <div className="pl-6">
               <table className="min-w-full divide-y divide-gray-200">
                 <thead>
                   <tr>
                     <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Category
                     </th>
                     {filters.compareWith !== 'none' && (
                       <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                         Previous
                       </th>
                     )}
                     <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Amount
                     </th>
                     {filters.compareWith !== 'none' && (
                       <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                         Change
                       </th>
                     )}
                   </tr>
                 </thead>
                 <tbody className="bg-white divide-y divide-gray-200">
                   {reportData.revenue.map((category, index) => (
                     <React.Fragment key={index}>
                       <tr className="hover:bg-gray-50">
                         <td className="px-3 py-4 text-sm font-medium text-gray-900">{category.category}</td>
                         {filters.compareWith !== 'none' && (
                           <td className="px-3 py-4 text-sm text-gray-500 text-right">{formatAmount(category.previousAmount)}</td>
                         )}
                         <td className="px-3 py-4 text-sm text-gray-900 font-medium text-right">{formatAmount(category.amount)}</td>
                         {filters.compareWith !== 'none' && (
                           <td className="px-3 py-4 text-right">
                             <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                               category.change >= 0 
                                 ? 'bg-green-100 text-green-800' 
                                 : 'bg-red-100 text-red-800'
                             }`}>
                               {category.change >= 0 ? (
                                 <>
                                   <TrendingUp size={12} className="mr-1" />
                                   +{category.change}%
                                 </>
                               ) : (
                                 <>
                                   <TrendingDown size={12} className="mr-1" />
                                   {category.change}%
                                 </>
                               )}
                             </span>
                           </td>
                         )}
                       </tr>
                       {filters.includeSubitems && category.items.map((item, itemIndex) => (
                         <tr key={`${index}-${itemIndex}`} className="bg-gray-50">
                           <td className="px-3 py-2 text-xs text-gray-500 pl-8">{item.name}</td>
                           {filters.compareWith !== 'none' && (
                             <td className="px-3 py-2 text-xs text-gray-500 text-right">{formatAmount(item.previousAmount)}</td>
                           )}
                           <td className="px-3 py-2 text-xs text-gray-700 text-right">{formatAmount(item.amount)}</td>
                           {filters.compareWith !== 'none' && (
                             <td className="px-3 py-2 text-right">
                               <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                                 item.change >= 0 
                                   ? 'bg-green-100 text-green-800' 
                                   : 'bg-red-100 text-red-800'
                               }`}>
                                 {item.change >= 0 ? `+${item.change}%` : `${item.change}%`}
                               </span>
                             </td>
                           )}
                         </tr>
                       ))}
                     </React.Fragment>
                   ))}
                   <tr className="bg-blue-50">
                     <td className="px-3 py-4 text-sm font-bold text-gray-900">Total Revenue</td>
                     {filters.compareWith !== 'none' && (
                       <td className="px-3 py-4 text-sm text-gray-900 font-bold text-right">
                         {formatAmount(reportData.revenue.reduce((sum, category) => sum + category.previousAmount, 0))}
                       </td>
                     )}
                     <td className="px-3 py-4 text-sm text-gray-900 font-bold text-right">{formatAmount(totalRevenue)}</td>
                     {filters.compareWith !== 'none' && (
                       <td className="px-3 py-4 text-right">
                         <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                           reportData.overview.revenueChange >= 0 
                             ? 'bg-green-100 text-green-800' 
                             : 'bg-red-100 text-red-800'
                         }`}>
                           {reportData.overview.revenueChange >= 0 ? (
                             <>
                               <TrendingUp size={12} className="mr-1" />
                               +{reportData.overview.revenueChange}%
                             </>
                           ) : (
                             <>
                               <TrendingDown size={12} className="mr-1" />
                               {reportData.overview.revenueChange}%
                             </>
                           )}
                         </span>
                       </td>
                     )}
                   </tr>
                 </tbody>
               </table>
             </div>
           )}
         </div>
         
         {/* Expenses Section */}
         <div className="mb-8">
           <button
             onClick={() => toggleCategory('expenses')}
             className="flex items-center justify-between w-full text-left mb-3"
           >
             <div className="flex items-center">
               {expandedCategories.expenses ? (
                 <ChevronDown size={18} className="text-gray-500 mr-2" />
               ) : (
                 <ChevronRight size={18} className="text-gray-500 mr-2" />
               )}
               <span className="text-lg font-medium text-red-900">Expenses</span>
             </div>
             <div className="text-xl font-bold text-gray-900">{formatAmount(totalExpenses)}</div>
           </button>
           
           {expandedCategories.expenses && (
             <div className="pl-6">
               <table className="min-w-full divide-y divide-gray-200">
                 <thead>
                   <tr>
                     <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Category
                     </th>
                     {filters.compareWith !== 'none' && (
                       <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                         Previous
                       </th>
                     )}
                     <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Amount
                     </th>
                     {filters.compareWith !== 'none' && (
                       <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                         Change
                       </th>
                     )}
                   </tr>
                 </thead>
                 <tbody className="bg-white divide-y divide-gray-200">
                   {reportData.expenses.map((category, index) => (
                     <React.Fragment key={index}>
                       <tr className="hover:bg-gray-50">
                         <td className="px-3 py-4 text-sm font-medium text-gray-900">{category.category}</td>
                         {filters.compareWith !== 'none' && (
                           <td className="px-3 py-4 text-sm text-gray-500 text-right">{formatAmount(category.previousAmount)}</td>
                         )}
                         <td className="px-3 py-4 text-sm text-gray-900 font-medium text-right">{formatAmount(category.amount)}</td>
                         {filters.compareWith !== 'none' && (
                           <td className="px-3 py-4 text-right">
                             <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                               category.change >= 0 
                                 ? 'bg-red-100 text-red-800' 
                                 : 'bg-green-100 text-green-800'
                             }`}>
                               {category.change >= 0 ? (
                                 <>
                                   <TrendingUp size={12} className="mr-1" />
                                   +{category.change}%
                                 </>
                               ) : (
                                 <>
                                   <TrendingDown size={12} className="mr-1" />
                                   {category.change}%
                                 </>
                               )}
                             </span>
                           </td>
                         )}
                       </tr>
                       {filters.includeSubitems && category.items.map((item, itemIndex) => (
                         <tr key={`${index}-${itemIndex}`} className="bg-gray-50">
                           <td className="px-3 py-2 text-xs text-gray-500 pl-8">{item.name}</td>
                           {filters.compareWith !== 'none' && (
                             <td className="px-3 py-2 text-xs text-gray-500 text-right">{formatAmount(item.previousAmount)}</td>
                           )}
                           <td className="px-3 py-2 text-xs text-gray-700 text-right">{formatAmount(item.amount)}</td>
                           {filters.compareWith !== 'none' && (
                             <td className="px-3 py-2 text-right">
                               <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                                 item.change >= 0 
                                   ? 'bg-red-100 text-red-800' 
                                   : 'bg-green-100 text-green-800'
                               }`}>
                                 {item.change >= 0 ? `+${item.change}%` : `${item.change}%`}
                               </span>
                             </td>
                           )}
                         </tr>
                       ))}
                     </React.Fragment>
                   ))}
                   <tr className="bg-red-50">
                     <td className="px-3 py-4 text-sm font-bold text-gray-900">Total Expenses</td>
                     {filters.compareWith !== 'none' && (
                       <td className="px-3 py-4 text-sm text-gray-900 font-bold text-right">
                         {formatAmount(reportData.expenses.reduce((sum, category) => sum + category.previousAmount, 0))}
                       </td>
                     )}
                     <td className="px-3 py-4 text-sm text-gray-900 font-bold text-right">{formatAmount(totalExpenses)}</td>
                     {filters.compareWith !== 'none' && (
                       <td className="px-3 py-4 text-right">
                         <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                           reportData.overview.expensesChange >= 0 
                             ? 'bg-red-100 text-red-800' 
                             : 'bg-green-100 text-green-800'
                         }`}>
                           {reportData.overview.expensesChange >= 0 ? (
                             <>
                               <TrendingUp size={12} className="mr-1" />
                               +{reportData.overview.expensesChange}%
                             </>
                           ) : (
                             <>
                               <TrendingDown size={12} className="mr-1" />
                               {reportData.overview.expensesChange}%
                             </>
                           )}
                         </span>
                       </td>
                     )}
                   </tr>
                 </tbody>
               </table>
             </div>
           )}
         </div>
         
         {/* Net Profit Section */}
         <div className="mt-6 border-t border-gray-200 pt-6">
           <div className="flex items-center justify-between w-full text-left">
             <span className="text-lg font-bold text-gray-900">Net Profit</span>
             <div className="text-xl font-bold text-green-700">{formatAmount(netProfit)}</div>
           </div>
           {filters.compareWith !== 'none' && (
             <div className="flex justify-end mt-2">
               <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium ${
                 reportData.overview.profitChange >= 0 
                   ? 'bg-green-100 text-green-800' 
                   : 'bg-red-100 text-red-800'
               }`}>
                 {reportData.overview.profitChange >= 0 ? (
                   <>
                     <TrendingUp size={14} className="mr-1" />
                     +{reportData.overview.profitChange}%
                   </>
                 ) : (
                   <>
                     <TrendingDown size={14} className="mr-1" />
                     {reportData.overview.profitChange}%
                   </>
                 )}
               </span>
             </div>
           )}
         </div>
       </div>
     </div>
   </div>
 );
};

export default ProfitLossReport;