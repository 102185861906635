// src/components/stock/StockItemDetail.js
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Edit, Download, Trash2, Package, 
  DollarSign, Tag, Truck, ArrowDownCircle, ArrowUpCircle,
  BarChart2, ShoppingCart, Calendar
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { useCurrency } from '../../../utils/currencyUtils';
import { db } from '../../../firebase/config';

const StockItemDetail = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const { formatAmount } = useCurrency(); // Use currency formatting from currencyUtils
  
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Fetch item details
  useEffect(() => {
    const fetchItemDetails = async () => {
      setLoading(true);
      try {
        // In a real app, this would be an API call
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Mock item data
        const mockItem = {
          id: itemId,
          sku: 'TS-001',
          name: 'T-Shirt Large',
          description: 'High-quality cotton t-shirt, large size. Available in black color.',
          category: 'Apparel',
          type: 'physical',
          costPrice: 8.50,
          sellingPrice: 19.99,
          taxRate: 0.0725,
          minStockLevel: 10,
          reorderPoint: 15,
          reorderQuantity: 50,
          locations: {
            'main-store': 15,
            'warehouse': 10
          },
          reservedQuantity: 2,
          imageUrl: null,
          transactions: [
            { 
              date: '2025-03-28', 
              type: 'Received', 
              quantity: 20, 
              location: 'Warehouse',
              reference: 'PO-1082',
              referenceUrl: '/purchasing/orders/1082'
            },
            { 
              date: '2025-03-25', 
              type: 'Sold', 
              quantity: -2, 
              location: 'Main Store',
              reference: 'SO-3421',
              referenceUrl: '/sales/orders/3421'
            },
            { 
              date: '2025-03-20', 
              type: 'Transfer', 
              quantity: 5, 
              fromLocation: 'Warehouse',
              toLocation: 'Main Store',
              reference: 'TR-095',
              referenceUrl: '/stock/transfers/095'
            }
          ],
          createdAt: '2025-01-10',
          updatedAt: '2025-03-28'
        };
        
        setItem(mockItem);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching item details:', error);
        setLoading(false);
      }
    };

    fetchItemDetails();
  }, [itemId]);

  // Handle delete
  const handleDelete = async () => {
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Navigate back to the items list
      navigate('/stock/items');
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  // Calculate total stock
  const getTotalStock = (item) => {
    if (!item) return 0;
    
    if (item.type === 'digital' || item.type === 'service') {
      return '∞';
    }
    
    let total = 0;
    Object.values(item.locations || {}).forEach(qty => {
      total += qty;
    });
    
    return total;
  };

  // Calculate available stock (total - reserved)
  const getAvailableStock = (item) => {
    if (!item) return 0;
    
    if (item.type === 'digital' || item.type === 'service') {
      return '∞';
    }
    
    const total = getTotalStock(item);
    return total - (item.reservedQuantity || 0);
  };

  // Check if item is low on stock
  const isLowStock = (item) => {
    if (!item) return false;
    
    if (item.type === 'digital' || item.type === 'service') {
      return false;
    }
    
    const totalStock = getTotalStock(item);
    return totalStock !== '∞' && totalStock <= item.minStockLevel;
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!item) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <Package size={48} className="mx-auto text-gray-400 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Item not found</h3>
          <p className="text-gray-500 mb-6">The item you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/inventory/items')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Inventory
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/stock/items"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Inventory
          </Link>
          
          <div className="flex space-x-3">
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
              <Download size={20} />
            </button>
            <Link
              to={`/stock/items/edit/${itemId}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Item Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className="w-16 h-16 rounded-lg bg-gray-100 flex items-center justify-center flex-shrink-0">
              {item.imageUrl ? (
                <img src={item.imageUrl} alt={item.name} className="w-full h-full object-cover rounded-lg" />
              ) : (
                <Package size={32} className="text-gray-400" />
              )}
            </div>
            
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">{item.name}</h1>
                <span className="text-gray-500">({item.sku})</span>
              </div>
              
              <div className="flex flex-wrap gap-2 mt-2">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                  <Tag size={12} className="mr-1" />
                  {item.category}
                </span>
                
                {isLowStock(item) && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
                    Low Stock
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="flex px-6 -mb-px">
            <button
              onClick={() => setActiveTab('details')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'details'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Details
            </button>
            <button
              onClick={() => setActiveTab('transactions')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'transactions'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Transactions
            </button>
          </nav>
        </div>
        
        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'details' ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left Column: Basic Info */}
              <div className="lg:col-span-2 space-y-8">
                {/* Description */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-2">Description</h3>
                  <p className="text-gray-700">{item.description || 'No description provided.'}</p>
                </div>
                
                {/* Pricing Information */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Pricing</h3>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="bg-gray-50 rounded-xl p-4">
                      <p className="text-sm text-gray-500 mb-1">Cost Price</p>
                      <div className="flex items-center text-gray-900 font-medium">
                        <DollarSign size={16} className="text-gray-400 mr-1" />
                        {formatAmount(item.costPrice)}
                      </div>
                    </div>
                    
                    <div className="bg-gray-50 rounded-xl p-4">
                      <p className="text-sm text-gray-500 mb-1">Selling Price</p>
                      <div className="flex items-center text-gray-900 font-medium">
                        <DollarSign size={16} className="text-gray-400 mr-1" />
                        {formatAmount(item.sellingPrice)}
                      </div>
                    </div>
                    
                    <div className="bg-gray-50 rounded-xl p-4">
                      <p className="text-sm text-gray-500 mb-1">Profit Margin</p>
                      <div className="flex items-center text-gray-900 font-medium">
                        {Math.round(((item.sellingPrice - item.costPrice) / item.sellingPrice) * 100)}%
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Inventory Levels by Location */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Inventory Levels</h3>
                  
                  {item.type === 'digital' || item.type === 'service' ? (
                    <p className="text-gray-700">This item is {item.type === 'digital' ? 'digital' : 'a service'} and doesn't have physical inventory.</p>
                  ) : (
                    <>
                      <div className="bg-gray-50 rounded-xl overflow-hidden">
                        <div className="grid grid-cols-3 border-b border-gray-200">
                          <div className="px-4 py-2 text-sm font-medium text-gray-500">Location</div>
                          <div className="px-4 py-2 text-sm font-medium text-gray-500 text-center">Quantity</div>
                          <div className="px-4 py-2 text-sm font-medium text-gray-500 text-right">Actions</div>
                        </div>
                        
                        {Object.entries(item.locations || {}).map(([location, quantity]) => (
                          <div key={location} className="grid grid-cols-3 border-b border-gray-100 last:border-b-0">
                            <div className="px-4 py-3 text-sm text-gray-900">
                              {location.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </div>
                            <div className="px-4 py-3 text-sm text-gray-900 text-center">{quantity}</div>
                            <div className="px-4 py-3 text-sm text-right flex justify-end space-x-2">
                              <button className="p-1 text-blue-600 hover:text-blue-800 hover:bg-blue-50 rounded">
                                <ArrowUpCircle size={16} />
                              </button>
                              <button className="p-1 text-blue-600 hover:text-blue-800 hover:bg-blue-50 rounded">
                                <ArrowDownCircle size={16} />
                              </button>
                            </div>
                          </div>
                        ))}
                        
                        <div className="grid grid-cols-3 bg-indigo-50">
                          <div className="px-4 py-3 text-sm font-medium text-gray-900">Total Available</div>
                          <div className="px-4 py-3 text-sm font-medium text-gray-900 text-center">
                            {getAvailableStock(item)}
                          </div>
                          <div className="px-4 py-3"></div>
                        </div>
                      </div>
                      
                      {item.reservedQuantity > 0 && (
                        <div className="mt-2 text-sm text-gray-500 flex items-center">
                          <ShoppingCart size={14} className="mr-1 text-gray-400" />
                          {item.reservedQuantity} units reserved for orders
                        </div>
                      )}
                    </>
                  )}
                </div>
                
                {/* Reordering Information */}
                {(item.type === 'physical' || item.type === 'raw_material') && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Reordering Information</h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div className="bg-gray-50 rounded-xl p-4">
                        <p className="text-sm text-gray-500 mb-1">Minimum Stock</p>
                        <p className="text-gray-900 font-medium">{item.minStockLevel}</p>
                      </div>
                      
                      <div className="bg-gray-50 rounded-xl p-4">
                        <p className="text-sm text-gray-500 mb-1">Reorder Point</p>
                        <p className="text-gray-900 font-medium">{item.reorderPoint}</p>
                      </div>
                      
                      <div className="bg-gray-50 rounded-xl p-4">
                        <p className="text-sm text-gray-500 mb-1">Reorder Quantity</p>
                        <p className="text-gray-900 font-medium">{item.reorderQuantity}</p>
                      </div>
                    </div>
                    
                    {isLowStock(item) && (
                      <div className="mt-4">
                        <Link
                          to={`/purchasing/orders/new?item=${item.id}`}
                          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                        >
                          <Truck size={16} className="mr-2" />
                          Create Purchase Order
                        </Link>
                      </div>
                    )}
                  </div>
                )}
              </div>
              
              {/* Right Column: Sidebar */}
              <div className="lg:col-span-1">
                {/* Stock Summary */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Stock Summary</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Total Stock</span>
                      <span className="text-sm font-medium text-gray-900">{getTotalStock(item)}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Reserved</span>
                      <span className="text-sm font-medium text-gray-900">{item.reservedQuantity || 0}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Available</span>
                      <span className="text-sm font-medium text-gray-900">{getAvailableStock(item)}</span>
                    </div>
                    
                    <div className="border-t border-gray-200 pt-3 mt-3">
                      <div className="flex justify-between">
                        <span className="text-sm text-gray-500">Status</span>
                        <span className={`text-sm font-medium ${isLowStock(item) ? 'text-amber-600' : 'text-green-600'}`}>
                          {isLowStock(item) ? 'Low Stock' : 'In Stock'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Actions */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Quick Actions</h3>
                  
                  <div className="space-y-2">
                    <Link
                      to={`/stock/adjustments/new?item=${item.id}`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Adjust Stock
                    </Link>
                    
                    <Link
                      to={`/stock/transfers/new?item=${item.id}`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Transfer Stock
                    </Link>
                    
                    <Link
                      to={`/sales/orders/new?item=${item.id}`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Create Sale
                    </Link>
                  </div>
                </div>
                
                {/* Meta Information */}
                <div className="bg-gray-50 rounded-xl p-5">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Information</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Created</span>
                      <span className="text-sm text-gray-900">{formatDate(item.createdAt)}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Last Updated</span>
                      <span className="text-sm text-gray-900">{formatDate(item.updatedAt)}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">ID</span>
                      <span className="text-sm text-gray-900">{item.id}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            /* Transactions Tab */
            <div>
              <div className="mb-6 flex justify-between items-center">
                <h3 className="text-lg font-medium text-gray-900">Transaction History</h3>
                
                <div className="flex space-x-2">
                  <button className="px-3 py-1.5 text-sm bg-[#190192] text-white rounded-lg">Last 30 Days</button>
                  <button className="px-3 py-1.5 text-sm bg-gray-100 text-gray-600 rounded-lg">Last 90 Days</button>
                  <button className="px-3 py-1.5 text-sm bg-gray-100 text-gray-600 rounded-lg">All Time</button>
                </div>
              </div>
              
              {item.transactions && item.transactions.length > 0 ? (
                <div className="bg-white rounded-lg shadow border border-gray-200 overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Type
                        </th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Quantity
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Location
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Reference
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {item.transactions.map((transaction, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {formatDate(transaction.date)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              transaction.type === 'Received' ? 'bg-green-100 text-green-800' :
                              transaction.type === 'Sold' ? 'bg-blue-100 text-blue-800' :
                              transaction.type === 'Transfer' ? 'bg-purple-100 text-purple-800' :
                              transaction.type === 'Adjustment' ? 'bg-amber-100 text-amber-800' :
                              'bg-gray-100 text-gray-800'
                            }`}>
                              {transaction.type}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-right font-medium">
                            <span className={transaction.quantity > 0 ? 'text-green-600' : 'text-red-600'}>
                              {transaction.quantity > 0 ? '+' : ''}{transaction.quantity}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {transaction.type === 'Transfer' 
                              ? `${transaction.fromLocation} → ${transaction.toLocation}`
                              : transaction.location}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {transaction.referenceUrl ? (
                              <Link to={transaction.referenceUrl} className="text-[#190192] hover:text-[#2C0DB5]">
                                {transaction.reference}
                              </Link>
                            ) : (
                              <span className="text-gray-500">{transaction.reference || '-'}</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="text-center py-12 bg-gray-50 rounded-lg">
                  <BarChart2 size={48} className="mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No transactions yet</h3>
                  <p className="text-gray-500 mb-6">
                    This item doesn't have any recorded transactions yet.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Item</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete <span className="font-medium">{item.name}</span>? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StockItemDetail;