// src/components/finance/reports/PayablesAgingReport.jsx
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Clock,
  AlertTriangle,
  CheckCircle,
  ExternalLink,
  FileText,
  DollarSign
} from 'lucide-react';
import ReportFilters from './ReportFilters';
import { useCurrency } from '../../../utils/currencyUtils';

const PayablesAgingReport = () => {
  const [searchParams] = useSearchParams();
  const { formatAmount } = useCurrency();

  // Initialise filters from URL search parameters
  const getInitialFilters = () => ({
    dateRange: searchParams.get('period') || 'month',
    startDate: searchParams.get('startDate') || '',
    endDate: searchParams.get('endDate') || '',
    status: searchParams.get('status') || 'all',
    overdue: searchParams.get('overdue') === 'true'
  });

  const [filters, setFilters] = useState(getInitialFilters());

  // Update filters when URL search parameters change
  useEffect(() => {
    setFilters(getInitialFilters());
  }, [searchParams]);

  const [reportData] = useState({
    asOfDate: 'March 31, 2025',
    summary: {
      totalPayables: 8920,
      current: 5300,
      overdue: 3620,
      overduePercentage: 40.6
    },
    agingCategories: [
      { name: 'Current', amount: 5300, percentage: 59.4 },
      { name: '1-30 Days', amount: 2450, percentage: 27.5 },
      { name: '31-60 Days', amount: 950, percentage: 10.6 },
      { name: '61-90 Days', amount: 220, percentage: 2.5 },
      { name: 'Over 90 Days', amount: 0, percentage: 0 }
    ],
    payables: [
      {
        id: 'BILL-2025-0047',
        vendor: 'Office Supplies Co.',
        date: '2025-03-20',
        dueDate: '2025-04-19',
        amount: 1850,
        outstanding: 1850,
        status: 'current',
        daysPastDue: 0
      },
      {
        id: 'BILL-2025-0042',
        vendor: 'Tech Hardware Inc.',
        date: '2025-03-05',
        dueDate: '2025-04-04',
        amount: 3450,
        outstanding: 3450,
        status: 'current',
        daysPastDue: 0
      },
      {
        id: 'BILL-2025-0038',
        vendor: 'Marketing Services Ltd.',
        date: '2025-02-18',
        dueDate: '2025-03-20',
        amount: 2450,
        outstanding: 2450,
        status: 'overdue',
        daysPastDue: 11
      },
      {
        id: 'BILL-2025-0029',
        vendor: 'Logistics Partners',
        date: '2025-01-25',
        dueDate: '2025-02-24',
        amount: 950,
        outstanding: 950,
        status: 'overdue',
        daysPastDue: 35
      },
      {
        id: 'BILL-2025-0015',
        vendor: 'Cleaning Services',
        date: '2025-01-02',
        dueDate: '2025-02-01',
        amount: 220,
        outstanding: 220,
        status: 'overdue',
        daysPastDue: 58
      }
    ]
  });

  // Handle filter changes coming from ReportFilters component
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    // In a real application, you would trigger a data fetch here based on the new filters.
  };

  // Combine filter checks for status and overdue flag
  const getFilteredPayables = () => {
    return reportData.payables.filter(item => {
      const statusMatch =
        filters.status === 'all' || item.status === filters.status;
      const overdueMatch = filters.overdue ? item.status === 'overdue' : true;
      return statusMatch && overdueMatch;
    });
  };

  const filteredPayables = getFilteredPayables();

  // Format date string to a readable format with fallback
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date)
      ? dateString
      : date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">Accounts Payable Aging</h2>

      <ReportFilters
        onFilterChange={handleFilterChange}
        availableFilters={['dateRange', 'status']}
        defaultDateRange={filters.dateRange}
      />

      {/* Summary Cards */}
      <div className="bg-white rounded-xl shadow-md p-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-medium text-gray-900">Payables Summary</h3>
          <div className="text-sm font-medium text-gray-500">
            As of {reportData.asOfDate}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Total Payables */}
          <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-blue-900">Total Payables</h4>
            </div>
            <p className="text-2xl font-bold text-gray-900">
              {formatAmount(reportData.summary.totalPayables)}
            </p>
            <p className="text-sm text-blue-600 mt-1">
              From {reportData.payables.length} bills
            </p>
          </div>

          {/* Current */}
          <div className="bg-green-50 rounded-lg p-4 border border-green-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-green-900">Current (Not Due)</h4>
            </div>
            <p className="text-2xl font-bold text-gray-900">
              {formatAmount(reportData.summary.current)}
            </p>
            <p className="text-sm text-green-600 mt-1">
              {reportData.agingCategories[0].percentage}% of total
            </p>
          </div>

          {/* Overdue */}
          <div className="bg-amber-50 rounded-lg p-4 border border-amber-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-amber-900">Overdue</h4>
            </div>
            <p className="text-2xl font-bold text-gray-900">
              {formatAmount(reportData.summary.overdue)}
            </p>
            <p className="text-sm text-amber-600 mt-1">
              {reportData.summary.overduePercentage}% of total
            </p>
          </div>

          {/* Aging Distribution */}
          <div className="col-span-1 md:col-span-2 lg:col-span-1 bg-indigo-50 rounded-lg p-4 border border-indigo-100">
            <h4 className="text-sm font-medium text-indigo-900 mb-3">Aging Distribution</h4>
            <div className="space-y-3">
              {reportData.agingCategories.map((category, index) => (
                <div key={index}>
                  <div className="flex justify-between items-center text-xs mb-1">
                    <span className="text-gray-600">{category.name}</span>
                    <span className="text-gray-800 font-medium">
                      {formatAmount(category.amount)}
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className={`h-2 rounded-full ${
                        index === 0 ? 'bg-green-500' :
                        index === 1 ? 'bg-yellow-400' :
                        index === 2 ? 'bg-orange-400' :
                        index === 3 ? 'bg-red-400' : 'bg-red-600'
                      }`}
                      style={{ width: `${category.percentage}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Payables Table */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="flex items-center justify-between p-6 border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-900">Accounts Payable Details</h3>
          <div className="flex items-center">
            <span className="mr-2 text-sm text-gray-500">
              {filteredPayables.length} of {reportData.payables.length} bills
            </span>
            <button className="flex items-center text-[#190192] text-sm font-medium">
              <FileText size={16} className="mr-1" />
              Export
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Bill No.
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Vendor
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Issue Date
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Due Date
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Outstanding
                </th>
                <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredPayables.map((bill) => (
                <tr key={bill.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-[#190192]">
                    <a href={`/finance/bills/${bill.id}`} className="hover:underline">
                      {bill.id}
                    </a>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {bill.vendor}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatDate(bill.date)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatDate(bill.dueDate)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                    {formatAmount(bill.amount)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-right">
                    {formatAmount(bill.outstanding)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    {bill.status === 'current' ? (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        <CheckCircle size={12} className="mr-1" />
                        Current
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
                        <Clock size={12} className="mr-1" />
                        {bill.daysPastDue} days overdue
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex items-center justify-end space-x-3">
                      <button className="text-green-600 hover:text-green-900">
                        <DollarSign size={16} />
                      </button>
                      <button className="text-blue-600 hover:text-blue-900">
                        <ExternalLink size={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {filteredPayables.length === 0 && (
          <div className="text-center py-12">
            <AlertTriangle size={48} className="mx-auto text-gray-400 mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No payables found</h3>
            <p className="text-gray-500 mb-6">
              There are no payables matching your current filters.
            </p>
            <button
              onClick={() => setFilters(prev => ({ ...prev, status: 'all', overdue: false }))}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Clear Filters
            </button>
          </div>
        )}
      </div>

      {/* Payment Planning Tips */}
      <div className="mt-6 bg-indigo-50 rounded-xl p-6 border border-indigo-100">
        <h3 className="text-lg font-medium text-indigo-900 mb-4">Payment Planning Tips</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <h4 className="font-medium text-gray-900 mb-2">Prioritise Payments</h4>
            <p className="text-sm text-gray-600">
              Pay bills with early payment discounts first, followed by bills with late payment penalties.
            </p>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <h4 className="font-medium text-gray-900 mb-2">Negotiate Terms</h4>
            <p className="text-sm text-gray-600">
              For large orders, try to negotiate better payment terms with your vendors.
            </p>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <h4 className="font-medium text-gray-900 mb-2">Cash Flow Planning</h4>
            <p className="text-sm text-gray-600">
              Align payment schedules with your expected cash inflows to maintain healthy cash flow.
            </p>
          </div>
        </div>
      </div>
    </div>
    );
};

export default PayablesAgingReport;
