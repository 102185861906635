// src/components/finance/reports/TaxSummaryReport.jsx
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { 
  FileText, ArrowRight, ChevronRight, ChevronDown,
  TrendingUp, TrendingDown, Download, Printer
} from 'lucide-react';
import ReportFilters from './ReportFilters';
import { useCurrency } from '../../../utils/currencyUtils';

const TaxSummaryReport = () => {
  const [searchParams] = useSearchParams();
  const { formatAmount } = useCurrency();
  const [filters, setFilters] = useState({
    dateRange: searchParams.get('period') || 'month',
    startDate: searchParams.get('startDate') || '',
    endDate: searchParams.get('endDate') || '',
    compareWith: searchParams.get('compare') || 'none'
  });
  
  const [expandedCategories, setExpandedCategories] = useState({
    collected: true,
    paid: true,
    summary: true
  });
  
  const [reportData, setReportData] = useState({
    period: 'March 2025',
    comparisonPeriod: 'February 2025',
    summary: {
      totalCollected: 4850,
      previousTotalCollected: 4250,
      totalPaid: 1200,
      previousTotalPaid: 1150,
      netTaxLiability: 3650,
      previousNetTaxLiability: 3100,
      change: 17.7
    },
    taxesCollected: [
      {
        name: 'Sales Tax',
        amount: 3650,
        previousAmount: 3220,
        change: 13.4,
        transactions: [
          { date: '2025-03-25', description: 'Invoice #1089', taxRate: '15%', amount: 450 },
          { date: '2025-03-22', description: 'Invoice #1087', taxRate: '15%', amount: 320 },
          { date: '2025-03-18', description: 'Invoice #1084', taxRate: '15%', amount: 580 },
          { date: '2025-03-15', description: 'Invoice #1082', taxRate: '15%', amount: 750 },
          { date: '2025-03-10', description: 'Invoice #1079', taxRate: '15%', amount: 680 },
          { date: '2025-03-05', description: 'Invoice #1076', taxRate: '15%', amount: 450 },
          { date: '2025-03-01', description: 'Invoice #1073', taxRate: '15%', amount: 420 }
        ]
      },
      {
        name: 'Service Tax',
        amount: 1200,
        previousAmount: 1030,
        change: 16.5,
        transactions: [
          { date: '2025-03-28', description: 'Invoice #1090', taxRate: '10%', amount: 250 },
          { date: '2025-03-20', description: 'Invoice #1085', taxRate: '10%', amount: 180 },
          { date: '2025-03-12', description: 'Invoice #1080', taxRate: '10%', amount: 220 },
          { date: '2025-03-08', description: 'Invoice #1077', taxRate: '10%', amount: 350 },
          { date: '2025-03-03', description: 'Invoice #1074', taxRate: '10%', amount: 200 }
        ]
      }
    ],
    taxesPaid: [
      {
        name: 'Expense Tax',
        amount: 850,
        previousAmount: 780,
        change: 9.0,
        transactions: [
          { date: '2025-03-27', description: 'Bill #047', taxRate: '15%', amount: 150 },
          { date: '2025-03-20', description: 'Bill #042', taxRate: '15%', amount: 220 },
          { date: '2025-03-15', description: 'Bill #039', taxRate: '15%', amount: 180 },
          { date: '2025-03-08', description: 'Bill #035', taxRate: '15%', amount: 120 },
          { date: '2025-03-02', description: 'Bill #031', taxRate: '15%', amount: 180 }
        ]
      },
      {
        name: 'Import Tax',
        amount: 350,
        previousAmount: 370,
        change: -5.4,
        transactions: [
          { date: '2025-03-22', description: 'Import #087', taxRate: '5%', amount: 180 },
          { date: '2025-03-10', description: 'Import #082', taxRate: '5%', amount: 170 }
        ]
      }
    ]
  });
  
  // Toggle category expansion
  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };
  
  // Handle filter changes
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    
    // In a real app, you would fetch new data based on filters
    // For now, we'll just update the period labels
    if (newFilters.dateRange === 'month') {
      setReportData(prev => ({
        ...prev,
        period: 'March 2025',
        comparisonPeriod: 'February 2025'
      }));
    } else if (newFilters.dateRange === 'quarter') {
      setReportData(prev => ({
        ...prev,
        period: 'Q1 2025',
        comparisonPeriod: 'Q4 2024'
      }));
    } else if (newFilters.dateRange === 'year') {
      setReportData(prev => ({
        ...prev,
        period: '2025',
        comparisonPeriod: '2024'
      }));
    }
  };
  
  // Format date string to readable format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };
  
  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">Tax Summary Report</h2>
      
      <ReportFilters 
        onFilterChange={handleFilterChange}
        availableFilters={['dateRange', 'compare', 'includeSubitems']}
        defaultDateRange={filters.dateRange}
      />
      
      {/* Tax Summary */}
      <div className="bg-white rounded-xl shadow-md p-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-medium text-gray-900">Tax Summary</h3>
          <div className="text-sm font-medium text-gray-500">
            {reportData.period} {filters.compareWith !== 'none' && `vs ${reportData.comparisonPeriod}`}
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {/* Taxes Collected */}
          <div className="bg-green-50 rounded-lg p-4 border border-green-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-green-900">Taxes Collected</h4>
              {filters.compareWith !== 'none' && (
                <span className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full ${
                  reportData.summary.totalCollected > reportData.summary.previousTotalCollected
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-red-100 text-red-800'
                }`}>
                  {reportData.summary.totalCollected > reportData.summary.previousTotalCollected ? '+' : ''}
                  {(((reportData.summary.totalCollected - reportData.summary.previousTotalCollected) / reportData.summary.previousTotalCollected) * 100).toFixed(1)}%
                </span>
              )}
            </div>
            <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.summary.totalCollected)}</p>
            {filters.compareWith !== 'none' && (
              <p className="text-sm text-gray-500 mt-1">vs {formatAmount(reportData.summary.previousTotalCollected)}</p>
            )}
          </div>
          
          {/* Taxes Paid */}
          <div className="bg-amber-50 rounded-lg p-4 border border-amber-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-amber-900">Taxes Paid</h4>
              {filters.compareWith !== 'none' && (
                <span className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full ${
                  reportData.summary.totalPaid > reportData.summary.previousTotalPaid
                    ? 'bg-amber-100 text-amber-800' 
                    : 'bg-green-100 text-green-800'
                }`}>
                  {reportData.summary.totalPaid > reportData.summary.previousTotalPaid ? '+' : ''}
                  {(((reportData.summary.totalPaid - reportData.summary.previousTotalPaid) / reportData.summary.previousTotalPaid) * 100).toFixed(1)}%
                </span>
              )}
            </div>
            <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.summary.totalPaid)}</p>
            {filters.compareWith !== 'none' && (
              <p className="text-sm text-gray-500 mt-1">vs {formatAmount(reportData.summary.previousTotalPaid)}</p>
            )}
          </div>
          
          {/* Net Tax Liability */}
          <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-blue-900">Net Tax Liability</h4>
              {filters.compareWith !== 'none' && (
                <span className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full ${
                  reportData.summary.change >= 0
                    ? 'bg-blue-100 text-blue-800' 
                    : 'bg-green-100 text-green-800'
                }`}>
                  {reportData.summary.change >= 0 ? '+' : ''}
                  {reportData.summary.change}%
                </span>
              )}
            </div>
            <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.summary.netTaxLiability)}</p>
            {filters.compareWith !== 'none' && (
              <p className="text-sm text-gray-500 mt-1">vs {formatAmount(reportData.summary.previousNetTaxLiability)}</p>
            )}
          </div>
        </div>
        
        {/* Tax Flow Diagram */}
        <div className="mt-6 pt-6 border-t border-gray-200">
          <h4 className="text-medium font-medium text-gray-700 mb-4">Tax Flow</h4>
          
          <div className="flex items-center justify-center flex-wrap">
            <div className="bg-green-50 p-3 rounded-lg border border-green-100 text-center">
              <div className="text-sm font-medium text-green-800 mb-1">Taxes Collected</div>
              <div className="text-lg font-bold text-gray-900">{formatAmount(reportData.summary.totalCollected)}</div>
            </div>
            
            <ArrowRight size={32} className="mx-4 text-gray-400" />
            
            <div className="bg-red-50 p-3 rounded-lg border border-red-100 text-center">
              <div className="text-sm font-medium text-red-800 mb-1">Taxes Paid</div>
              <div className="text-lg font-bold text-gray-900">- {formatAmount(reportData.summary.totalPaid)}</div>
            </div>
            
            <ArrowRight size={32} className="mx-4 text-gray-400" />
            
            <div className="bg-blue-50 p-3 rounded-lg border border-blue-100 text-center">
              <div className="text-sm font-medium text-blue-800 mb-1">Net Tax Liability</div>
              <div className="text-lg font-bold text-gray-900">{formatAmount(reportData.summary.netTaxLiability)}</div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Tax Breakdown */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="flex items-center justify-between p-6 border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-900">Tax Detail Breakdown</h3>
          <div className="flex space-x-3">
            <button className="flex items-center text-[#190192] text-sm font-medium">
              <Download size={16} className="mr-1" />
              Export
            </button>
            <button className="flex items-center text-[#190192] text-sm font-medium">
              <Printer size={16} className="mr-1" />
              Print
            </button>
          </div>
        </div>
        
        <div className="p-6">
          {/* Taxes Collected Section */}
          <div className="mb-8">
            <button
              onClick={() => toggleCategory('collected')}
              className="flex items-center justify-between w-full text-left mb-3"
            >
              <div className="flex items-center">
                {expandedCategories.collected ? (
                  <ChevronDown size={18} className="text-gray-500 mr-2" />
                ) : (
                  <ChevronRight size={18} className="text-gray-500 mr-2" />
                )}
                <span className="text-lg font-medium text-green-900">Taxes Collected</span>
              </div>
              <div className="text-lg font-bold text-gray-900">{formatAmount(reportData.summary.totalCollected)}</div>
            </button>
            
            {expandedCategories.collected && (
              <div className="pl-6">
                {reportData.taxesCollected.map((taxCategory, index) => (
                  <div key={index} className="mb-6">
                    <div className="flex justify-between items-center mb-3 bg-gray-50 p-3 rounded-lg">
                      <span className="font-medium text-gray-800">{taxCategory.name}</span>
                      <div className="flex items-center">
                        <span className="font-medium text-gray-900 mr-3">{formatAmount(taxCategory.amount)}</span>
                        {filters.compareWith !== 'none' && (
                          <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                            taxCategory.change >= 0 
                              ? 'bg-green-100 text-green-800' 
                              : 'bg-red-100 text-red-800'
                          }`}>
                            {taxCategory.change >= 0 ? (
                              <>
                                <TrendingUp size={12} className="mr-1" />
                                +{taxCategory.change}%
                              </>
                            ) : (
                              <>
                                <TrendingDown size={12} className="mr-1" />
                                {taxCategory.change}%
                              </>
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                    
                    {filters.includeSubitems && (
                      <div className="border rounded-lg overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Date
                              </th>
                              <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Description
                              </th>
                              <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Tax Rate
                              </th>
                              <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {taxCategory.transactions.map((transaction, tIdx) => (
                              <tr key={tIdx} className="hover:bg-gray-50">
                                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                                  {formatDate(transaction.date)}
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                                  {transaction.description}
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                                  {transaction.taxRate}
                                </td>
                                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 text-right font-medium">
                                  {formatAmount(transaction.amount)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                ))}
                
                {/* Total for Taxes Collected */}
                <div className="flex justify-between items-center mt-4 pt-4 border-t border-gray-200">
                  <span className="font-medium text-gray-900">Total Taxes Collected</span>
                  <span className="font-bold text-gray-900">{formatAmount(reportData.summary.totalCollected)}</span>
                </div>
              </div>
            )}
          </div>
          
          {/* Taxes Paid Section */}
          <div className="mb-8">
            <button
              onClick={() => toggleCategory('paid')}
              className="flex items-center justify-between w-full text-left mb-3"
            >
              <div className="flex items-center">
                {expandedCategories.paid ? (
                  <ChevronDown size={18} className="text-gray-500 mr-2" />
                ) : (
                  <ChevronRight size={18} className="text-gray-500 mr-2" />
                )}
                <span className="text-lg font-medium text-amber-900">Taxes Paid</span>
              </div>
              <div className="text-lg font-bold text-gray-900">{formatAmount(reportData.summary.totalPaid)}</div>
            </button>
            
            {expandedCategories.paid && (
              <div className="pl-6">
                {reportData.taxesPaid.map((taxCategory, index) => (
                  <div key={index} className="mb-6">
                    <div className="flex justify-between items-center mb-3 bg-gray-50 p-3 rounded-lg">
                      <span className="font-medium text-gray-800">{taxCategory.name}</span>
                      <div className="flex items-center">
                        <span className="font-medium text-gray-900 mr-3">{formatAmount(taxCategory.amount)}</span>
                        {filters.compareWith !== 'none' && (
                          <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                            taxCategory.change >= 0 
                              ? 'bg-amber-100 text-amber-800' 
                              : 'bg-green-100 text-green-800'
                          }`}>
                            {taxCategory.change >= 0 ? (
                              <>
                                <TrendingUp size={12} className="mr-1" />
                                +{taxCategory.change}%
                              </>
                            ) : (
                              <>
                                <TrendingDown size={12} className="mr-1" />
                               {taxCategory.change}%
                             </>
                           )}
                         </span>
                       )}
                     </div>
                   </div>
                   
                   {filters.includeSubitems && (
                     <div className="border rounded-lg overflow-hidden">
                       <table className="min-w-full divide-y divide-gray-200">
                         <thead className="bg-gray-50">
                           <tr>
                             <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                               Date
                             </th>
                             <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                               Description
                             </th>
                             <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                               Tax Rate
                             </th>
                             <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                               Amount
                             </th>
                           </tr>
                         </thead>
                         <tbody className="bg-white divide-y divide-gray-200">
                           {taxCategory.transactions.map((transaction, tIdx) => (
                             <tr key={tIdx} className="hover:bg-gray-50">
                               <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                                 {formatDate(transaction.date)}
                               </td>
                               <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                                 {transaction.description}
                               </td>
                               <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                                 {transaction.taxRate}
                               </td>
                               <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 text-right font-medium">
                                 {formatAmount(transaction.amount)}
                               </td>
                             </tr>
                           ))}
                         </tbody>
                       </table>
                     </div>
                   )}
                 </div>
               ))}
               
               {/* Total for Taxes Paid */}
               <div className="flex justify-between items-center mt-4 pt-4 border-t border-gray-200">
                 <span className="font-medium text-gray-900">Total Taxes Paid</span>
                 <span className="font-bold text-gray-900">{formatAmount(reportData.summary.totalPaid)}</span>
               </div>
             </div>
           )}
         </div>
         
         {/* Net Tax Liability Section */}
         <div className="mt-8 pt-6 border-t border-gray-200">
           <button
             onClick={() => toggleCategory('summary')}
             className="flex items-center justify-between w-full text-left mb-3"
           >
             <div className="flex items-center">
               {expandedCategories.summary ? (
                 <ChevronDown size={18} className="text-gray-500 mr-2" />
               ) : (
                 <ChevronRight size={18} className="text-gray-500 mr-2" />
               )}
               <span className="text-lg font-medium text-blue-900">Net Tax Summary</span>
             </div>
             <div className="text-lg font-bold text-gray-900">{formatAmount(reportData.summary.netTaxLiability)}</div>
           </button>
           
           {expandedCategories.summary && (
             <div className="mt-4 p-6 bg-blue-50 rounded-lg">
               <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                 <div>
                   <h4 className="text-sm font-medium text-blue-900 mb-3">Tax Calculation</h4>
                   <div className="space-y-2">
                     <div className="flex justify-between">
                       <span className="text-gray-700">Total Taxes Collected</span>
                       <span className="font-medium text-gray-900">{formatAmount(reportData.summary.totalCollected)}</span>
                     </div>
                     <div className="flex justify-between">
                       <span className="text-gray-700">Less: Total Taxes Paid</span>
                       <span className="font-medium text-gray-900">- {formatAmount(reportData.summary.totalPaid)}</span>
                     </div>
                     <div className="flex justify-between pt-2 border-t border-blue-200">
                       <span className="font-medium text-gray-900">Net Tax Liability</span>
                       <span className="font-bold text-gray-900">{formatAmount(reportData.summary.netTaxLiability)}</span>
                     </div>
                   </div>
                 </div>
                 
                 <div>
                   <h4 className="text-sm font-medium text-blue-900 mb-3">Filing Information</h4>
                   <div className="space-y-2">
                     <div className="flex justify-between">
                       <span className="text-gray-700">Reporting Period</span>
                       <span className="font-medium text-gray-900">{reportData.period}</span>
                     </div>
                     <div className="flex justify-between">
                       <span className="text-gray-700">Filing Deadline</span>
                       <span className="font-medium text-gray-900">April 30, 2025</span>
                     </div>
                     <div className="flex justify-between pt-2 border-t border-blue-200">
                       <span className="font-medium text-gray-900">Status</span>
                       <span className="font-medium bg-yellow-100 text-yellow-800 px-2 py-0.5 rounded text-xs">Pending</span>
                     </div>
                   </div>
                 </div>
               </div>
               
               <div className="mt-6 flex justify-end">
                 <button className="flex items-center text-sm font-medium text-white bg-[#190192] px-4 py-2 rounded-md">
                   <FileText size={16} className="mr-2" />
                   Prepare Tax Filing
                 </button>
               </div>
             </div>
           )}
         </div>
       </div>
     </div>
     
     {/* Tax Filing Tips */}
     <div className="mt-6 bg-indigo-50 rounded-xl p-6 border border-indigo-100">
       <h3 className="text-lg font-medium text-indigo-900 mb-4">Tax Filing Tips</h3>
       <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
         <div className="bg-white rounded-lg p-4 shadow-sm">
           <h4 className="font-medium text-gray-900 mb-2">Keep Documentation</h4>
           <p className="text-sm text-gray-600">
             Maintain detailed records of all tax-related transactions to support your tax filings.
           </p>
         </div>
         <div className="bg-white rounded-lg p-4 shadow-sm">
           <h4 className="font-medium text-gray-900 mb-2">Set Aside Tax Funds</h4>
           <p className="text-sm text-gray-600">
             Regularly set aside funds for tax payments to avoid cash flow issues when taxes are due.
           </p>
         </div>
         <div className="bg-white rounded-lg p-4 shadow-sm">
           <h4 className="font-medium text-gray-900 mb-2">File on Time</h4>
           <p className="text-sm text-gray-600">
             Submit tax returns and payments before deadlines to avoid penalties and interest charges.
           </p>
         </div>
       </div>
     </div>
   </div>
 );
};

export default TaxSummaryReport;