// src/components/finance/reports/BalanceSheetReport.jsx
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { 
  ChevronRight, ChevronDown, TrendingUp, TrendingDown 
} from 'lucide-react';
import ReportFilters from './ReportFilters';
import { useCurrency } from '../../../utils/currencyUtils';

const BalanceSheetReport = () => {
  const [searchParams] = useSearchParams();
  const { formatAmount } = useCurrency();
  const [filters, setFilters] = useState({
    dateRange: searchParams.get('period') || 'month',
    startDate: searchParams.get('startDate') || '',
    endDate: searchParams.get('endDate') || '',
    compareWith: searchParams.get('compare') || 'none'
  });
  
  const [expandedCategories, setExpandedCategories] = useState({
    assets: true,
    liabilities: true,
    equity: true
  });
  
  const [reportData, setReportData] = useState({
    asOfDate: 'March 31, 2025',
    comparisonDate: 'February 28, 2025',
    overview: {
      totalAssets: 127450,
      previousTotalAssets: 123150,
      totalLiabilities: 43500,
      previousTotalLiabilities: 45700,
      totalEquity: 83950,
      previousTotalEquity: 77450,
      assetsChange: 3.5,
      liabilitiesChange: -4.8,
      equityChange: 8.4
    },
    assets: [
      {
        category: 'Current Assets',
        amount: 58200,
        previousAmount: 55700,
        change: 4.5,
        items: [
          { name: 'Cash and Cash Equivalents', amount: 32500, previousAmount: 30000, change: 8.3 },
          { name: 'Accounts Receivable', amount: 18650, previousAmount: 19200, change: -2.9 },
          { name: 'Inventory', amount: 5200, previousAmount: 4500, change: 15.6 },
          { name: 'Prepaid Expenses', amount: 1850, previousAmount: 2000, change: -7.5 }
        ]
      },
      {
        category: 'Fixed Assets',
        amount: 69250,
        previousAmount: 67450,
        change: 2.7,
        items: [
          { name: 'Property and Equipment', amount: 85000, previousAmount: 85000, change: 0 },
          { name: 'Less: Accumulated Depreciation', amount: -17500, previousAmount: -16300, change: 7.4 },
          { name: 'Intangible Assets', amount: 1750, previousAmount: 1750, change: 0 }
        ]
      }
    ],
    liabilities: [
      {
        category: 'Current Liabilities',
        amount: 23200,
        previousAmount: 25400,
        change: -8.7,
        items: [
          { name: 'Accounts Payable', amount: 8920, previousAmount: 9450, change: -5.6 },
          { name: 'Short-term Loans', amount: 5000, previousAmount: 7500, change: -33.3 },
          { name: 'Accrued Expenses', amount: 4950, previousAmount: 4250, change: 16.5 },
          { name: 'Taxes Payable', amount: 4330, previousAmount: 4200, change: 3.1 }
        ]
      },
      {
        category: 'Long-term Liabilities',
        amount: 20300,
        previousAmount: 20300,
        change: 0,
        items: [
          { name: 'Long-term Loans', amount: 15000, previousAmount: 15000, change: 0 },
          { name: 'Deferred Tax Liabilities', amount: 5300, previousAmount: 5300, change: 0 }
        ]
      }
    ],
    equity: [
      {
        category: 'Owner\'s Equity',
        amount: 83950,
        previousAmount: 77450,
        change: 8.4,
        items: [
          { name: 'Capital', amount: 50000, previousAmount: 50000, change: 0 },
          { name: 'Retained Earnings', amount: 33950, previousAmount: 27450, change: 23.7 }
        ]
      }
    ]
  });
  
  // Toggle category expansion
  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };
  
  // Handle filter changes
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    
    // In a real app, you would fetch new data based on filters
    // For now, we'll just update the period labels
    if (newFilters.dateRange === 'month') {
      setReportData(prev => ({
        ...prev,
        asOfDate: 'March 31, 2025',
        comparisonDate: 'February 28, 2025'
      }));
    } else if (newFilters.dateRange === 'quarter') {
      setReportData(prev => ({
        ...prev,
        asOfDate: 'March 31, 2025',
        comparisonDate: 'December 31, 2024'
      }));
    } else if (newFilters.dateRange === 'year') {
      setReportData(prev => ({
        ...prev,
        asOfDate: 'December 31, 2024',
        comparisonDate: 'December 31, 2023'
      }));
    }
  };
  
  // Calculate totals
  const totalAssets = reportData.assets.reduce((sum, category) => sum + category.amount, 0);
  const totalLiabilities = reportData.liabilities.reduce((sum, category) => sum + category.amount, 0);
  const totalEquity = reportData.equity.reduce((sum, category) => sum + category.amount, 0);
  
  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">Balance Sheet</h2>
      
      <ReportFilters 
        onFilterChange={handleFilterChange}
        availableFilters={['dateRange', 'compare', 'includeSubitems']}
        defaultDateRange={filters.dateRange}
      />
      
      {/* Header Information */}
      <div className="bg-white rounded-xl shadow-md p-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-medium text-gray-900">Financial Position</h3>
          <div className="text-sm font-medium text-gray-500">
            As of {reportData.asOfDate} {filters.compareWith !== 'none' && `vs ${reportData.comparisonDate}`}
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Assets Summary */}
          <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-blue-900">Total Assets</h4>
              {filters.compareWith !== 'none' && (
                <span className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full ${
                  reportData.overview.assetsChange >= 0 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-red-100 text-red-800'
                }`}>
                  {reportData.overview.assetsChange >= 0 ? 
                    `+${reportData.overview.assetsChange}%` : 
                    `${reportData.overview.assetsChange}%`}
                </span>
              )}
            </div>
            <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.overview.totalAssets)}</p>
            {filters.compareWith !== 'none' && (
              <p className="text-sm text-gray-500 mt-1">vs {formatAmount(reportData.overview.previousTotalAssets)}</p>
            )}
          </div>
          
          {/* Liabilities Summary */}
          <div className="bg-red-50 rounded-lg p-4 border border-red-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-red-900">Total Liabilities</h4>
              {filters.compareWith !== 'none' && (
                <span className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full ${
                  reportData.overview.liabilitiesChange >= 0 
                    ? 'bg-red-100 text-red-800' 
                    : 'bg-green-100 text-green-800'
                }`}>
                  {reportData.overview.liabilitiesChange >= 0 ? 
                    `+${reportData.overview.liabilitiesChange}%` : 
                    `${reportData.overview.liabilitiesChange}%`}
                </span>
              )}
            </div>
            <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.overview.totalLiabilities)}</p>
            {filters.compareWith !== 'none' && (
              <p className="text-sm text-gray-500 mt-1">vs {formatAmount(reportData.overview.previousTotalLiabilities)}</p>
            )}
          </div>
          
          {/* Equity Summary */}
          <div className="bg-green-50 rounded-lg p-4 border border-green-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-green-900">Owner's Equity</h4>
              {filters.compareWith !== 'none' && (
                <span className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full ${
                  reportData.overview.equityChange >= 0 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-red-100 text-red-800'
                }`}>
                  {reportData.overview.equityChange >= 0 ? 
                    `+${reportData.overview.equityChange}%` : 
                    `${reportData.overview.equityChange}%`}
                </span>
              )}
            </div>
            <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.overview.totalEquity)}</p>
            {filters.compareWith !== 'none' && (
              <p className="text-sm text-gray-500 mt-1">vs {formatAmount(reportData.overview.previousTotalEquity)}</p>
            )}
          </div>
        </div>
      </div>
      
      {/* Detailed Report */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="flex items-center justify-between p-6 border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-900">Balance Sheet Details</h3>
          <p className="text-sm font-medium text-gray-500">
            As of {reportData.asOfDate}
          </p>
        </div>
        
        <div className="p-6">
          {/* Assets Section */}
          <div className="mb-8">
            <button
              onClick={() => toggleCategory('assets')}
              className="flex items-center justify-between w-full text-left mb-3"
            >
              <div className="flex items-center">
                {expandedCategories.assets ? (
                  <ChevronDown size={18} className="text-gray-500 mr-2" />
                ) : (
                  <ChevronRight size={18} className="text-gray-500 mr-2" />
                )}
                <span className="text-lg font-medium text-blue-900">Assets</span>
              </div>
              <div className="text-xl font-bold text-gray-900">{formatAmount(totalAssets)}</div>
            </button>
            
            {expandedCategories.assets && (
              <div className="pl-6">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category
                      </th>
                      {filters.compareWith !== 'none' && (
                        <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Previous
                        </th>
                      )}
                      <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      {filters.compareWith !== 'none' && (
                        <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Change
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {reportData.assets.map((category, index) => (
                      <React.Fragment key={index}>
                        <tr className="hover:bg-gray-50">
                          <td className="px-3 py-4 text-sm font-medium text-gray-900">{category.category}</td>
                          {filters.compareWith !== 'none' && (
                            <td className="px-3 py-4 text-sm text-gray-500 text-right">{formatAmount(category.previousAmount)}</td>
                          )}
                          <td className="px-3 py-4 text-sm text-gray-900 font-medium text-right">{formatAmount(category.amount)}</td>
                          {filters.compareWith !== 'none' && (
                            <td className="px-3 py-4 text-right">
                              <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                                category.change >= 0 
                                  ? 'bg-green-100 text-green-800' 
                                  : 'bg-red-100 text-red-800'
                              }`}>
                                {category.change >= 0 ? (
                                  <>
                                    <TrendingUp size={12} className="mr-1" />
                                    +{category.change}%
                                  </>
                                ) : (
                                  <>
                                    <TrendingDown size={12} className="mr-1" />
                                    {category.change}%
                                  </>
                                )}
                              </span>
                            </td>
                          )}
                        </tr>
                        {filters.includeSubitems && category.items.map((item, itemIndex) => (
                          <tr key={`${index}-${itemIndex}`} className="bg-gray-50">
                            <td className="px-3 py-2 text-xs text-gray-500 pl-8">{item.name}</td>
                            {filters.compareWith !== 'none' && (
                              <td className="px-3 py-2 text-xs text-gray-500 text-right">{formatAmount(item.previousAmount)}</td>
                            )}
                            <td className="px-3 py-2 text-xs text-gray-700 text-right">{formatAmount(item.amount)}</td>
                            {filters.compareWith !== 'none' && (
                              <td className="px-3 py-2 text-right">
                                <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                                  item.change >= 0 
                                    ? 'bg-green-100 text-green-800' 
                                    : 'bg-red-100 text-red-800'
                                }`}>
                                  {item.change >= 0 ? `+${item.change}%` : `${item.change}%`}
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                    <tr className="bg-blue-50">
                      <td className="px-3 py-4 text-sm font-bold text-gray-900">Total Assets</td>
                      {filters.compareWith !== 'none' && (
                        <td className="px-3 py-4 text-sm text-gray-900 font-bold text-right">
                          {formatAmount(reportData.overview.previousTotalAssets)}
                        </td>
                      )}
                      <td className="px-3 py-4 text-sm text-gray-900 font-bold text-right">{formatAmount(totalAssets)}</td>
                      {filters.compareWith !== 'none' && (
                        <td className="px-3 py-4 text-right">
                          <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                            reportData.overview.assetsChange >= 0 
                              ? 'bg-green-100 text-green-800' 
                              : 'bg-red-100 text-red-800'
                          }`}>
                            {reportData.overview.assetsChange >= 0 ? (
                              <>
                                <TrendingUp size={12} className="mr-1" />
                                +{reportData.overview.assetsChange}%
                              </>
                            ) : (
                              <>
                                <TrendingDown size={12} className="mr-1" />
                                {reportData.overview.assetsChange}%
                              </>
                            )}
                          </span>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          
          {/* Liabilities Section */}
          <div className="mb-8">
            <button
              onClick={() => toggleCategory('liabilities')}
              className="flex items-center justify-between w-full text-left mb-3"
            >
              <div className="flex items-center">
                {expandedCategories.liabilities ? (
                  <ChevronDown size={18} className="text-gray-500 mr-2" />
                ) : (
                  <ChevronRight size={18} className="text-gray-500 mr-2" />
                )}
                <span className="text-lg font-medium text-red-900">Liabilities</span>
              </div>
              <div className="text-xl font-bold text-gray-900">{formatAmount(totalLiabilities)}</div>
            </button>
            
            {expandedCategories.liabilities && (
              <div className="pl-6">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category
                      </th>
                      {filters.compareWith !== 'none' && (
                        <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Previous
                        </th>
                      )}
                      <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      {filters.compareWith !== 'none' && (
                        <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Change
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {reportData.liabilities.map((category, index) => (
                      <React.Fragment key={index}>
                        <tr className="hover:bg-gray-50">
                          <td className="px-3 py-4 text-sm font-medium text-gray-900">{category.category}</td>
                          {filters.compareWith !== 'none' && (
                            <td className="px-3 py-4 text-sm text-gray-500 text-right">{formatAmount(category.previousAmount)}</td>
                          )}
                          <td className="px-3 py-4 text-sm text-gray-900 font-medium text-right">{formatAmount(category.amount)}</td>
                          {filters.compareWith !== 'none' && (
                            <td className="px-3 py-4 text-right">
                              <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                                category.change >= 0 
                                  ? 'bg-red-100 text-red-800' 
                                  : 'bg-green-100 text-green-800'
                              }`}>
                                {category.change >= 0 ? (
                                  <>
                                    <TrendingUp size={12} className="mr-1" />
                                    +{category.change}%
                                  </>
                                ) : (
                                  <>
                                    <TrendingDown size={12} className="mr-1" />
                                    {category.change}%
                                  </>
                                )}
                              </span>
                            </td>
                          )}
                        </tr>
                        {filters.includeSubitems && category.items.map((item, itemIndex) => (
                          <tr key={`${index}-${itemIndex}`} className="bg-gray-50">
                            <td className="px-3 py-2 text-xs text-gray-500 pl-8">{item.name}</td>
                            {filters.compareWith !== 'none' && (
                              <td className="px-3 py-2 text-xs text-gray-500 text-right">{formatAmount(item.previousAmount)}</td>
                            )}
                            <td className="px-3 py-2 text-xs text-gray-700 text-right">{formatAmount(item.amount)}</td>
                            {filters.compareWith !== 'none' && (
                              <td className="px-3 py-2 text-right">
                                <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                                  item.change >= 0 
                                    ? 'bg-red-100 text-red-800' 
                                    : 'bg-green-100 text-green-800'
                                }`}>
                                  {item.change >= 0 ? `+${item.change}%` : `${item.change}%`}
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                    <tr className="bg-red-50">
                      <td className="px-3 py-4 text-sm font-bold text-gray-900">Total Liabilities</td>
                      {filters.compareWith !== 'none' && (
                        <td className="px-3 py-4 text-sm text-gray-900 font-bold text-right">
                          {formatAmount(reportData.overview.previousTotalLiabilities)}
                        </td>
                      )}
                      <td className="px-3 py-4 text-sm text-gray-900 font-bold text-right">{formatAmount(totalLiabilities)}</td>
                      {filters.compareWith !== 'none' && (
                        <td className="px-3 py-4 text-right">
                          <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                            reportData.overview.liabilitiesChange >= 0 
                              ? 'bg-red-100 text-red-800' 
                              : 'bg-green-100 text-green-800'
                          }`}>
                            {reportData.overview.liabilitiesChange >= 0 ? (
                              <>
                                <TrendingUp size={12} className="mr-1" />
                                +{reportData.overview.liabilitiesChange}%
                              </>
                            ) : (
                              <>
                                <TrendingDown size={12} className="mr-1" />
                                {reportData.overview.liabilitiesChange}%
                              </>
                            )}
                          </span>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          
          {/* Equity Section */}
          <div className="mb-8">
            <button
              onClick={() => toggleCategory('equity')}
              className="flex items-center justify-between w-full text-left mb-3"
            >
              <div className="flex items-center">
                {expandedCategories.equity ? (
                  <ChevronDown size={18} className="text-gray-500 mr-2" />
                ) : (
                  <ChevronRight size={18} className="text-gray-500 mr-2" />
                )}
                <span className="text-lg font-medium text-green-900">Equity</span>
              </div>
              <div className="text-xl font-bold text-gray-900">{formatAmount(totalEquity)}</div>
            </button>
            
            {expandedCategories.equity && (
              <div className="pl-6">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category
                      </th>
                      {filters.compareWith !== 'none' && (
                        <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Previous
                        </th>
                      )}
                      <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      {filters.compareWith !== 'none' && (
                        <th scope="col" className="px-3 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Change
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {reportData.equity.map((category, index) => (
                      <React.Fragment key={index}>
                        <tr className="hover:bg-gray-50">
                          <td className="px-3 py-4 text-sm font-medium text-gray-900">{category.category}</td>
                          {filters.compareWith !== 'none' && (
                            <td className="px-3 py-4 text-sm text-gray-500 text-right">{formatAmount(category.previousAmount)}</td>
                          )}
                          <td className="px-3 py-4 text-sm text-gray-900 font-medium text-right">{formatAmount(category.amount)}</td>
                          {filters.compareWith !== 'none' && (
                            <td className="px-3 py-4 text-right">
                              <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                                category.change >= 0 
                                  ? 'bg-green-100 text-green-800' 
                                  : 'bg-red-100 text-red-800'
                              }`}>
                                {category.change >= 0 ? (
                                  <>
                                    <TrendingUp size={12} className="mr-1" />
                                    +{category.change}%
                                  </>
                                ) : (
                                  <>
                                    <TrendingDown size={12} className="mr-1" />
                                    {category.change}%
                                  </>
                                )}
                              </span>
                            </td>
                          )}
                        </tr>
                        {filters.includeSubitems && category.items.map((item, itemIndex) => (
                          <tr key={`${index}-${itemIndex}`} className="bg-gray-50">
                            <td className="px-3 py-2 text-xs text-gray-500 pl-8">{item.name}</td>
                            {filters.compareWith !== 'none' && (
                              <td className="px-3 py-2 text-xs text-gray-500 text-right">{formatAmount(item.previousAmount)}</td>
                            )}
                            <td className="px-3 py-2 text-xs text-gray-700 text-right">{formatAmount(item.amount)}</td>
                            {filters.compareWith !== 'none' && (
                              <td className="px-3 py-2 text-right">
                                <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                                  item.change >= 0 
                                    ? 'bg-green-100 text-green-800' 
                                    : 'bg-red-100 text-red-800'
                                }`}>
                                  {item.change >= 0 ? `+${item.change}%` : `${item.change}%`}
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                    <tr className="bg-green-50">
                      <td className="px-3 py-4 text-sm font-bold text-gray-900">Total Equity</td>
                      {filters.compareWith !== 'none' && (
                        <td className="px-3 py-4 text-sm text-gray-900 font-bold text-right">
                          {formatAmount(reportData.overview.previousTotalEquity)}
                        </td>
                      )}
                      <td className="px-3 py-4 text-sm text-gray-900 font-bold text-right">{formatAmount(totalEquity)}</td>
                      {filters.compareWith !== 'none' && (
                        <td className="px-3 py-4 text-right">
                          <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                           reportData.overview.equityChange >= 0 
                             ? 'bg-green-100 text-green-800' 
                             : 'bg-red-100 text-red-800'
                         }`}>
                           {reportData.overview.equityChange >= 0 ? (
                             <>
                               <TrendingUp size={12} className="mr-1" />
                               +{reportData.overview.equityChange}%
                             </>
                           ) : (
                             <>
                               <TrendingDown size={12} className="mr-1" />
                               {reportData.overview.equityChange}%
                             </>
                           )}
                         </span>
                       </td>
                     )}
                   </tr>
                 </tbody>
               </table>
             </div>
           )}
         </div>
         
         {/* Balance Verification */}
         <div className="mt-6 border-t border-gray-200 pt-6">
           <div className="flex justify-between items-center">
             <h3 className="text-lg font-medium text-gray-900">Assets = Liabilities + Equity</h3>
             <div className="text-lg font-medium">
               {formatAmount(totalAssets)} = {formatAmount(totalLiabilities)} + {formatAmount(totalEquity)}
             </div>
           </div>
           <div className="mt-4 p-4 bg-gray-50 rounded-lg">
             <div className="flex items-center">
               <div className="w-full bg-gray-200 rounded-full h-3">
                 <div
                   className="bg-red-500 h-3 rounded-l-full"
                   style={{ width: `${(totalLiabilities / totalAssets) * 100}%` }}
                 ></div>
                 <div
                   className="bg-green-500 h-3 rounded-r-full"
                   style={{ 
                     width: `${(totalEquity / totalAssets) * 100}%`,
                     marginLeft: `${(totalLiabilities / totalAssets) * 100}%`
                   }}
                 ></div>
               </div>
             </div>
             <div className="flex justify-between mt-2 text-xs">
               <span className="text-red-600">Liabilities: {((totalLiabilities / totalAssets) * 100).toFixed(1)}%</span>
               <span className="text-green-600">Equity: {((totalEquity / totalAssets) * 100).toFixed(1)}%</span>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 );
};

export default BalanceSheetReport;