// src/components/communication/MessageList.js
import React from 'react';
import { AlertCircle } from 'lucide-react';

const MessageList = ({ messages, userProfile, messagesEndRef, isLoading, getColorForInitial }) => {
  // Format timestamp
  const formatMessageTime = (timestamp) => {
    if (!timestamp) return '';
    
    try {
      const date = timestamp instanceof Date 
        ? timestamp 
        : timestamp.seconds
          ? new Date(timestamp.seconds * 1000)
          : new Date(timestamp);
      
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } catch (error) {
      console.error("Error formatting message time:", error);
      return '';
    }
  };
  
  // Render attachment
  const renderAttachment = (attachment, index) => {
    if (attachment.type.startsWith('image/')) {
      return (
        <div key={index} className="mt-1 inline-block max-w-xs">
          <img 
            src={attachment.url} 
            alt={attachment.name}
            className="max-h-64 rounded border border-gray-200"
          />
          <p className="text-xs text-gray-500 mt-1">{attachment.name}</p>
        </div>
      );
    } else {
      return (
        <div key={index} className="mt-1 bg-gray-50 border rounded-md p-3 inline-block max-w-xs">
          <div className="flex items-center">
            <div className="mr-2">
              <svg className="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-900">{attachment.name}</p>
              <p className="text-xs text-gray-500">
                {attachment.size ? `${Math.round(attachment.size/1024)} KB` : 'Unknown size'}
              </p>
            </div>
          </div>
          <a 
            href={attachment.url} 
            download={attachment.name}
            target="_blank" 
            rel="noreferrer" 
            className="mt-2 text-xs text-[#190192] hover:underline block"
          >
            Download
          </a>
        </div>
      );
    }
  };
  
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-[#190192]"></div>
      </div>
    );
  }
  
  if (messages.length === 0) {
    return (
      <div className="flex items-center justify-center h-full text-center text-gray-500">
        <div>
          <svg className="h-12 w-12 mx-auto text-gray-400 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
          </svg>
          <p className="font-medium">No messages yet</p>
          <p className="text-sm">Start the conversation by sending a message!</p>
        </div>
      </div>
    );
  }
  
  return (
    <div className="space-y-4">
      {messages.map((message, index) => (
        <div key={message.id || index} className="flex items-start group">
          <div className="flex-shrink-0 mr-3">
            <div className={`w-8 h-8 rounded-full bg-${getColorForInitial(message.senderInitials?.[0] || 'A')}-100 flex items-center justify-center text-${getColorForInitial(message.senderInitials?.[0] || 'A')}-600 font-medium`}>
              {message.senderInitials}
            </div>
          </div>
          <div className="flex-1 overflow-hidden">
            <div className="flex items-center">
              <span className="font-medium text-gray-900 mr-2">{message.senderName}</span>
              <span className="text-xs text-gray-500">
                {formatMessageTime(message.timestamp)}
              </span>
            </div>
            <div className="mt-1 text-gray-800">
              {message.content}
            </div>
            
            {/* Message attachments */}
            {message.attachments && message.attachments.length > 0 && (
              <div className="mt-2">
                {message.attachments.map((attachment, idx) => renderAttachment(attachment, idx))}
              </div>
            )}
          </div>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;