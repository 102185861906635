// src/components/finance/TransactDetail.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Edit, Download, Trash2, Calendar, 
  DollarSign, Tag, FileText, Users, Building, ArrowUpRight, 
  ArrowDownRight, Receipt, BadgePercent, BarChart2,
  CheckCircle, Clock, Repeat, AlertCircle, AlertTriangle,
  CreditCard, ExternalLink, MessageSquare, Image, Film, PlusCircle,
  X, Check, Copy
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../firebase/config';
import { 
  doc, getDoc, deleteDoc, collection, 
  query, where, getDocs, addDoc, serverTimestamp,
  updateDoc
} from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';

const TransactDetail = () => {
  const { transactionId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  // Main states
  const [transaction, setTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [relatedTransactions, setRelatedTransactions] = useState([]);
  
  // Reference data
  const [business, setBusiness] = useState(null);
  const [account, setAccount] = useState(null);
  const [counterparty, setCounterparty] = useState(null);
  
  // UI states
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentDate, setPaymentDate] = useState(new Date().toISOString().split('T')[0]);
  const [paymentMethod, setPaymentMethod] = useState('bank_transfer');
  const [paymentReference, setPaymentReference] = useState('');
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false);
  const [paymentErrors, setPaymentErrors] = useState({});
  
  // Fetch transaction data
  useEffect(() => {
    const fetchTransactionData = async () => {
      if (!currentUser || !transactionId) return;
      
      setIsLoading(true);
      
      try {
        // Get transaction document
        const transactionRef = doc(db, 'financialTransactions', transactionId);
        const transactionSnap = await getDoc(transactionRef);
        
        if (!transactionSnap.exists()) {
          console.error('Transaction not found');
          navigate('/finance');
          return;
        }
        
        // Process transaction data
        const data = transactionSnap.data();
        const processedTransaction = {
          id: transactionSnap.id,
          ...data,
          date: data.date?.toDate() || new Date(),
          dueDate: data.dueDate?.toDate() || null,
          issueDate: data.issueDate?.toDate() || null,
          createdAt: data.createdAt?.toDate() || new Date(),
          updatedAt: data.updatedAt?.toDate() || new Date()
        };
        
        setTransaction(processedTransaction);
        
        // Fetch business data
        if (data.businessId) {
          const businessRef = doc(db, 'businesses', data.businessId);
          const businessSnap = await getDoc(businessRef);
          
          if (businessSnap.exists()) {
            setBusiness(businessSnap.data());
          }
        }
        
        // Fetch account data
        if (data.accountId) {
          const accountRef = doc(db, 'accounts', data.accountId);
          const accountSnap = await getDoc(accountRef);
          
          if (accountSnap.exists()) {
            setAccount(accountSnap.data());
          }
        }
        
        // Fetch counterparty for invoices/bills
        if (data.counterpartyId && data.counterpartyType) {
          const collectionName = data.counterpartyType === 'customer' ? 'customers' : 'suppliers';
          const counterpartyRef = doc(db, collectionName, data.counterpartyId);
          const counterpartySnap = await getDoc(counterpartyRef);
          
          if (counterpartySnap.exists()) {
            setCounterparty({
              ...counterpartySnap.data(),
              id: counterpartySnap.id,
              type: data.counterpartyType
            });
          }
        }
        
        // Fetch related transactions (payments, etc.)
        await fetchRelatedTransactions(transactionSnap.id, data.type);
        
      } catch (error) {
        console.error('Error fetching transaction:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchTransactionData();
  }, [currentUser, transactionId, navigate]);
  
  // Fetch related transactions
  const fetchRelatedTransactions = async (id, type) => {
    try {
      // For invoices - fetch payments made against this invoice
      if (type === 'invoice') {
        const paymentsQuery = query(
          collection(db, 'financialTransactions'),
          where('relatedTransactions', 'array-contains', id)
        );
        
        const paymentsSnapshot = await getDocs(paymentsQuery);
        const payments = [];
        
        paymentsSnapshot.forEach(doc => {
          const data = doc.data();
          payments.push({
            id: doc.id,
            ...data,
            date: data.date?.toDate() || new Date()
          });
        });
        
        setRelatedTransactions(payments);
      }
      
      // For bills - fetch payments made against this bill
      if (type === 'bill') {
        const paymentsQuery = query(
          collection(db, 'financialTransactions'),
          where('relatedTransactions', 'array-contains', id)
        );
        
        const paymentsSnapshot = await getDocs(paymentsQuery);
        const payments = [];
        
        paymentsSnapshot.forEach(doc => {
          const data = doc.data();
          payments.push({
            id: doc.id,
            ...data,
            date: data.date?.toDate() || new Date()
          });
        });
        
        setRelatedTransactions(payments);
      }
    } catch (error) {
      console.error('Error fetching related transactions:', error);
    }
  };
  
  // Handle delete transaction
  const handleDelete = async () => {
    try {
      // Delete attachment files from storage
      if (transaction.attachments && transaction.attachments.length > 0) {
        const deletePromises = transaction.attachments.map(async (attachment) => {
          if (attachment.url) {
            try {
              const fileRef = ref(storage, attachment.url);
              await deleteObject(fileRef);
            } catch (error) {
              console.error('Error deleting file:', error);
              // Continue with other deletions even if one fails
            }
          }
        });
        
        await Promise.all(deletePromises);
      }
      
      // Delete transaction document
      await deleteDoc(doc(db, 'financialTransactions', transactionId));
      
      // Navigate back to finance list
      navigate('/finance');
    } catch (error) {
      console.error('Error deleting transaction:', error);
    }
  };
  
  // Handle recording a payment (for invoices or bills)
  const handleRecordPayment = async () => {
    // Validate payment form
    const errors = {};
    if (!paymentAmount || isNaN(parseFloat(paymentAmount)) || parseFloat(paymentAmount) <= 0) {
      errors.amount = 'Valid payment amount is required';
    } else if (parseFloat(paymentAmount) > transaction.balanceDue) {
      errors.amount = 'Payment amount cannot exceed balance due';
    }
    
    if (!paymentDate) {
      errors.date = 'Payment date is required';
    }
    
    if (Object.keys(errors).length > 0) {
      setPaymentErrors(errors);
      return;
    }
    
    setIsSubmittingPayment(true);
    
    try {
      // Create payment transaction
      const paymentData = {
        businessId: transaction.businessId,
        accountId: transaction.accountId,
        type: 'simple',
        description: `Payment for ${transaction.type === 'invoice' ? 'Invoice' : 'Bill'} ${transaction.documentNumber || transaction.id}`,
        date: new Date(paymentDate),
        amount: transaction.type === 'invoice' ? parseFloat(paymentAmount) : -parseFloat(paymentAmount),
        category: transaction.type === 'invoice' ? 'payment_received' : 'payment_sent',
        reference: paymentReference || `Payment for ${transaction.documentNumber || transaction.id}`,
        paymentMethod: paymentMethod,
        relatedTransactions: [transaction.id],
        createdBy: currentUser.uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };
      
      // Add payment to financialTransactions collection
      await addDoc(collection(db, 'financialTransactions'), paymentData);
      
      // Update original transaction's paid amount and balance due
      const updatedPaidAmount = (transaction.paidAmount || 0) + parseFloat(paymentAmount);
      const updatedBalanceDue = transaction.totalAmount - updatedPaidAmount;
      
      // Update status if fully paid
      let updatedStatus = transaction.status;
      if (updatedBalanceDue <= 0) {
        updatedStatus = 'paid';
      } else if (transaction.status === 'overdue') {
        updatedStatus = 'partially_paid';
      }
      
      await updateDoc(doc(db, 'financialTransactions', transactionId), {
        paidAmount: updatedPaidAmount,
        balanceDue: updatedBalanceDue,
        status: updatedStatus,
        updatedAt: serverTimestamp()
      });
      
      // Reload the transaction data
      const updatedTransactionSnap = await getDoc(doc(db, 'financialTransactions', transactionId));
      const updatedData = updatedTransactionSnap.data();
      
      setTransaction({
        ...updatedData,
        id: transactionId,
        date: updatedData.date?.toDate() || new Date(),
        dueDate: updatedData.dueDate?.toDate() || null,
        issueDate: updatedData.issueDate?.toDate() || null,
        createdAt: updatedData.createdAt?.toDate() || new Date(),
        updatedAt: updatedData.updatedAt?.toDate() || new Date()
      });
      
      // Reload related transactions
      await fetchRelatedTransactions(transactionId, transaction.type);
      
      // Close modal and reset form
      setPaymentModalOpen(false);
      setPaymentAmount('');
      setPaymentDate(new Date().toISOString().split('T')[0]);
      setPaymentMethod('bank_transfer');
      setPaymentReference('');
      setPaymentErrors({});
    } catch (error) {
      console.error('Error recording payment:', error);
      setPaymentErrors({ submit: 'Failed to record payment. Please try again.' });
    } finally {
      setIsSubmittingPayment(false);
    }
  };
  
  // Download transaction as PDF
  const downloadTransactionPDF = () => {
    // This would normally generate a PDF for download
    // For now, just display an alert
    alert('PDF download functionality would be implemented here');
  };
  
  // Format date
  const formatDate = (date) => {
    if (!date) return '';
    
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Format currency
  const formatCurrency = (amount) => {
    if (amount === undefined || amount === null) return '';
    
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(amount);
  };
  
  // Get transaction type icon
  const getTransactionIcon = () => {
    if (!transaction) return DollarSign;
    
    switch (transaction.type) {
      case 'simple':
        return transaction.amount >= 0 ? ArrowUpRight : ArrowDownRight;
      case 'invoice':
        return Receipt;
      case 'bill':
        return BadgePercent;
      case 'budget':
        return BarChart2;
      default:
        return DollarSign;
    }
  };
  
  // Get status style
  const getStatusStyle = (status) => {
    switch (status) {
      case 'draft':
        return 'bg-gray-100 text-gray-800';
      case 'sent':
      case 'pending':
        return 'bg-blue-100 text-blue-800';
      case 'paid':
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'partially_paid':
        return 'bg-amber-100 text-amber-800';
      case 'overdue':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  };
  
  // Check if invoice/bill is overdue
  const isOverdue = () => {
    if (!transaction || !transaction.dueDate || 
        transaction.status === 'paid' || transaction.status === 'completed') {
      return false;
    }
    
    const dueDate = new Date(transaction.dueDate);
    const today = new Date();
    
    return dueDate < today && transaction.balanceDue > 0;
  };
  
  // Get counterparty icon
  const getCounterpartyIcon = () => {
    if (!transaction || !transaction.counterpartyType) return null;
    
    return transaction.counterpartyType === 'customer' ? Users : Building;
  };
  
  if (isLoading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }
  
  if (!transaction) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <AlertCircle size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Transaction Not Found</h3>
          <p className="text-gray-500 mb-6">The transaction you're looking for doesn't exist or has been deleted.</p>
          <Link
            to="/finance"
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Finance
          </Link>
        </div>
      </div>
    );
  }
  
  // Set up the main icon for this transaction
  const TransactionIcon = getTransactionIcon();
  const CounterpartyIcon = getCounterpartyIcon();

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/finance"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Finance
          </Link>
          
          <div className="flex space-x-3">
            <button 
              onClick={downloadTransactionPDF}
              className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg"
              title="Download PDF"
            >
              <Download size={20} />
            </button>
            <Link
              to={`/finance/edit/${transaction.id}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Transaction Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className={`w-16 h-16 rounded-lg flex items-center justify-center flex-shrink-0 ${
              transaction.type === 'simple' && transaction.amount >= 0 ? 'bg-green-100 text-green-600' :
              transaction.type === 'simple' ? 'bg-red-100 text-red-600' :
              transaction.type === 'invoice' ? 'bg-blue-100 text-blue-600' :
              transaction.type === 'bill' ? 'bg-amber-100 text-amber-600' :
              'bg-purple-100 text-purple-600'
            }`}>
              <TransactionIcon size={32} />
            </div>
            
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-3 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">
                  {transaction.type === 'simple' ? 'Transaction' : 
                   transaction.type === 'invoice' ? 'Invoice' : 
                   transaction.type === 'bill' ? 'Bill' : 'Budget Entry'}
                </h1>
                
                {(transaction.documentNumber || transaction.reference) && (
                  <span className="text-gray-500">
                    #{transaction.documentNumber || transaction.reference}
                  </span>
                )}
                
                {transaction.status && (
                  <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusStyle(transaction.status)}`}>
                    {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}
                  </span>
                )}
                
                {transaction.isRecurring && (
                  <span className="px-3 py-1 rounded-full text-sm font-medium bg-purple-100 text-purple-800">
                    <Repeat size={14} className="inline mr-1" />
                    Recurring
                  </span>
                )}
              </div>
              
              <div className="flex flex-wrap gap-4 mt-2 text-sm text-gray-500">
                <div className="flex items-center">
                  <Calendar size={16} className="mr-1 text-gray-400" />
                  {formatDate(transaction.date)}
                </div>
                
                {business && (
                  <div className="flex items-center">
                    <Building size={16} className="mr-1 text-gray-400" />
                    {business.name}
                  </div>
                )}
                
                {account && (
                  <div className="flex items-center">
                    <CreditCard size={16} className="mr-1 text-gray-400" />
                    {account.accountName}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        
        <div className="p-6">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Left Column: Transaction Details */}
            <div className="lg:col-span-2 space-y-8">
              {/* Main Transaction Information */}
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  {transaction.type === 'simple' ? 'Transaction Details' :
                   transaction.type === 'invoice' ? 'Invoice Details' :
                   transaction.type === 'bill' ? 'Bill Details' :
                   'Budget Details'}
                </h3>
                
                {/* Simple transaction details */}
                {transaction.type === 'simple' && (
                  <div className="bg-gray-50 rounded-lg p-6">
                    <div className="flex justify-between items-center mb-4">
                      <div>
                        <p className="text-gray-500 text-sm">Description</p>
                        <p className="text-gray-900 font-medium">{transaction.description}</p>
                      </div>
                      <div className="text-right">
                        <p className="text-gray-500 text-sm">Amount</p>
                        <p className={`text-xl font-bold ${transaction.amount >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                          {formatCurrency(transaction.amount)}
                        </p>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                      {transaction.category && (
                        <div>
                          <p className="text-gray-500 text-sm">Category</p>
                          <div className="flex items-center mt-1">
                            <Tag size={16} className="text-gray-500 mr-1" />
                            <p className="text-gray-900">{transaction.category}</p>
                          </div>
                        </div>
                      )}
                      
                      {transaction.paymentMethod && (
                        <div>
                          <p className="text-gray-500 text-sm">Payment Method</p>
                          <p className="text-gray-900 mt-1 capitalize">
                            {transaction.paymentMethod.replace('_', ' ')}
                          </p>
                        </div>
                      )}
                      
                      {transaction.reference && (
                        <div>
                          <p className="text-gray-500 text-sm">Reference</p>
                          <p className="text-gray-900 mt-1">{transaction.reference}</p>
                        </div>
                      )}
                    </div>
                    
                    {transaction.notes && (
                      <div className="mt-6 pt-6 border-t border-gray-200">
                        <p className="text-gray-500 text-sm">Notes</p>
                        <p className="text-gray-700 mt-1 whitespace-pre-line">{transaction.notes}</p>
                      </div>
                    )}
                  </div>
                )}
                
                {/* Invoice or Bill details */}
                {(transaction.type === 'invoice' || transaction.type === 'bill') && (
                  <div>
                    {/* Counterparty information */}
                    {counterparty && (
                      <div className="bg-gray-50 rounded-lg p-4 mb-6">
                        <div className="flex items-start">
                          <div className={`w-10 h-10 rounded-lg flex items-center justify-center ${
                            transaction.type === 'invoice' ? 'bg-blue-100 text-blue-600' : 'bg-amber-100 text-amber-600'
                          } mr-3`}>
                            {CounterpartyIcon && <CounterpartyIcon size={20} />}
                          </div>
                          <div>
                            <h4 className="font-medium text-gray-900">
                              {transaction.type === 'invoice' ? 'Customer' : 'Supplier'}
                            </h4>
                            <p className="text-gray-700">
                              {counterparty.name || counterparty.businessName || 
                               `${counterparty.firstName || ''} ${counterparty.lastName || ''}`.trim()}
                            </p>
                            {counterparty.email && (
                              <p className="text-gray-500 text-sm">{counterparty.email}</p>
                            )}
                            {counterparty.phone && (
                              <p className="text-gray-500 text-sm">{counterparty.phone}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    
                    {/* Due date and status */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                      <div className="bg-gray-50 rounded-lg p-4">
                        <p className="text-gray-500 text-sm">Issue Date</p>
                        <p className="text-gray-900 font-medium">{formatDate(transaction.issueDate)}</p>
                      </div>
                      
                      <div className={`rounded-lg p-4 ${
                        isOverdue() ? 'bg-red-50' : 'bg-gray-50'
                      }`}>
                        <p className={`text-sm ${
                          isOverdue() ? 'text-red-500' : 'text-gray-500'
                        }`}>Due Date</p>
                        <div className="flex items-center">
                          <p className={`font-medium ${
                            isOverdue() ? 'text-red-600' : 'text-gray-900'
                          }`}>
                            {formatDate(transaction.dueDate)}
                          </p>
                          {isOverdue() && (
                            <AlertTriangle size={16} className="ml-2 text-red-500" />
                          )}
                        </div>
                      </div>
                    </div>
                    
                    {/* Line items */}
                    {transaction.items && transaction.items.length > 0 && (
                      <div className="border rounded-lg overflow-hidden mb-6">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Description
                              </th>
                              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Quantity
                              </th>
                              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Price
                              </th>
                              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Tax
                              </th>
                              <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {transaction.items.map((item, index) => (
                              <tr key={item.id || index}>
                                <td className="px-6 py-4 whitespace-normal text-sm text-gray-900">
                                  {item.description}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                                  {item.quantity}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                                  {formatCurrency(item.price)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                                  {formatCurrency(item.taxAmount)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
                                  {formatCurrency(item.amount)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot className="bg-gray-50">
                            <tr>
                              <td colSpan="4" className="px-6 py-3 text-right text-sm font-medium text-gray-700">
                                Subtotal
                              </td>
                              <td className="px-6 py-3 text-right text-sm font-medium text-gray-900">
                                {formatCurrency(transaction.subtotal)}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="4" className="px-6 py-3 text-right text-sm font-medium text-gray-700">
                                Tax
                              </td>
                              <td className="px-6 py-3 text-right text-sm font-medium text-gray-900">
                                {formatCurrency(transaction.taxAmount)}
                              </td>
                            </tr>
                            <tr className="bg-gray-100">
                              <td colSpan="4" className="px-6 py-3 text-right text-sm font-bold text-gray-800">
                                Total
                              </td>
                              <td className="px-6 py-3 text-right text-sm font-bold text-gray-900">
                                {formatCurrency(transaction.totalAmount)}
                              </td>
                            </tr>
                            {(transaction.paidAmount > 0 || transaction.balanceDue > 0) && (
                              <>
                                <tr>
                                  <td colSpan="4" className="px-6 py-3 text-right text-sm font-medium text-gray-700">
                                    Paid
                                  </td>
                                  <td className="px-6 py-3 text-right text-sm font-medium text-green-600">
                                    {formatCurrency(transaction.paidAmount)}
                                  </td>
                                </tr>
                                <tr className="bg-gray-100">
                                  <td colSpan="4" className="px-6 py-3 text-right text-sm font-bold text-gray-800">
                                    Balance Due
                                  </td>
                                  <td className="px-6 py-3 text-right text-sm font-bold text-gray-900">
                                    {formatCurrency(transaction.balanceDue)}
                                  </td>
                                </tr>
                              </>
                            )}
                          </tfoot>
                        </table>
                      </div>
                    )}
                    
                    {/* Payment section for invoice/bill */}
                   {(transaction.type === 'invoice' || transaction.type === 'bill') && 
                     transaction.balanceDue > 0 && (
                     <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                       <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
                         <div>
                           <h4 className="font-medium text-gray-900">Balance Due</h4>
                           <p className="text-gray-700 mt-1">{formatCurrency(transaction.balanceDue)}</p>
                         </div>
                         
                         <button
                           onClick={() => {
                             setPaymentAmount(transaction.balanceDue.toString());
                             setPaymentModalOpen(true);
                           }}
                           className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                         >
                           <DollarSign size={16} className="mr-2" />
                           {transaction.type === 'invoice' ? 'Record Payment' : 'Pay Bill'}
                         </button>
                       </div>
                     </div>
                   )}
                   
                   {/* Notes */}
                   {transaction.notes && (
                     <div className="mt-6 pt-4 border-t border-gray-200">
                       <h4 className="font-medium text-gray-900 mb-2">Notes</h4>
                       <p className="text-gray-700 whitespace-pre-line">{transaction.notes}</p>
                     </div>
                   )}
                 </div>
               )}
               
               {/* Budget entry details */}
               {transaction.type === 'budget' && (
                 <div className="bg-gray-50 rounded-lg p-6">
                   <div className="flex justify-between items-center mb-6">
                     <div>
                       <p className="text-gray-500 text-sm">Description</p>
                       <p className="text-gray-900 font-medium">{transaction.description}</p>
                     </div>
                     <div className="text-right">
                       <p className="text-gray-500 text-sm">Planned Amount</p>
                       <p className="text-xl font-bold text-purple-600">
                         {formatCurrency(transaction.plannedAmount)}
                       </p>
                     </div>
                   </div>
                   
                   <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
                     <div>
                       <p className="text-gray-500 text-sm">Fiscal Period</p>
                       <p className="text-gray-900 mt-1">{transaction.fiscalPeriod}</p>
                     </div>
                     
                     {transaction.category && (
                       <div>
                         <p className="text-gray-500 text-sm">Budget Category</p>
                         <div className="flex items-center mt-1">
                           <Tag size={16} className="text-gray-500 mr-1" />
                           <p className="text-gray-900">{transaction.category}</p>
                         </div>
                       </div>
                     )}
                   </div>
                   
                   {transaction.notes && (
                     <div className="mt-6 pt-6 border-t border-gray-200">
                       <p className="text-gray-500 text-sm">Notes</p>
                       <p className="text-gray-700 mt-1 whitespace-pre-line">{transaction.notes}</p>
                     </div>
                   )}
                 </div>
               )}
             </div>
             
             {/* Related Transactions */}
             {(transaction.type === 'invoice' || transaction.type === 'bill') && (
               <div>
                 <h3 className="text-lg font-medium text-gray-900 mb-4">
                   {transaction.type === 'invoice' ? 'Payment History' : 'Payment History'}
                 </h3>
                 
                 {relatedTransactions.length > 0 ? (
                   <div className="border rounded-lg overflow-hidden">
                     <table className="min-w-full divide-y divide-gray-200">
                       <thead className="bg-gray-50">
                         <tr>
                           <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Date
                           </th>
                           <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Reference
                           </th>
                           <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Method
                           </th>
                           <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Amount
                           </th>
                           <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                             Actions
                           </th>
                         </tr>
                       </thead>
                       <tbody className="bg-white divide-y divide-gray-200">
                         {relatedTransactions.map((payment) => (
                           <tr key={payment.id}>
                             <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                               {formatDate(payment.date)}
                             </td>
                             <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                               {payment.reference || '-'}
                             </td>
                             <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">
                               {payment.paymentMethod?.replace('_', ' ') || '-'}
                             </td>
                             <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-right text-green-600">
                               {formatCurrency(Math.abs(payment.amount))}
                             </td>
                             <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                               <Link
                                 to={`/finance/${payment.id}`}
                                 className="text-[#190192] hover:text-[#2C0DB5]"
                               >
                                 View
                               </Link>
                             </td>
                           </tr>
                         ))}
                       </tbody>
                     </table>
                   </div>
                 ) : (
                   <div className="bg-gray-50 rounded-lg p-6 text-center">
                     <p className="text-gray-500">No payment records found.</p>
                   </div>
                 )}
               </div>
             )}
             
             {/* Recurring Transaction Details */}
             {transaction.isRecurring && (
               <div>
                 <h3 className="text-lg font-medium text-gray-900 mb-4">Recurrence Information</h3>
                 
                 <div className="bg-purple-50 rounded-lg p-6 border border-purple-100">
                   <div className="flex items-start">
                     <div className="w-10 h-10 rounded-lg bg-purple-100 flex items-center justify-center text-purple-600 mr-4">
                       <Repeat size={20} />
                     </div>
                     <div>
                       <h4 className="font-medium text-gray-900">Recurring Transaction</h4>
                       
                       <p className="text-gray-700 mt-2">
                         This transaction repeats every 
                         <span className="font-medium"> {transaction.recurrencePattern?.interval || 1} </span>
                         {transaction.recurrencePattern?.frequency === 'daily' && 'day(s)'}
                         {transaction.recurrencePattern?.frequency === 'weekly' && 'week(s)'}
                         {transaction.recurrencePattern?.frequency === 'monthly' && 'month(s)'}
                         {transaction.recurrencePattern?.frequency === 'yearly' && 'year(s)'}
                       </p>
                       
                       {transaction.recurrencePattern?.endDate && (
                         <p className="text-gray-700 mt-1">
                           Ends on: <span className="font-medium">{formatDate(transaction.recurrencePattern.endDate)}</span>
                         </p>
                       )}
                       
                       {transaction.recurrencePattern?.endAfterOccurrences && (
                         <p className="text-gray-700 mt-1">
                           Ends after: <span className="font-medium">{transaction.recurrencePattern.endAfterOccurrences} occurrences</span>
                         </p>
                       )}
                     </div>
                   </div>
                 </div>
               </div>
             )}
             
             {/* Attachments */}
             {transaction.attachments && transaction.attachments.length > 0 && (
               <div>
                 <h3 className="text-lg font-medium text-gray-900 mb-4">Attachments</h3>
                 
                 <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                   {transaction.attachments.map((attachment, index) => {
                     const isImage = attachment.type === 'image';
                     const MediaIcon = isImage ? Image : FileText;
                     
                     return (
                       <div key={attachment.id || index} className="border rounded-lg overflow-hidden">
                         <div className="p-3 border-b bg-gray-50 flex justify-between items-center">
                           <span className="text-sm font-medium truncate">{attachment.name}</span>
                         </div>
                         
                         <div 
                           className="h-40 bg-gray-100 flex items-center justify-center cursor-pointer"
                           onClick={() => window.open(attachment.url, '_blank')}
                         >
                           {isImage ? (
                             <img 
                               src={attachment.url} 
                               alt={attachment.name}
                               className="h-full w-full object-contain"
                             />
                           ) : (
                             <div className="text-center">
                               <MediaIcon size={32} className="mx-auto text-gray-400 mb-2" />
                               <span className="text-sm text-gray-500">
                                 {attachment.type === 'document' ? 'Document' : 'File'}
                               </span>
                             </div>
                           )}
                         </div>
                         
                         <div className="p-2 bg-white text-center">
                           <a 
                             href={attachment.url}
                             target="_blank"
                             rel="noopener noreferrer"
                             className="text-xs text-[#190192] hover:text-[#2C0DB5] flex items-center justify-center"
                           >
                             View <ExternalLink size={12} className="ml-1" />
                           </a>
                         </div>
                       </div>
                     );
                   })}
                 </div>
               </div>
             )}
           </div>
           
           {/* Right Column: Sidebar */}
           <div className="lg:col-span-1">
             {/* Summary Box */}
             <div className="bg-gray-50 rounded-xl p-5 mb-6">
               <h3 className="text-lg font-medium text-gray-900 mb-3">Summary</h3>
               
               <div className="space-y-4">
                 {/* For all transaction types */}
                 <div className="flex justify-between">
                   <span className="text-sm text-gray-500">Transaction Type</span>
                   <span className="text-sm font-medium text-gray-900 capitalize">
                     {transaction.type}
                   </span>
                 </div>
                 
                 {/* For invoice/bill */}
                 {(transaction.type === 'invoice' || transaction.type === 'bill') && (
                   <>
                     <div className="flex justify-between">
                       <span className="text-sm text-gray-500">
                         {transaction.type === 'invoice' ? 'Customer' : 'Supplier'}
                       </span>
                       <span className="text-sm font-medium text-gray-900">
                         {counterparty ? 
                           (counterparty.name || 
                            counterparty.businessName || 
                            `${counterparty.firstName || ''} ${counterparty.lastName || ''}`.trim()) : 
                           'Unknown'}
                       </span>
                     </div>
                     
                     <div className="flex justify-between">
                       <span className="text-sm text-gray-500">Total Amount</span>
                       <span className="text-sm font-medium text-gray-900">
                         {formatCurrency(transaction.totalAmount)}
                       </span>
                     </div>
                     
                     <div className="flex justify-between">
                       <span className="text-sm text-gray-500">Paid Amount</span>
                       <span className="text-sm font-medium text-green-600">
                         {formatCurrency(transaction.paidAmount || 0)}
                       </span>
                     </div>
                     
                     <div className="flex justify-between border-t border-gray-200 pt-2 mt-2">
                       <span className="text-sm font-medium text-gray-700">Balance Due</span>
                       <span className="text-sm font-medium text-gray-900">
                         {formatCurrency(transaction.balanceDue || 0)}
                       </span>
                     </div>
                   </>
                 )}
                 
                 {/* For simple transaction */}
                 {transaction.type === 'simple' && (
                   <div className="flex justify-between">
                     <span className="text-sm text-gray-500">Amount</span>
                     <span className={`text-sm font-medium ${transaction.amount >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                       {formatCurrency(transaction.amount)}
                     </span>
                   </div>
                 )}
                 
                 {/* For budget entry */}
                 {transaction.type === 'budget' && (
                   <>
                     <div className="flex justify-between">
                       <span className="text-sm text-gray-500">Fiscal Period</span>
                       <span className="text-sm font-medium text-gray-900">
                         {transaction.fiscalPeriod}
                       </span>
                     </div>
                     
                     <div className="flex justify-between">
                       <span className="text-sm text-gray-500">Planned Amount</span>
                       <span className="text-sm font-medium text-purple-600">
                         {formatCurrency(transaction.plannedAmount)}
                       </span>
                     </div>
                   </>
                 )}
               </div>
             </div>
             
             {/* Related Information */}
             <div className="bg-gray-50 rounded-xl p-5 mb-6">
               <h3 className="text-lg font-medium text-gray-900 mb-3">Information</h3>
               
               <div className="space-y-3">
                 <div className="flex justify-between">
                   <span className="text-sm text-gray-500">Created</span>
                   <span className="text-sm text-gray-900">{formatDate(transaction.createdAt)}</span>
                 </div>
                 
                 <div className="flex justify-between">
                   <span className="text-sm text-gray-500">Last Updated</span>
                   <span className="text-sm text-gray-900">{formatDate(transaction.updatedAt)}</span>
                 </div>
                 
                 <div className="flex justify-between">
                   <span className="text-sm text-gray-500">Transaction ID</span>
                   <span className="text-sm text-gray-900">{transaction.id.substring(0, 8)}</span>
                 </div>
               </div>
             </div>
             
             {/* Quick Actions */}
             <div className="bg-gray-50 rounded-xl p-5">
               <h3 className="text-lg font-medium text-gray-900 mb-3">Quick Actions</h3>
               
               <div className="space-y-2">
                 {/* Actions for invoice */}
                 {transaction.type === 'invoice' && (
                   <>
                     <button
                       onClick={() => alert('This would email the invoice to the customer')}
                       className="w-full text-left px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-100 flex items-center"
                     >
                       <MessageSquare size={16} className="mr-2 text-gray-500" />
                       Email Invoice
                     </button>
                     
                     {transaction.balanceDue > 0 && (
                       <button
                         onClick={() => {
                           setPaymentAmount(transaction.balanceDue.toString());
                           setPaymentModalOpen(true);
                         }}
                         className="w-full text-left px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-100 flex items-center"
                       >
                         <DollarSign size={16} className="mr-2 text-gray-500" />
                         Record Payment
                       </button>
                     )}
                     
                     <button
                       onClick={() => alert('This would mark the invoice as sent')}
                       className="w-full text-left px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-100 flex items-center"
                     >
                       <CheckCircle size={16} className="mr-2 text-gray-500" />
                       Mark as Sent
                     </button>
                   </>
                 )}
                 
                 {/* Actions for bill */}
                 {transaction.type === 'bill' && transaction.balanceDue > 0 && (
                   <button
                     onClick={() => {
                       setPaymentAmount(transaction.balanceDue.toString());
                       setPaymentModalOpen(true);
                     }}
                     className="w-full text-left px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-100 flex items-center"
                   >
                     <DollarSign size={16} className="mr-2 text-gray-500" />
                     Pay Bill
                   </button>
                 )}
                 
                 {/* Common actions */}
                 <Link
                   to={`/finance/edit/${transaction.id}`}
                   className="w-full text-left px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-100 flex items-center"
                 >
                   <Edit size={16} className="mr-2 text-gray-500" />
                   Edit Transaction
                 </Link>
                 
                 <button
                   onClick={() => setDeleteModalOpen(true)}
                   className="w-full text-left px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-100 flex items-center text-red-600"
                 >
                   <Trash2 size={16} className="mr-2" />
                   Delete Transaction
                 </button>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
     
     {/* Delete Confirmation Modal */}
     {deleteModalOpen && (
       <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
         <div className="bg-white rounded-lg max-w-md w-full p-6">
           <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Transaction</h3>
           <p className="text-gray-600 mb-6">
             Are you sure you want to delete this transaction? This action cannot be undone.
             {transaction.attachments && transaction.attachments.length > 0 && (
               <span className="block mt-2 text-red-600">
                 This will also permanently delete all attached files.
               </span>
             )}
           </p>
           <div className="flex justify-end space-x-3">
             <button
               onClick={() => setDeleteModalOpen(false)}
               className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
             >
               Cancel
             </button>
             <button
               onClick={handleDelete}
               className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
             >
               Delete
             </button>
           </div>
         </div>
       </div>
     )}
     
     {/* Payment Modal */}
     {paymentModalOpen && (
       <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
         <div className="bg-white rounded-lg max-w-md w-full p-6">
           <div className="flex justify-between items-center mb-4">
             <h3 className="text-xl font-medium text-gray-900">
               {transaction.type === 'invoice' ? 'Record Payment' : 'Pay Bill'}
             </h3>
             <button 
               onClick={() => setPaymentModalOpen(false)}
               className="text-gray-400 hover:text-gray-500"
             >
               <X size={20} />
             </button>
           </div>
           
           <div className="space-y-4">
             {/* Amount */}
             <div>
               <label htmlFor="paymentAmount" className="block text-sm font-medium text-gray-700 mb-1">
                 Payment Amount <span className="text-red-500">*</span>
               </label>
               <div className="relative">
                 <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                   <DollarSign size={16} className="text-gray-400" />
                 </div>
                 <input
                   id="paymentAmount"
                   type="text"
                   className={`pl-10 block w-full rounded-lg border ${
                     paymentErrors.amount ? 'border-red-300' : 'border-gray-300'
                   } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                   placeholder="0.00"
                   value={paymentAmount}
                   onChange={(e) => setPaymentAmount(e.target.value)}
                 />
               </div>
               {paymentErrors.amount && (
                 <p className="mt-1 text-red-600 text-xs flex items-center">
                   <AlertCircle size={12} className="mr-1" /> {paymentErrors.amount}
                 </p>
               )}
               
               <div className="mt-2 flex justify-between text-sm">
                 <span className="text-gray-500">Balance Due: {formatCurrency(transaction.balanceDue)}</span>
                 <button
                   type="button"
                   className="text-[#190192] hover:text-[#2C0DB5]"
                   onClick={() => setPaymentAmount(transaction.balanceDue.toString())}
                 >
                   Pay Full Amount
                 </button>
               </div>
             </div>
             
             {/* Date */}
             <div>
               <label htmlFor="paymentDate" className="block text-sm font-medium text-gray-700 mb-1">
                 Payment Date <span className="text-red-500">*</span>
               </label>
               <div className="relative">
                 <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                   <Calendar size={16} className="text-gray-400" />
                 </div>
                 <input
                   id="paymentDate"
                   type="date"
                   className={`pl-10 block w-full rounded-lg border ${
                     paymentErrors.date ? 'border-red-300' : 'border-gray-300'
                   } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                   value={paymentDate}
                   onChange={(e) => setPaymentDate(e.target.value)}
                 />
               </div>
               {paymentErrors.date && (
                 <p className="mt-1 text-red-600 text-xs flex items-center">
                   <AlertCircle size={12} className="mr-1" /> {paymentErrors.date}
                 </p>
               )}
             </div>
             
             {/* Payment Method */}
             <div>
               <label htmlFor="paymentMethod" className="block text-sm font-medium text-gray-700 mb-1">
                 Payment Method
               </label>
               <select
                 id="paymentMethod"
                 className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                 value={paymentMethod}
                 onChange={(e) => setPaymentMethod(e.target.value)}
               >
                 <option value="bank_transfer">Bank Transfer</option>
                 <option value="cash">Cash</option>
                 <option value="credit_card">Credit Card</option>
                 <option value="check">Check</option>
                 <option value="other">Other</option>
               </select>
             </div>
             
             {/* Reference */}
             <div>
               <label htmlFor="paymentReference" className="block text-sm font-medium text-gray-700 mb-1">
                 Reference (Optional)
               </label>
               <input
                 id="paymentReference"
                 type="text"
                 className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                 placeholder="e.g., Payment confirmation #"
                 value={paymentReference}
                 onChange={(e) => setPaymentReference(e.target.value)}
               />
             </div>
             
             {paymentErrors.submit && (
               <div className="p-3 bg-red-50 rounded-lg">
                 <p className="text-red-600 text-sm flex items-center">
                   <AlertCircle size={14} className="mr-2" /> {paymentErrors.submit}
                 </p>
               </div>
             )}
           </div>
           
           <div className="mt-6 flex justify-end space-x-3">
             <button
               onClick={() => setPaymentModalOpen(false)}
               className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
             >
               Cancel
             </button>
             <button
               onClick={handleRecordPayment}
               disabled={isSubmittingPayment}
               className={`px-4 py-2 bg-[#190192] text-white rounded-lg ${
                 isSubmittingPayment ? 'opacity-75 cursor-not-allowed' : 'hover:bg-[#2C0DB5]'
               } font-medium flex items-center`}
             >
               {isSubmittingPayment ? (
                 <>
                   <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                     <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                     <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                   </svg>
                   Processing...
                 </>
               ) : (
                 <>
                   <Check size={16} className="mr-2" />
                   {transaction.type === 'invoice' ? 'Record Payment' : 'Pay Bill'}
                 </>
               )}
             </button>
           </div>
         </div>
       </div>
     )}
   </>
 );
};

export default TransactDetail;