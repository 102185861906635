// src/components/finance/AccountForm.jsx
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  ChevronLeft, Check, AlertCircle, Building, 
  DollarSign, Euro, CreditCard, Briefcase, FileText,
  Wallet, Landmark, TrendingUp
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { AppContext } from '../../context/AppContext';
import { db } from '../../firebase/config';
import { 
  collection, addDoc, updateDoc, doc, getDoc, 
  serverTimestamp, query, where, getDocs 
} from 'firebase/firestore';
import { useCurrency, currencyOptions } from '../../utils/currencyUtils';

// Account types
const ACCOUNT_TYPES = [
  { id: 'bank', label: 'Bank Account', icon: Building, description: 'Checking or savings account at a financial institution' },
  { id: 'cash', label: 'Cash', icon: Wallet, description: 'Physical cash or petty cash fund' },
  { id: 'credit_card', label: 'Credit Card', icon: CreditCard, description: 'Credit card account for business expenses' },
  { id: 'investment', label: 'Investment', icon: TrendingUp, description: 'Investment or securities account' },
  { id: 'loan', label: 'Loan', icon: FileText, description: 'Business loan or line of credit' },
  { id: 'other', label: 'Other', icon: Briefcase, description: 'Other financial account type' }
];

const AccountForm = ({ isEditing = false }) => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const { currentUser, isDemoMode } = useAuth();
  const { activeBusiness } = useContext(AppContext);
  
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [businesses, setBusinesses] = useState([]);
  const { getCurrencyCode } = useCurrency();

  // Form data
  const [formData, setFormData] = useState({
    businessId: activeBusiness?.id || '',
    accountType: 'bank',
    accountName: '',
    accountNumber: '',
    routingNumber: '',
    institution: '',
    currency: activeBusiness?.currency || 'ZAR',
    openingBalance: '0.00',
    currentBalance: '0.00',
    description: '',
    isActive: true,
    openingDate: new Date().toISOString().split('T')[0]
  });

  const RandIcon = ({ size = 16, className = "" }) => (
    <div style={{ width: size, height: size }} className={`flex items-center justify-center ${className}`}>
      <span className="font-medium" style={{ fontSize: size * 0.75 }}>R</span>
    </div>
  );

  // In your getCurrencyIcon function:
const getCurrencyIcon = () => {
    const currencyCode = getCurrencyCode();
    switch(currencyCode) {
      case 'USD':
        return <DollarSign size={16} className="text-gray-400" />;
      case 'EUR':
        return <Euro size={16} className="text-gray-400" />;
      case 'ZAR':
        return <RandIcon size={16} className="text-gray-400" />;
      // Add cases for other currencies
      default:
        return <RandIcon size={16} className="text-gray-400" />;
    }
  };

  // Fetch businesses on component mount
  useEffect(() => {
    const fetchBusinesses = async () => {
      if (!currentUser) return;
      
      try {
        const businessQuery = query(
          collection(db, 'businesses'),
          where('createdBy', '==', currentUser.uid)
        );
        
        const querySnapshot = await getDocs(businessQuery);
        const businessesData = [];
        
        querySnapshot.forEach((doc) => {
          businessesData.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setBusinesses(businessesData);
        
        // Set default business if available and not already set
        if (businessesData.length > 0 && !formData.businessId) {
          setFormData(prev => ({
            ...prev,
            businessId: activeBusiness?.id || businessesData[0].id,
            currency: activeBusiness?.currency || 'USD'
          }));
        }
      } catch (error) {
        console.error('Error fetching businesses:', error);
      }
    };
    
    fetchBusinesses();
  }, [currentUser, activeBusiness]);

  // Fetch account data if editing
  useEffect(() => {
    const fetchAccountData = async () => {
      if (!isEditing || !accountId) return;
      
      setIsLoading(true);
      
      try {
        const accountDoc = await getDoc(doc(db, 'accounts', accountId));
        
        if (accountDoc.exists()) {
          const data = accountDoc.data();
          
          // Format dates and numeric values
          const formattedData = {
            ...data,
            openingDate: data.openingDate?.toDate().toISOString().split('T')[0] || new Date().toISOString().split('T')[0],
            openingBalance: data.openingBalance?.toString() || '0.00',
            currentBalance: data.currentBalance?.toString() || '0.00'
          };
          
          setFormData(formattedData);
        } else {
          navigate('/finance/accounts');
        }
      } catch (error) {
        console.error('Error fetching account:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchAccountData();
  }, [isEditing, accountId, navigate]);

  // Handle business change to update currency
  useEffect(() => {
    if (!isEditing && formData.businessId) {
      const selectedBusiness = businesses.find(b => b.id === formData.businessId);
      if (selectedBusiness?.currency) {
        setFormData(prev => ({
          ...prev,
          currency: selectedBusiness.currency
        }));
      }
    }
  }, [formData.businessId, businesses, isEditing]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear errors for this field
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  // Handle account type change
  const handleAccountTypeChange = (type) => {
    setFormData(prev => ({
      ...prev,
      accountType: type
    }));
  };

  // Form validation
  const validateForm = () => {
    const errors = {};
    
    // Required fields
    if (!formData.businessId) errors.businessId = 'Business is required';
    if (!formData.accountName.trim()) errors.accountName = 'Account name is required';
    if (!formData.openingDate) errors.openingDate = 'Opening date is required';
    
    // Validate account number for specific account types
    if (formData.accountType === 'bank' && !formData.accountNumber.trim()) {
      errors.accountNumber = 'Account number is required for bank accounts';
    }
    
    if (formData.accountType === 'credit_card' && !formData.accountNumber.trim()) {
      errors.accountNumber = 'Card number is required for credit cards';
    }
    
    // Validate numeric fields
    if (isNaN(parseFloat(formData.openingBalance))) {
      errors.openingBalance = 'Opening balance must be a valid number';
    }
    
    return errors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isDemoMode) {
      alert("Demo Mode Restriction:\nIn demo mode, data modifications are not saved. Sign up for a free account to use all features.");
      return;
    }
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      
      // Scroll to first error
      const firstErrorElement = document.querySelector('.text-red-600');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      return;
    }
    
    setIsLoading(true);
    
    try {
      // Prepare account data
      const accountData = {
        businessId: formData.businessId,
        accountType: formData.accountType,
        accountName: formData.accountName,
        accountNumber: formData.accountNumber,
        routingNumber: formData.routingNumber,
        institution: formData.institution,
        currency: formData.currency,
        openingBalance: parseFloat(formData.openingBalance),
        currentBalance: isEditing ? parseFloat(formData.currentBalance) : parseFloat(formData.openingBalance),
        openingDate: new Date(formData.openingDate),
        description: formData.description,
        isActive: formData.isActive,
        updatedAt: serverTimestamp()
      };
      
      // Save to Firestore
      if (isEditing) {
        await updateDoc(doc(db, 'accounts', accountId), accountData);
      } else {
        accountData.createdAt = serverTimestamp();
        accountData.createdBy = currentUser.uid;
        
        await addDoc(collection(db, 'accounts'), accountData);
      }
      
      // Navigate back to accounts list
      navigate('/finance/accounts');
      
    } catch (error) {
      console.error('Error saving account:', error);
      setFormErrors(prev => ({
        ...prev,
        submit: 'An error occurred. Please try again.'
      }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div className="bg-white shadow-md px-6 py-4 text-gray-800">
        <h2 className="text-lg font-medium">
          {isEditing ? 'Edit Account' : 'Add New Account'}
        </h2>
        <p className="text-purple-500 text-sm">
          {isEditing 
            ? 'Update your financial account details below' 
            : 'Set up a new financial account to track transactions'}
        </p>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        <div className="mb-6">
          <button 
            type="button"
            onClick={() => navigate('/finance/accounts')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Accounts
          </button>
        </div>
        
        {/* Basic Information */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
          
          {/* Business Selection */}
          <div className="mb-6">
            <label htmlFor="businessId" className="block text-sm font-medium text-gray-700 mb-1">
              Business <span className="text-red-500">*</span>
            </label>
            <select
              id="businessId"
              name="businessId"
              className={`block w-full rounded-lg border ${
                formErrors.businessId ? 'border-red-300' : 'border-gray-300'
              } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
              value={formData.businessId}
              onChange={handleInputChange}
              disabled={isLoading || isEditing} // Can't change business for existing account
            >
              <option value="">Select a business</option>
              {businesses.map(business => (
                <option key={business.id} value={business.id}>{business.name}</option>
              ))}
            </select>
            {formErrors.businessId && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.businessId}
              </p>
            )}
            {businesses.length === 0 && (
              <p className="mt-1 text-amber-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> No businesses found. Please create a business first.
              </p>
            )}
          </div>
          
          {/* Account Type Selection */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Account Type <span className="text-red-500">*</span>
            </label>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
              {ACCOUNT_TYPES.map(type => (
                <div
                  key={type.id}
                  onClick={() => !isEditing && handleAccountTypeChange(type.id)}
                  className={`border rounded-lg p-4 flex flex-col cursor-pointer transition-colors ${
                    formData.accountType === type.id
                      ? 'bg-purple-50 border-[#190192] text-[#190192]'
                      : 'border-gray-200 hover:border-gray-300'
                  } ${isEditing ? 'cursor-not-allowed opacity-75' : ''}`}
                >
                  <div className="flex items-center mb-1">
                    <type.icon size={18} className={formData.accountType === type.id ? 'text-[#190192]' : 'text-gray-500'} />
                    <span className="ml-2 font-medium">{type.label}</span>
                    {formData.accountType === type.id && <Check size={16} className="ml-auto" />}
                  </div>
                  <p className="text-xs text-gray-500">{type.description}</p>
                </div>
              ))}
            </div>
          </div>
          
          {/* Account Name */}
          <div className="mb-6">
            <label htmlFor="accountName" className="block text-sm font-medium text-gray-700 mb-1">
              Account Name <span className="text-red-500">*</span>
            </label>
            <input
              id="accountName"
              name="accountName"
              type="text"
              className={`block w-full rounded-lg border ${
                formErrors.accountName ? 'border-red-300' : 'border-gray-300'
              } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
              placeholder="e.g., Business Checking, Company Credit Card"
              value={formData.accountName}
              onChange={handleInputChange}
              disabled={isLoading}
            />
            {formErrors.accountName && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.accountName}
              </p>
            )}
          </div>
          
          {/* Description */}
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              Description (Optional)
            </label>
            <textarea
              id="description"
              name="description"
              rows="2"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              placeholder="Add a description of this account"
              value={formData.description}
              onChange={handleInputChange}
              disabled={isLoading}
            />
          </div>
        </div>
        
        {/* Account Details */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Account Details</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            {/* Institution */}
            <div>
              <label htmlFor="institution" className="block text-sm font-medium text-gray-700 mb-1">
                Financial Institution
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Landmark size={16} className="text-gray-400" />
                </div>
                <input
                  id="institution"
                  name="institution"
                  type="text"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  placeholder="e.g., Chase, Bank of America"
                  value={formData.institution}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
            </div>
            
            {/* Currency */}
            <div>
              <label htmlFor="currency" className="block text-sm font-medium text-gray-700 mb-1">
                Currency
              </label>
              <select
                id="currency"
                name="currency"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.currency}
                onChange={handleInputChange}
                disabled={isLoading || isEditing} // Can't change currency after account creation
              >
                {currencyOptions.map(currency => (
                  <option key={currency.code} value={currency.code}>
                    {currency.code} - {currency.name} ({currency.symbol})
                  </option>
                ))}
              </select>
              {isEditing && (
                <p className="mt-1 text-gray-500 text-xs">
                  Currency cannot be changed after account creation
                </p>
              )}
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            {/* Account Number */}
            <div>
              <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700 mb-1">
                {formData.accountType === 'credit_card' ? 'Card Number' : 'Account Number'}
                {(formData.accountType === 'bank' || formData.accountType === 'credit_card') && (
                  <span className="text-red-500"> *</span>
                )}
              </label>
              <input
                id="accountNumber"
                name="accountNumber"
                type="text"
                className={`block w-full rounded-lg border ${
                  formErrors.accountNumber ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                placeholder={formData.accountType === 'credit_card' ? "•••• •••• •••• ••••" : "Account number"}
                value={formData.accountNumber}
                onChange={handleInputChange}
                disabled={isLoading}
              />
              {formErrors.accountNumber && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.accountNumber}
                </p>
              )}
            </div>
            
            {/* Routing Number (only for bank accounts) */}
            {formData.accountType === 'bank' && (
              <div>
                <label htmlFor="routingNumber" className="block text-sm font-medium text-gray-700 mb-1">
                  Routing Number
                </label>
                <input
                  id="routingNumber"
                  name="routingNumber"
                  type="text"
                  className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  placeholder="Routing or SWIFT number"
                  value={formData.routingNumber}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
            )}
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Opening Date */}
            <div>
              <label htmlFor="openingDate" className="block text-sm font-medium text-gray-700 mb-1">
                Opening Date <span className="text-red-500">*</span>
              </label>
              <input
                id="openingDate"
                name="openingDate"
                type="date"
                className={`block w-full rounded-lg border ${
                  formErrors.openingDate ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.openingDate}
                onChange={handleInputChange}
                disabled={isLoading}
              />
              {formErrors.openingDate && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.openingDate}
                </p>
              )}
            </div>
            
            {/* Opening Balance */}
            <div>
              <label htmlFor="openingBalance" className="block text-sm font-medium text-gray-700 mb-1">
                Opening Balance <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    {getCurrencyIcon()}
                </div>
                <input
                  id="openingBalance"
                  name="openingBalance"
                  type="text"
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.openingBalance ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="0.00"
                  value={formData.openingBalance}
                  onChange={handleInputChange}
                  disabled={isLoading || isEditing} // Can't change opening balance after account creation
                />
              </div>
              {formErrors.openingBalance && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.openingBalance}
                </p>
              )}
              {isEditing && (
                <p className="mt-1 text-gray-500 text-xs">
                  Opening balance cannot be changed after account creation
                </p>
              )}
            </div>
          </div>
          
          {/* Current Balance (Only shown when editing) */}
          {isEditing && (
            <div className="mt-6">
              <label htmlFor="currentBalance" className="block text-sm font-medium text-gray-700 mb-1">
                Current Balance
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <DollarSign size={16} className="text-gray-400" />
                </div>
                <input
                  id="currentBalance"
                  name="currentBalance"
                  type="text"
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.currentBalance ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="0.00"
                  value={formData.currentBalance}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {formErrors.currentBalance && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.currentBalance}
                </p>
              )}
              <p className="mt-1 text-gray-500 text-xs">
                Note: This balance is typically updated automatically through transactions
              </p>
            </div>
          )}
        </div>
        
        {/* Account Status */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Account Status</h3>
          
          <div className="flex items-center">
            <input
              id="isActive"
              name="isActive"
              type="checkbox"
              className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
              checked={formData.isActive}
              onChange={handleInputChange}
              disabled={isLoading}
            />
            <label htmlFor="isActive" className="ml-2 block text-sm text-gray-900">
              This account is active
            </label>
          </div>
          <p className="mt-1 text-xs text-gray-500">
            Inactive accounts won't appear in transaction forms but will still be visible in reports
          </p>
        </div>
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-4 pt-6 border-t border-gray-200">
          <button
            type="button"
            onClick={() => navigate('/finance/accounts')}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
            disabled={isLoading}
          >
            Cancel
          </button>
          
          <button
            type="submit"
            className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
              isLoading ? 'bg-purple-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
            }`}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <Check size={18} className="mr-2" />
                {isEditing ? 'Update Account' : 'Create Account'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AccountForm;