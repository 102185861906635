// src/components/inventory/suppliers/SupplierList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, ChevronRight, Building, 
  MapPin, Phone, Mail, Download 
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';

const SupplierList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [suppliers, setSuppliers] = useState([]);

  // Fetch suppliers
  useEffect(() => {
    const fetchSuppliers = async () => {
      setIsLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(r => setTimeout(r, 800));
        
        // Mock suppliers data
        const mockData = [
          {
            id: '1',
            name: 'Global Textiles Inc.',
            category: 'Raw Materials',
            address: '123 Manufacturing Blvd, New York, NY',
            phone: '+1 (555) 987-6543',
            email: 'orders@globaltextiles.com',
            contactPerson: 'John Smith'
          },
          {
            id: '2',
            name: 'Tech Components Ltd',
            category: 'Electronics',
            address: '456 Tech Park, San Francisco, CA',
            phone: '+1 (555) 123-4567',
            email: 'sales@techcomponents.com',
            contactPerson: 'Sarah Johnson'
          },
          {
            id: '3',
            name: 'Packaging Solutions',
            category: 'Packaging',
            address: '789 Industrial Way, Chicago, IL',
            phone: '+1 (555) 456-7890',
            email: 'info@packagingsolutions.com',
            contactPerson: 'Michael Brown'
          }
        ];
        
        setSuppliers(mockData);
      } catch (error) {
        console.error('Error fetching suppliers:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchSuppliers();
  }, []);

  // Get filtered suppliers
  const getFilteredSuppliers = () => {
    let filtered = suppliers;
    
    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(supplier => 
        supplier.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        supplier.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        supplier.contactPerson?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Filter by category
    if (filterCategory !== 'all') {
      filtered = filtered.filter(supplier => supplier.category === filterCategory);
    }
    
    return filtered;
  };

  // Get unique categories from suppliers
  const categories = ['all', ...new Set(suppliers.map(supplier => supplier.category))];

  return (
    <div>
      {/* Search and Actions Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search by name, category, or contact person..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
          >
            <option value="all">All Categories</option>
            {categories.filter(c => c !== 'all').map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
          
          <button className="p-2.5 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50">
            <Download size={18} />
          </button>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {suppliers.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Building size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No suppliers found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You haven't added any suppliers yet. Click the 'Add Supplier' button to get started.
              </p>
              <Link
                to="/inventory/suppliers/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                Add Your First Supplier
              </Link>
            </div>
          ) : getFilteredSuppliers().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Building size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching suppliers</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any suppliers matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterCategory('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Suppliers List */
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {getFilteredSuppliers().map((supplier) => (
                <div key={supplier.id} className="bg-white rounded-xl border border-gray-200 shadow-sm hover:shadow-md transition-shadow overflow-hidden">
                  <div className="p-4">
                    <div className="mb-3">
                      <div className="flex items-center justify-between">
                        <h3 className="font-medium text-gray-900">{supplier.name}</h3>
                        <span className="px-2.5 py-0.5 bg-gray-100 text-gray-800 text-xs rounded-full">
                          {supplier.category}
                        </span>
                      </div>
                      {supplier.contactPerson && (
                        <p className="text-sm text-gray-500 mt-1">Contact: {supplier.contactPerson}</p>
                      )}
                    </div>
                    
                    <div className="space-y-2 mb-4">
                      {supplier.address && (
                        <div className="flex items-center text-sm text-gray-600">
                          <MapPin size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                          <span>{supplier.address}</span>
                        </div>
                      )}
                      
                      {supplier.phone && (
                        <div className="flex items-center text-sm text-gray-600">
                          <Phone size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                          <span>{supplier.phone}</span>
                        </div>
                      )}
                      
                      {supplier.email && (
                        <div className="flex items-center text-sm text-gray-600">
                          <Mail size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                            <a href={`mailto:${supplier.email}`} className="text-[#190192] hover:underline">
                              {supplier.email}
                            </a>
                          </div>
                        )}
                      </div>
                      
                      <div className="flex justify-end pt-3 border-t border-gray-100">
                        <Link
                          to={`/inventory/suppliers/${supplier.id}`}
                          className="text-[#190192] hover:text-[#2C0DB5] text-sm font-medium flex items-center"
                        >
                          View Details <ChevronRight size={16} className="ml-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    );
};

export default SupplierList;