// src/components/tasks/TaskForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { 
  Save, ChevronLeft, Plus, X, Calendar, 
  CheckCircle, ChevronDown, Target, Clock, User,
  Briefcase, AlertCircle, Tag, FileText, Check 
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, 
  doc, 
  getDoc, 
  setDoc, 
  addDoc, 
  query,
  where,
  limit,
  getDocs,
  orderBy,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore';

const TaskForm = ({ isEditing = false }) => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, userProfile, isDemoMode } = useAuth();
  
  // Parse query params for optional plan reference
  const queryParams = new URLSearchParams(location.search);
  const planIdParam = queryParams.get('planId');
  const planTitleParam = queryParams.get('planTitle');
  
  // Form state
  const [formData, setFormData] = useState({
    description: '',
    category: 'Scheduled',
    objectives: [{ text: '' }],
    taskRefType: planIdParam ? 'Plan' : '',
    taskRefId: planIdParam || '',
    taskRefTitle: planTitleParam || '',
    assignee: '',
    startDate: '',
    endDate: '',
    status: 'Not Started',
    priority: 'Medium',
  });

  // UI state
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [plans, setPlans] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [projects, setProjects] = useState([]);
  const [showRefOptions, setShowRefOptions] = useState(false);
  
  // Categories for dropdown
  const categories = ['Scheduled', 'Ad-hoc', 'Recurring'];
  const statuses = ['Not Started', 'In Progress', 'Completed', 'On Hold'];
  const priorities = ['Low', 'Medium', 'High', 'Critical'];
  
  // Mock data for assignees - in a real app this would come from the users collection
  const mockAssignees = [
    'Alex Johnson',
    'Maria Garcia',
    'Ahmed Khan',
    'Sarah Wilson',
    'David Chen',
    'Olivia Williams',
    'Mfundo Dzanibe'
  ];
  
  // Fetch task data if editing
  useEffect(() => {
    if (isEditing && taskId) {
      setIsLoading(true);
      // In a real app, fetch the task data from API
      // For now we'll just simulate it with a timeout
      const timer = setTimeout(() => {
        // This would be your API data in a real app
        setFormData({
          description: 'Prepare Q2 marketing materials for upcoming product launch',
          category: 'Scheduled',
          objectives: [
            { text: 'Create product brochures' },
            { text: 'Design social media templates' },
            { text: 'Prepare email campaign drafts' }
          ],
          taskRefType: 'Plan',
          taskRefId: '123',
          taskRefTitle: 'Q2 Marketing Strategy',
          assignee: 'Sarah Wilson',
          startDate: '2025-04-01',
          endDate: '2025-04-15',
          status: 'In Progress',
          priority: 'High',
        });
        setIsLoading(false);
      }, 800);
      
      return () => clearTimeout(timer);
    }
  }, [isEditing, taskId]);
  
  useEffect(() => {
    if (!userProfile?.businessName) return;
    
    // Fetch plans
    const fetchPlans = async () => {
      try {
        // In a real app, this would be an API call
        // For mock purposes, just use timeout
        await new Promise(r => setTimeout(r, 300));
        
        const plansList = [
          { id: '1', title: 'Q1 Sales Strategy', type: 'Plan' },
          { id: '2', title: 'Q2 Marketing Campaign', type: 'Plan' },
          { id: '3', title: 'Annual Business Review', type: 'Plan' },
          { id: '4', title: 'Product Roadmap 2025', type: 'Plan' },
          { id: '5', title: 'Cost Reduction Initiative', type: 'Plan' },
        ];
        
        setPlans(plansList);
        
        // If planIdParam exists, find and set the plan title
        if (planIdParam && !planTitleParam) {
          const plan = plansList.find(p => p.id === planIdParam);
          if (plan) {
            setFormData(prev => ({
              ...prev,
              taskRefTitle: plan.title,
              taskRefType: 'Plan',
              taskRefId: planIdParam
            }));
          }
        }
      } catch (err) {
        console.error('Error fetching plans:', err);
      }
    };
    
    // Fetch meetings
    const fetchMeetings = async () => {
      try {
        // In a real app, this would be an API call
        // For mock purposes, just use timeout
        await new Promise(r => setTimeout(r, 300));
        
        const meetingsList = [
          { id: 'm1', title: 'Weekly Team Standup', type: 'Meeting' },
          { id: 'm2', title: 'Client Presentation: XYZ Corp', type: 'Meeting' },
          { id: 'm3', title: 'Product Development Review', type: 'Meeting' },
          { id: 'm4', title: 'Budget Planning Session', type: 'Meeting' },
          { id: 'm5', title: 'Quarterly Board Meeting', type: 'Meeting' },
        ];
        
        setMeetings(meetingsList);
        
        // If meetingId exists in URL params, set it
        const meetingIdParam = queryParams.get('meetingId');
        const meetingTitleParam = queryParams.get('meetingTitle');
        
        if (meetingIdParam && meetingTitleParam) {
          setFormData(prev => ({
            ...prev,
            taskRefType: 'Meeting',
            taskRefId: meetingIdParam,
            taskRefTitle: meetingTitleParam
          }));
        } else if (meetingIdParam) {
          const meeting = meetingsList.find(m => m.id === meetingIdParam);
          if (meeting) {
            setFormData(prev => ({
              ...prev,
              taskRefTitle: meeting.title,
              taskRefType: 'Meeting',
              taskRefId: meetingIdParam
            }));
          }
        }
      } catch (err) {
        console.error('Error fetching meetings:', err);
      }
    };
    
    // Fetch projects
    const fetchProjects = async () => {
      try {
        // In a real app, this would be an API call
        // For mock purposes, just use timeout
        await new Promise(r => setTimeout(r, 300));
        
        const projectsList = [
          { id: 'p1', title: 'Website Redesign Project', type: 'Project' },
          { id: 'p2', title: 'CRM Implementation', type: 'Project' },
          { id: 'p3', title: 'New Product Launch', type: 'Project' },
          { id: 'p4', title: 'Office Relocation', type: 'Project' },
          { id: 'p5', title: 'Team Building Event', type: 'Project' },
        ];
        
        setProjects(projectsList);
        
        // If projectId exists in URL params, set it
        const projectIdParam = queryParams.get('projectId');
        const projectTitleParam = queryParams.get('projectTitle');
        
        if (projectIdParam && projectTitleParam) {
          setFormData(prev => ({
            ...prev,
            taskRefType: 'Project',
            taskRefId: projectIdParam,
            taskRefTitle: projectTitleParam
          }));
        } else if (projectIdParam) {
          const project = projectsList.find(p => p.id === projectIdParam);
          if (project) {
            setFormData(prev => ({
              ...prev,
              taskRefTitle: project.title,
              taskRefType: 'Project',
              taskRefId: projectIdParam
            }));
          }
        }
      } catch (err) {
        console.error('Error fetching projects:', err);
      }
    };
    
    // Execute fetch operations
    fetchPlans();
    fetchMeetings();
    fetchProjects();
    
    // Handle direct URL parameters when titles are provided
    if (planIdParam && planTitleParam) {
      setFormData(prev => ({
        ...prev,
        taskRefType: 'Plan',
        taskRefId: planIdParam,
        taskRefTitle: planTitleParam
      }));
    }
    
  }, [userProfile, location.search, planIdParam, planTitleParam, queryParams]);

  // Handle text input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  // Handle objective actions
  const addObjective = () => {
    setFormData(prev => ({
      ...prev,
      objectives: [...prev.objectives, { text: '' }]
    }));
  };
  
  const updateObjective = (index, value) => {
    const updatedObjectives = [...formData.objectives];
    updatedObjectives[index] = { text: value };
    setFormData(prev => ({
      ...prev,
      objectives: updatedObjectives
    }));
    
    // Clear error
    if (formErrors.objectives) {
      setFormErrors(prev => ({
        ...prev,
        objectives: ''
      }));
    }
  };
  
  const removeObjective = (index) => {
    if (formData.objectives.length > 1) {
      const updatedObjectives = [...formData.objectives];
      updatedObjectives.splice(index, 1);
      setFormData(prev => ({
        ...prev,
        objectives: updatedObjectives
      }));
    }
  };
  
  // Calculate duration between start and end dates
  const calculateDuration = () => {
    if (!formData.startDate || !formData.endDate) return null;
    
    const start = new Date(formData.startDate);
    const end = new Date(formData.endDate);
    
    // Calculate difference in milliseconds
    const diffTime = Math.abs(end - start);
    // Convert to days
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    return diffDays;
  };
  
  // Handle reference item selection
  const handleSelectRefItem = (item) => {
    setFormData(prev => ({
      ...prev,
      taskRefType: item.type,
      taskRefId: item.id,
      taskRefTitle: item.title
    }));
    setShowRefOptions(false);
  };
  
  // Get all reference items based on type
  const getRefItems = () => {
    switch (formData.taskRefType) {
      case 'Plan':
        return plans;
      case 'Meeting':
        return meetings;
      case 'Project':
        return projects;
      default:
        return [];
    }
  };
  
  // Validate the form
  const validateForm = () => {
    const errors = {};
    
    // Required fields
    if (!formData.description.trim()) {
      errors.description = 'Task description is required';
    }
    
    // Validate dates if both are provided
    if (formData.startDate && formData.endDate) {
      const start = new Date(formData.startDate);
      const end = new Date(formData.endDate);
      
      if (start > end) {
        errors.endDate = 'End date must be after start date';
      }
    }
    
    return errors;
  };
  
  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isDemoMode) {
      alert("Demo Mode Restriction:\nIn demo mode, data modifications are not saved. Sign up for a free account to use all features.");
      return;
    }
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      // Scroll to first error
      const firstError = document.querySelector('.text-red-600');
      if (firstError) {
        firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
    
    setIsLoading(true);
    
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Calculate duration
      const duration = calculateDuration();
      
      // Prepare task data for submission
      const taskData = {
        ...formData,
        objectives: formData.objectives.filter(objective => objective.text.trim()),
        taskRef: formData.taskRefId ? {
          type: formData.taskRefType,
          id: formData.taskRefId,
          title: formData.taskRefTitle
        } : null,
        duration,
        businessName: userProfile?.businessName,
        updatedAt: new Date().toISOString(),
      };
      
      console.log('Submitting task data:', taskData);
      
      // In a real app, you'd post to an API here
      
      // Navigate back
      setSuccess('Task successfully saved');
      setTimeout(() => {
        if (planIdParam) {
          navigate(`/plans/${planIdParam}`);
        } else {
          navigate('/tasks');
        }
      }, 1500);
      
    } catch (err) {
      console.error('Error saving task:', err);
      setFormErrors({ submit: `Failed to ${isEditing ? 'update' : 'create'} task: ${err.message}` });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-xs shadow-md overflow-hidden">
      <div className="bg-white shadow-md px-6 py-4 text-gray-800">
        <h2 className="text-lg font-medium">
          {isEditing ? 'Edit Task' : 'Create New Task'}
        </h2>
        <p className="text-purple-500 text-sm">
          {isEditing 
            ? 'Update task information in the form below' 
            : 'Fill out the form below to create a new task'}
        </p>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        <div className="mb-6">
          <button 
            type="button"
            onClick={() => planIdParam ? navigate(`/plans/${planIdParam}`) : navigate('/tasks')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            {planIdParam ? 'Back to Plan' : 'Back to Tasks'}
          </button>
        </div>
        
        {/* Success message */}
        {success && (
          <div className="mb-6 bg-green-50 border-l-4 border-green-500 p-4 text-green-700">
            {success}
          </div>
        )}
        
        {/* Task Description */}
        <div className="mb-6">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
            Task Description <span className="text-red-500">*</span>
          </label>
          <textarea
            id="description"
            name="description"
            rows={3}
            className={`w-full px-3 py-2 border ${
              formErrors.description ? 'border-red-300' : 'border-gray-300'
            } rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Describe the task to be completed"
            disabled={isLoading}
          />
          {formErrors.description && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.description}
            </p>
          )}
        </div>

        {/* Basic Information */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Task Details</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
                Category <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Tag size={18} className="text-gray-400" />
                </div>
                <select
                  id="category"
                  name="category"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.category}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  {categories.map((cat) => (
                    <option key={cat} value={cat}>{cat}</option>
                  ))}
                </select>
              </div>
            </div>
            
            <div>
              <label htmlFor="assignee" className="block text-sm font-medium text-gray-700 mb-1">
                Assignee
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User size={18} className="text-gray-400" />
                </div>
                <select
                  id="assignee"
                  name="assignee"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.assignee}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  <option value="">Select assignee</option>
                  {mockAssignees.map((person) => (
                    <option key={person} value={person}>{person}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="status" className="block text-sm font-medium text-gray-700 mb-1">
                Status
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <CheckCircle size={18} className="text-gray-400" />
                </div>
                <select
                  id="status"
                  name="status"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.status}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  {statuses.map((stat) => (
                    <option key={stat} value={stat}>{stat}</option>
                  ))}
                </select>
              </div>
            </div>
            
            <div>
              <label htmlFor="priority" className="block text-sm font-medium text-gray-700 mb-1">
                Priority
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Target size={18} className="text-gray-400" />
                </div>
                <select
                  id="priority"
                  name="priority"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.priority}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  {priorities.map((pri) => (
                    <option key={pri} value={pri}>{pri}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Objectives */}
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Objectives</h3>
            <button
              type="button"
              className="text-[#190192] hover:text-[#2C0DB5] text-sm flex items-center"
              onClick={addObjective}
              disabled={isLoading}
            >
              <Plus size={14} className="mr-1" />
              Add Objective
            </button>
          </div>
          
          <div className="space-y-3">
            {formData.objectives.map((objective, index) => (
              <div key={index} className="flex items-center">
                <div className="flex-shrink-0 mr-2">
                  <div className="w-6 h-6 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 text-xs font-medium">
                    {index + 1}
                  </div>
                </div>
                <input
                  type="text"
                  className="flex-grow px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={objective.text}
                  onChange={(e) => updateObjective(index, e.target.value)}
                  placeholder="Enter objective..."
                  disabled={isLoading}
                />
                <button
                  type="button"
                  className="ml-2 text-gray-400 hover:text-red-500"
                  onClick={() => removeObjective(index)}
                  disabled={isLoading || formData.objectives.length <= 1}
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Task Reference */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Task Reference</h3>
          
          <div className="border border-gray-200 rounded-lg p-4 bg-gray-50">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Reference Type
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FileText size={18} className="text-gray-400" />
                  </div>
                  <select
                    name="taskRefType"
                    className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192] bg-white"
                    value={formData.taskRefType}
                    onChange={(e) => {
                      setFormData(prev => ({
                        ...prev,
                        taskRefType: e.target.value,
                        taskRefId: '',
                        taskRefTitle: ''
                      }));
                    }}
                    disabled={isLoading}
                  >
                    <option value="">Select reference type</option>
                    <option value="Plan">Business Plan</option>
                    <option value="Meeting">Meeting</option>
                    <option value="Project">Project</option>
                  </select>
                </div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Referenced Item
                </label>
                <div className="relative">
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      className="flex-1 pl-3 py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192] bg-white text-gray-700"
                      placeholder={formData.taskRefType ? `Select a ${formData.taskRefType.toLowerCase()}...` : "Select a reference type first"}
                      value={formData.taskRefTitle}
                      readOnly
                      disabled={!formData.taskRefType}
                    />
                    <button
                      type="button"
                      className="inline-flex items-center px-3 py-2 border border-l-0 border-gray-300 rounded-r-lg bg-gray-50 text-gray-500 hover:text-gray-600 hover:bg-gray-100"
                      onClick={() => formData.taskRefType && setShowRefOptions(!showRefOptions)}
                      disabled={!formData.taskRefType || isLoading}
                    >
                      {showRefOptions ? <X size={16} /> : <ChevronDown size={16} />}
                    </button>
                  </div>
                  
                  {/* Reference options dropdown */}
                  {showRefOptions && formData.taskRefType && (
                    <div className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-lg py-1 text-base overflow-auto focus:outline-none border border-gray-200">
                      {getRefItems().length > 0 ? (
                        getRefItems().map((item) => (
                          <div
                            key={item.id}
                            className="cursor-pointer select-none relative py-2 px-3 hover:bg-indigo-50 text-gray-800"
                            onClick={() => handleSelectRefItem(item)}
                          >
                            <span className="block truncate">{item.title}</span>
                          </div>
                        ))
                      ) : (
                        <div className="cursor-default select-none relative py-2 px-3 text-gray-500">
                          No {formData.taskRefType.toLowerCase()}s available
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {formData.taskRefTitle && (
                  <p className="mt-1 text-xs text-gray-500">
                    This task will be linked to the selected {formData.taskRefType.toLowerCase()}.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Time Frame */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Time Frame</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Calendar size={18} className="text-gray-400" />
                </div>
                <input
                  id="startDate"
                  name="startDate"
                  type="date"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
            </div>
            
            <div>
              <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                End Date
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Calendar size={18} className="text-gray-400" />
                </div>
                <input
                  id="endDate"
                  name="endDate"
                  type="date"
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.endDate ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  value={formData.endDate}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
                {formErrors.endDate && (
                  <p className="mt-1 text-red-600 text-xs flex items-center">
                    <AlertCircle size={12} className="mr-1" /> {formErrors.endDate}
                  </p>
                )}
              </div>
            </div>
            
            <div>
              <label htmlFor="duration" className="block text-sm font-medium text-gray-700 mb-1">
                Duration (days)
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Clock size={18} className="text-gray-400" />
                </div>
                <div className="pl-10 w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm bg-gray-50 text-gray-700">
                  {calculateDuration() !== null ? calculateDuration() : 'Not set'}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Form Actions */}
        <div className="flex justify-end space-x-4 mt-10 pt-6 border-t border-gray-200">
          <button
            type="button"
            onClick={() => planIdParam ? navigate(`/plans/${planIdParam}`) : navigate('/tasks')}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
            disabled={isLoading}
          >
            Cancel
          </button>
          
          <button
            type="submit"
            disabled={isLoading}
            className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
              isLoading ? 'bg-purple-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
            }`}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <Check size={18} className="mr-2" />
                {isEditing ? 'Update Task' : 'Create Task'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TaskForm;