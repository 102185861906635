// src/pages/CreateProfile.js
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  User, Mail, Phone, MapPin, Briefcase, Building, Users, Camera,
  Upload, AlertCircle, CheckCircle, ChevronDown, X 
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase/config';

const CreateProfile = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { currentUser } = useAuth();
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    pronoun: '',
    profileImage: null,
    mobile: '',
    email: currentUser?.email || '',
    address: '',
    country: '',
    businessId: '',
    userRole: '',
    position: ''
  });
  
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [showPronounDropdown, setShowPronounDropdown] = useState(false);
  const [showRoleDropdown, setShowRoleDropdown] = useState(false);
  const [showCountryDropdown, setShowCountryDropdown] = useState(false);
  const [error, setError] = useState(null);
  
  // Available options for dropdowns
  const pronounOptions = [
    'He/Him', 
    'She/Her', 
    'They/Them', 
    'Ze/Zir', 
    'Prefer not to say'
  ];
  
  const roleOptions = [
    'Administrator',
    'Manager',
    'Employee',
    'Accountant',
    'Sales Representative',
    'Support Staff'
  ];
  
  const countryOptions = [
    'United States',
    'Canada',
    'United Kingdom',
    'Australia',
    'Germany',
    'France',
    'Japan',
    'India',
    'Brazil',
    'South Africa'
  ];

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Handle profile image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    if (file.size > 5 * 1024 * 1024) {
      setFormErrors(prev => ({
        ...prev,
        profileImage: 'Image size should be less than 5MB'
      }));
      return;
    }
    
    if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
      setFormErrors(prev => ({
        ...prev,
        profileImage: 'Only JPG, JPEG, and PNG formats are supported'
      }));
      return;
    }
    
    setFormData(prev => ({
      ...prev,
      profileImage: file
    }));
    
    // Create preview URL
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
    
    // Clear error for this field
    setFormErrors(prev => ({
      ...prev,
      profileImage: ''
    }));
  };
  
  // Handle dropdown selection
  const handleDropdownSelect = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    
    // Close the dropdown
    if (field === 'pronoun') setShowPronounDropdown(false);
    if (field === 'userRole') setShowRoleDropdown(false);
    if (field === 'country') setShowCountryDropdown(false);
    
    // Clear error for this field
    if (formErrors[field]) {
      setFormErrors(prev => ({
        ...prev,
        [field]: ''
      }));
    }
  };
  
  // Remove profile image
  const handleRemoveImage = () => {
    setPreviewImage(null);
    setFormData(prev => ({
      ...prev,
      profileImage: null
    }));
    
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  
  // Validate form
  const validateForm = () => {
    const errors = {};
    
    if (!formData.firstName.trim()) errors.firstName = 'First name is required';
    if (!formData.lastName.trim()) errors.lastName = 'Last name is required';
    if (!formData.email.trim()) errors.email = 'Email is required';
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Email is invalid';
    if (!formData.mobile.trim()) errors.mobile = 'Mobile number is required';
    if (!formData.address.trim()) errors.address = 'Address is required';
    if (!formData.country) errors.country = 'Country is required';
    if (!formData.userRole) errors.userRole = 'User role is required';
    if (!formData.position.trim()) errors.position = 'Position is required';
    
    return errors;
  };
  
  // Upload profile image to Firebase Storage
  const uploadProfileImage = async (file) => {
    if (!file) return null;
    
    const storageRef = ref(storage, `profileImages/${currentUser.uid}/${Date.now()}_${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    
    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Track upload progress if needed
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload progress: ${progress}%`);
        },
        (error) => {
          console.error('Error uploading image:', error);
          reject(error);
        },
        async () => {
          // Upload completed, get download URL
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!currentUser) {
      setError("You must be logged in to create a profile");
      return;
    }
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    
    setIsSubmitting(true);
    setError(null);
    
    try {
      // 1. Upload profile image if exists
      let profileImageUrl = null;
      if (formData.profileImage) {
        profileImageUrl = await uploadProfileImage(formData.profileImage);
      }
      
      // 2. Prepare profile data
      const profileData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        fullName: `${formData.firstName} ${formData.lastName}`,
        pronoun: formData.pronoun || null,
        email: formData.email,
        mobile: formData.mobile,
        address: formData.address,
        country: formData.country,
        businessId: formData.businessId || null,
        userRole: formData.userRole,
        position: formData.position,
        profileImageUrl: profileImageUrl,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        uid: currentUser.uid
      };
      
      // 3. Save profile data to Firestore
      // Create profile document with user's UID as the document ID
      await setDoc(doc(db, 'userProfiles', currentUser.uid), profileData);
      
      // 4. Update user document with basic profile info
      await setDoc(doc(db, 'users', currentUser.uid), {
        displayName: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        profileImageUrl: profileImageUrl,
        updatedAt: serverTimestamp()
      }, { merge: true });
      
      // 5. Navigate to dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error('Error creating profile:', error);
      setError("Failed to create profile. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <div className="mb-10 text-center">
        <h1 className="text-3xl font-bold text-gray-900 mb-2">Create Your Profile</h1>
        <p className="text-gray-600">Complete your profile information to get started with BizDash</p>
      </div>
      
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="bg-gradient-to-r from-[#190192] to-[#3B0DB5] px-6 py-4">
          <h2 className="text-lg font-medium text-white">Personal Information</h2>
          <p className="text-purple-200 text-sm">Basic details about you that will be visible to your team</p>
        </div>
        
        {error && (
          <div className="bg-red-50 p-4 mx-6 mt-6 rounded-lg">
            <p className="text-red-600 text-sm flex items-center">
              <AlertCircle size={16} className="mr-2" />
              {error}
            </p>
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="p-6">
          {/* Profile Image Upload Section */}
          <div className="mb-8 flex flex-col items-center">
            <div className="relative mb-4">
              {previewImage ? (
                <div className="relative">
                  <img 
                    src={previewImage} 
                    alt="Profile preview" 
                    className="w-32 h-32 rounded-full object-cover border-4 border-purple-100"
                  />
                  <button 
                    type="button" 
                    onClick={handleRemoveImage}
                    className="absolute -top-2 -right-2 bg-red-100 rounded-full p-1 text-red-600 hover:bg-red-200"
                  >
                    <X size={16} />
                  </button>
                </div>
              ) : (
                <div className="w-32 h-32 rounded-full bg-purple-100 flex items-center justify-center text-[#190192]">
                  <Camera size={40} />
                </div>
              )}
              
              <div 
                onClick={() => fileInputRef.current?.click()} 
                className="absolute bottom-0 right-0 bg-[#190192] rounded-full p-2 cursor-pointer hover:bg-[#2C0DB5] transition-colors"
              >
                <Upload size={16} className="text-white" />
              </div>
              
              <input 
                type="file" 
                ref={fileInputRef}
                className="hidden" 
                accept="image/png, image/jpeg, image/jpg" 
                onChange={handleImageUpload}
              />
            </div>
            
            <button 
              type="button" 
              onClick={() => fileInputRef.current?.click()}
              className="text-[#190192] text-sm font-medium hover:text-[#2C0DB5]"
            >
              Upload profile picture
            </button>
            
            <p className="text-gray-500 text-xs mt-1">
              Maximum file size: 5MB (JPG, JPEG, or PNG)
            </p>
            
            {formErrors.profileImage && (
              <p className="text-red-600 text-xs mt-1 flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.profileImage}
              </p>
            )}
          </div>
          
          {/* Name and Pronoun Fields */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                First Name *
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User size={18} className="text-gray-400" />
                </div>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={formData.firstName}
                  onChange={handleChange}
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.firstName ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="Enter your first name"
                />
              </div>
              {formErrors.firstName && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.firstName}
                </p>
              )}
            </div>
            
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                Last Name *
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User size={18} className="text-gray-400" />
                </div>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={formData.lastName}
                  onChange={handleChange}
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.lastName ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="Enter your last name"
                />
              </div>
              {formErrors.lastName && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.lastName}
                </p>
              )}
            </div>
          </div>
          
          {/* Pronoun Field */}
          <div className="mb-6">
            <label htmlFor="pronoun" className="block text-sm font-medium text-gray-700 mb-1">
              Pronoun
            </label>
            <div className="relative">
              <div 
                className={`flex items-center justify-between px-3 py-2 border ${
                  formErrors.pronoun ? 'border-red-300' : 'border-gray-300'
                } rounded-lg cursor-pointer`}
                onClick={() => setShowPronounDropdown(!showPronounDropdown)}
              >
                <span className={formData.pronoun ? 'text-gray-900' : 'text-gray-400'}>
                  {formData.pronoun || 'Select pronoun (optional)'}
                </span>
                <ChevronDown size={18} className="text-gray-400" />
              </div>
              
              {showPronounDropdown && (
                <div className="absolute z-10 mt-1 w-full bg-white rounded-lg shadow-lg max-h-60 overflow-auto">
                  {pronounOptions.map((option) => (
                    <div 
                      key={option}
                      className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                        formData.pronoun === option ? 'bg-purple-50 text-[#190192]' : 'text-gray-700'
                      }`}
                      onClick={() => handleDropdownSelect('pronoun', option)}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          
          {/* Contact Information */}
          <div className="mt-10 mb-4">
            <h3 className="text-lg font-medium text-gray-900 mb-1">Contact Information</h3>
            <p className="text-gray-500 text-sm">How others can reach you</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email *
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail size={18} className="text-gray-400" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.email ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="Enter your email address"
                />
              </div>
              {formErrors.email && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.email}
                </p>
              )}
            </div>
            
            <div>
              <label htmlFor="mobile" className="block text-sm font-medium text-gray-700 mb-1">
                Mobile Number *
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Phone size={18} className="text-gray-400" />
                </div>
                <input
                  id="mobile"
                  name="mobile"
                  type="tel"
                  value={formData.mobile}
                  onChange={handleChange}
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.mobile ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="Enter your mobile number"
                />
              </div>
              {formErrors.mobile && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.mobile}
                </p>
              )}
            </div>
          </div>
          
          {/* Rest of the form remains unchanged */}
          <div className="mb-6">
            <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
              Address *
            </label>
            <div className="relative">
              <div className="absolute top-3 left-3 flex items-center pointer-events-none">
                <MapPin size={18} className="text-gray-400" />
              </div>
              <textarea
                id="address"
                name="address"
                rows="3"
                value={formData.address}
                onChange={handleChange}
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.address ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                placeholder="Enter your address"
              />
            </div>
            {formErrors.address && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.address}
              </p>
            )}
          </div>
          
          {/* Country Dropdown */}
          <div className="mb-6">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">
              Country *
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MapPin size={18} className="text-gray-400" />
              </div>
              
              <div 
                className={`flex items-center justify-between pl-10 pr-3 py-2 border ${
                  formErrors.country ? 'border-red-300' : 'border-gray-300'
                } rounded-lg cursor-pointer`}
                onClick={() => setShowCountryDropdown(!showCountryDropdown)}
              >
                <span className={formData.country ? 'text-gray-900' : 'text-gray-400'}>
                  {formData.country || 'Select your country'}
                </span>
                <ChevronDown size={18} className="text-gray-400" />
              </div>
              
              {showCountryDropdown && (
                <div className="absolute z-10 mt-1 w-full bg-white rounded-lg shadow-lg max-h-60 overflow-auto">
                  {countryOptions.map((option) => (
                    <div 
                      key={option}
                      className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                        formData.country === option ? 'bg-purple-50 text-[#190192]' : 'text-gray-700'
                      }`}
                      onClick={() => handleDropdownSelect('country', option)}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {formErrors.country && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.country}
              </p>
            )}
          </div>
          
          {/* Professional Information */}
          <div className="mt-10 mb-4">
            <h3 className="text-lg font-medium text-gray-900 mb-1">Professional Information</h3>
            <p className="text-gray-500 text-sm">Details about your role in the organization</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label htmlFor="businessId" className="block text-sm font-medium text-gray-700 mb-1">
                Business ID
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Building size={18} className="text-gray-400" />
                </div>
                <input
                  id="businessId"
                  name="businessId"
                  type="text"
                  value={formData.businessId}
                  onChange={handleChange}
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  placeholder="Enter business ID (if applicable)"
                />
              </div>
            </div>
            
            <div>
              <label htmlFor="position" className="block text-sm font-medium text-gray-700 mb-1">
                Position *
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Briefcase size={18} className="text-gray-400" />
                </div>
                <input
                  id="position"
                  name="position"
                  type="text"
                  value={formData.position}
                  onChange={handleChange}
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.position ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="Enter your position"
                />
              </div>
              {formErrors.position && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.position}
                </p>
              )}
            </div>
          </div>
          
          {/* User Role Dropdown */}
          <div className="mb-8">
            <label htmlFor="userRole" className="block text-sm font-medium text-gray-700 mb-1">
              User Role *
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Users size={18} className="text-gray-400" />
              </div>
              
              <div 
                className={`flex items-center justify-between pl-10 pr-3 py-2 border ${
                  formErrors.userRole ? 'border-red-300' : 'border-gray-300'
                } rounded-lg cursor-pointer`}
                onClick={() => setShowRoleDropdown(!showRoleDropdown)}
              >
                <span className={formData.userRole ? 'text-gray-900' : 'text-gray-400'}>
                  {formData.userRole || 'Select your role'}
                </span>
                <ChevronDown size={18} className="text-gray-400" />
              </div>
              
              {showRoleDropdown && (
                <div className="absolute z-10 mt-1 w-full bg-white rounded-lg shadow-lg max-h-60 overflow-auto">
                  {roleOptions.map((option) => (
                    <div 
                      key={option}
                      className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                        formData.userRole === option ? 'bg-purple-50 text-[#190192]' : 'text-gray-700'
                      }`}
                      onClick={() => handleDropdownSelect('userRole', option)}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {formErrors.userRole && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.userRole}
              </p>
            )}
          </div>
          
          {/* Form Actions */}
          <div className="flex justify-end space-x-4 mt-10 pt-6 border-t border-gray-200">
            <button
              type="button"
              onClick={() => navigate('/dashboard')}
              className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            
            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
                isSubmitting ? 'bg-purple-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
              }`}
            >
              {isSubmitting ? (
                <>
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Saving...
                </>
              ) : (
                <>
                  <CheckCircle size={18} className="mr-2" />
                  Save Profile
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateProfile;