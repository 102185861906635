// src/components/plans/PlanDetail.js
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  FileText, MapPin, Calendar, Phone, Mail, Users, ChevronLeft, Edit, Download,
  Trash2, ExternalLink, DollarSign, CheckCircle, Target, Clock, Activity,
  PlusCircle, ChevronRight, Briefcase, ArrowLeft, ArrowRight, X, AlertTriangle 
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';

const PlanDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Fetch plan data
  useEffect(() => {
    const fetchPlanDetails = async () => {
      setLoading(true);
      try {
        // In a real app, this would be an API call
        // For now, we'll simulate with setTimeout
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Mock plan data
        const mockPlan = {
          id,
          title: 'Q1 Sales Strategy',
          category: 'Sales',
          description: 'Strategic sales plan for the first quarter focusing on new product launches and key account expansion. This initiative aligns with our annual growth targets and emphasizes new market penetration.',
          status: 'In Progress',
          priority: 'High',
          startDate: '2025-01-01',
          endDate: '2025-03-31',
          projectLead: 'Mark Wilson',
          department: 'Sales Team',
          budget: 35000,
          progress: 65,
          createdAt: '2024-12-10',
          updatedAt: '2025-01-22',
          objectives: [
            { id: 1, text: 'Increase quarterly sales by 15% compared to previous year' },
            { id: 2, text: 'Launch 2 new product lines with full sales enablement' },
            { id: 3, text: 'Expand into 3 new regional markets' },
            { id: 4, text: 'Improve lead conversion rate to 12%' }
          ],
          kpis: [
            { id: 1, text: 'Total revenue: $1.2M', status: 'on-track' },
            { id: 2, text: 'New customers acquired: 75', status: 'at-risk' },
            { id: 3, text: 'Sales team productivity: +18%', status: 'exceeded' },
            { id: 4, text: 'Lead-to-customer conversion: 10.5%', status: 'on-track' }
          ],
          tasks: [
            { id: 1, title: 'Finalize Q1 targets', assignee: 'Mark Wilson', dueDate: '2025-01-05', status: 'completed' },
            { id: 2, title: 'Develop sales materials', assignee: 'Jessica Lee', dueDate: '2025-01-15', status: 'completed' },
            { id: 3, title: 'Train sales team on new products', assignee: 'Robert Chen', dueDate: '2025-01-25', status: 'in-progress' },
            { id: 4, title: 'Launch account expansion program', assignee: 'Sarah Johnson', dueDate: '2025-02-10', status: 'in-progress' },
            { id: 5, title: 'Mid-quarter review', assignee: 'Mark Wilson', dueDate: '2025-02-15', status: 'not-started' },
            { id: 6, title: 'Regional market launch', assignee: 'David Parker', dueDate: '2025-03-01', status: 'not-started' }
          ],
          relatedPolicies: ['Sales Enablement Policy', 'Customer Engagement Protocol']
        };
        
        setPlan(mockPlan);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching plan details:', error);
        setLoading(false);
      }
    };

    fetchPlanDetails();
  }, [id]);

  // Handle delete
  const handleDelete = async () => {
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Navigate back to the plan list
      navigate('/plans');
    } catch (error) {
      console.error('Error deleting plan:', error);
    }
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return 'Not set';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Get status style
  const getStatusStyle = (status) => {
    switch (status) {
      case 'In Progress':
        return 'bg-blue-100 text-blue-700';
      case 'Planning':
        return 'bg-purple-100 text-purple-700';
      case 'Not Started':
        return 'bg-gray-100 text-gray-700';
      case 'Completed':
        return 'bg-green-100 text-green-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  };
  
  // Get priority style
  const getPriorityStyle = (priority) => {
    switch (priority) {
      case 'Low':
        return 'bg-green-100 text-green-800';
      case 'Medium':
        return 'bg-blue-100 text-blue-800';
      case 'High':
        return 'bg-orange-100 text-orange-800';
      case 'Critical':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  
  // Get KPI status style
  const getKpiStatusStyle = (status) => {
    switch (status) {
      case 'exceeded':
        return 'text-green-600';
      case 'on-track':
        return 'text-blue-600';
      case 'at-risk':
        return 'text-orange-600';
      case 'behind':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };
  
  // Get KPI status icon
  const getKpiStatusIcon = (status) => {
    switch (status) {
      case 'exceeded':
        return <CheckCircle size={16} className="mr-1 text-green-600" />;
      case 'on-track':
        return <CheckCircle size={16} className="mr-1 text-blue-600" />;
      case 'at-risk':
        return <AlertTriangle size={16} className="mr-1 text-orange-600" />;
      case 'behind':
        return <AlertTriangle size={16} className="mr-1 text-red-600" />;
      default:
        return <Activity size={16} className="mr-1 text-gray-600" />;
    }
  };
  
  // Get task status style
  const getTaskStatusStyle = (status) => {
    switch (status) {
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'in-progress':
        return 'bg-blue-100 text-blue-800';
      case 'not-started':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!plan) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Plan not found</h3>
          <p className="text-gray-500 mb-6">The plan you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/plans')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Plans
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/plans"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Plans
          </Link>
          
          <div className="flex space-x-3">
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
              <Download size={20} />
            </button>
            <Link
              to={`/plans/edit/${id}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Plan Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className="w-16 h-16 rounded-lg bg-indigo-100 flex items-center justify-center flex-shrink-0 text-[#190192]">
              <FileText size={32} />
            </div>
            
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">{plan.title}</h1>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(plan.status)}`}>
                  {plan.status}
                </span>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getPriorityStyle(plan.priority)}`}>
                  {plan.priority} Priority
                </span>
              </div>
              
              <p className="text-gray-600">{plan.category}</p>
              
              {/* Progress bar */}
              <div className="mt-4">
                <div className="flex items-center justify-between mb-1">
                  <span className="text-sm font-medium text-gray-700">Progress</span>
                  <span className="text-sm font-medium text-gray-700">{plan.progress}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div 
                    className="bg-blue-600 h-2.5 rounded-full" 
                    style={{ width: `${plan.progress}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="flex px-6 -mb-px">
            <button
              onClick={() => setActiveTab('overview')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'overview'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('tasks')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'tasks'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Tasks ({plan.tasks.length})
            </button>
          </nav>
        </div>
        
        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'overview' ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left Column: Basic Info */}
              <div className="lg:col-span-2 space-y-8">
                {/* Description */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Description</h3>
                  <p className="text-gray-700 whitespace-pre-line">{plan.description}</p>
                </div>
                
                {/* Objectives */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Objectives</h3>
                  <div className="space-y-3">
                    {plan.objectives.map((objective) => (
                      <div key={objective.id} className="flex items-start">
                        <div className="flex-shrink-0 w-6 h-6 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 mr-3">
                          <Target size={14} />
                        </div>
                        <p className="text-gray-700">{objective.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
                
                {/* Timeline & Budget */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-gray-50 rounded-xl p-5">
                    <h3 className="text-md font-medium text-gray-900 mb-3">Timeline</h3>
                    <div className="flex items-center text-gray-700 mb-2">
                      <Calendar size={18} className="mr-2 text-gray-500" />
                      <span className="font-medium">Start:</span>
                      <span className="ml-2">{formatDate(plan.startDate)}</span>
                    </div>
                    <div className="flex items-center text-gray-700">
                      <Calendar size={18} className="mr-2 text-gray-500" />
                      <span className="font-medium">End:</span>
                      <span className="ml-2">{formatDate(plan.endDate)}</span>
                    </div>
                  </div>
                  
                  <div className="bg-gray-50 rounded-xl p-5">
                    <h3 className="text-md font-medium text-gray-900 mb-3">Budget</h3>
                    <div className="flex items-center text-gray-700">
                      <DollarSign size={18} className="mr-2 text-gray-500" />
                      <span className="text-2xl font-bold">{plan.budget.toLocaleString()}</span>
                    </div>
                  </div>
                </div>
                
                {/* KPIs */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Key Performance Indicators</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {plan.kpis.map((kpi) => (
                      <div key={kpi.id} className="border border-gray-200 rounded-lg p-4">
                        <div className={`flex items-center ${getKpiStatusStyle(kpi.status)}`}>
                          {getKpiStatusIcon(kpi.status)}
                          <p className="font-medium">{kpi.text}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              
              {/* Right Column: Sidebar */}
              <div className="lg:col-span-1">
                {/* Team Info */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Team Information</h3>
                  
                  <div className="space-y-4">
                    <div>
                      <p className="text-sm text-gray-500 mb-1">Project Lead</p>
                      <div className="flex items-center">
                        <div className="w-8 h-8 rounded-full bg-indigo-100 flex items-center justify-center text-indigo-600 font-medium mr-2">
                          {plan.projectLead.split(' ').map(name => name[0]).join('')}
                        </div>
                        <p className="font-medium text-gray-800">{plan.projectLead}</p>
                      </div>
                    </div>
                    
                    <div>
                      <p className="text-sm text-gray-500 mb-1">Department</p>
                      <div className="flex items-center">
                        <Briefcase size={16} className="mr-2 text-gray-500" />
                        <p className="text-gray-800">{plan.department}</p>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Related Policies */}
                {plan.relatedPolicies && plan.relatedPolicies.length > 0 && (
                  <div className="bg-gray-50 rounded-xl p-5 mb-6">
                    <h3 className="text-lg font-medium text-gray-900 mb-3">Related Policies</h3>
                    <ul className="space-y-2">
                      {plan.relatedPolicies.map((policy, index) => (
                        <li key={index} className="flex items-center text-gray-800">
                          <FileText size={16} className="mr-2 text-gray-500" />
                          <span>{policy}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                
                {/* Meta Information */}
                <div className="bg-gray-50 rounded-xl p-5">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Information</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Created</span>
                      <span className="text-sm text-gray-900 flex items-center">
                        <Clock size={14} className="mr-1.5" />
                        {formatDate(plan.createdAt)}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Last Updated</span>
                      <span className="text-sm text-gray-900 flex items-center">
                        <Clock size={14} className="mr-1.5" />
                        {formatDate(plan.updatedAt)}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">ID</span>
                      <span className="text-sm text-gray-900">{plan.id}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            /* Tasks Tab */
            <div>
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-xl font-medium text-gray-900">Tasks</h3>
                <button
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Add Task
                </button>
              </div>
              
              {plan.tasks.length === 0 ? (
                <div className="bg-gray-50 rounded-xl py-12 px-6 text-center">
                  <CheckCircle size={48} className="mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No tasks yet</h3>
                  <p className="text-gray-500 mb-6 max-w-md mx-auto">
                    This plan doesn't have any tasks yet. Add tasks to track progress.
                  </p>
                  <button
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                  >
                    <PlusCircle size={16} className="mr-2" />
                    Add First Task
                  </button>
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Task
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Assignee
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Due Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {plan.tasks.map((task) => (
                        <tr key={task.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900">{task.title}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="w-6 h-6 rounded-full bg-indigo-100 flex items-center justify-center text-indigo-600 text-xs font-medium mr-2">
                                {task.assignee.split(' ').map(name => name[0]).join('')}
                              </div>
                              <div className="text-sm text-gray-900">{task.assignee}</div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">{formatDate(task.dueDate)}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getTaskStatusStyle(task.status)}`}>
                              {task.status.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <button className="text-indigo-600 hover:text-indigo-900 mr-3">Edit</button>
                            <button className="text-red-600 hover:text-red-900">Delete</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Plan</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete <span className="font-medium">{plan.title}</span>? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PlanDetail;