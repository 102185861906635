// App.js
import React from 'react';
import { AuthProvider, useAuth } from './context/AuthContext';
import { AppProvider } from './context/AppContext';
import AppRoutes from './routes';

const Providers = () => {
  const { isDemoMode } = useAuth();
  
  return (
    <AppProvider isDemoMode={isDemoMode}>
      <AppRoutes />
    </AppProvider>
  );
};

function App() {
  return (
    <AuthProvider>
      <Providers />
    </AuthProvider>
  );
}

export default App;
