// src/pages/tasks/TaskPage.js
import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { PlusCircle, CheckCircle, List, LayoutGrid } from 'lucide-react';
import TaskList from '../components/tasks/TaskList';
import TaskKanban from '../components/tasks/TaskKanban';

const TaskPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'kanban'

  // Check if we're on the main task list page
  const isMainPage = location.pathname === '/tasks' || location.pathname === '/tasks/';

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="bg-gradient-to-r from-[#190192] to-[#3B0DB5] px-6 py-4 text-white">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold">Tasks Management</h1>
              <p className="text-purple-200 text-sm">
                Track, assign, and manage tasks across your organization
              </p>
            </div>

            <div className="flex items-center space-x-3">
              {isMainPage && (
                <div className="bg-white bg-opacity-20 rounded-lg flex mr-3">
                  <button
                    onClick={() => setViewMode('list')}
                    className={`p-2 ${viewMode === 'list' ? 'bg-white text-[#190192]' : 'text-white'} rounded-lg flex items-center`}
                  >
                    <List size={18} />
                  </button>
                  <button
                    onClick={() => setViewMode('kanban')}
                    className={`p-2 ${viewMode === 'kanban' ? 'bg-white text-[#190192]' : 'text-white'} rounded-lg flex items-center`}
                  >
                    <LayoutGrid size={18} />
                  </button>
                </div>
              )}
              
              {isMainPage && (
                <button
                  onClick={() => navigate('/tasks/new')}
                  className="px-4 py-2 bg-white text-[#190192] rounded-lg hover:bg-opacity-90 font-medium transition-all flex items-center"
                >
                  <PlusCircle size={18} className="mr-2" />
                  Add Task
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Content */}
      {isMainPage ? (
        viewMode === 'list' ? <TaskList /> : <TaskKanban />
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default TaskPage;