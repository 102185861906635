// src/components/business/BusinessList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, PlusCircle, ChevronRight, Building, Users, 
  MapPin, Globe, Briefcase, ChevronDown, ChevronUp, ExternalLink,
  Map, Building2, AlertCircle
} from 'lucide-react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../context/AuthContext';

const BusinessList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterLevel, setFilterLevel] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [businesses, setBusinesses] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState({});
  
  const { currentUser } = useAuth();

  // Fetch businesses from Firestore
  useEffect(() => {
    const fetchBusinesses = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        if (!currentUser) {
          throw new Error('User not authenticated');
        }
        
        // Create a query against the businesses collection
        const businessQuery = query(
          collection(db, 'businesses'),
          where('createdBy', '==', currentUser.uid)
        );
        
        const querySnapshot = await getDocs(businessQuery);
        
        // Transform Firestore data
        const businessData = [];
        querySnapshot.forEach((doc) => {
          businessData.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setBusinesses(businessData);
      } catch (error) {
        console.error('Error fetching businesses:', error);
        setError('Failed to load businesses. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchBusinesses();
  }, [currentUser]);

  // Toggle expanded group
  const toggleExpandGroup = (businessId) => {
    setExpandedGroups(prev => ({
      ...prev,
      [businessId]: !prev[businessId]
    }));
  };

  // Get child businesses
  const getChildBusinesses = (parentId) => {
    return businesses.filter(business => business.parentBusinessId === parentId);
  };

  // Get filtered and organized businesses
  const getFilteredBusinesses = () => {
    // First filter by search term and level if applicable
    let filtered = businesses;
    
    if (searchTerm) {
      filtered = filtered.filter(b => 
        (b.name && b.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (b.industry && b.industry.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (b.address && b.address.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    
    if (filterLevel !== 'all') {
      filtered = filtered.filter(b => b.level === filterLevel);
    }
    
    // For search results and specific level filters, we'll show a flat list
    if (searchTerm || filterLevel !== 'all') {
      return filtered;
    }
    
    // For the main view without filters, only return top-level businesses (HQs)
    return filtered.filter(b => !b.parentBusinessId);
  };

  // Render a business card with its children
  const renderBusinessCard = (business, depth = 0) => {
    const hasChildren = getChildBusinesses(business.id).length > 0;
    const isExpanded = expandedGroups[business.id];
    const childBusinesses = getChildBusinesses(business.id);
    
    // Choose the right icon based on business level
    let LevelIcon;
    switch (business.level) {
      case 'Headquarters':
        LevelIcon = Building;
        break;
      case 'Subsidiary':
        LevelIcon = Building2;
        break;
      case 'Branch':
        LevelIcon = Map;
        break;
      case 'Department':
        LevelIcon = Briefcase;
        break;
      default:
        LevelIcon = Building;
    }
    
    return (
        <div key={business.id} className={`mb-4 ${depth > 0 ? 'ml-6 pl-4 border-l border-gray-200' : ''}`}>
        <div className="bg-white rounded-xl border border-gray-200 shadow-sm hover:shadow-md transition-shadow overflow-hidden">
          <div className="p-4">
            <div className="flex justify-between items-start mb-3">
              <div className="flex items-center">
                <div className={`w-10 h-10 rounded-lg flex items-center justify-center mr-3 ${
                  business.level === 'Headquarters' ? 'bg-transparent text-[#190192]' :
                  business.level === 'Subsidiary' ? 'bg-blue-100 text-blue-700' :
                  business.level === 'Branch' ? 'bg-green-100 text-green-700' :
                  'bg-amber-100 text-amber-700'
                }`}>
                  {business.businessLogoUrl ? (
                    <img 
                      src={business.businessLogoUrl} 
                      alt={business.name} 
                      className="w-full h-full object-contain rounded-lg"
                    />
                  ) : (
                    <LevelIcon size={20} />
                  )}
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">{business.name}</h3>
                  <p className="text-sm text-gray-500">{business.level} • {business.industry || 'No industry'}</p>
                </div>
              </div>
              
              {hasChildren && (
                <button 
                  onClick={() => toggleExpandGroup(business.id)} 
                  className="p-1 rounded-full hover:bg-gray-100"
                >
                  {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </button>
              )}
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-3">
              {business.address && (
                <div className="flex items-center text-sm text-gray-600">
                  <MapPin size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                  <span>{business.address}</span>
                </div>
              )}
              
              <div className="flex items-center text-sm text-gray-600">
                <Users size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                <span>{business.employees || 0} employees</span>
              </div>
              
              {business.website && (
                <div className="flex items-center text-sm text-gray-600">
                  <Globe size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                  <a 
                    href={business.website.startsWith('http') ? business.website : `https://${business.website}`} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-[#190192] hover:underline flex items-center"
                  >
                    {business.website} <ExternalLink size={12} className="ml-1" />
                  </a>
                </div>
              )}
              
              <div className="flex items-center text-sm text-gray-600">
                <Briefcase size={16} className="mr-2 text-gray-400 flex-shrink-0" />
                <span>{business.category || 'Products'} • {business.type || 'Physical'}</span>
              </div>
            </div>
            
            <div className="flex justify-between items-center pt-3 border-t border-gray-100">
              {hasChildren && (
                <div className="text-sm text-gray-600">
                  {childBusinesses.length} {childBusinesses.length === 1 ? 'entity' : 'entities'}
                </div>
              )}
              
              <Link
                to={`/business/${business.id}`}
                className="text-[#190192] hover:text-[#2C0DB5] text-sm font-medium flex items-center"
              >
                View Details <ChevronRight size={16} className="ml-1" />
              </Link>
            </div>
          </div>
        </div>
        
        {/* Render children if expanded */}
        {isExpanded && childBusinesses.map(child => renderBusinessCard(child, depth + 1))}
      </div>
    );
  };

  return (
    <div>
      {/* Search and Actions Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search businesses by name, industry, or location..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterLevel}
            onChange={(e) => setFilterLevel(e.target.value)}
          >
            <option value="all">All Levels</option>
            <option value="Headquarters">Headquarters</option>
            <option value="Subsidiary">Subsidiaries</option>
            <option value="Branch">Branches</option>
            <option value="Department">Departments</option>
          </select>
          
          <Link
            to="/business/new"
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5]"
          >
            <PlusCircle size={18} className="mr-2" />
            Add Business
          </Link>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : error ? (
        /* Error State */
        <div className="bg-red-50 rounded-lg py-6 px-6 text-center">
          <AlertCircle size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Error Loading Data</h3>
          <p className="text-gray-600 mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
          >
            Try Again
          </button>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {businesses.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Building size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No businesses found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You haven't added any businesses yet. Click the 'Add Business' button to get started.
              </p>
              <Link
                to="/business/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                <PlusCircle size={16} className="mr-2" />
                Add Your First Business
              </Link>
            </div>
          ) : getFilteredBusinesses().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Building size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching businesses</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any businesses matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterLevel('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Business List */
            <div>
              {getFilteredBusinesses().map(business => renderBusinessCard(business))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BusinessList;