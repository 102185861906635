// src/components/finance/reports/ReceivablesAgingReport.jsx
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { 
  Clock, AlertTriangle, CheckCircle, ExternalLink, FileText, Mail
} from 'lucide-react';
import ReportFilters from './ReportFilters';
import { useCurrency } from '../../../utils/currencyUtils';

const ReceivablesAgingReport = () => {
  const [searchParams] = useSearchParams();
  const { formatAmount } = useCurrency();
  const [filters, setFilters] = useState({
    dateRange: searchParams.get('period') || 'month',
    startDate: searchParams.get('startDate') || '',
    endDate: searchParams.get('endDate') || '',
    status: searchParams.get('status') || 'all',
    overdue: searchParams.get('overdue') === 'true'
  });
  
  const [reportData, setReportData] = useState({
    asOfDate: 'March 31, 2025',
    summary: {
      totalReceivables: 18650,
      current: 8250,
      overdue: 10400,
      overduePercentage: 55.8
    },
    agingCategories: [
      { name: 'Current', amount: 8250, percentage: 44.2 },
      { name: '1-30 Days', amount: 4800, percentage: 25.7 },
      { name: '31-60 Days', amount: 3450, percentage: 18.5 },
      { name: '61-90 Days', amount: 1200, percentage: 6.4 },
      { name: 'Over 90 Days', amount: 950, percentage: 5.1 }
    ],
    receivables: [
      {
        id: 'INV-2025-0128',
        customer: 'Acme Corporation',
        date: '2025-03-15',
        dueDate: '2025-04-14',
        amount: 3250,
        outstanding: 3250,
        status: 'current',
        daysPastDue: 0
      },
      {
        id: 'INV-2025-0115',
        customer: 'TechSolutions Inc.',
        date: '2025-02-28',
        dueDate: '2025-03-30',
        amount: 2800,
        outstanding: 2800,
        status: 'current',
        daysPastDue: 0
      },
      {
        id: 'INV-2025-0108',
        customer: 'GlobalTrade Co.',
        date: '2025-02-15',
        dueDate: '2025-03-17',
        amount: 4800,
        outstanding: 4800,
        status: 'overdue',
        daysPastDue: 14
      },
      {
        id: 'INV-2025-0094',
        customer: 'Innovate Enterprises',
        date: '2025-01-28',
        dueDate: '2025-02-27',
        amount: 3450,
        outstanding: 3450,
        status: 'overdue',
        daysPastDue: 32
      },
      {
        id: 'INV-2025-0072',
        customer: 'Summit Partners',
        date: '2025-01-02',
        dueDate: '2025-02-01',
        amount: 1200,
        outstanding: 1200,
        status: 'overdue',
        daysPastDue: 58
      },
      {
        id: 'INV-2024-0958',
        customer: 'Metro Supplies',
        date: '2024-12-05',
        dueDate: '2025-01-04',
        amount: 2250,
        outstanding: 2200,
        status: 'overdue',
        daysPastDue: 86
      },
      {
        id: 'INV-2024-0942',
        customer: 'Coastal Industries',
        date: '2024-11-20',
        dueDate: '2024-12-20',
        amount: 950,
        outstanding: 950,
        status: 'overdue',
        daysPastDue: 101
      }
    ]
  });
  
  // Handle filter changes
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    
    // In a real app, you would fetch new data based on filters
    // For now, we'll use the same data
  };
  
  // Filter the receivables list based on current filters
  const getFilteredReceivables = () => {
    let filtered = [...reportData.receivables];
    
    if (filters.status === 'current') {
      filtered = filtered.filter(item => item.status === 'current');
    } else if (filters.status === 'overdue') {
      filtered = filtered.filter(item => item.status === 'overdue');
    }
    
    if (filters.overdue) {
      filtered = filtered.filter(item => item.status === 'overdue');
    }
    
    return filtered;
  };
  
  // Format date string to readable format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };
  
  const filteredReceivables = getFilteredReceivables();
  
  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">Accounts Receivable Aging</h2>
      
      <ReportFilters 
        onFilterChange={handleFilterChange}
        availableFilters={['dateRange', 'status']}
        defaultDateRange={filters.dateRange}
      />
      
      {/* Summary Cards */}
      <div className="bg-white rounded-xl shadow-md p-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-medium text-gray-900">Receivables Summary</h3>
          <div className="text-sm font-medium text-gray-500">
            As of {reportData.asOfDate}
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Total Receivables */}
          <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-blue-900">Total Receivables</h4>
            </div>
            <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.summary.totalReceivables)}</p>
            <p className="text-sm text-blue-600 mt-1">From {reportData.receivables.length} invoices</p>
          </div>
          
          {/* Current */}
          <div className="bg-green-50 rounded-lg p-4 border border-green-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-green-900">Current (Not Due)</h4>
            </div>
            <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.summary.current)}</p>
            <p className="text-sm text-green-600 mt-1">{reportData.agingCategories[0].percentage}% of total</p>
          </div>
          
          {/* Overdue */}
          <div className="bg-amber-50 rounded-lg p-4 border border-amber-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-amber-900">Overdue</h4>
            </div>
            <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.summary.overdue)}</p>
            <p className="text-sm text-amber-600 mt-1">{reportData.summary.overduePercentage}% of total</p>
          </div>
          
          {/* Aging Distribution */}
          <div className="col-span-1 md:col-span-2 lg:col-span-1 bg-indigo-50 rounded-lg p-4 border border-indigo-100">
            <h4 className="text-sm font-medium text-indigo-900 mb-3">Aging Distribution</h4>
            
            <div className="space-y-3">
              {reportData.agingCategories.map((category, index) => (
                <div key={index}>
                  <div className="flex justify-between items-center text-xs mb-1">
                    <span className="text-gray-600">{category.name}</span>
                    <span className="text-gray-800 font-medium">{formatAmount(category.amount)}</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div 
                      className={`h-2 rounded-full ${
                        index === 0 ? 'bg-green-500' :
                        index === 1 ? 'bg-yellow-400' :
                        index === 2 ? 'bg-orange-400' :
                        index === 3 ? 'bg-red-400' : 'bg-red-600'
                      }`}
                      style={{ width: `${category.percentage}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      {/* Receivables Table */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="flex items-center justify-between p-6 border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-900">Accounts Receivable Details</h3>
          <div className="flex items-center">
            <span className="mr-2 text-sm text-gray-500">
              {filteredReceivables.length} of {reportData.receivables.length} invoices
            </span>
            <button className="flex items-center text-[#190192] text-sm font-medium">
              <FileText size={16} className="mr-1" />
              Export
            </button>
          </div>
        </div>
        
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Invoice
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Customer
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Issue Date
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Due Date
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Outstanding
                </th>
                <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredReceivables.map((invoice) => (
                <tr key={invoice.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-[#190192]">
                    <a href={`/finance/invoices/${invoice.id}`} className="hover:underline">
                      {invoice.id}
                    </a>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {invoice.customer}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatDate(invoice.date)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatDate(invoice.dueDate)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                    {formatAmount(invoice.amount)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-right">
                    {formatAmount(invoice.outstanding)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    {invoice.status === 'current' ? (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        <CheckCircle size={12} className="mr-1" />
                        Current
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
                        <Clock size={12} className="mr-1" />
                        {invoice.daysPastDue} days overdue
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex items-center justify-end space-x-3">
                      <button className="text-indigo-600 hover:text-indigo-900">
                        <Mail size={16} />
                      </button>
                      <button className="text-blue-600 hover:text-blue-900">
                        <ExternalLink size={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        {filteredReceivables.length === 0 && (
          <div className="text-center py-12">
            <AlertTriangle size={48} className="mx-auto text-gray-400 mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No receivables found</h3>
            <p className="text-gray-500 mb-6">
              There are no receivables matching your current filters.
            </p>
            <button
              onClick={() => setFilters(prev => ({ ...prev, status: 'all', overdue: false }))}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Clear Filters
            </button>
          </div>
        )}
      </div>
      
      {/* Collection Tips */}
      <div className="mt-6 bg-indigo-50 rounded-xl p-6 border border-indigo-100">
        <h3 className="text-lg font-medium text-indigo-900 mb-4">Collection Tips</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <h4 className="font-medium text-gray-900 mb-2">Send Reminders</h4>
            <p className="text-sm text-gray-600">
              Send friendly payment reminders before invoices are due and follow up on overdue payments promptly.
            </p>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <h4 className="font-medium text-gray-900 mb-2">Offer Payment Plans</h4>
            <p className="text-sm text-gray-600">
              For larger invoices, consider offering payment plans to make it easier for customers to pay.
            </p>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <h4 className="font-medium text-gray-900 mb-2">Early Payment Discounts</h4>
            <p className="text-sm text-gray-600">
              Encourage prompt payment by offering small discounts for early payments.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceivablesAgingReport;