// src/pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import { DollarSign, ShoppingCart, Package, Users, TrendingUp, Activity, 
         AlertTriangle, ChevronRight, ArrowUpRight, Calendar, Tag, ChevronDown } from 'lucide-react';
import { useAuth } from '../context/AuthContext';


const Dashboard = () => {
  const { currentUser, userProfile, loading, isDemoMode } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [timeframe, setTimeframe] = useState('week');
  const [showTimeframeDropdown, setShowTimeframeDropdown] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    stats: {
      revenue: 'R24,850',
      revenueChange: 8.2,
      orders: 142,
      ordersChange: 12.5,
      inventory: '76%',
      inventoryChange: -5.3,
      activeEmployees: 15,
      employeeChange: 2
    },
    recentActivity: [
      {
        id: 1,
        user: {
          name: 'Jennifer Davis',
          avatar: '/avatars/jennifer.jpg',
          initial: 'J',
          color: 'blue'
        },
        action: 'processed a new order #1089',
        time: '32 minutes ago',
        status: 'completed'
      },
      {
        id: 2,
        user: {
          name: 'Mike Kim',
          avatar: '/avatars/mike.jpg',
          initial: 'M',
          color: 'green'
        },
        action: 'updated inventory counts',
        time: '1 hour ago',
        status: 'completed'
      },
      {
        id: 3,
        user: {
          name: 'Amy Liu',
          avatar: '/avatars/amy.jpg',
          initial: 'A',
          color: 'purple'
        },
        action: 'approved expense report',
        time: '2 hours ago',
        status: 'pending'
      }
    ],
    businessInsights: [
      {
        id: 1,
        type: 'warning',
        title: 'Inventory Alert',
        description: 'Office Desk Chair (SKU #1242) inventory at 15% - reorder recommended',
        action: 'Order Now',
        icon: AlertTriangle
      },
      {
        id: 2,
        type: 'success',
        title: 'Sales Growth',
        description: 'Electronics category sales increased 22% compared to last month',
        action: 'View Report',
        icon: TrendingUp
      },
      {
        id: 3,
        type: 'info',
        title: 'Cash Flow Opportunity',
        description: 'Three unpaid invoices totaling $2,875 are past due',
        action: 'Send Reminders',
        icon: Activity
      }
    ],
    upcomingTasks: [
      {
        id: 1,
        title: 'Vendor Payment Review',
        time: 'Today, 2:00 PM',
        type: 'finance',
        icon: DollarSign
      },
      {
        id: 2,
        title: 'Marketing Strategy Meeting',
        time: 'Tomorrow, 10:00 AM',
        type: 'marketing',
        icon: Tag
      },
      {
        id: 3,
        title: 'Inventory Reconciliation',
        time: 'Mar 29, 3:30 PM',
        type: 'inventory',
        icon: Package
      }
    ],
    topProducts: [
      { id: 1, name: 'Wireless Headphones', units: 48, revenue: 4320, trend: 'up', trendValue: 2.4 },
      { id: 2, name: 'Office Desk Chair', units: 35, revenue: 8750, trend: 'up', trendValue: 5.7 },
      { id: 3, name: 'Portable Monitor', units: 29, revenue: 5800, trend: 'down', trendValue: 1.2 },
      { id: 4, name: 'Wireless Keyboard', units: 25, revenue: 2500, trend: 'up', trendValue: 3.8 },
      { id: 5, name: 'USB-C Hub Adapter', units: 22, revenue: 1320, trend: 'up', trendValue: 1.5 }
    ]
  });

  // Simulate loading data
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // Handle timeframe change
  const handleTimeframeChange = (newTimeframe) => {
    setTimeframe(newTimeframe);
    setShowTimeframeDropdown(false);
  };

  // Helper function to format currency
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
};

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin h-12 w-12 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  const getUserFirstName = () => {
    // Check for demo mode first
    if (isDemoMode) {
      return 'Guest';
    }
  
    // Check if userProfile exists and return first name
    if (userProfile) {
      return `${userProfile.firstName || ''}`.trim();
    }
  
    // If no userProfile, fallback to currentUser's email or 'User'
    return currentUser?.email || 'User';
  };
  

  // Get appropriate greeting based on time of day
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return 'Good morning';
    if (hour < 18) return 'Good afternoon';
    return 'Good evening';
  };

  // Get icon background and text color based on insight type
  const getInsightStyles = (type) => {
    switch (type) {
      case 'warning':
        return { bg: 'bg-amber-100', color: 'text-amber-600' };
      case 'success':
        return { bg: 'bg-green-100', color: 'text-green-600' };
      case 'info':
        return { bg: 'bg-blue-100', color: 'text-blue-600' };
      default:
        return { bg: 'bg-gray-100', color: 'text-gray-600' };
    }
  };

  // Get task icon background color based on task type
  const getTaskIconBg = (type) => {
    switch (type) {
      case 'finance':
        return 'bg-green-100 text-green-600';
      case 'marketing':
        return 'bg-purple-100 text-purple-600';
      case 'inventory':
        return 'bg-amber-100 text-amber-600';
      default:
        return 'bg-blue-100 text-blue-600';
    }
  };

  // Get timeframe label for display
  const getTimeframeLabel = () => {
    switch (timeframe) {
      case 'week':
        return 'This Week';
      case 'month':
        return 'This Month';
      case 'quarter':
        return 'This Quarter';
      case 'year':
        return 'This Year';
      default:
        return 'This Week';
    }
  };

  return (
    <div className="space-y-8 pb-10">
      {/* Header */}
      <div className="bg-gradient-to-r from-[#190192] to-[#3B0DB5] rounded-xl shadow-xl p-6 md:p-8 text-white">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="col-span-2">
            <h1 className="text-3xl font-bold mb-2">{getGreeting()}, {getUserFirstName()}</h1>
            <p className="text-purple-200">Here's what's happening with your business today</p>
          </div>
          <div className="flex justify-start md:justify-end items-center space-x-3">
            <div className="relative">
              <button 
                className="px-4 py-2 bg-white bg-opacity-20 hover:bg-opacity-30 rounded-lg transition-all flex items-center"
                onClick={() => setShowTimeframeDropdown(!showTimeframeDropdown)}
              >
                {getTimeframeLabel()}
                <ChevronDown size={16} className="ml-2" />
              </button>
              
              {showTimeframeDropdown && (
                <div className="absolute mt-1 right-0 w-40 bg-white rounded-lg shadow-lg py-1 z-10">
                  <button 
                    className={`w-full text-left px-4 py-2 text-sm hover:bg-purple-50 ${timeframe === 'week' ? 'text-[#190192] font-medium' : 'text-gray-700'}`}
                    onClick={() => handleTimeframeChange('week')}
                  >
                    This Week
                  </button>
                  <button 
                    className={`w-full text-left px-4 py-2 text-sm hover:bg-purple-50 ${timeframe === 'month' ? 'text-[#190192] font-medium' : 'text-gray-700'}`}
                    onClick={() => handleTimeframeChange('month')}
                  >
                    This Month
                  </button>
                  <button 
                    className={`w-full text-left px-4 py-2 text-sm hover:bg-purple-50 ${timeframe === 'quarter' ? 'text-[#190192] font-medium' : 'text-gray-700'}`}
                    onClick={() => handleTimeframeChange('quarter')}
                  >
                    This Quarter
                  </button>
                  <button 
                    className={`w-full text-left px-4 py-2 text-sm hover:bg-purple-50 ${timeframe === 'year' ? 'text-[#190192] font-medium' : 'text-gray-700'}`}
                    onClick={() => handleTimeframeChange('year')}
                  >
                    This Year
                  </button>
                </div>
              )}
            </div>
            <button className="px-4 py-2 bg-white text-[#190192] rounded-lg hover:bg-opacity-90 font-medium transition-all">
              Export Data
            </button>
          </div>
        </div>
      </div>
      
      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white rounded-xl shadow-md p-6 transition-all hover:shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <div className="w-12 h-12 bg-green-100 rounded-lg flex items-center justify-center text-green-600">
              <DollarSign size={24} />
            </div>
            <span className={`px-2.5 py-1 rounded-full text-xs font-medium ${
              dashboardData.stats.revenueChange >= 0 
                ? 'bg-green-100 text-green-800' 
                : 'bg-red-100 text-red-800'
            }`}>
              {dashboardData.stats.revenueChange >= 0 ? '+' : ''}
              {dashboardData.stats.revenueChange}%
            </span>
          </div>
          <h3 className="text-gray-500 text-sm font-medium">Monthly Revenue</h3>
          <p className="text-3xl font-bold mt-1 text-gray-800">{dashboardData.stats.revenue}</p>
        </div>
        
        <div className="bg-white rounded-xl shadow-md p-6 transition-all hover:shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <div className="w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center text-[#190192]">
              <ShoppingCart size={24} />
            </div>
            <span className={`px-2.5 py-1 rounded-full text-xs font-medium ${
              dashboardData.stats.ordersChange >= 0 
                ? 'bg-green-100 text-green-800' 
                : 'bg-red-100 text-red-800'
            }`}>
              {dashboardData.stats.ordersChange >= 0 ? '+' : ''}
              {dashboardData.stats.ordersChange}%
            </span>
          </div>
          <h3 className="text-gray-500 text-sm font-medium">New Orders</h3>
          <p className="text-3xl font-bold mt-1 text-gray-800">{dashboardData.stats.orders}</p>
        </div>
        
        <div className="bg-white rounded-xl shadow-md p-6 transition-all hover:shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <div className="w-12 h-12 bg-amber-100 rounded-lg flex items-center justify-center text-amber-600">
              <Package size={24} />
            </div>
            <span className={`px-2.5 py-1 rounded-full text-xs font-medium ${
              dashboardData.stats.inventoryChange >= 0 
                ? 'bg-green-100 text-green-800' 
                : 'bg-blue-100 text-blue-800'
            }`}>
              {dashboardData.stats.inventoryChange}%
            </span>
          </div>
          <h3 className="text-gray-500 text-sm font-medium">Inventory Status</h3>
          <p className="text-3xl font-bold mt-1 text-gray-800">{dashboardData.stats.inventory}</p>
        </div>
        
        <div className="bg-white rounded-xl shadow-md p-6 transition-all hover:shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <div className="w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center text-[#190192]">
              <Users size={24} />
            </div>
            <span className={`px-2.5 py-1 rounded-full text-xs font-medium ${
              dashboardData.stats.employeeChange >= 0 
                ? 'bg-green-100 text-green-800' 
                : 'bg-red-100 text-red-800'
            }`}>
              {dashboardData.stats.employeeChange >= 0 ? '+' : ''}
              {dashboardData.stats.employeeChange}
            </span>
          </div>
          <h3 className="text-gray-500 text-sm font-medium">Active Employees</h3>
          <p className="text-3xl font-bold mt-1 text-gray-800">{dashboardData.stats.activeEmployees}</p>
        </div>
      </div>
      
      {/* Charts and Activity Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Sales Trend Chart */}
        <div className="lg:col-span-2 bg-white rounded-xl shadow-md p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-semibold text-gray-800">Sales Performance</h3>
            <div className="flex space-x-2">
              <button className="px-3 py-1.5 text-sm bg-[#190192] text-white rounded-lg">Monthly</button>
              <button className="px-3 py-1.5 text-sm bg-gray-100 text-gray-600 rounded-lg">Quarterly</button>
              <button className="px-3 py-1.5 text-sm bg-gray-100 text-gray-600 rounded-lg">Yearly</button>
            </div>
          </div>
          <div className="h-64 flex items-center justify-center bg-gray-50 rounded-lg border border-gray-100">
            <p className="text-gray-400 flex items-center">
              <Activity className="mr-2" size={20} />
              Sales performance chart would appear here
            </p>
          </div>
          <div className="mt-4 grid grid-cols-3 gap-4">
            <div className="p-3 bg-gray-50 rounded-lg">
              <p className="text-xs text-gray-500">Total Sales</p>
              <p className="text-lg font-semibold mt-1">{formatCurrency(87620)}</p>
            </div>
            <div className="p-3 bg-gray-50 rounded-lg">
              <p className="text-xs text-gray-500">Avg. Order Value</p>
              <p className="text-lg font-semibold mt-1">{formatCurrency(512)}</p>
            </div>
            <div className="p-3 bg-gray-50 rounded-lg">
              <p className="text-xs text-gray-500">Conversion Rate</p>
              <p className="text-lg font-semibold mt-1">3.2%</p>
            </div>
          </div>
        </div>
        
        {/* Recent Activity */}
        <div className="bg-white rounded-xl shadow-md p-6">
          <h3 className="text-lg font-semibold text-gray-800 mb-6">Recent Activity</h3>
          <div className="space-y-6">
            {dashboardData.recentActivity.map((activity) => (
              <div key={activity.id} className="flex items-start">
                <div className={`w-10 h-10 rounded-full ${
                  activity.user.color === 'blue' ? 'bg-blue-100 text-blue-600' :
                  activity.user.color === 'green' ? 'bg-green-100 text-green-600' :
                  'bg-purple-100 text-[#190192]'
                } flex-shrink-0 mr-3 flex items-center justify-center font-medium`}>
                  {activity.user.initial}
                </div>
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-800">{activity.user.name}</p>
                    <span className={`px-2 py-0.5 rounded text-xs ${
                      activity.status === 'completed' 
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-amber-100 text-amber-800'
                    }`}>
                      {activity.status === 'completed' ? 'Completed' : 'Pending'}
                    </span>
                  </div>
                  <p className="text-sm text-gray-600">{activity.action}</p>
                  <p className="text-xs text-gray-500 mt-1">{activity.time}</p>
                </div>
              </div>
            ))}
          </div>
          <button className="w-full mt-6 py-2 text-sm text-[#190192] font-medium hover:text-[#2C0DB5] flex items-center justify-center">
            View All Activity <ChevronRight size={16} className="ml-1" />
          </button>
        </div>
      </div>
      
      {/* Business Insights and Upcoming Tasks */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-xl shadow-md p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-semibold text-gray-800">Business Insights</h3>
            <button className="text-[#190192] hover:text-[#2C0DB5] text-sm font-medium">Manage Alerts</button>
          </div>
          <div className="space-y-4">
            {dashboardData.businessInsights.map((insight) => {
              const styles = getInsightStyles(insight.type);
              return (
                <div key={insight.id} className="p-4 rounded-lg border border-gray-100 hover:border-gray-200 transition-all">
                  <div className="flex items-start">
                    <div className={`w-8 h-8 rounded-lg flex items-center justify-center flex-shrink-0 mr-3 ${styles.bg} ${styles.color}`}>
                      <insight.icon size={18} />
                    </div>
                    <div className="flex-1">
                      <h4 className="font-medium text-gray-800">{insight.title}</h4>
                      <p className="text-sm text-gray-600 mt-1">{insight.description}</p>
                      <button className="mt-2 text-sm font-medium text-[#190192] hover:text-[#2C0DB5] flex items-center">
                        {insight.action} <ArrowUpRight size={14} className="ml-1" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        
        {/* Upcoming Tasks Section */}
        <div className="bg-white rounded-xl shadow-md p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-semibold text-gray-800">Upcoming Tasks</h3>
            <button className="text-[#190192] hover:text-[#2C0DB5] text-sm font-medium flex items-center">
              <Calendar size={16} className="mr-1" />
              View Calendar
            </button>
          </div>
          <div className="space-y-4">
            {dashboardData.upcomingTasks.map((task) => (
              <div key={task.id} className="p-4 rounded-lg border border-gray-100 hover:border-gray-200 transition-all">
                <div className="flex items-start">
                  <div className={`w-8 h-8 rounded-lg flex items-center justify-center flex-shrink-0 mr-3 ${getTaskIconBg(task.type)}`}>
                    <task.icon size={18} />
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-800">{task.title}</h4>
                    <p className="text-sm text-gray-500 mt-1">{task.time}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button className="w-full mt-6 py-2 border border-gray-200 rounded-lg text-sm font-medium text-gray-600 hover:text-gray-800 hover:border-gray-300 flex items-center justify-center">
            Add New Task <ChevronRight size={16} className="ml-1" />
          </button>
        </div>
      </div>
      
      {/* Top Products Section */}
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-semibold text-gray-800">Top Selling Products</h3>
          <button className="text-[#190192] hover:text-[#2C0DB5] text-sm font-medium">View All Products</button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="border-b border-gray-200">
                <th className="pb-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                <th className="pb-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Units</th>
                <th className="pb-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Revenue</th>
                <th className="pb-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Trend</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {dashboardData.topProducts.map((product) => (
                <tr key={product.id} className="hover:bg-gray-50">
                  <td className="py-4 text-sm font-medium text-gray-800">{product.name}</td>
                  <td className="py-4 text-sm text-gray-600 text-right">{product.units}</td>
                  <td className="py-4 text-sm text-gray-800 text-right font-medium">{formatCurrency(product.revenue)}</td>
                  <td className="py-4 text-right">
                    <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                      product.trend === 'up' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}>
                      {product.trend === 'up' ? '+' : '-'}
                      {product.trendValue}%
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;