// src/components/finance/reports/ExpenseReport.jsx
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { 
  PieChart, BarChart, 
  ChevronRight, ChevronDown, 
  TrendingUp, TrendingDown,
  ExternalLink
} from 'lucide-react';
import ReportFilters from './ReportFilters';
import { useCurrency } from '../../../utils/currencyUtils';

const ExpenseReport = () => {
  const [searchParams] = useSearchParams();
  const { formatAmount } = useCurrency();
  const [filters, setFilters] = useState({
    dateRange: searchParams.get('period') || 'month',
    startDate: searchParams.get('startDate') || '',
    endDate: searchParams.get('endDate') || '',
    compareWith: searchParams.get('compare') || 'none'
  });
  
  const [expandedCategories, setExpandedCategories] = useState({});
  
  const [reportData, setReportData] = useState({
    period: 'March 2025',
    comparisonPeriod: 'February 2025',
    summary: {
      totalExpenses: 24850,
      previousTotalExpenses: 23500,
      change: 5.7
    },
    expenseCategories: [
      {
        name: 'Salaries & Wages',
        amount: 12500,
        previousAmount: 11800,
        change: 5.9,
        percentage: 50.3,
        items: [
          { name: 'Regular Salaries', amount: 10000, previousAmount: 9500, change: 5.3 },
          { name: 'Overtime Pay', amount: 1500, previousAmount: 1300, change: 15.4 },
          { name: 'Benefits', amount: 1000, previousAmount: 1000, change: 0 }
        ]
      },
      {
        name: 'Rent & Utilities',
        amount: 4350,
        previousAmount: 4300,
        change: 1.2,
        percentage: 17.5,
        items: [
          { name: 'Office Rent', amount: 3500, previousAmount: 3500, change: 0 },
          { name: 'Electricity', amount: 450, previousAmount: 420, change: 7.1 },
          { name: 'Water & Sanitation', amount: 150, previousAmount: 130, change: 15.4 },
          { name: 'Internet & Phone', amount: 250, previousAmount: 250, change: 0 }
        ]
      },
      {
        name: 'Supplies & Materials',
        amount: 3200,
        previousAmount: 3100,
        change: 3.2,
        percentage: 12.9,
        items: [
          { name: 'Office Supplies', amount: 850, previousAmount: 820, change: 3.7 },
          { name: 'Inventory Purchases', amount: 2100, previousAmount: 2050, change: 2.4 },
          { name: 'Shipping Materials', amount: 250, previousAmount: 230, change: 8.7 }
        ]
      },
      {
        name: 'Marketing & Advertising',
        amount: 2450,
        previousAmount: 2300,
        change: 6.5,
        percentage: 9.9,
        items: [
          { name: 'Online Advertising', amount: 1500, previousAmount: 1400, change: 7.1 },
          { name: 'Print Materials', amount: 450, previousAmount: 420, change: 7.1 },
          { name: 'Marketing Services', amount: 500, previousAmount: 480, change: 4.2 }
        ]
      },
      {
        name: 'Professional Services',
        amount: 1350,
        previousAmount: 1250,
        change: 8.0,
        percentage: 5.4,
        items: [
          { name: 'Accounting Services', amount: 800, previousAmount: 750, change: 6.7 },
          { name: 'Legal Services', amount: 350, previousAmount: 300, change: 16.7 },
          { name: 'Consulting Fees', amount: 200, previousAmount: 200, change: 0 }
        ]
      },
      {
        name: 'Other Expenses',
        amount: 1000,
        previousAmount: 750,
        change: 33.3,
        percentage: 4.0,
        items: [
          { name: 'Bank Fees', amount: 220, previousAmount: 200, change: 10.0 },
          { name: 'Insurance', amount: 450, previousAmount: 450, change: 0 },
          { name: 'Subscriptions', amount: 330, previousAmount: 100, change: 230.0 }
        ]
      }
    ],
    vendors: [
      { name: 'Office Solutions Inc.', amount: 3250, previousAmount: 3100, change: 4.8 },
      { name: 'Payroll Services Co.', amount: 12500, previousAmount: 11800, change: 5.9 },
      { name: 'Marketing Partners', amount: 1500, previousAmount: 1400, change: 7.1 },
      { name: 'Utility Provider', amount: 850, previousAmount: 800, change: 6.3 },
      { name: 'Legal Associates', amount: 350, previousAmount: 300, change: 16.7 }
    ],
    expensesByMonth: [
      { month: 'Jan', amount: 23200 },
      { month: 'Feb', amount: 23500 },
      { month: 'Mar', amount: 24850 }
    ]
  });
  
  // Toggle category expansion
  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };
  
  // Handle filter changes
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    
    // In a real app, you would fetch new data based on filters
    // For now, we'll just update the period labels
    if (newFilters.dateRange === 'month') {
      setReportData(prev => ({
        ...prev,
        period: 'March 2025',
        comparisonPeriod: 'February 2025'
      }));
    } else if (newFilters.dateRange === 'quarter') {
      setReportData(prev => ({
        ...prev,
        period: 'Q1 2025',
        comparisonPeriod: 'Q4 2024'
      }));
    } else if (newFilters.dateRange === 'year') {
      setReportData(prev => ({
        ...prev,
        period: '2025',
        comparisonPeriod: '2024'
      }));
    }
  };
  
  // Sort expense categories by amount (descending)
  const sortedExpenseCategories = [...reportData.expenseCategories].sort((a, b) => b.amount - a.amount);
  
  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">Expense Analysis</h2>
      
      <ReportFilters 
        onFilterChange={handleFilterChange}
        availableFilters={['dateRange', 'compare', 'includeSubitems']}
        defaultDateRange={filters.dateRange}
      />
      
      {/* Expense Summary */}
      <div className="bg-white rounded-xl shadow-md p-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg font-medium text-gray-900">Expense Summary</h3>
          <div className="text-sm font-medium text-gray-500">
            {reportData.period} {filters.compareWith !== 'none' && `vs ${reportData.comparisonPeriod}`}
          </div>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Total Expenses Card */}
          <div className="bg-red-50 rounded-lg p-4 border border-red-100">
            <div className="flex justify-between items-start mb-2">
              <h4 className="text-sm font-medium text-red-900">Total Expenses</h4>
              {filters.compareWith !== 'none' && (
                <span className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full ${
                  reportData.summary.change >= 0 
                    ? 'bg-red-100 text-red-800' 
                    : 'bg-green-100 text-green-800'
                }`}>
                  {reportData.summary.change >= 0 ? '+' : ''}
                  {reportData.summary.change}%
                </span>
              )}
            </div>
            <p className="text-2xl font-bold text-gray-900">{formatAmount(reportData.summary.totalExpenses)}</p>
            {filters.compareWith !== 'none' && (
              <p className="text-sm text-gray-500 mt-1">vs {formatAmount(reportData.summary.previousTotalExpenses)}</p>
            )}
          </div>
          
          {/* Expense Distribution Visualization */}
          <div className="lg:col-span-2 bg-gray-50 rounded-lg p-4 border border-gray-200">
            <h4 className="text-sm font-medium text-gray-700 mb-3">Expense Distribution</h4>
            
            <div className="space-y-3">
              {sortedExpenseCategories.map((category, index) => (
                <div key={index}>
                  <div className="flex justify-between items-center text-xs mb-1">
                    <span className="text-gray-600">{category.name}</span>
                    <div className="flex items-center">
                      <span className="text-gray-800 font-medium mr-2">{formatAmount(category.amount)}</span>
                      <span className="text-gray-500">({category.percentage}%)</span>
                    </div>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div 
                      className={`h-2 rounded-full ${
                        index === 0 ? 'bg-red-500' :
                        index === 1 ? 'bg-blue-500' :
                        index === 2 ? 'bg-green-500' :
                        index === 3 ? 'bg-yellow-500' :
                        index === 4 ? 'bg-purple-500' : 'bg-gray-500'
                      }`}
                      style={{ width: `${category.percentage}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      {/* Expense Details */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="flex items-center justify-between p-6 border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-900">Expense Details by Category</h3>
          <div className="flex items-center">
            <button className="ml-2 text-[#190192] text-sm font-medium flex items-center">
              <PieChart size={16} className="mr-1" />
              View Charts
            </button>
          </div>
        </div>
        
        <div className="p-6">
          {/* Expense Categories */}
          {sortedExpenseCategories.map((category, index) => (
            <div key={index} className="mb-6">
              <button
                onClick={() => toggleCategory(category.name)}
                className="flex items-center justify-between w-full text-left mb-3"
              >
                <div className="flex items-center">
                  {expandedCategories[category.name] ? (
                    <ChevronDown size={18} className="text-gray-500 mr-2" />
                  ) : (
                    <ChevronRight size={18} className="text-gray-500 mr-2" />
                  )}
                  <span className="font-medium text-gray-900">{category.name}</span>
                </div>
                <div className="flex items-center">
                  <span className="font-medium text-gray-900 mr-3">{formatAmount(category.amount)}</span>
                  {filters.compareWith !== 'none' && (
                    <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                      category.change >= 0 
                        ? 'bg-red-100 text-red-800' 
                        : 'bg-green-100 text-green-800'
                    }`}>
                      {category.change >= 0 ? (
                        <>
                          <TrendingUp size={12} className="mr-1" />
                          +{category.change}%
                        </>
                      ) : (
                        <>
                          <TrendingDown size={12} className="mr-1" />
                          {category.change}%
                        </>
                      )}
                    </span>
                  )}
                </div>
              </button>
              
              {expandedCategories[category.name] && filters.includeSubitems && (
                <div className="pl-6 mb-6">
                  <div className="border rounded-lg overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Subcategory
                          </th>
                          {filters.compareWith !== 'none' && (
                            <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Previous
                            </th>
                          )}
                          <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Amount
                          </th>
                          {filters.compareWith !== 'none' && (
                            <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Change
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {category.items.map((item, itemIndex) => (
                          <tr key={itemIndex} className="hover:bg-gray-50">
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                              {item.name}
                            </td>
                            {filters.compareWith !== 'none' && (
                              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500 text-right">
                                {formatAmount(item.previousAmount)}
                              </td>
                            )}
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900 text-right font-medium">
                              {formatAmount(item.amount)}
                            </td>
                            {filters.compareWith !== 'none' && (
                              <td className="px-4 py-2 whitespace-nowrap text-right">
                                <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                                  item.change >= 0 
                                    ? 'bg-red-100 text-red-800' 
                                    : 'bg-green-100 text-green-800'
                                }`}>
                                  {item.change >= 0 ? `+${item.change}%` : `${item.change}%`}
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              
              {index < sortedExpenseCategories.length - 1 && (
                <div className="border-b border-gray-200 my-4"></div>
              )}
            </div>
          ))}
        </div>
      </div>
      
      {/* Additional Views */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
        {/* Top Vendors */}
        <div className="bg-white rounded-xl shadow-md overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">Top Vendors</h3>
          </div>
          
          <div className="p-6">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Vendor Name
                    </th>
                    <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                    {filters.compareWith !== 'none' && (
                      <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Change
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {reportData.vendors.map((vendor, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 font-medium">
                        <a href="#" className="flex items-center text-[#190192] hover:underline">
                          {vendor.name}
                          <ExternalLink size={14} className="ml-1" />
                        </a>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 text-right font-medium">
                        {formatAmount(vendor.amount)}
                      </td>
                      {filters.compareWith !== 'none' && (
                        <td className="px-4 py-3 whitespace-nowrap text-right">
                          <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${
                            vendor.change >= 0 
                              ? 'bg-red-100 text-red-800' 
                              : 'bg-green-100 text-green-800'
                          }`}>
                            {vendor.change >= 0 ? (
                              <>
                                <TrendingUp size={12} className="mr-1" />
                                +{vendor.change}%
                              </>
                            ) : (
                              <>
                                <TrendingDown size={12} className="mr-1" />
                                {vendor.change}%
                              </>
                            )}
                          </span>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        {/* Expense Trend */}
        <div className="bg-white rounded-xl shadow-md overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">Monthly Expense Trend</h3>
          </div>
          
          <div className="p-6">
            <div className="h-64 flex items-center justify-center bg-gray-50 rounded-lg border border-gray-100">
              <div className="w-full px-6">
                {/* Simple bar chart visualization */}
                <div className="flex items-end h-40 space-x-4">
                  {reportData.expensesByMonth.map((month, index) => {
                    const maxAmount = Math.max(...reportData.expensesByMonth.map(m => m.amount));
                    const height = (month.amount / maxAmount) * 100;
                    
                    return (
                      <div key={index} className="flex-1 flex flex-col items-center">
                        <div 
                          className={`w-full ${
                            reportData.period.includes(month.month) ? 'bg-[#190192]' : 'bg-blue-400'
                          } rounded-t`}
                          style={{ height: `${height}%` }}
                        ></div>
                        <div className="mt-2 text-xs font-medium text-gray-600">{month.month}</div>
                        <div className="mt-1 text-xs text-gray-500">{formatAmount(month.amount)}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="mt-4 flex justify-center">
              <button className="flex items-center text-[#190192] text-sm font-medium">
                <BarChart size={16} className="mr-1" />
                View Detailed Trend Analysis
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {/* Expense Reduction Tips */}
      <div className="mt-6 bg-indigo-50 rounded-xl p-6 border border-indigo-100">
        <h3 className="text-lg font-medium text-indigo-900 mb-4">Cost Reduction Opportunities</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <h4 className="font-medium text-gray-900 mb-2">Review Subscriptions</h4>
            <p className="text-sm text-gray-600">
              Your subscription expenses increased by 230%. Review all subscriptions for potential consolidation or cancellation.
            </p>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <h4 className="font-medium text-gray-900 mb-2">Negotiate with Vendors</h4>
            <p className="text-sm text-gray-600">
              Consider negotiating better rates with your top vendors to reduce overall expenses.
            </p>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm">
           <h4 className="font-medium text-gray-900 mb-2">Negotiate with Vendors</h4>
           <p className="text-sm text-gray-600">
             Consider negotiating better rates with your top vendors to reduce overall expenses.
           </p>
         </div>
         <div className="bg-white rounded-lg p-4 shadow-sm">
           <h4 className="font-medium text-gray-900 mb-2">Optimize Utility Usage</h4>
           <p className="text-sm text-gray-600">
             Implement energy-saving measures to reduce utility costs, which have increased by over 7% from last period.
           </p>
         </div>
       </div>
     </div>
   </div>
 );
};

export default ExpenseReport;