// src/components/inventory/suppliers/SupplierForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  ChevronLeft, Check, AlertCircle, Building, 
  MapPin, Globe, Phone, Mail, User, FileText,
  DollarSign, Tag, CreditCard, Clock
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { db } from '../../../firebase/config';


const SupplierForm = ({ isEditing = false }) => {
  const { supplierId } = useParams();
  const navigate = useNavigate();
  const { currentUser, isDemoMode } = useAuth();

  // Form state
  const [formData, setFormData] = useState({
    name: '',
    contactPerson: '',
    email: '',
    phone: '',
    mobile: '',
    website: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    category: '',
    taxId: '',
    paymentTerms: '',
    creditLimit: '',
    currency: 'USD',
    notes: '',
    status: 'active'
  });

  // State for validation errors and loading state
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  // Supplier categories
  const supplierCategories = [
    'Raw Materials',
    'Packaging',
    'Electronics',
    'Office Supplies',
    'Services',
    'Maintenance',
    'Equipment',
    'Other'
  ];
  
  // Payment terms options
  const paymentTermsOptions = [
    { value: 'immediate', label: 'Immediate' },
    { value: 'net15', label: 'Net 15 days' },
    { value: 'net30', label: 'Net 30 days' },
    { value: 'net45', label: 'Net 45 days' },
    { value: 'net60', label: 'Net 60 days' },
    { value: 'eom', label: 'End of Month' },
    { value: 'custom', label: 'Custom Terms' }
  ];
  
  // Currency options
  const currencyOptions = [
    { code: 'USD', name: 'US Dollar' },
    { code: 'EUR', name: 'Euro' },
    { code: 'GBP', name: 'British Pound' },
    { code: 'ZAR', name: 'South African Rand' },
    { code: 'JPY', name: 'Japanese Yen' },
    { code: 'CAD', name: 'Canadian Dollar' },
    { code: 'AUD', name: 'Australian Dollar' },
    { code: 'INR', name: 'Indian Rupee' }
  ];

  // Fetch supplier data if editing
  useEffect(() => {
    if (isEditing && supplierId) {
      setIsLoading(true);
      
      // In a real app, this would be an API call to fetch the supplier data
      setTimeout(() => {
        // Mock supplier data
        const mockSupplier = {
          id: supplierId,
          name: 'Global Textiles Inc.',
          contactPerson: 'John Smith',
          email: 'orders@globaltextiles.com',
          phone: '+1 (555) 987-6543',
          mobile: '+1 (555) 123-7890',
          website: 'https://www.globaltextiles.com',
          address: '123 Manufacturing Blvd',
          city: 'New York',
          state: 'NY',
          zipCode: '10001',
          country: 'United States',
          category: 'Raw Materials',
          taxId: 'TX-12345678',
          paymentTerms: 'net30',
          creditLimit: '25000',
          currency: 'USD',
          notes: 'Reliable supplier for all fabric materials.',
          status: 'active'
        };
        
        setFormData(mockSupplier);
        setIsLoading(false);
      }, 800);
    }
  }, [isEditing, supplierId]);

  // Handle text input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Use the checked value for checkboxes
    const newValue = type === 'checkbox' ? checked : value;
    
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Validate the form
  const validateForm = () => {
    const errors = {};
    
    // Required fields
    if (!formData.name.trim()) errors.name = 'Supplier name is required';
    if (!formData.email.trim()) errors.email = 'Email address is required';
    if (!formData.phone.trim()) errors.phone = 'Phone number is required';
    
    // Format validations
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email format is invalid';
    }
    
    if (formData.website && !/^(http|https):\/\/[^ "]+$/.test(formData.website)) {
      errors.website = 'Website URL must start with http:// or https://';
    }
    
    if (formData.creditLimit && isNaN(parseFloat(formData.creditLimit))) {
      errors.creditLimit = 'Credit limit must be a valid number';
    }
    
    return errors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

        if (isDemoMode) {
            alert("Demo Mode Restriction:\nIn demo mode, data modifications are not saved. Sign up for a free account to use all features.");
            return;
        }
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      // Scroll to first error
      const firstError = document.querySelector('.text-red-600');
      if (firstError) {
        firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
    
    setIsLoading(true);
    
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // In a real app, you'd post to an API here
      console.log('Submitting supplier data:', formData);
      
      // Navigate back to the suppliers list or detail page
      navigate(isEditing ? `/inventory/suppliers/${supplierId}` : '/inventory/suppliers');
    } catch (error) {
      console.error('Error saving supplier:', error);
      setFormErrors(prev => ({
        ...prev,
        submit: 'An error occurred while saving. Please try again.'
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(isEditing ? `/inventory/suppliers/${supplierId}` : '/inventory/suppliers');
  };

  if (isLoading && isEditing) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xs shadow-md overflow-hidden">
      <div className="bg-white shadow-md px-6 py-4 text-gray-800">
        <h2 className="text-lg font-medium">
          {isEditing ? 'Edit Supplier' : 'Add New Supplier'}
        </h2>
        <p className="text-purple-500 text-sm">
          {isEditing 
            ? 'Update supplier information in the form below' 
            : 'Fill out the form below to add a new supplier to your inventory'}
        </p>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        <div className="mb-6">
          <button 
            type="button"
            onClick={handleCancel}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Suppliers
          </button>
        </div>
        
        {/* Basic Information */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            {/* Company Name */}
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Company Name <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Building size={18} className="text-gray-400" />
                </div>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.name ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="Enter company name"
                  value={formData.name}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {formErrors.name && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.name}
                </p>
              )}
            </div>
            
            {/* Contact Person */}
            <div>
              <label htmlFor="contactPerson" className="block text-sm font-medium text-gray-700 mb-1">
                Contact Person
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User size={18} className="text-gray-400" />
                </div>
                <input
                  id="contactPerson"
                  name="contactPerson"
                  type="text"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  placeholder="Enter contact person name"
                  value={formData.contactPerson}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            {/* Email */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail size={18} className="text-gray-400" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.email ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="Enter email address"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {formErrors.email && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.email}
                </p>
              )}
            </div>
            
            {/* Website */}
            <div>
              <label htmlFor="website" className="block text-sm font-medium text-gray-700 mb-1">
                Website
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Globe size={18} className="text-gray-400" />
                </div>
                <input
                  id="website"
                  name="website"
                  type="url"
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.website ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="https://www.example.com"
                  value={formData.website}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {formErrors.website && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.website}
                </p>
              )}
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            {/* Phone Number */}
            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                Phone Number <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Phone size={18} className="text-gray-400" />
                </div>
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.phone ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="Enter phone number"
                  value={formData.phone}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {formErrors.phone && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.phone}
                </p>
              )}
            </div>
            
            {/* Mobile Number */}
            <div>
              <label htmlFor="mobile" className="block text-sm font-medium text-gray-700 mb-1">
                Mobile Number
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Phone size={18} className="text-gray-400" />
                </div>
                <input
                  id="mobile"
                  name="mobile"
                  type="tel"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  placeholder="Enter mobile number"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
          
          {/* Category */}
          <div className="mb-4">
            <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
              Category
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Tag size={18} className="text-gray-400" />
              </div>
              <select
                id="category"
                name="category"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.category}
                onChange={handleInputChange}
                disabled={isLoading}
              >
                <option value="">Select a category</option>
                {supplierCategories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        
        {/* Address Information */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Address Information</h3>
          
          {/* Street Address */}
          <div className="mb-4">
            <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
              Street Address
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MapPin size={18} className="text-gray-400" />
              </div>
              <input
                id="address"
                name="address"
                type="text"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                placeholder="Enter street address"
                value={formData.address}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </div>
          </div>
          
          <div className="grid grid-cols-2 gap-4 mb-4">
            {/* City */}
            <div>
              <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-1">
                City
              </label>
              <input
                id="city"
                name="city"
                type="text"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                placeholder="Enter city"
                value={formData.city}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </div>
            
            {/* State/Province */}
            <div>
              <label htmlFor="state" className="block text-sm font-medium text-gray-700 mb-1">
                State/Province
              </label>
              <input
                id="state"
                name="state"
                type="text"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                placeholder="Enter state/province"
                value={formData.state}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </div>
          </div>
          
          <div className="grid grid-cols-2 gap-4">
            {/* Zip/Postal Code */}
            <div>
              <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700 mb-1">
                Zip/Postal Code
              </label>
              <input
                id="zipCode"
                name="zipCode"
                type="text"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                placeholder="Enter zip/postal code"
                value={formData.zipCode}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </div>
            
            {/* Country */}
            <div>
              <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">
                Country
              </label>
              <input
                id="country"
                name="country"
                type="text"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                placeholder="Enter country"
                value={formData.country}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
        
        {/* Financial Information */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Financial Information</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            {/* Tax ID */}
            <div>
              <label htmlFor="taxId" className="block text-sm font-medium text-gray-700 mb-1">
                Tax ID / VAT Number
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FileText size={18} className="text-gray-400" />
                </div>
                <input
                  id="taxId"
                  name="taxId"
                  type="text"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  placeholder="Enter tax ID or VAT number"
                  value={formData.taxId}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
            </div>
            
            {/* Payment Terms */}
            <div>
              <label htmlFor="paymentTerms" className="block text-sm font-medium text-gray-700 mb-1">
                Payment Terms
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Clock size={18} className="text-gray-400" />
                </div>
                <select
                  id="paymentTerms"
                  name="paymentTerms"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.paymentTerms}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  <option value="">Select payment terms</option>
                  {paymentTermsOptions.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Credit Limit */}
            <div>
              <label htmlFor="creditLimit" className="block text-sm font-medium text-gray-700 mb-1">
                Credit Limit
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <DollarSign size={18} className="text-gray-400" />
                </div>
                <input
                  id="creditLimit"
                  name="creditLimit"
                  type="text"
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.creditLimit ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  placeholder="Enter credit limit"
                  value={formData.creditLimit}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {formErrors.creditLimit && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.creditLimit}
                </p>
              )}
            </div>
            
            {/* Currency */}
            <div>
              <label htmlFor="currency" className="block text-sm font-medium text-gray-700 mb-1">
                Currency
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <CreditCard size={18} className="text-gray-400" />
                </div>
                <select
                  id="currency"
                  name="currency"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.currency}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  {currencyOptions.map(currency => (
                    <option key={currency.code} value={currency.code}>
                      {currency.code} - {currency.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        
        {/* Additional Information */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Additional Information</h3>
          
          {/* Notes */}
          <div className="mb-4">
            <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
              Notes
            </label>
            <textarea
              id="notes"
              name="notes"
              rows="4"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              placeholder="Enter any additional notes about this supplier"
              value={formData.notes}
              onChange={handleInputChange}
              disabled={isLoading}
            ></textarea>
          </div>
          
          {/* Status */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Status
            </label>
            <div className="flex space-x-4">
              <div className="flex items-center">
                <input
                  id="status-active"
                  name="status"
                  type="radio"
                  value="active"
                  checked={formData.status === 'active'}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300"
                  disabled={isLoading}
                />
                <label htmlFor="status-active" className="ml-2 block text-sm text-gray-700">
                  Active
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="status-inactive"
                  name="status"
                  type="radio"
                  value="inactive"
                  checked={formData.status === 'inactive'}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300"
                  disabled={isLoading}
                />
                <label htmlFor="status-inactive" className="ml-2 block text-sm text-gray-700">
                  Inactive
                </label>
              </div>
            </div>
          </div>
        </div>
        
        {/* Form submission error message */}
        {formErrors.submit && (
          <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-red-600 text-sm flex items-center">
              <AlertCircle size={16} className="mr-2" />
              {formErrors.submit}
            </p>
          </div>
        )}
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-4 mt-10 pt-6 border-t border-gray-200">
          <button
            type="button"
            onClick={handleCancel}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
            disabled={isLoading}
          >
            Cancel
          </button>
          
          <button
            type="submit"
            disabled={isLoading}
            className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
              isLoading ? 'bg-purple-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
            }`}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <Check size={18} className="mr-2" />
                {isEditing ? 'Update Supplier' : 'Create Supplier'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SupplierForm;