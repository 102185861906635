// src/utils/currencyUtils.js
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';

// List of currency options for the dropdown
export const currencyOptions = [
  { code: 'USD', name: 'US Dollar', symbol: '$' },
  { code: 'EUR', name: 'Euro', symbol: '€' },
  { code: 'GBP', name: 'British Pound', symbol: '£' },
  { code: 'JPY', name: 'Japanese Yen', symbol: '¥' },
  { code: 'CAD', name: 'Canadian Dollar', symbol: 'CA$' },
  { code: 'AUD', name: 'Australian Dollar', symbol: 'A$' },
  { code: 'ZAR', name: 'South African Rand', symbol: 'R' },
  { code: 'INR', name: 'Indian Rupee', symbol: '₹' },
  { code: 'CNY', name: 'Chinese Yuan', symbol: '¥' },
  { code: 'BRL', name: 'Brazilian Real', symbol: 'R$' },
  { code: 'NGN', name: 'Nigerian Naira', symbol: '₦' },
  { code: 'MXN', name: 'Mexican Peso', symbol: 'Mex$' },
  // Add more currencies as needed
];

// Format currency based on provided currency code
export function formatCurrency(amount, currencyCode = 'USD', options = {}) {
  if (amount === null || amount === undefined) return '';
  
  // Default formatting options
  const defaultOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  
  // Merge default options with any passed in
  const formattingOptions = { ...defaultOptions, ...options };
  
  try {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: currencyCode,
      ...formattingOptions
    }).format(amount);
  } catch (error) {
    // Fallback to USD if the currency code is invalid
    console.warn(`Invalid currency code: ${currencyCode}. Falling back to USD.`);
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
      ...formattingOptions
    }).format(amount);
  }
}

// Hook to use the application's current currency
export function useCurrency() {
  const { activeBusiness } = useContext(AppContext);
  
  const formatAmount = (amount, options = {}) => {
    const currencyCode = activeBusiness?.currency || 'ZAR';
    return formatCurrency(amount, currencyCode, options);
  };
  
  const getCurrencyCode = () => {
    return activeBusiness?.currency || 'ZAR';
  };
  
  const getCurrencySymbol = () => {
    const currencyCode = activeBusiness?.currency || 'ZAR';
    const currency = currencyOptions.find(c => c.code === currencyCode);
    return currency?.symbol || '$';
  };
  
  return {
    formatAmount,
    getCurrencyCode,
    getCurrencySymbol
  };
}