// src/pages/CommunicationPage.js
import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MessageSquare, Mail, Bot, ChevronRight } from 'lucide-react';

const CommunicationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(getActiveTab());

  function getActiveTab() {
    const path = location.pathname.split('/')[2] || 'internal';
    return path;
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`/communication/${tab}`);
  };

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="bg-gradient-to-r from-[#190192] to-[#3B0DB5] px-6 py-4 text-white">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold">Communication</h1>
              <p className="text-purple-200 text-sm">
                Manage all your business communications in one place
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="border-b border-gray-200">
          <nav className="flex space-x-8 px-6" aria-label="Tabs">
            <button
              onClick={() => handleTabChange('internal')}
              className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm ${
                activeTab === 'internal'
                  ? 'border-[#190192] text-[#190192]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <MessageSquare className="h-5 w-5 mr-2" />
              Internal Messaging
            </button>
            <button
              onClick={() => handleTabChange('external')}
              className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm ${
                activeTab === 'external'
                  ? 'border-[#190192] text-[#190192]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <Mail className="h-5 w-5 mr-2" />
              External Messaging
            </button>
            <button
              onClick={() => handleTabChange('ai')}
              className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm ${
                activeTab === 'ai'
                  ? 'border-[#190192] text-[#190192]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <Bot className="h-5 w-5 mr-2" />
              AI Chat
            </button>
          </nav>
        </div>

        {/* Tab Content */}
        <div className="p-0">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default CommunicationPage;