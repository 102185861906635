// src/components/communication/DirectMessageList.js
import React, { useState } from 'react';
import { Plus, Search, X, User } from 'lucide-react';

const DirectMessageList = ({ directMessages, activeChannel, onDirectMessageSelect, isDirectMessage, getColorForInitial }) => {
  const [showNewMessagePanel, setShowNewMessagePanel] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Mock search functionality
  const handleSearch = () => {
    if (!searchTerm.trim()) return;
    
    setIsLoading(true);
    // In a real app, this would search users in Firestore
    setTimeout(() => {
      setSearchResults([
        { id: 'user1', name: 'Jane Smith', role: 'Marketing' },
        { id: 'user2', name: 'John Doe', role: 'Operations' },
        { id: 'user3', name: 'Alice Johnson', role: 'Finance' }
      ].filter(user => 
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
      ));
      setIsLoading(false);
    }, 500);
  };
  
  return (
    <div className="px-4 py-3">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-xs font-semibold text-gray-300 uppercase tracking-wider">Direct Messages</h3>
        <button 
          className="text-gray-300 hover:text-white"
          onClick={() => setShowNewMessagePanel(!showNewMessagePanel)}
        >
          <Plus size={16} />
        </button>
      </div>
      
      {/* New Message Panel */}
      {showNewMessagePanel && (
        <div className="mb-3 bg-[#ffffff22] p-2 rounded-md">
          <div className="flex items-center justify-between mb-2">
            <p className="text-xs text-white font-medium">New Message</p>
            <button
              className="text-gray-300 hover:text-white"
              onClick={() => setShowNewMessagePanel(false)}
            >
              <X size={14} />
            </button>
          </div>
          
          <div className="mb-2">
            <div className="relative">
              <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={14} />
              <input
                type="text"
                placeholder="Search users..."
                className="w-full pl-8 pr-2 py-1 text-sm border rounded focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white text-gray-800"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
              />
            </div>
            <button
              onClick={handleSearch}
              className="mt-1 text-xs text-white hover:underline w-full text-center block"
            >
              Search
            </button>
          </div>
          
          <div className="max-h-40 overflow-y-auto">
            {isLoading ? (
              <div className="text-center py-2">
                <div className="animate-spin h-4 w-4 border-2 border-white rounded-full border-t-transparent mx-auto"></div>
                <p className="text-xs text-white mt-1">Searching...</p>
              </div>
            ) : searchResults.length > 0 ? (
              searchResults.map((user) => (
                <div 
                  key={user.id}
                  className="flex items-center p-2 hover:bg-[#ffffff33] rounded cursor-pointer"
                  onClick={() => {
                    // In a real app, this would start a conversation with the user
                    console.log('Start conversation with:', user.id);
                    setShowNewMessagePanel(false);
                  }}
                >
                  <div className={`w-6 h-6 rounded-full bg-${getColorForInitial(user.name[0])}-500 flex items-center justify-center text-white text-xs font-medium mr-2`}>
                    {user.name[0]}
                  </div>
                  <div>
                    <p className="text-sm font-medium text-white">{user.name}</p>
                    <p className="text-xs text-gray-300">{user.role}</p>
                  </div>
                </div>
              ))
            ) : searchTerm ? (
              <p className="text-xs text-white text-center py-2">
                No users match your search
              </p>
            ) : (
              <p className="text-xs text-white text-center py-2">
                Search for users to message
              </p>
            )}
          </div>
        </div>
      )}
      
      {/* Existing Direct Messages List */}
      <div className="space-y-1">
        {directMessages.map((dm) => (
          <button
            key={dm.id}
            className={`flex items-center justify-between w-full px-2 py-1.5 rounded-md text-sm ${
              activeChannel === dm.id && isDirectMessage
                ? 'bg-[#ffffff33] text-white'
                : 'hover:bg-[#ffffff22] text-gray-300'
            }`}
            onClick={() => onDirectMessageSelect(dm.id)}
          >
            <div className="flex items-center">
              <div className="relative">
                <div className={`w-6 h-6 rounded-full bg-${dm.color}-500 flex items-center justify-center text-white font-medium mr-2`}>
                  {dm.avatar}
                </div>
                <span className={`absolute bottom-0 right-0 w-2 h-2 rounded-full border border-[#190192] ${
                  dm.status === 'online' ? 'bg-green-500' :
                  dm.status === 'away' ? 'bg-yellow-500' :
                  'bg-gray-300'
                }`}></span>
              </div>
              <span>{dm.name}</span>
            </div>
            {dm.unread > 0 && (
              <span className="bg-white text-[#190192] text-xs font-medium px-2 py-0.5 rounded-full">
                {dm.unread}
              </span>
            )}
          </button>
        ))}
        
        {directMessages.length === 0 && !showNewMessagePanel && (
          <div className="text-center py-4">
            <User className="mx-auto h-6 w-6 text-gray-300 opacity-50" />
            <p className="text-sm text-gray-300 mt-2">No direct messages yet</p>
            <p className="text-xs text-gray-400 mt-1">Click the + icon to start a conversation</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DirectMessageList;