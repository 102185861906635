// src/components/finance/AccountsList.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, PlusCircle, ChevronRight, DollarSign, 
  CreditCard, Briefcase, Download, AlertCircle, Building, TrendingUp, FileText
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
// Make sure this import path is correct
import { AppContext } from '../../context/AppContext';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useCurrency, formatCurrency } from '../../utils/currencyUtils';

const AccountsList = () => {
  // Add debugging
  console.log('AppContext object:', AppContext);
  
  const { currentUser } = useAuth();
  // Let's verify if context is available
  const context = useContext(AppContext);
  console.log('Context value from useContext:', context);
  
  // Now try to use the context safely
  const { activeBusiness, loading: contextLoading } = context || { activeBusiness: null, loading: false };
  console.log('Active business from context:', activeBusiness);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState('all');

  const appContext = useContext(AppContext);
 
  const { formatAmount } = useCurrency();

  // Fetch accounts
  useEffect(() => {
    const fetchAccounts = async () => {
      if (!currentUser) return;
      
      setIsLoading(true);
      
      try {
        // First fetch businesses
        const businessQuery = query(
          collection(db, 'businesses'),
          where('createdBy', '==', currentUser.uid)
        );
        
        const businessSnapshot = await getDocs(businessQuery);
        const businessesData = [];
        
        businessSnapshot.forEach((doc) => {
          businessesData.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setBusinesses(businessesData);
        
        // Then fetch accounts
        let accountsQuery;
        
        if (selectedBusiness === 'all') {
          // Fetch all accounts for current user's businesses
          const businessIds = businessesData.map(business => business.id);
          accountsQuery = query(
            collection(db, 'accounts'),
            where('businessId', 'in', businessIds.length > 0 ? businessIds : ['none'])
          );
        } else {
          // Fetch accounts for selected business
          accountsQuery = query(
            collection(db, 'accounts'),
            where('businessId', '==', selectedBusiness)
          );
        }
        
        const accountsSnapshot = await getDocs(accountsQuery);
        const accountsData = [];
        
        accountsSnapshot.forEach((doc) => {
          accountsData.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setAccounts(accountsData);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchAccounts();
  }, [currentUser, selectedBusiness]);

  // Filter accounts based on search term and account type
  const getFilteredAccounts = () => {
    return accounts.filter(account => {
      // Filter by search term
      const matchesSearch = 
        account.accountName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        account.accountNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        account.description?.toLowerCase().includes(searchTerm.toLowerCase());
      
      // Filter by account type
      const matchesType = filterType === 'all' || account.accountType === filterType;
      
      return matchesSearch && matchesType;
    });
  };

  // Get unique account types
  const accountTypes = ['all', ...new Set(accounts.map(account => account.accountType))];

  // Get business name by ID
  const getBusinessName = (businessId) => {
    const business = businesses.find(b => b.id === businessId);
    return business ? business.name : 'Unknown Business';
  };

  // Format currency
  const formatCurrency = (value, currency = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(value || 0);
  };

  // Get account type icon
  const getAccountTypeIcon = (type) => {
    switch (type) {
      case 'bank':
        return <Building size={18} className="text-blue-600" />;
      case 'cash':
        return <DollarSign size={18} className="text-green-600" />;
      case 'credit_card':
        return <CreditCard size={18} className="text-purple-600" />;
      case 'investment':
        return <TrendingUp size={18} className="text-amber-600" />;
      case 'loan':
        return <FileText size={18} className="text-red-600" />;
      default:
        return <Briefcase size={18} className="text-gray-600" />;
    }
  };

  return (
    <div>
      {/* Search and Actions Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search accounts by name, number or description..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex flex-wrap gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={selectedBusiness}
            onChange={(e) => setSelectedBusiness(e.target.value)}
          >
            <option value="all">All Businesses</option>
            {businesses.map(business => (
              <option key={business.id} value={business.id}>{business.name}</option>
            ))}
          </select>
          
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="all">All Account Types</option>
            {accountTypes.filter(type => type !== 'all').map(type => (
              <option key={type} value={type}>
                {type.charAt(0).toUpperCase() + type.slice(1).replace('_', ' ')}
              </option>
            ))}
          </select>
          
          <Link
            to="/finance/accounts/new"
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5]"
          >
            <PlusCircle size={18} className="mr-2" />
            Add Account
          </Link>
          
          <button className="p-2.5 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50">
            <Download size={18} />
          </button>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {accounts.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Briefcase size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No accounts found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You haven't added any accounts yet. Accounts help you track your finances across different sources.
              </p>
              <Link
                to="/finance/accounts/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                <PlusCircle size={16} className="mr-2" />
                Add Your First Account
              </Link>
            </div>
          ) : getFilteredAccounts().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Briefcase size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching accounts</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any accounts matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterType('all'); setSelectedBusiness('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Accounts Table */
            <div className="bg-white rounded-xl shadow-md overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Account
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Type
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Business
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Balance
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {getFilteredAccounts().map((account) => (
                    <tr key={account.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10 rounded-md bg-gray-100 flex items-center justify-center">
                            {getAccountTypeIcon(account.accountType)}
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{account.accountName}</div>
                            {account.accountNumber && (
                              <div className="text-sm text-gray-500">
                                {account.accountType === 'credit_card' ? 
                                  `•••• ${account.accountNumber.slice(-4)}` : 
                                  account.accountNumber}
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2.5 py-0.5 inline-flex text-xs leading-5 font-medium rounded-full bg-gray-100 text-gray-800">
                          {account.accountType.charAt(0).toUpperCase() + account.accountType.slice(1).replace('_', ' ')}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {getBusinessName(account.businessId)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-right">
                        <span className={`font-medium ${account.balance < 0 ? 'text-red-600' : 'text-gray-900'}`}>
                            {formatAmount(account.balance)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link
                          to={`/finance/accounts/${account.id}`}
                          className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
                        >
                          View <ChevronRight size={16} className="ml-1" />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AccountsList;