// src/components/inventory/receiving/ReceivingList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, ChevronRight, Truck, 
  Calendar, CheckCircle, XCircle, Download,
  ShoppingBag, Clock
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';

const ReceivingList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [receivingOrders, setReceivingOrders] = useState([]);

  // Fetch receiving orders
  useEffect(() => {
    const fetchReceivingOrders = async () => {
      setIsLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(r => setTimeout(r, 800));
        
        // Mock receiving orders data
        const mockData = [
          {
            id: '1',
            receiptNumber: 'GRN-2025-001',
            poNumber: 'PO-2025-002',
            supplier: {
              id: '2',
              name: 'Tech Components Ltd'
            },
            receiveDate: '2025-03-15',
            status: 'complete',
            items: 3,
            notes: 'All items received in good condition'
          },
          {
            id: '2',
            receiptNumber: 'GRN-2025-002',
            poNumber: 'PO-2025-003',
            supplier: {
              id: '3',
              name: 'Packaging Solutions'
            },
            receiveDate: '2025-03-20',
            status: 'partial',
            items: 1,
            notes: 'Partial delivery, remaining items expected next week'
          },
          {
            id: '3',
            receiptNumber: null,
            poNumber: 'PO-2025-001',
            supplier: {
              id: '1',
              name: 'Global Textiles Inc.'
            },
            receiveDate: null,
            status: 'pending',
            items: 5,
            notes: ''
          }
        ];
        
        setReceivingOrders(mockData);
      } catch (error) {
        console.error('Error fetching receiving orders:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchReceivingOrders();
  }, []);

  // Get filtered receiving orders
  const getFilteredReceivingOrders = () => {
    let filtered = receivingOrders;
    
    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(order => 
        (order.receiptNumber?.toLowerCase().includes(searchTerm.toLowerCase()) || false) ||
        order.poNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Filter by status
    if (filterStatus !== 'all') {
      filtered = filtered.filter(order => order.status === filterStatus);
    }
    
    return filtered;
  };
  
  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return 'Not received';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Get status badge color
  const getStatusBadge = (status) => {
    switch (status) {
      case 'complete':
        return { bg: 'bg-green-100', text: 'text-green-800', icon: CheckCircle };
      case 'partial':
        return { bg: 'bg-yellow-100', text: 'text-yellow-800', icon: Clock };
      case 'pending':
        return { bg: 'bg-blue-100', text: 'text-blue-800', icon: Truck };
      default:
        return { bg: 'bg-gray-100', text: 'text-gray-800', icon: Clock };
    }
  };

  return (
    <div>
      {/* Search and Filters Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search by GRN, PO number or supplier..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Statuses</option>
            <option value="complete">Complete</option>
            <option value="partial">Partial</option>
            <option value="pending">Pending</option>
          </select>
          
          <button className="p-2.5 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50">
            <Download size={18} />
          </button>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {receivingOrders.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Truck size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No receiving orders found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You don't have any items to receive. Create a purchase order first.
              </p>
              <Link
                to="/inventory/buying/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                Create Purchase Order
              </Link>
            </div>
          ) : getFilteredReceivingOrders().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Truck size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching receiving orders</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any receiving orders matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterStatus('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Receiving Orders Table */
            <div className="bg-white rounded-xl shadow-md overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      GRN / PO Number
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Supplier
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Receive Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Items
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {getFilteredReceivingOrders().map((order) => {
                    const statusBadge = getStatusBadge(order.status);
                    const StatusIcon = statusBadge.icon;
                    
                    return (
                      <tr key={order.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {order.receiptNumber || 'Pending Receipt'}
                          </div>
                          <div className="text-sm text-gray-500 flex items-center mt-1">
                            <ShoppingBag size={14} className="mr-1 text-gray-400" />
                            {order.poNumber}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <Link to={`/inventory/suppliers/${order.supplier.id}`} className="hover:text-[#190192]">
                            {order.supplier.name}
                          </Link>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <div className="flex items-center">
                            <Calendar size={14} className="text-gray-400 mr-1" />
                            {formatDate(order.receiveDate)}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${statusBadge.bg} ${statusBadge.text}`}>
                            <StatusIcon size={12} className="mr-1" />
                            {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                          {order.items}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          {order.status === 'pending' ? (
                            <Link
                              to={`/inventory/buying/${order.id}/receive`}
                              className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
                            >
                              Receive <ChevronRight size={16} className="ml-1" />
                            </Link>
                          ) : (
                            <Link
                              to={`/inventory/receiving/${order.id}`}
                              className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
                            >
                              View <ChevronRight size={16} className="ml-1" />
                            </Link>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ReceivingList;