import React, { useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, Settings } from 'lucide-react';
import logo from '../assets/images/logo.png';
import { useAuth } from '../context/AuthContext';

const PublicLayout = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  const handleDemoMode = () => {
    auth.enableDemoMode();
    setMobileMenuOpen(false);
    navigate('/dashboard'); // Navigate to Dashboard after enabling demo mode
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const navigation = [
    { name: 'Home', path: '/' },
    { name: 'Features', path: '/features' },
    { name: 'Pricing', path: '/pricing' },
    { name: 'About', path: '/about' },
    { name: 'Contact', path: '/contact' }
  ];

  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Navigation */}
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/" className="flex-shrink-0 flex items-center">
                  <img className="h-8 w-auto" src={logo} alt="Schoolmate Logo" />
                </Link>
              </div>
              <nav className="hidden sm:ml-6 sm:flex sm:space-x-8">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
                      isActive(item.path)
                        ? 'border-[#190192] text-[#190192]'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`}
                  >
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>

            <div className="hidden sm:ml-6 sm:flex sm:items-center sm:space-x-4">
              <button
                onClick={handleDemoMode}
                className="block px-4 py-2 text-base font-medium text-white bg-amber-500 rounded-lg hover:bg-amber-600"
              >
                Try Demo
              </button>
              <Link
                to="/login"
                className={`px-4 py-2 text-sm font-medium rounded-lg ${
                  isActive('/login')
                    ? 'bg-[#190192] text-white'
                    : 'text-[#190192] border border-[#190192] hover:bg-purple-50'
                }`}
              >
                Sign In
              </Link>
              <Link
                to="/register"
                className={`px-4 py-2 text-sm font-medium rounded-lg ${
                  isActive('/register')
                    ? 'bg-gray-100 text-gray-800'
                    : 'bg-[#190192] text-white hover:bg-[#2C0DB5]'
                }`}
              >
                Get Started
              </Link>
            </div>

            <div className="flex items-center sm:hidden">
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
              >
                {mobileMenuOpen ? (
                  <X className="block h-6 w-6" />
                ) : (
                  <Menu className="block h-6 w-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        {mobileMenuOpen && (
          <div className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`block pl-3 pr-4 py-2 border-l-4 text-base font-medium ${
                    isActive(item.path)
                      ? 'bg-purple-50 border-[#190192] text-[#190192]'
                      : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800'
                  }`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4 space-x-3">
                <button
                  onClick={handleDemoMode}
                  className="block px-4 py-2 text-base font-medium text-white bg-amber-500 rounded-lg hover:bg-amber-600"
                >
                  Try Demo
                </button>
                <Link
                  to="/login"
                  className="block text-base font-medium text-[#190192] hover:text-[#2C0DB5]"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Sign In
                </Link>
                <Link
                  to="/register"
                  className="block px-4 py-2 text-base font-medium text-white bg-[#190192] rounded-lg hover:bg-[#2C0DB5]"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        )}
      </header>

      {/* Main Content */}
      <main className="flex-grow">
        <Outlet />
      </main>
    </div>
  );
};

export default PublicLayout;
