// src/components/records/RecordDetail.js
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Edit, Download, Trash2, Calendar, 
  Clock, Tag, User, Users, Briefcase, Building, 
  Package, Wrench, FileText, MessageSquare, AlertCircle,
  CheckCircle, DollarSign, Image, Film, ExternalLink,
  AlertTriangle, X 
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../firebase/config';
import { 
  doc, getDoc, deleteDoc, collection, 
  getDocs, query, where 
} from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';

const RecordDetail = () => {
  const { recordId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  const [record, setRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  
  // Reference data
  const [business, setBusiness] = useState(null);
  const [targetRef, setTargetRef] = useState(null);
  const [associatedTask, setAssociatedTask] = useState(null);
  
  // Fetch record and related data
  useEffect(() => {
    const fetchRecordData = async () => {
      if (!recordId || !currentUser) return;
      
      setIsLoading(true);
      
      try {
        // Fetch record
        const recordRef = doc(db, 'records', recordId);
        const recordSnap = await getDoc(recordRef);
        
        if (!recordSnap.exists()) {
          console.error('Record not found');
          navigate('/records');
          return;
        }
        
        const recordData = {
          id: recordSnap.id,
          ...recordSnap.data(),
          date: recordSnap.data().date?.toDate() || new Date(),
          startDate: recordSnap.data().startDate?.toDate() || null,
          endDate: recordSnap.data().endDate?.toDate() || null
        };
        
        setRecord(recordData);
        
        // Fetch business data
        if (recordData.businessId) {
          const businessRef = doc(db, 'businesses', recordData.businessId);
          const businessSnap = await getDoc(businessRef);
          
          if (businessSnap.exists()) {
            setBusiness({
              id: businessSnap.id,
              ...businessSnap.data()
            });
          }
        }
        
        // Fetch target reference
        if (recordData.targetType && recordData.targetRef) {
          const targetCollectionName = getCollectionNameByType(recordData.targetType);
          if (targetCollectionName) {
            const targetDocRef = doc(db, targetCollectionName, recordData.targetRef);
            const targetSnap = await getDoc(targetDocRef);
            
            if (targetSnap.exists()) {
              const targetData = targetSnap.data();
              setTargetRef({
                id: targetSnap.id,
                type: recordData.targetType,
                name: targetData.name || targetData.fullName || 
                      `${targetData.firstName || ''} ${targetData.lastName || ''}`.trim(),
                ...targetData
              });
            }
          }
        }
        
        // Fetch associated task
        if (recordData.taskId) {
          const taskRef = doc(db, 'tasks', recordData.taskId);
          const taskSnap = await getDoc(taskRef);
          
          if (taskSnap.exists()) {
            setAssociatedTask({
              id: taskSnap.id,
              ...taskSnap.data(),
              dueDate: taskSnap.data().dueDate?.toDate() || null
            });
          }
        }
      } catch (error) {
        console.error('Error fetching record:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchRecordData();
  }, [recordId, currentUser, navigate]);
  
  // Helper to get collection name by target type
  const getCollectionNameByType = (type) => {
    switch (type) {
      case 'employee': return 'employees';
      case 'customer': return 'customers';
      case 'supplier': return 'suppliers';
      case 'structure': return 'structures';
      case 'stock': return 'stockItems';
      case 'asset': return 'assets';
      default: return null;
    }
  };
  
  // Format date
  const formatDate = (date) => {
    if (!date) return '';
    
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Format time
  const formatTime = (time) => {
    if (!time) return '';
    
    // Convert "HH:MM" string to displayable time
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
    
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };
  
  // Format duration
  const formatDuration = (startDate, endDate, startTime, endTime) => {
    if (!startDate || !endDate) return '';
    
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    // If times are provided
    if (startTime && endTime) {
      const [startHours, startMinutes] = startTime.split(':');
      const [endHours, endMinutes] = endTime.split(':');
      
      start.setHours(parseInt(startHours, 10), parseInt(startMinutes, 10), 0);
      end.setHours(parseInt(endHours, 10), parseInt(endMinutes, 10), 0);
    }
    
    // Calculate duration
    const durationMs = end.getTime() - start.getTime();
    
    if (durationMs <= 0) return 'Invalid duration';
    
    const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
    const hours = Math.floor((durationMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
    
    let result = '';
    if (days > 0) result += `${days} day${days !== 1 ? 's' : ''} `;
    if (hours > 0) result += `${hours} hour${hours !== 1 ? 's' : ''} `;
    if (minutes > 0) result += `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    
    return result.trim() || 'Less than a minute';
  };
  
  // Format currency
  const formatCurrency = (amount) => {
    if (amount === null || amount === undefined) return '';
    
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };
  
  // Get target icon component
  const getTargetIcon = () => {
    if (!record || !record.targetType) return FileText;
    
    switch (record.targetType) {
      case 'employee': return User;
      case 'customer': return Users;
      case 'supplier': return Briefcase;
      case 'structure': return Building;
      case 'stock': return Package;
      case 'asset': return Wrench;
      default: return FileText;
    }
  };
  
  // Get media type icon
  const getMediaTypeIcon = (type) => {
    switch (type) {
      case 'image': return Image;
      case 'video': return Film;
      case 'document': return FileText;
      default: return FileText;
    }
  };
  
  // Get status color
  const getStatusColor = (status) => {
    switch (status) {
      case 'green': return 'bg-green-500';
      case 'orange': return 'bg-orange-500';
      case 'red': return 'bg-red-500';
      default: return 'bg-gray-500';
    }
  };
  
  // Get status text
  const getStatusText = (status) => {
    switch (status) {
      case 'green': return 'Green (Normal)';
      case 'orange': return 'Orange (Warning)';
      case 'red': return 'Red (Critical)';
      default: return 'Unknown';
    }
  };
  
  // Handle delete record
  const handleDelete = async () => {
    try {
      // Delete media files first
      if (record.mediaUrls && record.mediaUrls.length > 0) {
        const deletePromises = record.mediaUrls.map(async (media) => {
          try {
            const storageRef = ref(storage, media.url);
            await deleteObject(storageRef);
          } catch (error) {
            console.error('Error deleting file:', error);
            // Continue with other deletions even if one fails
          }
        });
        
        await Promise.all(deletePromises);
      }
      
      // Delete the record document
      await deleteDoc(doc(db, 'records', recordId));
      
      // Navigate back to records list
      navigate('/records');
    } catch (error) {
      console.error('Error deleting record:', error);
      alert('An error occurred while deleting the record. Please try again.');
    }
  };
  
  // View media file
  const viewMediaFile = (url) => {
    window.open(url, '_blank');
  };
  
  if (isLoading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }
  
  if (!record) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <AlertCircle size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Record Not Found</h3>
          <p className="text-gray-500 mb-6">The record you're looking for doesn't exist or has been deleted.</p>
          <Link
            to="/records"
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Records
          </Link>
        </div>
      </div>
    );
  }

  // Define Target Icon component
  const TargetIcon = getTargetIcon();

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/records"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Records
          </Link>
          
          <div className="flex space-x-3">
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
              <Download size={20} />
            </button>
            <Link
              to={`/records/edit/${record.id}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Record Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className={`w-16 h-16 rounded-lg flex items-center justify-center flex-shrink-0 ${
              record.targetType ? 'bg-purple-100 text-[#190192]' : 'bg-gray-100 text-gray-500'
            }`}>
              <TargetIcon size={32} />
            </div>
            
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">
                  {record.recordId || `Record #${record.id.substring(0, 6)}`}
                </h1>
                
                <div className={`w-4 h-4 rounded-full ${getStatusColor(record.status)}`}></div>
              </div>
              
              <div className="flex flex-wrap gap-2 mt-2">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                  <Tag size={12} className="mr-1" />
                  {record.category || 'Uncategorized'}
                </span>
                
                {record.requireAction && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
                    <AlertTriangle size={12} className="mr-1" />
                    Action Required
                  </span>
                )}
                
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                  <Calendar size={12} className="mr-1" />
                  {formatDate(record.date)}
                </span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="p-6">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Left Column: Record Details */}
            <div className="lg:col-span-2 space-y-8">
              {/* Message */}
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Message</h3>
                <div className="bg-gray-50 rounded-lg p-4">
                  <p className="text-gray-800 whitespace-pre-line">{record.message}</p>
                </div>
              </div>
              
              {/* Target Information */}
              {record.targetType && targetRef && (
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Target Information</h3>
                  <div className="bg-gray-50 rounded-lg p-4">
                    <div className="flex items-start">
                      <div className="w-10 h-10 rounded-lg bg-purple-100 flex items-center justify-center text-[#190192] flex-shrink-0 mr-3">
                        <TargetIcon size={20} />
                      </div>
                      <div>
                        <h4 className="font-medium text-gray-900">
                          {targetRef.name || 'Unknown'}
                        </h4>
                        <p className="text-sm text-gray-500 capitalize">{record.targetType}</p>
                        
                        {/* Stock-specific information */}
                        {record.targetType === 'stock' && record.measurements?.includes('quantity') && (
                          <div className="mt-2 p-2 bg-blue-50 rounded border border-blue-100">
                            <p className="text-sm text-blue-800">
                              Quantity: <span className="font-medium">{record.quantity || 0}</span>
                            </p>
                            <p className="text-xs text-blue-600 mt-1">
                              This stock adjustment affects the inventory level
                            </p>
                          </div>
                        )}
                        
                        <Link 
                          to={`/${record.targetType}s/${record.targetRef}`}
                          className="mt-2 inline-flex items-center text-[#190192] text-sm hover:text-[#2C0DB5]"
                        >
                          View {record.targetType} details <ExternalLink size={12} className="ml-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              {/* Measurements */}
              {record.measurements && record.measurements.length > 0 && (
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Measurements</h3>
                  <div className="bg-gray-50 rounded-lg p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Date & Time Information */}
                    {(record.measurements.includes('date') || 
                      record.measurements.includes('time') || 
                      record.measurements.includes('duration')) && (
                      <div className="md:col-span-2 border-b border-gray-200 pb-4 mb-4">
                        <h4 className="font-medium text-gray-900 mb-2">Date & Time Information</h4>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {record.measurements.includes('date') && (
                            <div className="flex items-start">
                              <Calendar size={16} className="text-gray-500 mt-0.5 mr-2" />
                              <div>
                                <p className="text-sm text-gray-500">
                                  {record.measurements.includes('duration') ? 'Start Date' : 'Date'}
                                </p>
                                <p className="text-gray-900">{formatDate(record.startDate)}</p>
                              </div>
                            </div>
                          )}
                          
                          {record.measurements.includes('time') && (
                            <div className="flex items-start">
                              <Clock size={16} className="text-gray-500 mt-0.5 mr-2" />
                              <div>
                                <p className="text-sm text-gray-500">
                                  {record.measurements.includes('duration') ? 'Start Time' : 'Time'}
                                </p>
                                <p className="text-gray-900">{formatTime(record.startTime)}</p>
                              </div>
                            </div>
                          )}
                          
                          {/* End date/time if duration is measured */}
                          {record.measurements.includes('duration') && (
                            <>
                              {record.measurements.includes('date') && record.endDate && (
                                <div className="flex items-start">
                                  <Calendar size={16} className="text-gray-500 mt-0.5 mr-2" />
                                  <div>
                                    <p className="text-sm text-gray-500">End Date</p>
                                    <p className="text-gray-900">{formatDate(record.endDate)}</p>
                                  </div>
                                </div>
                              )}
                              
                              {record.measurements.includes('time') && record.endTime && (
                                <div className="flex items-start">
                                  <Clock size={16} className="text-gray-500 mt-0.5 mr-2" />
                                  <div>
                                    <p className="text-sm text-gray-500">End Time</p>
                                    <p className="text-gray-900">{formatTime(record.endTime)}</p>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        
                        {/* Duration calculation */}
                        {record.measurements.includes('duration') && 
                         record.startDate && record.endDate && (
                          <div className="mt-3 p-2 bg-gray-100 rounded">
                            <p className="text-sm">
                              <span className="font-medium">Duration: </span>
                              {formatDuration(
                                record.startDate, 
                                record.endDate, 
                                record.startTime, 
                                record.endTime
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    
                    {/* Quantity */}
                    {record.measurements.includes('quantity') && (
                      <div className="flex items-start">
                        <Package size={16} className="text-gray-500 mt-0.5 mr-2" />
                        <div>
                          <p className="text-sm text-gray-500">Quantity</p>
                          <p className="text-gray-900 font-medium">{record.quantity || 0}</p>
                        </div>
                      </div>
                    )}
                    
                    {/* Amount */}
                    {record.measurements.includes('amount') && (
                      <div className="flex items-start">
                        <DollarSign size={16} className="text-gray-500 mt-0.5 mr-2" />
                        <div>
                          <p className="text-sm text-gray-500">Amount</p>
                          <p className="text-gray-900 font-medium">{formatCurrency(record.amount)}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              
              {/* Media Gallery */}
              {record.mediaUrls && record.mediaUrls.length > 0 && (
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Media</h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {record.mediaUrls.map((media, index) => {
                      const MediaIcon = getMediaTypeIcon(media.type);
                      
                      return (
                        <div 
                          key={index} 
                          className="border rounded-lg overflow-hidden hover:shadow-md transition-shadow cursor-pointer"
                          onClick={() => viewMediaFile(media.url)}
                        >
                          {media.type === 'image' ? (
                            <div className="h-40 bg-gray-100">
                              <img 
                                src={media.url} 
                                alt={media.name || `Image ${index + 1}`}
                                className="w-full h-full object-cover"
                              />
                            </div>
                          ) : (
                            <div className="h-40 bg-gray-100 flex flex-col items-center justify-center">
                              <MediaIcon size={36} className="text-gray-400 mb-2" />
                              <p className="text-sm text-gray-500 capitalize">{media.type} File</p>
                            </div>
                          )}
                          
                          <div className="p-3 bg-white">
                            <div className="flex items-center">
                              <MediaIcon size={14} className="text-gray-500 mr-2" />
                              <p className="text-sm text-gray-700 truncate">
                                {media.name || `${media.type} ${index + 1}`}
                              </p>
                            </div>
                            <p className="text-xs text-gray-500 mt-1">Click to view</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            
            {/* Right Column: Sidebar */}
            <div className="lg:col-span-1">
              {/* Status */}
              <div className="bg-gray-50 rounded-xl p-5 mb-6">
                <h3 className="text-lg font-medium text-gray-900 mb-3">Status</h3>
                
                <div className="flex items-center mb-4">
                  <div className={`w-4 h-4 rounded-full ${getStatusColor(record.status)} mr-2`}></div>
                  <span className="font-medium">{getStatusText(record.status)}</span>
                </div>
                
                {record.requireAction && (
                  <div className="p-3 bg-amber-50 rounded-lg border border-amber-100 mb-4">
                    <div className="flex items-start">
                      <AlertTriangle size={16} className="text-amber-600 mt-0.5 mr-2 flex-shrink-0" />
                      <div>
                        <p className="text-amber-800 font-medium">Action Required</p>
                        <p className="text-sm text-amber-600">This record has been flagged as requiring action.</p>
                      </div>
                    </div>
                  </div>
                )}
                
                {/* Associated Task */}
                {record.taskId && (
                  <div className="p-3 bg-blue-50 rounded-lg border border-blue-100">
                    <div className="flex items-start">
                      <CheckCircle size={16} className="text-blue-600 mt-0.5 mr-2 flex-shrink-0" />
                      <div>
                        <p className="text-blue-800 font-medium">Associated Task</p>
                        {associatedTask ? (
                          <>
                            <p className="text-sm text-blue-700">{associatedTask.title}</p>
                            {associatedTask.dueDate && (
                              <p className="text-xs text-blue-600 mt-1">
                                Due: {formatDate(associatedTask.dueDate)}
                              </p>
                            )}
                          </>
                        ) : (
                          <p className="text-sm text-blue-600">Task is linked to this record</p>
                        )}
                        <Link
                          to={`/tasks/${record.taskId}`}
                          className="text-[#190192] hover:text-[#2C0DB5] text-sm font-medium inline-flex items-center mt-1"
                        >
                          View Task <ExternalLink size={14} className="ml-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              
              {/* Business Information */}
              {business && (
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Business</h3>
                  
                  <div className="flex items-start mb-3">
                    <Building size={16} className="text-gray-500 mt-0.5 mr-2 flex-shrink-0" />
                    <div>
                      <p className="text-gray-900 font-medium">{business.name}</p>
                      <p className="text-sm text-gray-500">
                        {business.level || 'Business'} • {business.industry || 'Industry'}
                      </p>
                    </div>
                  </div>
                  
                  <Link
                    to={`/business/${business.id}`}
                    className="text-[#190192] hover:text-[#2C0DB5] text-sm font-medium inline-flex items-center"
                  >
                    View Business <ExternalLink size={14} className="ml-1" />
                  </Link>
                </div>
              )}
              
              {/* Meta Information */}
              <div className="bg-gray-50 rounded-xl p-5">
                <h3 className="text-lg font-medium text-gray-900 mb-3">Information</h3>
                
                <div className="space-y-3">
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500">Created</span>
                    <span className="text-sm text-gray-900">{formatDate(record.createdAt?.toDate())}</span>
                  </div>
                  
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500">Last Updated</span>
                    <span className="text-sm text-gray-900">{formatDate(record.updatedAt?.toDate())}</span>
                  </div>
                  
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500">Record ID</span>
                    <span className="text-sm text-gray-900">{record.recordId || record.id.substring(0, 8)}</span>
                  </div>
                  
                  {record.type && (
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Record Type</span>
                      <span className="text-sm text-gray-900">{record.type}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Record</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this record? This action cannot be undone.
              {record.mediaUrls && record.mediaUrls.length > 0 && (
                <span className="block mt-2 text-red-600">
                  This will also permanently delete all associated media files.
                </span>
              )}
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
 };
 
 export default RecordDetail;