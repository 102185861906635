// src/components/communication/ChannelList.js
import React, { useState } from 'react';
import { Plus, Lock, ChevronDown, ChevronUp } from 'lucide-react';

const ChannelList = ({ channels, activeChannel, onChannelSelect, isDirectMessage }) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newChannelName, setNewChannelName] = useState('');
  const [newChannelType, setNewChannelType] = useState('public');
  const [error, setError] = useState('');
  
  // This would be implemented with Firebase in a real app
  const handleCreateChannel = (e) => {
    e.preventDefault();
    
    if (!newChannelName.trim()) {
      setError('Channel name is required');
      return;
    }
    
    // In a real app, you would create the channel in Firebase
    console.log('Creating channel:', {
      name: newChannelName,
      type: newChannelType
    });
    
    // Reset form
    setNewChannelName('');
    setShowCreateForm(false);
  };
  
  return (
    <div className="px-4 py-3">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-xs font-semibold text-gray-300 uppercase tracking-wider">Channels</h3>
        <button 
          className="text-gray-300 hover:text-white"
          onClick={() => setShowCreateForm(!showCreateForm)}
        >
          <Plus size={16} />
        </button>
      </div>
      
      {/* Create Channel Form */}
      {showCreateForm && (
        <div className="mb-3 bg-[#ffffff22] p-2 rounded-md">
          <form onSubmit={handleCreateChannel}>
            {error && (
              <p className="text-xs text-red-300 mb-1">{error}</p>
            )}
            <input
              type="text"
              placeholder="Channel name"
              className="w-full px-2 py-1 text-sm border rounded mb-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white text-gray-800"
              value={newChannelName}
              onChange={(e) => setNewChannelName(e.target.value)}
            />
            <div className="flex items-center space-x-2 mb-2">
              <label className="flex items-center text-xs text-white">
                <input
                  type="radio"
                  name="channelType"
                  value="public"
                  checked={newChannelType === 'public'}
                  onChange={() => setNewChannelType('public')}
                  className="mr-1"
                />
                Public
              </label>
              <label className="flex items-center text-xs text-white">
              <input
                 type="radio"
                 name="channelType"
                 value="private"
                 checked={newChannelType === 'private'}
                 onChange={() => setNewChannelType('private')}
                 className="mr-1"
               />
               Private
             </label>
           </div>
           <div className="flex justify-end">
             <button
               type="button"
               className="px-2 py-1 text-xs text-gray-300 hover:text-white mr-2"
               onClick={() => setShowCreateForm(false)}
             >
               Cancel
             </button>
             <button
               type="submit"
               className="px-2 py-1 text-xs bg-white text-[#190192] rounded hover:bg-gray-100"
             >
               Create
             </button>
           </div>
         </form>
       </div>
     )}
     
     {/* Channels List */}
     <div className="space-y-1">
       {channels.map((channel) => (
         <button
           key={channel.id}
           className={`flex items-center justify-between w-full px-2 py-1.5 rounded-md text-sm ${
             activeChannel === channel.id && !isDirectMessage
               ? 'bg-[#ffffff33] text-white'
               : 'hover:bg-[#ffffff22] text-gray-300'
           }`}
           onClick={() => onChannelSelect(channel.id)}
         >
           <div className="flex items-center">
             <span className="mr-2">#</span>
             <span>{channel.name}</span>
             {channel.type === 'private' && (
               <Lock size={12} className="ml-1 text-gray-400" />
             )}
           </div>
           {channel.unread > 0 && (
             <span className="bg-white text-[#190192] text-xs font-medium px-2 py-0.5 rounded-full">
               {channel.unread}
             </span>
           )}
         </button>
       ))}
       
       {channels.length === 0 && (
         <div className="text-center py-2 text-sm text-gray-400">
           No channels available
         </div>
       )}
     </div>
   </div>
 );
};

export default ChannelList;