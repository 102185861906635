// src/components/records/RecordList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, PlusCircle, ChevronRight, Calendar, 
  FileText, AlertCircle, CheckCircle, Clock, Download,
  MessageSquare, User, Users, Briefcase, Building, Package,
  X, Filter as FilterIcon, List, Grid, Wrench, Tag
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, query, where, orderBy, limit, 
  getDocs, startAfter, doc, getDoc 
} from 'firebase/firestore';

const RECORDS_PER_PAGE = 10;

// Record categories
const RECORD_CATEGORIES = [
  'All',
  'Administration', 
  'Operations', 
  'HR', 
  'Marketing', 
  'Finance', 
  'Legal', 
  'Policies'
];

// Target type icons mapping
const TARGET_ICONS = {
  'employee': User,
  'customer': Users,
  'supplier': Briefcase,
  'structure': Building,
  'stock': Package,
  'asset': Wrench
};

const RecordList = () => {
  const { currentUser } = useAuth();
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  
  // Filter and search state
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('All');
  const [filterStatus, setFilterStatus] = useState('All');
  const [filterTarget, setFilterTarget] = useState('All');
  const [filterRequiresAction, setFilterRequiresAction] = useState(false);
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  
  // Display options
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'grid'
  const [showFilters, setShowFilters] = useState(false);
  
  // Reference data for display
  const [targetRefs, setTargetRefs] = useState({});
  const [businessRefs, setBusinessRefs] = useState({});
  
  // Fetch records
  useEffect(() => {
    const fetchRecords = async () => {
      if (!currentUser) return;
      
      setIsLoading(true);
      
      try {
        // Build the query
        let recordsQuery = query(
          collection(db, 'records'),
          where('userId', '==', currentUser.uid),
          orderBy('date', 'desc'),
          limit(RECORDS_PER_PAGE)
        );
        
        // Apply filters if necessary
        if (filterCategory !== 'All') {
          recordsQuery = query(
            collection(db, 'records'),
            where('userId', '==', currentUser.uid),
            where('category', '==', filterCategory),
            orderBy('date', 'desc'),
            limit(RECORDS_PER_PAGE)
          );
        }
        
        // Execute the query
        const querySnapshot = await getDocs(recordsQuery);
        
        // Process the results
        const recordsData = [];
        querySnapshot.forEach((doc) => {
          recordsData.push({
            id: doc.id,
            ...doc.data(),
            date: doc.data().date?.toDate() || new Date()
          });
        });
        
        // Store the last visible document for pagination
        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastVisible(lastDoc);
        
        // Update state
        setRecords(recordsData);
        setHasMore(recordsData.length === RECORDS_PER_PAGE);
        
        // Fetch reference data for records
        fetchReferencesForRecords(recordsData);
      } catch (error) {
        console.error('Error fetching records:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchRecords();
  }, [currentUser, filterCategory]);
  
  // Fetch more records (pagination)
  const fetchMoreRecords = async () => {
    if (!hasMore || !lastVisible) return;
    
    setIsLoadingMore(true);
    
    try {
      // Build the query
      let recordsQuery = query(
        collection(db, 'records'),
        where('userId', '==', currentUser.uid),
        orderBy('date', 'desc'),
        startAfter(lastVisible),
        limit(RECORDS_PER_PAGE)
      );
      
      // Apply filters if necessary
      if (filterCategory !== 'All') {
        recordsQuery = query(
          collection(db, 'records'),
          where('userId', '==', currentUser.uid),
          where('category', '==', filterCategory),
          orderBy('date', 'desc'),
          startAfter(lastVisible),
          limit(RECORDS_PER_PAGE)
        );
      }
      
      // Execute the query
      const querySnapshot = await getDocs(recordsQuery);
      
      // Process the results
      const newRecords = [];
      querySnapshot.forEach((doc) => {
        newRecords.push({
          id: doc.id,
          ...doc.data(),
          date: doc.data().date?.toDate() || new Date()
        });
      });
      
      // Store the last visible document for next pagination
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisible(lastDoc);
      
      // Update state
      setRecords(prev => [...prev, ...newRecords]);
      setHasMore(newRecords.length === RECORDS_PER_PAGE);
      
      // Fetch reference data for new records
      fetchReferencesForRecords(newRecords);
    } catch (error) {
      console.error('Error fetching more records:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };
  
  // Fetch reference data for records (business names, target names)
  const fetchReferencesForRecords = async (recordsToProcess) => {
    const businessIds = new Set();
    const targetRefsByType = {
      employee: new Set(),
      customer: new Set(),
      supplier: new Set(),
      structure: new Set(),
      stock: new Set(),
      asset: new Set()
    };
    
    // Collect all reference IDs
    recordsToProcess.forEach(record => {
      if (record.businessId) businessIds.add(record.businessId);
      if (record.targetType && record.targetRef) {
        if (targetRefsByType[record.targetType]) {
          targetRefsByType[record.targetType].add(record.targetRef);
        }
      }
    });
    
    // Fetch business names
    const businessPromises = Array.from(businessIds).map(async (businessId) => {
      if (businessRefs[businessId]) return null; // Skip if already fetched
      
      try {
        const businessDoc = await getDoc(doc(db, 'businesses', businessId));
        if (businessDoc.exists()) {
          return { 
            id: businessId, 
            name: businessDoc.data().name || 'Unknown Business'
          };
        }
      } catch (error) {
        console.error(`Error fetching business ${businessId}:`, error);
      }
      return null;
    });
    
    // Fetch target references by type
    const targetPromises = [];
    Object.entries(targetRefsByType).forEach(([type, ids]) => {
      Array.from(ids).forEach(id => {
        if (targetRefs[`${type}-${id}`]) return; // Skip if already fetched
        
        const collectionName = getCollectionNameByType(type);
        if (!collectionName) return;
        
        targetPromises.push(
          (async () => {
            try {
              const targetDoc = await getDoc(doc(db, collectionName, id));
              if (targetDoc.exists()) {
                const data = targetDoc.data();
                return { 
                  id,
                  type,
                  name: data.name || data.fullName || 
                        `${data.firstName || ''} ${data.lastName || ''}`.trim() ||
                        'Unknown'
                };
              }
            } catch (error) {
              console.error(`Error fetching ${type} reference ${id}:`, error);
            }
            return null;
          })()
        );
      });
    });
    
    // Wait for all promises to resolve
    const [businessResults, targetResults] = await Promise.all([
      Promise.all(businessPromises),
      Promise.all(targetPromises)
    ]);
    
    // Update reference data
    const newBusinessRefs = { ...businessRefs };
    businessResults.filter(Boolean).forEach(business => {
      newBusinessRefs[business.id] = business.name;
    });
    
    const newTargetRefs = { ...targetRefs };
    targetResults.filter(Boolean).forEach(target => {
      newTargetRefs[`${target.type}-${target.id}`] = target.name;
    });
    
    setBusinessRefs(newBusinessRefs);
    setTargetRefs(newTargetRefs);
  };
  
  // Helper to get collection name by target type
  const getCollectionNameByType = (type) => {
    switch (type) {
      case 'employee': return 'employees';
      case 'customer': return 'customers';
      case 'supplier': return 'suppliers';
      case 'structure': return 'structures';
      case 'stock': return 'stockItems';
      case 'asset': return 'assets';
      default: return null;
    }
  };
  
  // Handle search
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  
  // Apply all filters to records
  const getFilteredRecords = () => {
    return records.filter(record => {
      // Text search
      if (searchTerm && !record.message.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }
      
      // Category filter (already applied in the query for primary category)
      
      // Status filter
      if (filterStatus !== 'All' && record.status !== filterStatus.toLowerCase()) {
        return false;
      }
      
      // Target type filter
      if (filterTarget !== 'All' && record.targetType !== filterTarget) {
        return false;
      }
      
      // Requires action filter
      if (filterRequiresAction && !record.requireAction) {
        return false;
      }
      
      // Date range filter
      if (dateRange.start) {
        const startDate = new Date(dateRange.start);
        if (record.date < startDate) return false;
      }
      
      if (dateRange.end) {
        const endDate = new Date(dateRange.end);
        endDate.setHours(23, 59, 59); // End of day
        if (record.date > endDate) return false;
      }
      
      return true;
    });
  };
  
  // Format date
  const formatDate = (date) => {
    if (!date) return '';
    
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Get status badge classes
  const getStatusClasses = (status) => {
    switch (status) {
      case 'green':
        return 'bg-green-100 text-green-800';
      case 'orange':
        return 'bg-orange-100 text-orange-800';
      case 'red':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  
  // Reset all filters
  const resetFilters = () => {
    setSearchTerm('');
    setFilterCategory('All');
    setFilterStatus('All');
    setFilterTarget('All');
    setFilterRequiresAction(false);
    setDateRange({ start: '', end: '' });
  };
  
  // Get target icon component
  const getTargetIcon = (targetType) => {
    const IconComponent = TARGET_ICONS[targetType] || FileText;
    return <IconComponent size={16} />;
  };
  
  // Filter options
  const statusOptions = [
    { id: 'All', label: 'All Statuses' },
    { id: 'green', label: 'Green' },
    { id: 'orange', label: 'Orange' },
    { id: 'red', label: 'Red' }
  ];
  
  const targetOptions = [
    { id: 'All', label: 'All Targets' },
    { id: 'employee', label: 'Employees' },
    { id: 'customer', label: 'Customers' },
    { id: 'supplier', label: 'Suppliers' },
    { id: 'structure', label: 'Structures' },
    { id: 'stock', label: 'Stock Items' },
    { id: 'asset', label: 'Assets' }
  ];
  
  // Filtered records
  const filteredRecords = getFilteredRecords();

  return (
    <div>
      {/* Search and Actions Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search records by message content..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        
        <div className="flex gap-3">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className={`px-4 py-2.5 rounded-lg border flex items-center ${
              showFilters ? 'border-[#190192] text-[#190192] bg-purple-50' : 'border-gray-200 text-gray-600'
            }`}
          >
            <FilterIcon size={18} className="mr-2" />
            Filters
          </button>
          
          <div className="flex rounded-lg border border-gray-200 overflow-hidden">
            <button
              onClick={() => setViewMode('list')}
              className={`p-2.5 ${viewMode === 'list' ? 'bg-[#190192] text-white' : 'text-gray-500 hover:bg-gray-100'}`}
            >
              <List size={18} />
            </button>
            <button
              onClick={() => setViewMode('grid')}
              className={`p-2.5 ${viewMode === 'grid' ? 'bg-[#190192] text-white' : 'text-gray-500 hover:bg-gray-100'}`}
            >
              <Grid size={18} />
            </button>
          </div>
          
          <button className="p-2.5 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50">
            <Download size={18} />
          </button>
          
          <Link
            to="/records/new"
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5]"
          >
            <PlusCircle size={18} className="mr-2" />
            New Record
          </Link>
        </div>
      </div>
      
      {/* Filters Panel */}
      {showFilters && (
        <div className="bg-white rounded-xl shadow-md p-6 mb-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Filters</h3>
            <button
              onClick={resetFilters}
              className="text-[#190192] hover:text-[#2C0DB5] text-sm"
            >
              Reset Filters
            </button>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Category Filter */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Category
              </label>
              <select
                value={filterCategory}
                onChange={(e) => setFilterCategory(e.target.value)}
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              >
                {RECORD_CATEGORIES.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
            
            {/* Status Filter */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Status
              </label>
              <select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              >
                {statusOptions.map(option => (
                  <option key={option.id} value={option.id}>{option.label}</option>
                ))}
              </select>
            </div>
            
            {/* Target Type Filter */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Target Type
              </label>
              <select
                value={filterTarget}
                onChange={(e) => setFilterTarget(e.target.value)}
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              >
                {targetOptions.map(option => (
                  <option key={option.id} value={option.id}>{option.label}</option>
                ))}
              </select>
            </div>
            
            {/* Date Range */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <input
                type="date"
                value={dateRange.start}
                onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                End Date
              </label>
              <input
                type="date"
                value={dateRange.end}
                onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              />
            </div>
            
            {/* Requires Action Toggle */}
            <div className="flex items-center">
              <input
                id="filterRequiresAction"
                type="checkbox"
                checked={filterRequiresAction}
                onChange={(e) => setFilterRequiresAction(e.target.checked)}
                className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
              />
              <label htmlFor="filterRequiresAction" className="ml-2 block text-sm text-gray-700">
                Requires Action Only
              </label>
            </div>
          </div>
        </div>
      )}

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {records.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <FileText size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No records found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You haven't created any records yet. Click the 'New Record' button to get started.
              </p>
              <Link
                to="/records/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                <PlusCircle size={16} className="mr-2" />
                Create Your First Record
              </Link>
            </div>
          ) : filteredRecords.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <FileText size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching records</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any records matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={resetFilters}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Records Display */
            <div>
              {viewMode === 'list' ? (
                /* List View */
                <div className="bg-white rounded-xl shadow-md overflow-hidden">
                  {filteredRecords.map((record, index) => (
                    <div 
                      key={record.id}
                      className={`p-4 ${index !== filteredRecords.length - 1 ? 'border-b border-gray-200' : ''}`}
                    >
                      <div className="flex flex-col md:flex-row md:items-center gap-4">
                        {/* Status Indicator */}
                        <div className="flex-shrink-0">
                          <div className={`w-3 h-3 rounded-full bg-${record.status}-500`}></div>
                        </div>
                        
                        {/* Record Content */}
                        <div className="flex-grow">
                          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                            <div className="flex items-center">
                              <Calendar size={16} className="text-gray-400 mr-2" />
                              <span className="text-sm text-gray-500">{formatDate(record.date)}</span>
                              
                              {record.category && (
                                <>
                                  <span className="mx-2 text-gray-300">•</span>
                                  <span className="px-2 py-0.5 rounded-full text-xs bg-purple-100 text-purple-800">
                                    {record.category}
                                  </span>
                                </>
                              )}
                              
                              {record.requireAction && (
                                <span className="ml-2 px-2 py-0.5 rounded-full text-xs bg-amber-100 text-amber-800">
                                  Action Required
                                </span>
                              )}
                            </div>
                            
                            <div className="text-sm text-gray-500">
                              {businessRefs[record.businessId] || 'Unknown Business'}
                            </div>
                          </div>
                          
                          <p className="text-gray-800 mt-2 line-clamp-2">{record.message}</p>
                          
                          {/* Target Reference */}
                          {record.targetType && record.targetRef && (
                            <div className="mt-2 flex items-center text-sm text-gray-600">
                              {getTargetIcon(record.targetType)}
                              <span className="ml-2">
                                {TARGET_ICONS[record.targetType] ? `${record.targetType}: ` : ''}
                                {targetRefs[`${record.targetType}-${record.targetRef}`] || 'Unknown'}
                              </span>
                            </div>
                          )}
                        </div>
                        
                        {/* Actions */}
                        <div className="flex-shrink-0">
                          <Link
                            to={`/records/${record.id}`}
                            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
                          >
                            View <ChevronRight size={16} className="ml-1" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                /* Grid View */
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {filteredRecords.map(record => (
                    <div 
                      key={record.id}
                      className="bg-white rounded-xl shadow-md overflow-hidden border border-gray-200 hover:shadow-lg transition-shadow"
                    >
                      <div className="p-4">
                        <div className="flex items-center justify-between mb-3">
                          <div className="flex items-center">
                            <div className={`w-3 h-3 rounded-full bg-${record.status}-500 mr-2`}></div>
                            <span className="text-sm font-medium text-gray-900">
                              {record.recordId || `Record #${record.id.substring(0, 6)}`}
                            </span>
                          </div>
                          <span className="text-xs text-gray-500">{formatDate(record.date)}</span>
                        </div>
                        
                        <div className="flex items-center mb-3">
                          <Tag size={14} className="text-gray-400 mr-2" />
                          <span className="text-sm text-gray-600">
                            {record.category || 'Uncategorized'}
                          </span>
                          {record.requireAction && (
                            <span className="ml-2 px-2 py-0.5 rounded-full text-xs bg-amber-100 text-amber-800 truncate">
                              Action Required
                            </span>
                          )}
                        </div>
                        
                        <p className="text-gray-800 mb-3 line-clamp-3">{record.message}</p>
                        
                        {/* Target Reference */}
                        {record.targetType && record.targetRef && (
                          <div className="mb-3 flex items-center text-sm text-gray-600">
                            {getTargetIcon(record.targetType)}
                            <span className="ml-2 truncate">
                              {targetRefs[`${record.targetType}-${record.targetRef}`] || 'Unknown'}
                            </span>
                          </div>
                        )}
                        
                        <div className="pt-3 mt-3 border-t border-gray-100 flex justify-between items-center">
                          <span className="text-xs text-gray-500 truncate">
                            {businessRefs[record.businessId] || 'Unknown Business'}
                          </span>
                          <Link
                            to={`/records/${record.id}`}
                            className="text-[#190192] hover:text-[#2C0DB5] text-sm inline-flex items-center"
                          >
                            Details <ChevronRight size={14} className="ml-1" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              
              {/* Load More Button */}
              {hasMore && (
                <div className="mt-6 text-center">
                  <button
                    onClick={fetchMoreRecords}
                    disabled={isLoadingMore}
                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                  >
                    {isLoadingMore ? (
                      <>
                        <span className="inline-block animate-spin h-4 w-4 border-2 border-gray-500 rounded-full border-t-transparent mr-2 align-middle"></span>
                        Loading...
                      </>
                    ) : (
                      'Load More Records'
                    )}
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RecordList;