// src/pages/plans/PlanPage.js
import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { PlusCircle, FileText } from 'lucide-react';

const PlanPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if we're on the main plan list page
  const isMainPage = location.pathname === '/plans' || location.pathname === '/plans/';

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="bg-gradient-to-r from-[#190192] to-[#3B0DB5] px-6 py-4 text-white">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold">Business Plans</h1>
              <p className="text-purple-200 text-sm">
                Create and manage your strategic business plans and initiatives.
              </p>
            </div>

            {isMainPage && (
              <button
                onClick={() => navigate('/plans/new')}
                className="px-4 py-2 bg-white text-[#190192] rounded-lg hover:bg-opacity-90 font-medium transition-all flex items-center"
              >
                <PlusCircle size={18} className="mr-2" />
                Add Plan
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Nested Routes */}
      <Outlet />
    </div>
  );
};

export default PlanPage;