// src/components/inventory/reports/InventoryReportList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, ChevronRight, BarChart2, 
  Download, FileText, Calendar, Filter
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';

const InventoryReportList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);

  // Fetch reports
  useEffect(() => {
    const fetchReports = async () => {
      setIsLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(r => setTimeout(r, 800));
        
        // Mock reports data
        const mockData = [
          {
            id: '1',
            title: 'Monthly Inventory Valuation',
            type: 'valuation',
            description: 'Summary of inventory value at the end of the month',
            generated: '2025-03-01',
            period: 'March 2025'
          },
          {
            id: '2',
            title: 'Low Stock Items',
            type: 'stock-level',
            description: 'Items that are at or below their minimum stock level',
            generated: '2025-03-15',
            period: 'Current'
          },
          {
            id: '3',
            title: 'Stock Movement Analysis',
            type: 'movement',
            description: 'Analysis of stock movement patterns for the past quarter',
            generated: '2025-03-20',
            period: 'Q1 2025'
          },
          {
            id: '4',
            title: 'Supplier Performance Report',
            type: 'supplier',
            description: 'Evaluation of supplier reliability and delivery performance',
            generated: '2025-03-25',
            period: 'January - March 2025'
          }
        ];
        
        setReports(mockData);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchReports();
  }, []);

  // Get filtered reports
  const getFilteredReports = () => {
    let filtered = reports;
    
    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(report => 
        report.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        report.period.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Filter by type
    if (filterType !== 'all') {
      filtered = filtered.filter(report => report.type === filterType);
    }
    
    return filtered;
  };
  
  // Format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Get report icon
  const getReportIcon = (type) => {
    switch (type) {
      case 'valuation':
        return BarChart2;
      case 'stock-level':
        return Filter;
      case 'movement':
        return BarChart2;
      case 'supplier':
        return FileText;
      default:
        return BarChart2;
    }
  };

  return (
    <div>
      {/* Search and Filters Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search reports by title, description, or period..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
          >
            <option value="all">All Reports</option>
            <option value="valuation">Valuation</option>
            <option value="stock-level">Stock Level</option>
            <option value="movement">Movement</option>
            <option value="supplier">Supplier</option>
          </select>
          
          <button className="p-2.5 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50">
            <Download size={18} />
          </button>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {reports.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <BarChart2 size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No reports available</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                There are no inventory reports generated yet. Reports will be automatically generated as you use the system.
              </p>
            </div>
          ) : getFilteredReports().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <BarChart2 size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching reports</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any reports matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterType('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Reports Grid */
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {getFilteredReports().map((report) => {
                const ReportIcon = getReportIcon(report.type);
                
                return (
                  <div key={report.id} className="bg-white rounded-xl border border-gray-200 shadow-sm hover:shadow-md transition-shadow overflow-hidden">
                    <div className="p-5">
                      <div className="flex items-center mb-4">
                        <div className="w-10 h-10 rounded-lg bg-purple-100 flex items-center justify-center text-[#190192] mr-3">
                          <ReportIcon size={20} />
                        </div>
                        <div>
                          <h3 className="font-medium text-gray-900">{report.title}</h3>
                          <p className="text-xs text-gray-500 flex items-center">
                            <Calendar size={12} className="mr-1" />
                            {formatDate(report.generated)}
                          </p>
                        </div>
                      </div>
                      
                      <p className="text-sm text-gray-600 mb-3">{report.description}</p>
                      
                      <div className="flex justify-between items-center text-sm">
                        <span className="text-gray-500">Period: {report.period}</span>
                        
                        <Link
                          to={`/inventory/reports/${report.id}`}
                          className="text-[#190192] hover:text-[#2C0DB5] font-medium flex items-center"
                        >
                          View <ChevronRight size={16} className="ml-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InventoryReportList;