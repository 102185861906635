// src/components/tasks/TaskDetail.js
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  CheckCircle, Calendar, Clock, User, ChevronLeft, 
  Edit, Download, Trash2, FileText, X, Target,
  PlusCircle, ChevronRight, AlertTriangle, Tag, 
  MessageSquare, Mail, ArrowUpRight
} from 'lucide-react';

const TaskDetail = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('details');

  // Fetch task data
  useEffect(() => {
    const fetchTaskDetails = async () => {
      setLoading(true);
      try {
        // In a real app, this would be an API call
        // For now, we'll simulate with setTimeout
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Mock task data
        const mockTask = {
          id: taskId,
          description: 'Finalize Q2 marketing campaign materials',
          category: 'Scheduled',
          assignee: 'Sarah Wilson',
          assigneeEmail: 'sarah.wilson@company.com',
          assigneeRole: 'Marketing Manager',
          startDate: '2025-04-01',
          endDate: '2025-04-10',
          status: 'In Progress',
          priority: 'High',
          progress: 60,
          taskRef: {
            type: 'Plan',
            id: '2',
            title: 'Q2 Marketing Campaign'
          },
          objectives: [
            { id: 1, text: 'Design social media graphics', status: 'completed' },
            { id: 2, text: 'Write email copy', status: 'in-progress' },
            { id: 3, text: 'Create landing page content', status: 'not-started' }
          ],
          comments: [
            { id: 1, user: 'Maria Garcia', content: 'I can help with the email copy editing if needed.', date: '2025-03-25' },
            { id: 2, user: 'Alex Johnson', content: 'Please make sure all materials match our updated brand guidelines.', date: '2025-03-26' },
            { id: 3, user: 'Sarah Wilson', content: 'Social media graphics are now complete and ready for review.', date: '2025-03-28' }
          ],
          attachments: [
            { id: 1, name: 'brand_guidelines_2025.pdf', type: 'pdf', size: '2.4 MB', date: '2025-03-20' },
            { id: 2, name: 'social_media_mockups.png', type: 'image', size: '1.8 MB', date: '2025-03-28' }
          ],
          createdAt: '2025-03-15',
          updatedAt: '2025-03-28',
          createdBy: 'Alex Johnson'
        };
        
        setTask(mockTask);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching task details:', error);
        setLoading(false);
      }
    };

    fetchTaskDetails();
  }, [taskId]);

  // Handle delete
  const handleDelete = async () => {
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Navigate back to the task list
      navigate('/tasks');
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return 'Not set';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Get status style
  const getStatusStyle = (status) => {
    switch (status) {
      case 'In Progress':
        return 'bg-blue-100 text-blue-700';
      case 'Not Started':
        return 'bg-gray-100 text-gray-700';
      case 'Completed':
        return 'bg-green-100 text-green-700';
      case 'On Hold':
        return 'bg-amber-100 text-amber-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  };
  
  // Get priority style
  const getPriorityStyle = (priority) => {
    switch (priority) {
      case 'Low':
        return 'bg-green-100 text-green-800';
      case 'Medium':
        return 'bg-blue-100 text-blue-800';
      case 'High':
        return 'bg-orange-100 text-orange-800';
      case 'Critical':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  
  // Get objective status style & icon
  const getObjectiveStatus = (status) => {
    switch (status) {
      case 'completed':
        return {
          icon: <CheckCircle size={16} className="text-green-600" />,
          style: 'text-green-600'
        };
      case 'in-progress':
        return {
          icon: <AlertTriangle size={16} className="text-blue-600" />,
          style: 'text-blue-600'
        };
      case 'not-started':
        return {
          icon: <Target size={16} className="text-gray-500" />,
          style: 'text-gray-500'
        };
      default:
        return {
          icon: <Target size={16} className="text-gray-500" />,
          style: 'text-gray-500'
        };
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!task) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Task not found</h3>
          <p className="text-gray-500 mb-6">The task you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/tasks')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Tasks
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/tasks"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Tasks
          </Link>
          
          <div className="flex space-x-3">
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
              <Download size={20} />
            </button>
            <Link
              to={`/tasks/edit/${taskId}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Task Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className="w-16 h-16 rounded-lg bg-indigo-100 flex items-center justify-center flex-shrink-0 text-[#190192]">
              <CheckCircle size={32} />
            </div>
            
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">{task.description}</h1>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(task.status)}`}>
                  {task.status}
                </span>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getPriorityStyle(task.priority)}`}>
                  {task.priority} Priority
                </span>
              </div>
              
              <div className="flex items-center text-gray-600 gap-4 flex-wrap">
                <span className="inline-flex items-center">
                  <Tag size={16} className="mr-1 text-gray-400" />
                  {task.category}
                </span>
                
                {task.taskRef && (
                  <span className="inline-flex items-center">
                    <FileText size={16} className="mr-1 text-gray-400" />
                    {task.taskRef.type}: {task.taskRef.title}
                  </span>
                )}
              </div>
              
              {/* Progress bar */}
              <div className="mt-4">
                <div className="flex items-center justify-between mb-1">
                  <span className="text-sm font-medium text-gray-700">Progress</span>
                  <span className="text-sm font-medium text-gray-700">{task.progress}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div 
                    className="bg-blue-600 h-2.5 rounded-full" 
                    style={{ width: `${task.progress}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="flex px-6 -mb-px">
            <button
              onClick={() => setActiveTab('details')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'details'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Details
            </button>
            <button
              onClick={() => setActiveTab('comments')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'comments'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Comments ({task.comments.length})
            </button>
            <button
              onClick={() => setActiveTab('attachments')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'attachments'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Attachments ({task.attachments.length})
            </button>
          </nav>
        </div>
        
        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'details' ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left Column: Basic Info */}
              <div className="lg:col-span-2 space-y-8">
                {/* Objectives */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Objectives</h3>
                  <div className="space-y-3">
                    {task.objectives.map((objective) => {
                      const { icon, style } = getObjectiveStatus(objective.status);
                      return (
                        <div key={objective.id} className="flex items-start p-3 border border-gray-100 rounded-lg">
                          <div className="flex-shrink-0 mr-3">
                            {icon}
                          </div>
                          <p className={`${style}`}>{objective.text}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                
                {/* Timeline */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Timeline</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="bg-gray-50 rounded-xl p-5">
                      <h4 className="text-sm font-medium text-gray-700 mb-2">Start Date</h4>
                      <div className="flex items-center">
                        <Calendar size={18} className="mr-2 text-gray-500" />
                        <span className="text-gray-900">{formatDate(task.startDate)}</span>
                      </div>
                    </div>
                    
                    <div className="bg-gray-50 rounded-xl p-5">
                      <h4 className="text-sm font-medium text-gray-700 mb-2">End Date</h4>
                      <div className="flex items-center">
                        <Calendar size={18} className="mr-2 text-gray-500" />
                        <span className="text-gray-900">{formatDate(task.endDate)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                {/* Related Items */}
                {task.taskRef && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Related Item</h3>
                    <div className="bg-gray-50 rounded-xl p-5">
                      <div className="flex items-center">
                        <div className="w-10 h-10 rounded-lg bg-indigo-100 flex items-center justify-center text-indigo-600 mr-4">
                          <FileText size={20} />
                        </div>
                        <div>
                          <h4 className="text-sm font-medium text-gray-500">{task.taskRef.type}</h4>
                          <p className="text-gray-900 font-medium">{task.taskRef.title}</p>
                        </div>
                        <div className="ml-auto">
                          <Link
                            to={`/${task.taskRef.type.toLowerCase()}s/${task.taskRef.id}`}
                            className="text-[#190192] hover:text-[#2C0DB5] text-sm flex items-center"
                          >
                            View <ArrowUpRight size={14} className="ml-1" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              
              {/* Right Column: Sidebar */}
              <div className="lg:col-span-1">
                {/* Assignee Info */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Assignee</h3>
                  
                  {task.assignee ? (
                    <div className="space-y-4">
                      <div className="flex items-center">
                        <div className="w-10 h-10 rounded-full bg-indigo-100 flex items-center justify-center text-indigo-600 font-medium mr-3">
                          {task.assignee.split(' ').map(name => name[0]).join('')}
                        </div>
                        <div>
                          <p className="font-medium text-gray-800">{task.assignee}</p>
                          <p className="text-sm text-gray-500">{task.assigneeRole}</p>
                        </div>
                      </div>
                      
                      {task.assigneeEmail && (
                        <div className="flex items-center text-sm">
                          <Mail size={16} className="mr-2 text-gray-400" />
                          <a href={`mailto:${task.assigneeEmail}`} className="text-[#190192] hover:underline">
                            {task.assigneeEmail}
                          </a>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="flex items-center text-gray-500">
                      <User size={16} className="mr-2" />
                      <span>No assignee set</span>
                    </div>
                  )}
                </div>
                
                {/* Created By */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Created by</h3>
                  <div className="flex items-center">
                    <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-gray-700 font-medium mr-2">
                      {task.createdBy.split(' ').map(name => name[0]).join('')}
                    </div>
                    <p className="font-medium text-gray-800">{task.createdBy}</p>
                  </div>
                </div>
                
                {/* Meta Information */}
                <div className="bg-gray-50 rounded-xl p-5">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Information</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Created</span>
                      <span className="text-sm text-gray-900 flex items-center">
                        <Clock size={14} className="mr-1.5" />
                        {formatDate(task.createdAt)}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Last Updated</span>
                      <span className="text-sm text-gray-900 flex items-center">
                        <Clock size={14} className="mr-1.5" />
                        {formatDate(task.updatedAt)}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">ID</span>
                      <span className="text-sm text-gray-900">{task.id}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : activeTab === 'comments' ? (
            /* Comments Tab */
            <div>
              <div className="mb-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Comments</h3>
                
                {/* New comment form */}
                <div className="mb-6">
                  <textarea
                    placeholder="Add a comment..."
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                    rows="3"
                  ></textarea>
                  <div className="mt-2 flex justify-end">
                    <button className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5]">
                      Add Comment
                    </button>
                  </div>
                </div>
                
                {/* Comments list */}
                <div className="space-y-6">
                  {task.comments.map((comment) => (
                    <div key={comment.id} className="bg-gray-50 rounded-lg p-4">
                      <div className="flex items-start">
                        <div className="w-8 h-8 rounded-full bg-indigo-100 flex items-center justify-center text-indigo-600 font-medium mr-3 flex-shrink-0">
                          {comment.user.split(' ').map(name => name[0]).join('')}
                        </div>
                        <div className="flex-1">
                          <div className="flex items-center justify-between mb-1">
                            <span className="font-medium text-gray-900">{comment.user}</span>
                            <span className="text-xs text-gray-500">{formatDate(comment.date)}</span>
                          </div>
                          <p className="text-gray-700">{comment.content}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            /* Attachments Tab */
            <div>
              <div className="mb-6">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-medium text-gray-900">Attachments</h3>
                  <button className="text-[#190192] hover:text-[#2C0DB5] flex items-center text-sm">
                    <PlusCircle size={16} className="mr-1" />
                    Add Attachment
                  </button>
                </div>
                
                {task.attachments.length === 0 ? (
                  <div className="text-center py-10 border border-dashed border-gray-300 rounded-lg">
                    <FileText size={40} className="mx-auto text-gray-400 mb-2" />
                    <p className="text-gray-500">No attachments yet</p>
                    <button className="mt-3 px-4 py-2 text-sm bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5]">
                      Upload File
                    </button>
                  </div>
                ) : (
                  <div className="space-y-3">
                    {task.attachments.map((file) => (
                      <div key={file.id} className="flex items-center p-3 border border-gray-200 rounded-lg">
                        <div className="w-10 h-10 bg-gray-100 rounded-lg flex items-center justify-center text-gray-500 mr-3">
                          <FileText size={20} />
                        </div>
                        <div className="flex-1">
                          <p className="font-medium text-gray-900">{file.name}</p>
                          <p className="text-xs text-gray-500">{file.size} • Uploaded {formatDate(file.date)}</p>
                        </div>
                        <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
                          <Download size={18} />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Task</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this task? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TaskDetail;