// src/components/inventory/buying/PurchaseOrderList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, ChevronRight, ShoppingBag, 
  Calendar, DollarSign, Clock, Download,
  CheckCircle, AlertCircle, XCircle
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { useCurrency } from '../../../utils/currencyUtils';

const PurchaseOrderList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const { formatAmount } = useCurrency();

  // Fetch purchase orders
  useEffect(() => {
    const fetchPurchaseOrders = async () => {
      setIsLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(r => setTimeout(r, 800));
        
        // Mock purchase orders data
        const mockData = [
          {
            id: '1',
            poNumber: 'PO-2025-001',
            supplier: {
              id: '1',
              name: 'Global Textiles Inc.'
            },
            orderDate: '2025-03-01',
            status: 'pending',
            totalAmount: 2850.00,
            items: 5,
            dueDate: '2025-03-31'
          },
          {
            id: '2',
            poNumber: 'PO-2025-002',
            supplier: {
              id: '2',
              name: 'Tech Components Ltd'
            },
            orderDate: '2025-03-10',
            status: 'approved',
            totalAmount: 1275.50,
            items: 3,
            dueDate: '2025-04-10'
          },
          {
            id: '3',
            poNumber: 'PO-2025-003',
            supplier: {
              id: '3',
              name: 'Packaging Solutions'
            },
            orderDate: '2025-03-18',
            status: 'received',
            totalAmount: 945.75,
            items: 2,
            dueDate: '2025-04-18'
          },
          {
            id: '4',
            poNumber: 'PO-2025-004',
            supplier: {
              id: '1',
              name: 'Global Textiles Inc.'
            },
            orderDate: '2025-03-22',
            status: 'cancelled',
            totalAmount: 1500.00,
            items: 4,
            dueDate: '2025-04-22'
          }
        ];
        
        setPurchaseOrders(mockData);
      } catch (error) {
        console.error('Error fetching purchase orders:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchPurchaseOrders();
  }, []);

  // Get filtered purchase orders
  const getFilteredPurchaseOrders = () => {
    let filtered = purchaseOrders;
    
    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(po => 
        po.poNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        po.supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Filter by status
    if (filterStatus !== 'all') {
      filtered = filtered.filter(po => po.status === filterStatus);
    }
    
    return filtered;
  };
  
  // Format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Get status badge color
  const getStatusBadge = (status) => {
    switch (status) {
      case 'pending':
        return { bg: 'bg-yellow-100', text: 'text-yellow-800', icon: Clock };
      case 'approved':
        return { bg: 'bg-blue-100', text: 'text-blue-800', icon: CheckCircle };
      case 'received':
        return { bg: 'bg-green-100', text: 'text-green-800', icon: CheckCircle };
      case 'cancelled':
        return { bg: 'bg-red-100', text: 'text-red-800', icon: XCircle };
      default:
        return { bg: 'bg-gray-100', text: 'text-gray-800', icon: Clock };
    }
  };

  return (
    <div>
      {/* Search and Filters Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search by PO number or supplier..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Statuses</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="received">Received</option>
            <option value="cancelled">Cancelled</option>
          </select>
          
          <button className="p-2.5 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50">
            <Download size={18} />
          </button>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {purchaseOrders.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <ShoppingBag size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No purchase orders found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You haven't created any purchase orders yet. Click the 'New Purchase Order' button to get started.
              </p>
              <Link
                to="/inventory/buying/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                Create Your First Purchase Order
              </Link>
            </div>
          ) : getFilteredPurchaseOrders().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <ShoppingBag size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching purchase orders</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any purchase orders matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterStatus('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Purchase Orders Table */
            <div className="bg-white rounded-xl shadow-md overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      PO Number
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Supplier
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Items
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {getFilteredPurchaseOrders().map((po) => {
                    const statusBadge = getStatusBadge(po.status);
                    const StatusIcon = statusBadge.icon;
                    
                    return (
                      <tr key={po.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {po.poNumber}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <Link to={`/inventory/suppliers/${po.supplier.id}`} className="hover:text-[#190192]">
                            {po.supplier.name}
                          </Link>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <div className="flex items-center">
                            <Calendar size={14} className="text-gray-400 mr-1" />
                            {formatDate(po.orderDate)}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${statusBadge.bg} ${statusBadge.text}`}>
                            <StatusIcon size={12} className="mr-1" />
                            {po.status.charAt(0).toUpperCase() + po.status.slice(1)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                          {po.items}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">
                          {formatAmount(po.totalAmount)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <Link
                            to={`/inventory/buying/${po.id}`}
                            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
                          >
                            View <ChevronRight size={16} className="ml-1" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PurchaseOrderList;