// src/components/finance/TransactList.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, PlusCircle, ChevronRight, Calendar, 
  ArrowUpRight, ArrowDownRight, Receipt, BadgePercent, 
  BarChart2, DollarSign, Download, ChevronDown, X, Users,
  Building, Tag, ShoppingCart, Check
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, query, where, orderBy, limit, 
  getDocs, startAfter, Timestamp 
} from 'firebase/firestore';

const TRANSACTIONS_PER_PAGE = 20;

const TransactList = () => {
  const { currentUser } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  
  // Filter and search state
  const [searchTerm, setSearchTerm] = useState('');
  const [activeBusinessId, setActiveBusinessId] = useState('all');
  const [activeType, setActiveType] = useState('all');
  const [activeStatus, setActiveStatus] = useState('all');
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [showFilters, setShowFilters] = useState(false);
  
  // Reference data
  const [businesses, setBusinesses] = useState([]);
  const [counterpartyNames, setCounterpartyNames] = useState({});
  
  // Fetch transactions
  useEffect(() => {
    const fetchTransactions = async () => {
      if (!currentUser) return;
      
      setIsLoading(true);
      
      try {
        // First fetch businesses to use in the filter
        const businessesQuery = query(
          collection(db, 'businesses'),
          where('createdBy', '==', currentUser.uid)
        );
        
        const businessSnapshot = await getDocs(businessesQuery);
        const businessData = [];
        businessSnapshot.forEach(doc => {
          businessData.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setBusinesses(businessData);
        
        // Now fetch transactions
        let transactionsQuery;
        
        if (activeBusinessId === 'all') {
          transactionsQuery = query(
            collection(db, 'financialTransactions'),
            where('createdBy', '==', currentUser.uid),
            orderBy('date', 'desc'),
            limit(TRANSACTIONS_PER_PAGE)
          );
        } else {
          transactionsQuery = query(
            collection(db, 'financialTransactions'),
            where('createdBy', '==', currentUser.uid),
            where('businessId', '==', activeBusinessId),
            orderBy('date', 'desc'),
            limit(TRANSACTIONS_PER_PAGE)
          );
        }
        
        const querySnapshot = await getDocs(transactionsQuery);
        
        const transactionsData = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          transactionsData.push({
            id: doc.id,
            ...data,
            date: data.date?.toDate() || new Date(),
            dueDate: data.dueDate?.toDate() || null,
            createdAt: data.createdAt?.toDate() || new Date()
          });
        });
        
        setTransactions(transactionsData);
        setFilteredTransactions(transactionsData);
        
        // Set the last visible document for pagination
        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastVisible(lastDoc);
        
        // Check if there are more documents
        setHasMore(querySnapshot.docs.length === TRANSACTIONS_PER_PAGE);
        
        // Fetch counterparty names
        await fetchCounterpartyNames(transactionsData);
        
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchTransactions();
  }, [currentUser, activeBusinessId]);
  
  // Load more transactions
  const loadMoreTransactions = async () => {
    if (!hasMore || !lastVisible) return;
    
    setIsLoadingMore(true);
    
    try {
      let transactionsQuery;
      
      if (activeBusinessId === 'all') {
        transactionsQuery = query(
          collection(db, 'financialTransactions'),
          where('createdBy', '==', currentUser.uid),
          orderBy('date', 'desc'),
          startAfter(lastVisible),
          limit(TRANSACTIONS_PER_PAGE)
        );
      } else {
        transactionsQuery = query(
          collection(db, 'financialTransactions'),
          where('createdBy', '==', currentUser.uid),
          where('businessId', '==', activeBusinessId),
          orderBy('date', 'desc'),
          startAfter(lastVisible),
          limit(TRANSACTIONS_PER_PAGE)
        );
      }
      
      const querySnapshot = await getDocs(transactionsQuery);
      
      const newTransactions = [];
      querySnapshot.forEach(doc => {
        const data = doc.data();
        newTransactions.push({
          id: doc.id,
          ...data,
          date: data.date?.toDate() || new Date(),
          dueDate: data.dueDate?.toDate() || null,
          createdAt: data.createdAt?.toDate() || new Date()
        });
      });
      
      // Update state
      setTransactions(prev => [...prev, ...newTransactions]);
      
      // Fetch counterparty names
      await fetchCounterpartyNames(newTransactions);
      
      // Apply filters to new transactions
      applyFilters([...transactions, ...newTransactions]);
      
      // Set the last visible document for pagination
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisible(lastDoc);
      
      // Check if there are more documents
      setHasMore(querySnapshot.docs.length === TRANSACTIONS_PER_PAGE);
      
    } catch (error) {
      console.error('Error loading more transactions:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };
  
  // Fetch counterparty names
  const fetchCounterpartyNames = async (transactionsToProcess) => {
    const counterpartyIds = {
      customer: new Set(),
      supplier: new Set()
    };
    
    // Collect unique counterparty IDs
    transactionsToProcess.forEach(transaction => {
      if (transaction.counterpartyId && transaction.counterpartyType) {
        counterpartyIds[transaction.counterpartyType].add(transaction.counterpartyId);
      }
    });
    
    // Fetch customer names
    if (counterpartyIds.customer.size > 0) {
      try {
        const customerPromises = Array.from(counterpartyIds.customer).map(async id => {
          const customerDoc = await getDocs(
            query(collection(db, 'customers'), where('id', '==', id))
          );
          
          if (!customerDoc.empty) {
            const customerData = customerDoc.docs[0].data();
            return {
              id,
              name: customerData.name || customerData.businessName || 
                    `${customerData.firstName || ''} ${customerData.lastName || ''}`.trim() || 
                    'Unknown Customer'
            };
          }
          return { id, name: 'Unknown Customer' };
        });
        
        const customers = await Promise.all(customerPromises);
        const customerNames = {};
        customers.forEach(customer => {
          customerNames[customer.id] = customer.name;
        });
        
        setCounterpartyNames(prev => ({
          ...prev,
          ...customerNames
        }));
      } catch (error) {
        console.error('Error fetching customer names:', error);
      }
    }
    
    // Fetch supplier names
    if (counterpartyIds.supplier.size > 0) {
      try {
        const supplierPromises = Array.from(counterpartyIds.supplier).map(async id => {
          const supplierDoc = await getDocs(
            query(collection(db, 'suppliers'), where('id', '==', id))
          );
          
          if (!supplierDoc.empty) {
            const supplierData = supplierDoc.docs[0].data();
            return {
              id,
              name: supplierData.name || supplierData.businessName || 
                    `${supplierData.firstName || ''} ${supplierData.lastName || ''}`.trim() || 
                    'Unknown Supplier'
            };
          }
          return { id, name: 'Unknown Supplier' };
        });
        
        const suppliers = await Promise.all(supplierPromises);
        const supplierNames = {};
        suppliers.forEach(supplier => {
          supplierNames[supplier.id] = supplier.name;
        });
        
        setCounterpartyNames(prev => ({
          ...prev,
          ...supplierNames
        }));
      } catch (error) {
        console.error('Error fetching supplier names:', error);
      }
    }
  };
  
  // Apply filters
  const applyFilters = (transactionsToFilter = transactions) => {
    let filtered = [...transactionsToFilter];
    
    // Apply type filter
    if (activeType !== 'all') {
      filtered = filtered.filter(transaction => transaction.type === activeType);
    }
    
    // Apply status filter
    if (activeStatus !== 'all') {
      filtered = filtered.filter(transaction => transaction.status === activeStatus);
    }
    
    // Apply date range filter
    if (dateRange.start) {
      const startDate = new Date(dateRange.start);
      filtered = filtered.filter(transaction => 
        transaction.date >= startDate
      );
    }
    
    if (dateRange.end) {
      const endDate = new Date(dateRange.end);
      endDate.setHours(23, 59, 59, 999); // End of day
      filtered = filtered.filter(transaction => 
        transaction.date <= endDate
      );
    }
    
    // Apply search term
    if (searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(transaction => 
        transaction.description?.toLowerCase().includes(lowerCaseSearchTerm) ||
        transaction.documentNumber?.toLowerCase().includes(lowerCaseSearchTerm) ||
        transaction.notes?.toLowerCase().includes(lowerCaseSearchTerm) ||
        (counterpartyNames[transaction.counterpartyId] || '').toLowerCase().includes(lowerCaseSearchTerm)
      );
    }
    
    setFilteredTransactions(filtered);
 };
 
 // Handle search input change
 const handleSearchChange = (e) => {
   setSearchTerm(e.target.value);
 };
 
 // Apply filters when filter values change
 useEffect(() => {
   applyFilters();
 }, [activeType, activeStatus, dateRange, searchTerm]);
 
 // Reset all filters
 const resetFilters = () => {
   setSearchTerm('');
   setActiveType('all');
   setActiveStatus('all');
   setDateRange({ start: '', end: '' });
 };
 
 // Format date
 const formatDate = (date) => {
   if (!date) return '';
   
   return new Date(date).toLocaleDateString('en-US', {
     year: 'numeric',
     month: 'short',
     day: 'numeric'
   });
 };
 
 // Format currency
 const formatCurrency = (amount, negative = false) => {
   if (amount === undefined || amount === null) return '';
   
   const value = negative ? -Math.abs(amount) : amount;
   
   return new Intl.NumberFormat('en-US', {
     style: 'currency',
     currency: 'USD',
     minimumFractionDigits: 2
   }).format(value);
 };
 
 // Get transaction icon
 const getTransactionIcon = (transaction) => {
   switch (transaction.type) {
     case 'simple':
       return transaction.amount >= 0 ? ArrowUpRight : ArrowDownRight;
     case 'invoice':
       return Receipt;
     case 'bill':
       return BadgePercent;
     case 'budget':
       return BarChart2;
     default:
       return DollarSign;
   }
 };
 
 // Get status chip styling
 const getStatusStyle = (status) => {
   switch (status) {
     case 'draft':
       return 'bg-gray-100 text-gray-800';
     case 'sent':
     case 'pending':
       return 'bg-blue-100 text-blue-800';
     case 'paid':
     case 'completed':
       return 'bg-green-100 text-green-800';
     case 'overdue':
       return 'bg-red-100 text-red-800';
     default:
       return 'bg-gray-100 text-gray-700';
   }
 };
 
 // Get business name by ID
 const getBusinessName = (businessId) => {
   const business = businesses.find(b => b.id === businessId);
   return business ? business.name : 'Unknown Business';
 };
 
 // Get counterparty name by ID
 const getCounterpartyName = (transaction) => {
   if (!transaction.counterpartyId || !transaction.counterpartyType) return '';
   
   return counterpartyNames[transaction.counterpartyId] || 
          (transaction.counterpartyType === 'customer' ? 'Customer' : 'Supplier');
 };

 return (
   <div>
     {/* Search and Actions Bar */}
     <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
       <div className="relative flex-1">
         <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
         <input
           type="text"
           className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
           placeholder="Search transactions..."
           value={searchTerm}
           onChange={handleSearchChange}
         />
       </div>
       
       <div className="flex gap-3">
         <button
           onClick={() => setShowFilters(!showFilters)}
           className={`px-4 py-2.5 rounded-lg border flex items-center ${
             showFilters ? 'border-[#190192] text-[#190192] bg-purple-50' : 'border-gray-200 text-gray-600'
           }`}
         >
           <Filter size={18} className="mr-2" />
           Filters
         </button>
         
         <button className="p-2.5 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50">
           <Download size={18} />
         </button>
         
         <Link
           to="/finance/new"
           className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5]"
         >
           <PlusCircle size={18} className="mr-2" />
           New Transaction
         </Link>
       </div>
     </div>
     
     {/* Filters Panel */}
     {showFilters && (
       <div className="bg-white rounded-xl shadow-md p-6 mb-6">
         <div className="flex justify-between items-center mb-4">
           <h3 className="text-lg font-medium text-gray-900">Filters</h3>
           <button
             onClick={resetFilters}
             className="text-[#190192] hover:text-[#2C0DB5] text-sm"
           >
             Reset Filters
           </button>
         </div>
         
         <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
           {/* Business Filter */}
           <div>
             <label className="block text-sm font-medium text-gray-700 mb-1">
               Business
             </label>
             <select
               value={activeBusinessId}
               onChange={(e) => setActiveBusinessId(e.target.value)}
               className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
             >
               <option value="all">All Businesses</option>
               {businesses.map(business => (
                 <option key={business.id} value={business.id}>{business.name}</option>
               ))}
             </select>
           </div>
           
           {/* Type Filter */}
           <div>
             <label className="block text-sm font-medium text-gray-700 mb-1">
               Transaction Type
             </label>
             <select
               value={activeType}
               onChange={(e) => setActiveType(e.target.value)}
               className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
             >
               <option value="all">All Types</option>
               <option value="simple">Simple Transaction</option>
               <option value="invoice">Invoice</option>
               <option value="bill">Bill</option>
               <option value="budget">Budget</option>
             </select>
           </div>
           
           {/* Status Filter */}
           <div>
             <label className="block text-sm font-medium text-gray-700 mb-1">
               Status
             </label>
             <select
               value={activeStatus}
               onChange={(e) => setActiveStatus(e.target.value)}
               className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
             >
               <option value="all">All Statuses</option>
               <option value="draft">Draft</option>
               <option value="sent">Sent</option>
               <option value="pending">Pending</option>
               <option value="paid">Paid</option>
               <option value="overdue">Overdue</option>
             </select>
           </div>
           
           {/* Date Range */}
           <div>
             <label className="block text-sm font-medium text-gray-700 mb-1">
               Start Date
             </label>
             <input
               type="date"
               value={dateRange.start}
               onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
               className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
             />
           </div>
           
           <div>
             <label className="block text-sm font-medium text-gray-700 mb-1">
               End Date
             </label>
             <input
               type="date"
               value={dateRange.end}
               onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
               className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
             />
           </div>
         </div>
       </div>
     )}

     {/* Loading State */}
     {isLoading ? (
       <div className="flex justify-center items-center h-64">
         <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
       </div>
     ) : (
       <>
         {/* Empty State */}
         {transactions.length === 0 ? (
           <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
             <DollarSign size={48} className="mx-auto text-gray-400 mb-4" />
             <h3 className="text-lg font-medium text-gray-900 mb-2">No transactions found</h3>
             <p className="text-gray-500 mb-6 max-w-md mx-auto">
               You haven't recorded any financial transactions yet. Click the 'New Transaction' button to get started.
             </p>
             <Link
               to="/finance/new"
               className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
             >
               <PlusCircle size={16} className="mr-2" />
               Record Your First Transaction
             </Link>
           </div>
         ) : filteredTransactions.length === 0 ? (
           <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
             <DollarSign size={48} className="mx-auto text-gray-400 mb-4" />
             <h3 className="text-lg font-medium text-gray-900 mb-2">No matching transactions</h3>
             <p className="text-gray-500 mb-6 max-w-md mx-auto">
               We couldn't find any transactions matching your search criteria. Try adjusting your search or filters.
             </p>
             <button
               onClick={resetFilters}
               className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
             >
               Clear Filters
             </button>
           </div>
         ) : (
           /* Transactions Table */
           <div className="bg-white rounded-xl shadow-md overflow-hidden">
             <div className="overflow-x-auto">
               <table className="min-w-full divide-y divide-gray-200">
                 <thead className="bg-gray-50">
                   <tr>
                     <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Date
                     </th>
                     <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Description
                     </th>
                     <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Business
                     </th>
                     <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Reference
                     </th>
                     <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Amount
                     </th>
                     <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Status
                     </th>
                     <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                       Actions
                     </th>
                   </tr>
                 </thead>
                 <tbody className="bg-white divide-y divide-gray-200">
                   {filteredTransactions.map((transaction) => {
                     const TransactionIcon = getTransactionIcon(transaction);
                     
                     return (
                       <tr key={transaction.id} className="hover:bg-gray-50">
                         <td className="px-6 py-4 whitespace-nowrap">
                           <div className="text-sm text-gray-900">{formatDate(transaction.date)}</div>
                           <div className="text-xs text-gray-500">
                             {transaction.type === 'invoice' || transaction.type === 'bill' ? formatDate(transaction.dueDate) : ''}
                           </div>
                         </td>
                         <td className="px-6 py-4">
                           <div className="flex items-center">
                             <div className={`flex-shrink-0 h-8 w-8 rounded-full flex items-center justify-center ${
                               transaction.type === 'simple' && transaction.amount >= 0 ? 'bg-green-100 text-green-600' :
                               transaction.type === 'simple' ? 'bg-red-100 text-red-600' :
                               transaction.type === 'invoice' ? 'bg-blue-100 text-blue-600' :
                               transaction.type === 'bill' ? 'bg-amber-100 text-amber-600' :
                               'bg-purple-100 text-purple-600'
                             }`}>
                               <TransactionIcon size={16} />
                             </div>
                             <div className="ml-3">
                               <div className="text-sm font-medium text-gray-900">{transaction.description}</div>
                               {(transaction.type === 'invoice' || transaction.type === 'bill') && (
                                 <div className="text-xs text-gray-500 flex items-center">
                                   {transaction.type === 'invoice' ? (
                                     <Users size={12} className="mr-1" />
                                   ) : (
                                     <Building size={12} className="mr-1" />
                                   )}
                                   {getCounterpartyName(transaction)}
                                 </div>
                               )}
                               {transaction.type === 'simple' && transaction.category && (
                                 <div className="text-xs text-gray-500 flex items-center">
                                   <Tag size={12} className="mr-1" />
                                   {transaction.category}
                                 </div>
                               )}
                             </div>
                           </div>
                         </td>
                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                           {getBusinessName(transaction.businessId)}
                         </td>
                         <td className="px-6 py-4 whitespace-nowrap">
                           <div className="text-sm text-gray-900">
                             {transaction.documentNumber || transaction.reference || '-'}
                           </div>
                         </td>
                         <td className="px-6 py-4 whitespace-nowrap text-right">
                           <div className={`text-sm font-medium ${
                             transaction.type === 'simple' && transaction.amount < 0 ? 'text-red-600' :
                             'text-green-600'
                           }`}>
                             {transaction.type === 'simple' 
                               ? formatCurrency(transaction.amount) 
                               : formatCurrency(transaction.totalAmount || transaction.amount)}
                           </div>
                           {(transaction.type === 'invoice' || transaction.type === 'bill') && transaction.balanceDue > 0 && (
                             <div className="text-xs text-gray-500">
                               {formatCurrency(transaction.balanceDue)} due
                             </div>
                           )}
                         </td>
                         <td className="px-6 py-4 whitespace-nowrap text-center">
                           {transaction.status && (
                             <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(transaction.status)}`}>
                               {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}
                             </span>
                           )}
                           {transaction.isRecurring && (
                             <div className="text-xs text-purple-600 mt-1 flex items-center justify-center">
                               <Check size={10} className="mr-1" />
                               Recurring
                             </div>
                           )}
                         </td>
                         <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                           <Link
                             to={`/finance/${transaction.id}`}
                             className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
                           >
                             View <ChevronRight size={16} className="ml-1" />
                           </Link>
                         </td>
                       </tr>
                     );
                   })}
                 </tbody>
               </table>
             </div>
             
             {/* Load More Button */}
             {hasMore && (
               <div className="px-6 py-4 border-t border-gray-200 text-center">
                 <button
                   onClick={loadMoreTransactions}
                   disabled={isLoadingMore}
                   className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                 >
                   {isLoadingMore ? (
                     <>
                       <span className="inline-block animate-spin h-4 w-4 border-2 border-gray-500 rounded-full border-t-transparent mr-2 align-middle"></span>
                       Loading...
                     </>
                   ) : (
                     'Load More'
                   )}
                 </button>
               </div>
             )}
           </div>
         )}
       </>
     )}
   </div>
 );
};

export default TransactList;