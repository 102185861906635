// src/components/inventory/buying/PurchaseOrderForm.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { 
  ChevronLeft, Check, AlertCircle, ShoppingBag, 
  Search, Calendar, Building, DollarSign, 
  Package, Trash, PlusCircle, Upload, X,
  FileText, CreditCard, Truck
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { useCurrency } from '../../../utils/currencyUtils';
import { db } from '../../../firebase/config';

const PurchaseOrderForm = ({ isEditing = false }) => {
  const { poId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, isDemoMode } = useAuth();
  const { formatAmount } = useCurrency();
  
  const fileInputRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const supplierIdFromParams = queryParams.get('supplier');
  const itemIdFromParams = queryParams.get('item');
  
  // Form state
  const [formData, setFormData] = useState({
    poNumber: '',
    supplierId: supplierIdFromParams || '',
    orderDate: new Date().toISOString().split('T')[0],
    expectedDeliveryDate: '',
    status: 'draft',
    shippingMethod: '',
    shippingAddress: '',
    paymentTerms: '',
    currency: 'USD',
    notes: '',
    items: [],
    subtotal: 0,
    taxAmount: 0,
    shippingCost: 0,
    totalAmount: 0,
    attachments: []
  });

  // Reference data
  const [suppliers, setSuppliers] = useState([]);
  const [stockItems, setStockItems] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  
  // State for searching items
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  
  // State for file uploads
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  
  // State for validation errors and loading state
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  // Shipping methods
  const shippingMethods = [
    { value: 'standard', label: 'Standard Shipping' },
    { value: 'express', label: 'Express Shipping' },
    { value: 'overnight', label: 'Overnight Shipping' },
    { value: 'pickup', label: 'Supplier Pickup' },
    { value: 'freight', label: 'Freight' }
  ];
  
  // Payment terms
  const paymentTermsOptions = [
    { value: 'immediate', label: 'Immediate Payment' },
    { value: 'net15', label: 'Net 15 days' },
    { value: 'net30', label: 'Net 30 days' },
    { value: 'net45', label: 'Net 45 days' },
    { value: 'net60', label: 'Net 60 days' },
    { value: 'eom', label: 'End of Month' }
  ];

  // Currency options
  const currencyOptions = [
    { code: 'USD', name: 'US Dollar', symbol: '$' },
    { code: 'EUR', name: 'Euro', symbol: '€' },
    { code: 'GBP', name: 'British Pound', symbol: '£' },
    { code: 'ZAR', name: 'South African Rand', symbol: 'R' },
    { code: 'JPY', name: 'Japanese Yen', symbol: '¥' },
    { code: 'CAD', name: 'Canadian Dollar', symbol: 'CA$' },
    { code: 'AUD', name: 'Australian Dollar', symbol: 'A$' }
  ];

  // Fetch suppliers and stock items data
  useEffect(() => {
    const fetchReferenceData = async () => {
      try {
        // In a real app, these would be API calls
        // Simulate API call with timeout
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Mock suppliers data
        const mockSuppliers = [
          {
            id: '1',
            name: 'Global Textiles Inc.',
            category: 'Raw Materials',
            paymentTerms: 'net30',
            currency: 'USD',
            address: '123 Manufacturing Blvd, New York, NY 10001'
          },
          {
            id: '2',
            name: 'Tech Components Ltd',
            category: 'Electronics',
            paymentTerms: 'net15',
            currency: 'USD',
            address: '456 Tech Park, San Francisco, CA 94107'
          },
          {
            id: '3',
            name: 'Packaging Solutions',
            category: 'Packaging',
            paymentTerms: 'net45',
            currency: 'USD',
            address: '789 Industrial Way, Chicago, IL 60607'
          }
        ];
        
        // Mock stock items data
        const mockStockItems = [
          {
            id: '1',
            sku: 'TS-001',
            name: 'T-Shirt Large',
            category: 'Apparel',
            costPrice: 8.50
          },
          {
            id: '2',
            sku: 'MG-100',
            name: 'Coffee Mug',
            category: 'Gifts',
            costPrice: 5.75
          },
          {
            id: '3',
            sku: 'DL-050',
            name: 'E-book Bundle',
            category: 'Digital Products',
            costPrice: 10.00
          },
          {
            id: '4',
            sku: 'RM-201',
            name: 'Cotton Fabric',
            category: 'Raw Materials',
            costPrice: 4.25
          },
          {
            id: '5',
            sku: 'TS-002',
            name: 'T-Shirt Medium',
            category: 'Apparel',
            costPrice: 8.50
          }
        ];
        
        setSuppliers(mockSuppliers);
        setStockItems(mockStockItems);
        
        // If supplier ID is provided in params, pre-select it
        if (supplierIdFromParams) {
          const selectedSupp = mockSuppliers.find(s => s.id === supplierIdFromParams);
          if (selectedSupp) {
            setSelectedSupplier(selectedSupp);
            // Apply supplier defaults
            setFormData(prev => ({
              ...prev,
              supplierId: selectedSupp.id,
              paymentTerms: selectedSupp.paymentTerms,
              currency: selectedSupp.currency,
              shippingAddress: selectedSupp.address
            }));
          }
        }
        
        // If item ID is provided in params, add it to the order
        if (itemIdFromParams) {
          const itemToAdd = mockStockItems.find(i => i.id === itemIdFromParams);
          if (itemToAdd) {
            handleAddItem(itemToAdd);
          }
        }
        
      } catch (error) {
        console.error('Error fetching reference data:', error);
      }
    };
    
    fetchReferenceData();
    
    // Generate PO number if creating a new purchase order
    if (!isEditing) {
      const generatedPoNumber = generatePONumber();
      setFormData(prev => ({
        ...prev,
        poNumber: generatedPoNumber
      }));
    }
  }, [supplierIdFromParams, itemIdFromParams, isEditing]);

  // Fetch purchase order data if editing
  useEffect(() => {
    if (isEditing && poId) {
      setIsLoading(true);
      
      // In a real app, this would be an API call
      setTimeout(() => {
        // Mock purchase order data
        const mockPurchaseOrder = {
          id: poId,
          poNumber: 'PO-2025-001',
          supplierId: '1',
          orderDate: '2025-03-01',
          expectedDeliveryDate: '2025-03-31',
          status: 'pending',
          shippingMethod: 'standard',
          shippingAddress: '123 Manufacturing Blvd, New York, NY 10001',
          paymentTerms: 'net30',
          currency: 'USD',
          notes: 'Please ship as soon as possible.',
          items: [
            {
              id: '1',
              sku: 'TS-001',
              name: 'T-Shirt Large',
              description: 'Large size t-shirt, black color',
              quantity: 50,
              unitPrice: 8.50,
              taxRate: 7,
              amount: 425.00,
              taxAmount: 29.75,
              totalAmount: 454.75
            },
            {
              id: '4',
              sku: 'RM-201',
              name: 'Cotton Fabric',
              description: 'Premium cotton fabric, white, 5 yards',
              quantity: 100,
              unitPrice: 4.25,
              taxRate: 7,
              amount: 425.00,
              taxAmount: 29.75,
              totalAmount: 454.75
            }
          ],
          subtotal: 850.00,
          taxAmount: 59.50,
          shippingCost: 25.00,
          totalAmount: 934.50,
          attachments: []
        };
        
        setFormData(mockPurchaseOrder);
        
        // Set selected supplier
        const supplier = suppliers.find(s => s.id === mockPurchaseOrder.supplierId);
        if (supplier) {
          setSelectedSupplier(supplier);
        }
        
        setIsLoading(false);
      }, 800);
    }
  }, [isEditing, poId, suppliers]);

  // Generate a unique PO number
  const generatePONumber = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
    return `PO-${year}-${month}${random}`;
  };
  
  // Handle supplier change
  const handleSupplierChange = (e) => {
    const supplierId = e.target.value;
    setFormData(prev => ({
      ...prev,
      supplierId
    }));
    
    // Clear supplier-related errors
    if (formErrors.supplierId) {
      setFormErrors(prev => ({
        ...prev,
        supplierId: ''
      }));
    }
    
    // Find and set selected supplier
    const supplier = suppliers.find(s => s.id === supplierId);
    setSelectedSupplier(supplier);
    
    // Apply supplier defaults if found
    if (supplier) {
      setFormData(prev => ({
        ...prev,
        paymentTerms: supplier.paymentTerms || prev.paymentTerms,
        currency: supplier.currency || prev.currency,
        shippingAddress: supplier.address || prev.shippingAddress
      }));
    }
  };
  
  // Handle text input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
    
    // Special handling for shipping cost changes
    if (name === 'shippingCost') {
      const newShippingCost = value === '' ? 0 : parseFloat(value);
      if (!isNaN(newShippingCost)) {
        calculateTotals({
          ...formData,
          shippingCost: newShippingCost
        });
      }
    }
  };
  
  // Handle item search
  const handleItemSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    
    if (searchValue.trim() === '') {
      setSearchResults([]);
      setShowSearchResults(false);
      return;
    }
    
    // Filter stock items based on search term
    const results = stockItems.filter(item => 
      item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.sku.toLowerCase().includes(searchValue.toLowerCase())
    );
    
    setSearchResults(results);
    setShowSearchResults(true);
  };
  
  // Handle adding item to the purchase order
  const handleAddItem = (item) => {
    // Check if the item is already added
    const isItemAlreadyAdded = formData.items.some(existingItem => existingItem.id === item.id);
    
    if (isItemAlreadyAdded) {
      // If already added, you could increment quantity or show a message
      const updatedItems = formData.items.map(existingItem => {
        if (existingItem.id === item.id) {
          const newQuantity = (existingItem.quantity || 1) + 1;
          const amount = newQuantity * existingItem.unitPrice;
          const taxAmount = amount * (existingItem.taxRate / 100);
          
          return {
            ...existingItem,
            quantity: newQuantity,
            amount,
            taxAmount,
            totalAmount: amount + taxAmount
          };
        }
        return existingItem;
      });
      
      setFormData(prev => {
        const newData = {
          ...prev,
          items: updatedItems
        };
        
        // Recalculate totals
        calculateTotals(newData);
        
        return newData;
      });
    } else {
      // Add new item
      const newItem = {
        id: item.id,
        sku: item.sku,
        name: item.name,
        description: '',
        quantity: 1,
        unitPrice: item.costPrice,
        taxRate: 0,
        amount: item.costPrice,
        taxAmount: 0,
        totalAmount: item.costPrice
      };
      
      setFormData(prev => {
        const newData = {
          ...prev,
          items: [...prev.items, newItem]
        };
        
        // Recalculate totals
        calculateTotals(newData);
        
        return newData;
      });
    }
    
    // Clear search
    setSearchTerm('');
    setSearchResults([]);
    setShowSearchResults(false);
    
    // Clear items error if present
    if (formErrors.items) {
      setFormErrors(prev => ({
        ...prev,
        items: ''
      }));
    }
  };
  
  // Handle removing item from the purchase order
  const handleRemoveItem = (itemId) => {
    const updatedItems = formData.items.filter(item => item.id !== itemId);
    
    setFormData(prev => {
      const newData = {
        ...prev,
        items: updatedItems
      };
      
      // Recalculate totals
      calculateTotals(newData);
      
      return newData;
    });
  };
  
  // Handle item field changes
  const handleItemChange = (itemId, field, value) => {
    const updatedItems = formData.items.map(item => {
      if (item.id === itemId) {
        const updatedItem = { ...item, [field]: value };
        
        // Recalculate item totals if quantity, price or tax changed
        if (field === 'quantity' || field === 'unitPrice' || field === 'taxRate') {
          const quantity = parseFloat(updatedItem.quantity) || 0;
          const unitPrice = parseFloat(updatedItem.unitPrice) || 0;
          const taxRate = parseFloat(updatedItem.taxRate) || 0;
          
          const amount = quantity * unitPrice;
          const taxAmount = amount * (taxRate / 100);
          
          updatedItem.amount = amount;
          updatedItem.taxAmount = taxAmount;
          updatedItem.totalAmount = amount + taxAmount;
        }
        
        return updatedItem;
      }
      return item;
    });
    
    setFormData(prev => {
      const newData = {
        ...prev,
        items: updatedItems
      };
      
      // Recalculate totals
      calculateTotals(newData);
      
      return newData;
    });
  };
  
  // Calculate order totals
  const calculateTotals = (data) => {
    const items = data.items || [];
    
    let subtotal = 0;
    let taxAmount = 0;
    
    items.forEach(item => {
      subtotal += parseFloat(item.amount) || 0;
      taxAmount += parseFloat(item.taxAmount) || 0;
    });
    
    const shippingCost = parseFloat(data.shippingCost) || 0;
    const totalAmount = subtotal + taxAmount + shippingCost;
    
    setFormData(prev => ({
      ...prev,
      subtotal,
      taxAmount,
      totalAmount
    }));
  };
  
  // Handle file upload
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    
    if (files.length === 0) return;
    
    // Validate files (size and type)
    const validFiles = files.filter(file => {
      // Check file size (10MB limit)
      if (file.size > 10 * 1024 * 1024) {
        setFormErrors(prev => ({
          ...prev,
          attachments: `File ${file.name} exceeds the 10MB size limit`
        }));
        return false;
      }
      
      // Validate file type
      const validTypes = [
        'image/jpeg', 'image/png', 'image/jpg',
        'application/pdf', 
        'application/msword', 
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];
      
      if (!validTypes.includes(file.type)) {
        setFormErrors(prev => ({
          ...prev,
          attachments: `File ${file.name} has an unsupported format`
        }));
        return false;
      }
      
      return true;
    });
    
    // Add valid files to state
    setUploadedFiles(prev => [...prev, ...validFiles.map(file => ({
      id: Math.random().toString(36).substring(2),
      file,
      name: file.name,
      type: file.type.startsWith('image/') ? 'image' : 'document',
      progress: 0
    }))]);
    
    // Clear input
    e.target.value = null;
    
    // Clear errors
    if (formErrors.attachments) {
      setFormErrors(prev => ({ ...prev, attachments: '' }));
    }
  };
  
  // Remove attachment
  const handleRemoveFile = (fileId) => {
    setUploadedFiles(prev => prev.filter(file => file.id !== fileId));
  };
  
  // Validate the form
  const validateForm = () => {
    const errors = {};
    
    // Required fields
    if (!formData.supplierId) errors.supplierId = 'Supplier is required';
    if (!formData.orderDate) errors.orderDate = 'Order date is required';
    if (!formData.expectedDeliveryDate) errors.expectedDeliveryDate = 'Expected delivery date is required';
    
    // Validate items
    if (!formData.items || formData.items.length === 0) {
      errors.items = 'At least one item is required';
    } else {
      // Check each item
      formData.items.forEach((item, index) => {
        if (!item.quantity || isNaN(parseFloat(item.quantity)) || parseFloat(item.quantity) <= 0) {
          errors[`items[${index}].quantity`] = 'Valid quantity is required';
        }
        if (!item.unitPrice || isNaN(parseFloat(item.unitPrice))) {
          errors[`items[${index}].unitPrice`] = 'Valid unit price is required';
        }
      });
    }
    
    // Validate shipping cost if provided
    if (formData.shippingCost && isNaN(parseFloat(formData.shippingCost))) {
      errors.shippingCost = 'Shipping cost must be a valid number';
    }
    
    return errors;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isDemoMode) {
        alert("Demo Mode Restriction:\nIn demo mode, data modifications are not saved. Sign up for a free account to use all features.");
        return;
      }
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      // Scroll to first error
      const firstError = document.querySelector('.text-red-600');
      if (firstError) {
        firstError.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
    
    setIsLoading(true);
    
    try {
      // In a real app, this would handle file uploads and post to an API
      // For now, we'll just simulate the API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Log data to console for debugging
      console.log('Submitting purchase order:', formData);
      
      // Navigate back to the list or detail page
      navigate(isEditing ? `/inventory/buying/${poId}` : '/inventory/buying');
    } catch (error) {
      console.error('Error saving purchase order:', error);
      setFormErrors(prev => ({
        ...prev,
        submit: 'An error occurred while saving. Please try again.'
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(isEditing ? `/inventory/buying/${poId}` : '/inventory/buying');
  };

  // Get currency symbol
  const getCurrencySymbol = (code) => {
    const currency = currencyOptions.find(c => c.code === code);
    return currency ? currency.symbol : '$';
  };

  if (isLoading && isEditing) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xs shadow-md overflow-hidden">
      <div className="bg-white shadow-md px-6 py-4 text-gray-800">
        <h2 className="text-lg font-medium">
          {isEditing ? 'Edit Purchase Order' : 'Create Purchase Order'}
        </h2>
        <p className="text-purple-500 text-sm">
          {isEditing 
            ? 'Update the purchase order details below' 
            : 'Fill out the form below to create a new purchase order'}
        </p>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        <div className="mb-6">
          <button 
            type="button"
            onClick={handleCancel}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Purchase Orders
          </button>
        </div>
        
        {/* Purchase Order Information */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Purchase Order Information</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            {/* PO Number */}
            <div>
              <label htmlFor="poNumber" className="block text-sm font-medium text-gray-700 mb-1">
                PO Number
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <ShoppingBag size={18} className="text-gray-400" />
                </div>
                <input
                  id="poNumber"
                  name="poNumber"
                  type="text"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.poNumber}
                  readOnly
                />
              </div>
              <p className="mt-1 text-xs text-gray-500">Automatically generated. Read only.</p>
            </div>
            
            {/* Supplier */}
            <div>
              <label htmlFor="supplierId" className="block text-sm font-medium text-gray-700 mb-1">
                Supplier <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Building size={18} className="text-gray-400" />
                </div>
                <select
                  id="supplierId"
                  name="supplierId"
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.supplierId ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  value={formData.supplierId}
                  onChange={handleSupplierChange}
                  disabled={isLoading}
                >
                  <option value="">Select a supplier</option>
                  {suppliers.map(supplier => (
                    <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                  ))}
                </select>
              </div>
              {formErrors.supplierId && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.supplierId}
                </p>
              )}
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            {/* Order Date */}
            <div>
              <label htmlFor="orderDate" className="block text-sm font-medium text-gray-700 mb-1">
                Order Date <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Calendar size={18} className="text-gray-400" />
                </div>
                <input
                  id="orderDate"
                  name="orderDate"
                  type="date"
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.orderDate ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  value={formData.orderDate}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {formErrors.orderDate && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.orderDate}
                </p>
              )}
            </div>
            
            {/* Expected Delivery Date */}
            <div>
              <label htmlFor="expectedDeliveryDate" className="block text-sm font-medium text-gray-700 mb-1">
                Expected Delivery Date <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Calendar size={18} className="text-gray-400" />
                </div>
                <input
                  id="expectedDeliveryDate"
                  name="expectedDeliveryDate"
                  type="date"
                  className={`pl-10 block w-full rounded-lg border ${
                    formErrors.expectedDeliveryDate ? 'border-red-300' : 'border-gray-300'
                  } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                  value={formData.expectedDeliveryDate}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              {formErrors.expectedDeliveryDate && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.expectedDeliveryDate}
                </p>
              )}
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            {/* Status */}
            <div>
              <label htmlFor="status" className="block text-sm font-medium text-gray-700 mb-1">
                Status
              </label>
              <select
                id="status"
                name="status"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.status}
                onChange={handleInputChange}
                disabled={isLoading}
              >
                <option value="draft">Draft</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>
            
            {/* Shipping Method */}
            <div>
              <label htmlFor="shippingMethod" className="block text-sm font-medium text-gray-700 mb-1">
                Shipping Method
              </label>
              <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Truck size={18} className="text-gray-400" />
                </div>
                <select
                  id="shippingMethod"
                  name="shippingMethod"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.shippingMethod}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  <option value="">Select shipping method</option>
                  {shippingMethods.map(method => (
                    <option key={method.value} value={method.value}>{method.label}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          
          {/* Shipping Address */}
          <div className="mb-4">
            <label htmlFor="shippingAddress" className="block text-sm font-medium text-gray-700 mb-1">
              Shipping Address
            </label>
            <textarea
              id="shippingAddress"
              name="shippingAddress"
              rows="3"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              placeholder="Enter shipping address"
              value={formData.shippingAddress}
              onChange={handleInputChange}
              disabled={isLoading}
            ></textarea>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Payment Terms */}
            <div>
              <label htmlFor="paymentTerms" className="block text-sm font-medium text-gray-700 mb-1">
                Payment Terms
              </label>
              <select
                id="paymentTerms"
                name="paymentTerms"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.paymentTerms}
                onChange={handleInputChange}
                disabled={isLoading}
              >
                <option value="">Select payment terms</option>
                {paymentTermsOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            
            {/* Currency */}
            <div>
              <label htmlFor="currency" className="block text-sm font-medium text-gray-700 mb-1">
                Currency
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <CreditCard size={18} className="text-gray-400" />
                </div>
                <select
                  id="currency"
                  name="currency"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.currency}
                  onChange={handleInputChange}
                  disabled={isLoading}
                >
                  {currencyOptions.map(currency => (
                    <option key={currency.code} value={currency.code}>
                      {currency.code} - {currency.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        
        {/* Order Items */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Order Items</h3>
          
          {/* Item Search */}
          <div className="mb-4 relative">
            <label htmlFor="itemSearch" className="block text-sm font-medium text-gray-700 mb-1">
              Search and Add Items
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search size={18} className="text-gray-400" />
              </div>
              <input
                id="itemSearch"
                type="text"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                placeholder="Search items by name or SKU"
                value={searchTerm}
                onChange={handleItemSearch}
                disabled={isLoading}
              />
            </div>
            
            {/* Search Results Dropdown */}
            {showSearchResults && searchResults.length > 0 && (
              <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto">
                {searchResults.map(item => (
                  <div 
                    key={item.id}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-100 flex items-center justify-between"
                    onClick={() => handleAddItem(item)}
                  >
                    <div>
                      <div className="font-medium text-gray-900">{item.name}</div>
                      <div className="text-sm text-gray-500">SKU: {item.sku}</div>
                    </div>
                    <div className="text-sm text-gray-700 font-medium">
                      {getCurrencySymbol(formData.currency)}{item.costPrice.toFixed(2)}
                    </div>
                  </div>
                ))}
              </div>
            )}
            
            {searchTerm && searchResults.length === 0 && showSearchResults && (
              <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg p-4 text-center">
                <p className="text-gray-500">No items found matching "{searchTerm}"</p>
              </div>
            )}
          </div>
          
          {/* Error Message for Items */}
          {formErrors.items && (
            <div className="mb-4">
              <p className="text-red-600 text-sm flex items-center">
                <AlertCircle size={16} className="mr-2" /> {formErrors.items}
              </p>
            </div>
          )}
          
          {/* Items Table */}
          <div className="border rounded-lg overflow-hidden mb-4">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Item
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </th>
                  <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Quantity
                  </th>
                  <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Unit Price
                  </th>
                  <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tax %
                  </th>
                  <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th scope="col" className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {formData.items.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="px-6 py-8 text-center text-sm text-gray-500">
                      No items added yet. Search for items above to add them to this purchase order.
                    </td>
                  </tr>
                ) : (
                  formData.items.map((item, index) => (
                    <tr key={item.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">{item.name}</div>
                        <div className="text-sm text-gray-500">SKU: {item.sku}</div>
                      </td>
                      <td className="px-6 py-4">
                        <input
                          type="text"
                          className="block w-full rounded-md border border-gray-300 shadow-sm py-1.5 px-2 text-sm focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                          placeholder="Item description"
                          value={item.description}
                          onChange={(e) => handleItemChange(item.id, 'description', e.target.value)}
                          disabled={isLoading}
                        />
                      </td>
                      <td className="px-4 py-4 text-center">
                        <input
                          type="number"
                          min="1"
                          className={`w-20 rounded-md border ${
                            formErrors[`items[${index}].quantity`] ? 'border-red-300' : 'border-gray-300'
                          } shadow-sm py-1.5 px-2 text-sm text-center focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                          value={item.quantity}
                          onChange={(e) => handleItemChange(item.id, 'quantity', e.target.value)}
                          disabled={isLoading}
                        />
                        {formErrors[`items[${index}].quantity`] && (
                          <p className="text-red-600 text-xs mt-1">{formErrors[`items[${index}].quantity`]}</p>
                        )}
                      </td>
                      <td className="px-4 py-4 text-right">
                        <div className="relative w-24 ml-auto">
                          <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                            <span className="text-gray-500">{getCurrencySymbol(formData.currency)}</span>
                          </div>
                          <input
                            type="number"
                            step="0.01"
                            min="0"
                            className={`pl-6 w-full rounded-md border ${
                              formErrors[`items[${index}].unitPrice`] ? 'border-red-300' : 'border-gray-300'
                            } shadow-sm py-1.5 px-2 text-sm text-right focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                            value={item.unitPrice}
                            onChange={(e) => handleItemChange(item.id, 'unitPrice', e.target.value)}
                            disabled={isLoading}
                          />
                          {formErrors[`items[${index}].unitPrice`] && (
                            <p className="text-red-600 text-xs mt-1">{formErrors[`items[${index}].unitPrice`]}</p>
                          )}
                        </div>
                      </td>
                      <td className="px-4 py-4 text-right">
                        <input
                          type="number"
                          min="0"
                          max="100"
                          className="w-16 rounded-md border border-gray-300 shadow-sm py-1.5 px-2 text-sm text-right focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                          value={item.taxRate}
                          onChange={(e) => handleItemChange(item.id, 'taxRate', e.target.value)}
                          disabled={isLoading}
                        />
                      </td>
                      <td className="px-4 py-4 text-right text-sm font-medium text-gray-900">
                        {getCurrencySymbol(formData.currency)}
                        {item.amount ? item.amount.toFixed(2) : '0.00'}
                      </td>
                      <td className="px-4 py-4 text-center">
                        <button
                          type="button"
                          onClick={() => handleRemoveItem(item.id)}
                          className="text-red-500 hover:text-red-700"
                          disabled={isLoading}
                        >
                          <Trash size={18} />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          
          {/* Add Item Button */}
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
            onClick={() => {
              setSearchTerm('');
              setShowSearchResults(true);
            }}
            disabled={isLoading}
          >
            <PlusCircle size={16} className="mr-2" />
            Add Another Item
          </button>
          
          {/* Order Totals */}
          <div className="mt-6 border-t border-gray-200 pt-4">
            <div className="flex justify-end">
              <div className="w-full md:w-64">
                <div className="flex justify-between py-2 text-sm">
                  <span className="font-medium text-gray-500">Subtotal:</span>
                  <span className="font-medium text-gray-900">
                    {getCurrencySymbol(formData.currency)}
                    {formData.subtotal.toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between py-2 text-sm">
                  <span className="font-medium text-gray-500">Tax:</span>
                  <span className="font-medium text-gray-900">
                    {getCurrencySymbol(formData.currency)}
                    {formData.taxAmount.toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between py-2 text-sm">
                  <div className="flex items-center">
                    <span className="font-medium text-gray-500 mr-2">Shipping:</span>
                    <input
                      type="number"
                      name="shippingCost"
                      min="0"
                      step="0.01"
                      className={`w-20 rounded-md border ${
                        formErrors.shippingCost ? 'border-red-300' : 'border-gray-300'
                      } shadow-sm py-1 px-2 text-sm focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192] text-right`}
                      value={formData.shippingCost}
                      onChange={handleInputChange}
                      disabled={isLoading}
                    />
                  </div>
                  <span className="font-medium text-gray-900">
                    {getCurrencySymbol(formData.currency)}
                    {(parseFloat(formData.shippingCost) || 0).toFixed(2)}
                  </span>
                </div>
                {formErrors.shippingCost && (
                  <p className="text-red-600 text-xs mt-1 text-right">{formErrors.shippingCost}</p>
                )}
                <div className="flex justify-between py-2 text-base font-bold border-t border-gray-200 mt-2 pt-2">
                  <span className="text-gray-900">Total:</span>
                  <span className="text-[#190192]">
                    {getCurrencySymbol(formData.currency)}
                    {formData.totalAmount.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Notes */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Additional Information</h3>
          
          {/* Notes */}
          <div className="mb-4">
            <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">
              Notes
            </label>
            <textarea
              id="notes"
              name="notes"
              rows="4"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              placeholder="Enter any additional notes for this purchase order"
              value={formData.notes}
              onChange={handleInputChange}
              disabled={isLoading}
            ></textarea>
          </div>
          
          {/* Attachments */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Attachments
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600 justify-center">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer bg-white rounded-md font-medium text-[#190192] hover:text-[#2C0DB5] focus-within:outline-none"
                  >
                    <span>Upload files</span>
                    <input
                      id="file-upload"
                      ref={fileInputRef}
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      multiple
                      onChange={handleFileUpload}
                      disabled={isLoading}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">
                  PDF, DOC, XLS, PNG, JPG up to 10MB
                </p>
              </div>
            </div>
            
            {formErrors.attachments && (
              <p className="mt-2 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.attachments}
              </p>
            )}
            
            {/* File List */}
            {uploadedFiles.length > 0 && (
              <div className="mt-4 space-y-2">
                {uploadedFiles.map(file => (
                  <div key={file.id} className="flex items-center justify-between px-4 py-2 border rounded-md">
                    <div className="flex items-center">
                      <FileText size={18} className="text-gray-400 mr-2" />
                      <span className="text-sm text-gray-900 truncate">{file.name}</span>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleRemoveFile(file.id)}
                      className="text-red-500 hover:text-red-700 p-1"
                      disabled={isLoading}
                    >
                      <X size={18} />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        
        {/* Form submission error message */}
        {formErrors.submit && (
          <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-red-600 text-sm flex items-center">
              <AlertCircle size={16} className="mr-2" />
              {formErrors.submit}
            </p>
          </div>
        )}
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-4 mt-10 pt-6 border-t border-gray-200">
          <button
            type="button"
            onClick={handleCancel}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
            disabled={isLoading}
          >
            Cancel
          </button>
          
          <button
            type="submit"
            disabled={isLoading}
            className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
              isLoading ? 'bg-purple-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
            }`}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <Check size={18} className="mr-2" />
                {isEditing ? 'Update Purchase Order' : 'Create Purchase Order'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PurchaseOrderForm;