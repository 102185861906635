// src/components/communication/InternalMessaging.js
// src/components/communication/InternalMessaging.js - Add fileInputRef declaration
import React, { useState, useEffect, useRef } from 'react';
import { 
  MessageSquare, Users, Search, Plus, Paperclip, Send, MoreHorizontal, 
  Image, File, FileText, Video, Music, Upload, AlertCircle,
  Smile, X, User, Menu, ChevronRight, ChevronLeft
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db, storage } from '../../firebase/config';
import { 
  collection, query, where, orderBy, onSnapshot, addDoc, 
  doc, updateDoc, arrayUnion, Timestamp, getDoc, getDocs, limit
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Import sub-components
import ChannelList from './ChannelList';
import DirectMessageList from './DirectMessageList';
import MessageList from './MessageList';
import EmojiPicker from './EmojiPicker';

const InternalMessaging = () => {
  const { currentUser, userProfile } = useAuth();
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [activeChannel, setActiveChannel] = useState(null);
  const [activeChannelData, setActiveChannelData] = useState(null);
  const [isDirectMessage, setIsDirectMessage] = useState(false);
  const [channels, setChannels] = useState([]);
  const [directMessages, setDirectMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showChannelMembers, setShowChannelMembers] = useState(false);
  const [selectedChannelForMembers, setSelectedChannelForMembers] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const messageInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const dropAreaRef = useRef(null);
  const fileInputRef = useRef(null); // Added this ref
  
  // Detect mobile devices
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    // Check on initial load
    checkIfMobile();
    
    // Listen for resize events
    window.addEventListener('resize', checkIfMobile);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  // Get color for user initial
  const getColorForInitial = (initial) => {
    const colors = ['blue', 'green', 'purple', 'yellow', 'red'];
    const charCode = initial ? initial.charCodeAt(0) : 0;
    return colors[charCode % colors.length];
  };

  // Fetch Channels
  useEffect(() => {
    if (!userProfile?.id || !userProfile?.institutionName) {
      setIsLoading(false);
      return;
    }
    
    setIsLoading(true);
    let unsubscribe = () => {};
    
    try {
      // Get channels the user has access to
      const channelsRef = collection(db, "channels");
      const q = query(
        channelsRef,
        where("businessId", "==", userProfile.businessId),
        orderBy("name")
      );
      
      unsubscribe = onSnapshot(q, (snapshot) => {
        const channelList = [];
        snapshot.forEach((doc) => {
          // Calculate unread count - in a real app this would be more sophisticated
          const unreadCount = Math.floor(Math.random() * 15); // Just for demo
          
          channelList.push({
            id: doc.id,
            ...doc.data(),
            unread: unreadCount
          });
        });
        
        setChannels(channelList);
        
        // If we don't have channel data yet, set the active channel
        if (channelList.length > 0 && !activeChannelData) {
          const defaultChannel = channelList.find(c => c.id === 'general') || channelList[0];
          setActiveChannel(defaultChannel.id);
          setActiveChannelData(defaultChannel);
          setIsDirectMessage(false);
        }
        
        setIsLoading(false);
      }, (error) => {
        console.error("Error fetching channels:", error);
        setError("Failed to load channels. Please try again.");
        setIsLoading(false);
      });
    } catch (error) {
      console.error("Error setting up channel listener:", error);
      setError("Failed to initialize channel listener.");
      setIsLoading(false);
    }
    
    return () => unsubscribe();
  }, [userProfile]);

  // Fetch Direct Messages
  useEffect(() => {
    if (!userProfile?.id) return;
    
    let unsubscribe = () => {};
    
    try {
      // Get user's direct message conversations
      const conversationsRef = collection(db, "conversations");
      const q = query(
        conversationsRef,
        where("participants", "array-contains", userProfile.id)
      );
      
      unsubscribe = onSnapshot(q, async (snapshot) => {
        const dmList = [];
        
        // For each conversation, get the other participant's details
        for (const docSnapshot of snapshot.docs) {
          const conversation = docSnapshot.data();
          const otherParticipantId = conversation.participants.find(id => id !== userProfile.id);
          
          if (otherParticipantId) {
            try {
              const userDoc = await getDoc(doc(db, "users", otherParticipantId));
              
              if (userDoc.exists()) {
                const userData = userDoc.data();
                // Calculate unread messages
                const unreadCount = conversation.unreadBy && conversation.unreadBy[userProfile.id] 
                  ? conversation.unreadBy[userProfile.id] 
                  : 0;
                
                dmList.push({
                  id: docSnapshot.id,
                  userId: otherParticipantId,
                  name: `${userData.firstName || ''} ${userData.lastName || ''}`.trim() || 'Unknown User',
                  avatar: `${userData.firstName?.charAt(0) || ''}${userData.lastName?.charAt(0) || '?'}`,
                  color: getColorForInitial(userData.firstName?.charAt(0) || 'A'),
                  status: userData.status || 'offline',
                  unread: unreadCount
                });
              }
            } catch (error) {
              console.error("Error fetching user details:", error);
            }
          }
        }
        
        setDirectMessages(dmList);
      }, (error) => {
        console.error("Error fetching direct messages:", error);
      });
    } catch (error) {
      console.error("Error setting up direct message listener:", error);
    }
    
    return () => unsubscribe();
  }, [userProfile]);

  // Fetch Messages for Active Channel/DM
  useEffect(() => {
    if (!activeChannel) return;
    
    // Clear current messages
    setMessages([]);
    
    let messagesRef;
    let q;
    
    if (isDirectMessage) {
      // Direct messages
      messagesRef = collection(db, "messages");
      q = query(
        messagesRef,
        where("conversationId", "==", activeChannel),
        orderBy("timestamp", "asc"),
        limit(50)
      );
    } else {
      // Channel messages
      messagesRef = collection(db, "messages");
      q = query(
        messagesRef,
        where("channelId", "==", activeChannel),
        orderBy("timestamp", "asc"),
        limit(50)
      );
    }
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messageList = [];
      snapshot.forEach((doc) => {
        messageList.push({
          id: doc.id,
          ...doc.data()
        });
      });
      
      setMessages(messageList);
      
      // Mark as read if direct message
      if (isDirectMessage && activeChannelData) {
        updateUnreadMessages(activeChannel);
      }
      
      // Scroll to bottom
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }, (error) => {
      console.error("Error fetching messages:", error);
      setError("Failed to load messages. Please try again.");
    });
    
    return unsubscribe;
  }, [activeChannel, isDirectMessage, activeChannelData]);

  // Update unread messages count
  const updateUnreadMessages = async (conversationId) => {
    if (!userProfile?.id) return;
    
    try {
      const conversationRef = doc(db, "conversations", conversationId);
      await updateDoc(conversationRef, {
        [`unreadBy.${userProfile.id}`]: 0
      });
    } catch (error) {
      console.error("Error updating unread messages:", error);
    }
  };

  // Handle channel change
  const handleChannelChange = (channelId) => {
    const selectedChannel = channels.find(c => c.id === channelId);
    if (selectedChannel) {
      setActiveChannel(channelId);
      setActiveChannelData(selectedChannel);
      setIsDirectMessage(false);
    }
  };

  // Handle direct message selection
  const handleDirectMessageSelect = async (dmId) => {
    const selectedDM = directMessages.find(dm => dm.id === dmId);
    if (selectedDM) {
      setActiveChannel(dmId);
      setActiveChannelData(selectedDM);
      setIsDirectMessage(true);
      
      // Mark as read
      updateUnreadMessages(dmId);
    }
  };

  // Send message
  const handleSendMessage = async () => {
    if ((!newMessage.trim() && attachments.length === 0) || !activeChannel || !userProfile) return;
    
    try {
      // Create message object
      const messageData = {
        content: newMessage.trim(),
        senderId: userProfile.id,
        senderName: `${userProfile.firstName} ${userProfile.lastName}`.trim(),
        senderInitials: `${userProfile.firstName?.charAt(0) || ''}${userProfile.lastName?.charAt(0) || ''}`,
        timestamp: new Date(),
        ...(isDirectMessage 
          ? { conversationId: activeChannel } 
          : { channelId: activeChannel }),
        pinned: false
      };
      
      // Add attachments if any
      if (attachments.length > 0) {
        messageData.attachments = attachments.map(attachment => ({
          name: attachment.name,
          url: attachment.url,
          type: attachment.type,
          size: attachment.size
        }));
      }
      
      // Add message to Firestore
      await addDoc(collection(db, "messages"), messageData);
      
      // If it's a DM, update the conversation with unread message
      if (isDirectMessage) {
        const conversationRef = doc(db, "conversations", activeChannel);
        const conversationDoc = await getDoc(conversationRef);
        
        if (conversationDoc.exists()) {
          const otherParticipantId = conversationDoc.data().participants.find(id => id !== userProfile.id);
          
          if (otherParticipantId) {
            await updateDoc(conversationRef, {
              lastMessage: newMessage.trim() || 'Attachment',
              lastMessageTimestamp: new Date(),
              [`unreadBy.${otherParticipantId}`]: (conversationDoc.data().unreadBy?.[otherParticipantId] || 0) + 1
            });
          }
        }
      }
      
      // Clear message and attachments
      setNewMessage('');
      setAttachments([]);
      setTimeout(() => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' }), 100);
    } catch (error) {
      console.error("Error sending message:", error);
      setError("Failed to send message. Please try again.");
    }
  };

  // Handle file upload
  const handleFileUpload = async (file) => {
    if (!file || !activeChannel) return;
    
    try {
      setError('');
      
      // Check file size (10MB limit)
      if (file.size > 10 * 1024 * 1024) {
        setError("File is too large. Maximum size is 10MB.");
        return;
      }
      
      // Create a unique filename to prevent collisions
      const fileExtension = file.name.split('.').pop();
      const uniqueFileName = `${Date.now()}_${Math.random().toString(36).substring(2, 15)}.${fileExtension}`;
      
      // Determine the correct path based on channel type
      const storagePath = isDirectMessage 
        ? `messages/direct/${activeChannel}/${uniqueFileName}`
        : `messages/channels/${activeChannel}/${uniqueFileName}`;
      
      // Upload to Firebase Storage
      const storageRef = ref(storage, storagePath);
      
      // Upload the file
      const uploadTask = uploadBytes(storageRef, file);
      const snapshot = await uploadTask;
      
      // Get the download URL
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      // Add to attachments
      const newAttachment = {
        name: file.name,
        url: downloadURL,
        type: file.type,
        size: file.size,
        path: storagePath
      };
      
      setAttachments(prev => [...prev, newAttachment]);
      return newAttachment;
    } catch (error) {
      console.error("Error uploading file:", error);
      setError(`Failed to upload file: ${error.message}`);
      return null;
    }
  };

  // Handle emoji selection
  const handleEmojiSelect = (emoji) => {
    setNewMessage(prev => prev + emoji);
    setShowEmojiPicker(false);
  };

  return (
    <div className="flex h-full relative">
      {/* Mobile Sidebar Toggle */}
      {isMobile && !mobileSidebarOpen && (
        <button 
          onClick={() => setMobileSidebarOpen(true)}
          className="fixed left-0 top-1/2 transform -translate-y-1/2 z-20 bg-[#190192] text-white p-2 rounded-r-md shadow-md"
        >
          <ChevronRight size={20} />
        </button>
      )}
      
      {/* Mobile Sidebar Overlay */}
      {mobileSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={() => setMobileSidebarOpen(false)}
        ></div>
      )}

      {/* Sidebar - Left panel with purple background */}
      {(!isMobile || mobileSidebarOpen) && (
        <div className={`fixed md:relative w-64 flex-shrink-0 flex flex-col bg-[#190192] text-white overflow-hidden h-full z-30 transition-transform ${
          isMobile && !mobileSidebarOpen ? '-translate-x-full' : 'translate-x-0'
        }`}>
          {/* Search */}
          <div className="p-4 border-b border-[#ffffff33] flex justify-between items-center">
            <div className="relative flex-1">
              <Search size={16} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#190192]" />
              <input 
                type="text" 
                placeholder="Search messages..." 
                className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-300 text-sm bg-white text-gray-800" 
              />
            </div>
            
            {/* Close button for mobile */}
            <button 
              onClick={() => setMobileSidebarOpen(false)}
              className="md:hidden ml-2 text-white hover:text-gray-200"
            >
              <ChevronLeft size={20} />
            </button>
          </div>
          
          {/* Scrollable sidebar content */}
          <div className="flex-1 overflow-y-auto">
            {/* Channels */}
            <ChannelList 
              channels={channels}
              activeChannel={activeChannel}
              onChannelSelect={(channelId) => {
                handleChannelChange(channelId);
                if (isMobile) {
                  setMobileSidebarOpen(false);
                }
              }}
              isDirectMessage={isDirectMessage}
            />
            
            {/* Direct Messages */}
            <DirectMessageList 
              directMessages={directMessages}
              activeChannel={activeChannel}
              onDirectMessageSelect={(dmId) => {
                handleDirectMessageSelect(dmId);
                if (isMobile) {
                  setMobileSidebarOpen(false);
                }
              }}
              isDirectMessage={isDirectMessage}
              getColorForInitial={getColorForInitial}
            />
          </div>
        </div>
      )}

      {/* Main Content - Message area and input */}
      <div className="flex-1 flex flex-col overflow-hidden h-full">
        {/* Channel Header */}
        <div className="flex-shrink-0 h-14 border-b flex items-center justify-between px-4 bg-white">
          <div className="flex items-center">
            <h2 className="text-lg font-semibold text-[#190192] mr-2">
              {isDirectMessage 
                ? activeChannelData?.name 
                : `#${activeChannelData?.name || 'Loading...'}`}
            </h2>
            <span className="text-sm text-gray-500">
              {(!isDirectMessage && activeChannelData?.membersCount) 
                ? `${activeChannelData.membersCount} members` 
                : ''}
            </span>
          </div>
    
          <div className="flex items-center space-x-3">
            {!isDirectMessage && activeChannelData && (
              <button
                className="text-gray-500 hover:text-gray-700"
                title="Manage channel members"
              >
                <Users size={20} />
              </button>
            )}
            
            <button className="text-gray-500 hover:text-gray-700">
              <MoreHorizontal size={20} />
            </button>
          </div>
        </div>
        
        {/* Messages Container */}
        <div 
          className="flex-1 overflow-y-auto bg-white p-4"
          ref={dropAreaRef}
        >
          {isDragging && (
            <div className="absolute inset-0 bg-blue-50 border-2 border-dashed border-blue-300 flex items-center justify-center z-10">
              <div className="text-blue-500 text-center">
                <Upload size={40} className="mx-auto mb-2" />
                <p className="font-medium">Drop files to upload</p>
              </div>
            </div>
          )}
          
          <MessageList 
            messages={messages}
            userProfile={userProfile}
            messagesEndRef={messagesEndRef}
            isLoading={isLoading}
            getColorForInitial={getColorForInitial}
          />
        </div>
        
        {/* Message Input */}
        <div className="border-t bg-white p-3">
          {/* Attachments Preview */}
          {attachments.length > 0 && (
            <div className="px-3 py-2 bg-gray-100 rounded-md mb-2">
              <div className="flex flex-wrap gap-2">
                {attachments.map((attachment, index) => (
                  <div 
                    key={index}
                    className="bg-white rounded p-2 border flex items-center"
                  >
                    {attachment.type.startsWith('image/') ? (
                      <Image size={16} className="text-blue-500 mr-2" />
                    ) : (
                      <File size={16} className="text-blue-500 mr-2" />
                    )}
                    <span className="text-sm truncate max-w-[150px]">{attachment.name}</span>
                    <button 
                      onClick={() => setAttachments(prev => prev.filter((_, i) => i !== index))}
                      className="ml-2 text-gray-400 hover:text-red-500"
                    >
                      <X size={14} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          
          {/* Error display */}
          {error && (
            <div className="px-3 py-2 bg-red-50 text-red-600 text-sm mb-2 rounded-md">
              <AlertCircle size={16} className="inline-block mr-1" />
              {error}
            </div>
          )}
          
          {/* Input Area */}
          <div className="flex items-start bg-gray-100 rounded-lg">
            <button 
              onClick={() => fileInputRef.current?.click()}
              className="p-2 text-gray-500 hover:text-gray-700"
              title="Attach file"
            >
              <Paperclip size={20} />
              <input 
                type="file" 
                ref={fileInputRef}
                className="hidden" 
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    handleFileUpload(file);
                    e.target.value = '';
                  }
                }}
              />
            </button>
            
            <textarea
              ref={messageInputRef}
              className="flex-1 bg-transparent border-0 p-2 resize-none focus:outline-none focus:ring-0"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              rows={1}
              style={{ height: '40px' }}
            />
            
            <div className="p-2 flex items-center">
              <div className="relative">
                <button 
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  className="text-gray-500 hover:text-gray-700 mr-2"
                  title="Add emoji"
                >
                  <Smile size={20} />
                </button>
                
                {showEmojiPicker && (
                  <EmojiPicker 
                    onSelect={handleEmojiSelect}
                    onClickOutside={() => setShowEmojiPicker(false)}
                  />
                )}
              </div>
              
              <button 
                onClick={handleSendMessage}
                disabled={!newMessage.trim() && attachments.length === 0}
                className={`p-1 rounded-full ${
                  !newMessage.trim() && attachments.length === 0
                    ? 'text-gray-400 cursor-not-allowed'
                    : 'text-[#190192] hover:bg-[#190192] hover:text-white'
                }`}
                title="Send message"
              >
                <Send size={20} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalMessaging;