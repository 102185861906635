// src/pages/InventoryPage.js
import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { 
  Package, Users, ShoppingBag, Truck, 
  BarChart2, PlusCircle 
} from 'lucide-react';

const InventoryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/');
  
  // Determine active tab based on URL
  const getActiveTab = () => {
    if (path.includes('items')) return 'items';
    if (path.includes('suppliers')) return 'suppliers';
    if (path.includes('buying')) return 'buying';
    if (path.includes('receiving')) return 'receiving';
    if (path.includes('reports')) return 'reports';
    return 'items'; // Default tab
  };
  
  const activeTab = getActiveTab();
  
  // Handle tab change
  const handleTabChange = (tab) => {
    switch (tab) {
      case 'items':
        navigate('/inventory/items');
        break;
      case 'suppliers':
        navigate('/inventory/suppliers');
        break;
      case 'buying':
        navigate('/inventory/buying');
        break;
      case 'receiving':
        navigate('/inventory/receiving');
        break;
      case 'reports':
        navigate('/inventory/reports');
        break;
      default:
        navigate('/inventory/items');
    }
  };
  
  // Check if we're on the main inventory list page for each tab
  const isItemsListPage = path.length === 3 && path[2] === 'items';
  const isSuppliersListPage = path.length === 3 && path[2] === 'suppliers';
  const isBuyingListPage = path.length === 3 && path[2] === 'buying';
  const isReceivingListPage = path.length === 3 && path[2] === 'receiving';
  const isReportsListPage = path.length === 3 && path[2] === 'reports';

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <div className="bg-gradient-to-r from-[#190192] to-[#3B0DB5] px-6 py-4 text-white">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold">Inventory Management</h1>
              <p className="text-purple-200 text-sm">
                Manage your inventory, suppliers, purchases, and stock levels.
              </p>
            </div>

            {/* Add buttons based on active tab */}
            {isItemsListPage && (
              <button
                onClick={() => navigate('/inventory/items/new')}
                className="px-4 py-2 bg-white text-[#190192] rounded-lg hover:bg-opacity-90 font-medium transition-all flex items-center"
              >
                <PlusCircle size={18} className="mr-2" />
                Add Item
              </button>
            )}
            {isSuppliersListPage && (
              <button
                onClick={() => navigate('/inventory/suppliers/new')}
                className="px-4 py-2 bg-white text-[#190192] rounded-lg hover:bg-opacity-90 font-medium transition-all flex items-center"
              >
                <PlusCircle size={18} className="mr-2" />
                Add Supplier
              </button>
            )}
            {isBuyingListPage && (
              <button
                onClick={() => navigate('/inventory/buying/new')}
                className="px-4 py-2 bg-white text-[#190192] rounded-lg hover:bg-opacity-90 font-medium transition-all flex items-center"
              >
                <PlusCircle size={18} className="mr-2" />
                New Purchase Order
              </button>
            )}
          </div>
        </div>
        
        {/* Tabs */}
        <div className="px-6 bg-white">
          <div className="flex overflow-x-auto">
            <button 
              onClick={() => handleTabChange('items')}
              className={`py-4 px-6 flex items-center border-b-2 font-medium text-sm transition-colors ${
                activeTab === 'items' 
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <Package size={18} className="mr-2" />
              Items
            </button>
            
            <button 
              onClick={() => handleTabChange('suppliers')}
              className={`py-4 px-6 flex items-center border-b-2 font-medium text-sm transition-colors ${
                activeTab === 'suppliers' 
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <Users size={18} className="mr-2" />
              Suppliers
            </button>
            
            <button 
              onClick={() => handleTabChange('buying')}
              className={`py-4 px-6 flex items-center border-b-2 font-medium text-sm transition-colors ${
                activeTab === 'buying' 
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <ShoppingBag size={18} className="mr-2" />
              Purchase Orders
            </button>
            
            <button 
              onClick={() => handleTabChange('receiving')}
              className={`py-4 px-6 flex items-center border-b-2 font-medium text-sm transition-colors ${
                activeTab === 'receiving' 
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <Truck size={18} className="mr-2" />
              Receiving
            </button>
            
            <button 
              onClick={() => handleTabChange('reports')}
              className={`py-4 px-6 flex items-center border-b-2 font-medium text-sm transition-colors ${
                activeTab === 'reports' 
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <BarChart2 size={18} className="mr-2" />
              Reports
            </button>
          </div>
        </div>
      </div>

      {/* Nested Routes */}
      <Outlet />
    </div>
  );
};

export default InventoryPage;