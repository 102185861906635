// src/context/AppContext.js
import React, { createContext, useState, useEffect } from 'react';
import { db } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';

export const AppContext = createContext();

// Ensure isDemoMode defaults to false if not provided
export const AppProvider = ({ children, isDemoMode = false }) => {
  const { currentUser } = useAuth();
  const [activeBusiness, setActiveBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    console.log("Demo mode:", isDemoMode); // Debug: verify the flag is set correctly
    
    // Bypass Firestore logic if in demo mode
    if (isDemoMode) {
      setActiveBusiness(null);
      setLoading(false);
      return;
    }
    
    const fetchActiveBusiness = async () => {
      if (!currentUser) {
        setActiveBusiness(null);
        setLoading(false);
        return;
      }
      
      try {
        // Check localStorage for the active business ID
        const activeBusinessId = localStorage.getItem('activeBusinessId');
        
        if (!activeBusinessId) {
          // If no active business is stored, try to get the user's default business
          const userRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userRef);
          
          if (userDoc.exists() && userDoc.data().defaultBusinessId) {
            const businessId = userDoc.data().defaultBusinessId;
            const businessRef = doc(db, 'businesses', businessId);
            const businessDoc = await getDoc(businessRef);
            
            if (businessDoc.exists()) {
              setActiveBusiness({
                id: businessId,
                ...businessDoc.data()
              });
              localStorage.setItem('activeBusinessId', businessId);
            }
          }
        } else {
          // If an active business ID is stored, fetch that business
          const businessRef = doc(db, 'businesses', activeBusinessId);
          const businessDoc = await getDoc(businessRef);
          
          if (businessDoc.exists()) {
            setActiveBusiness({
              id: activeBusinessId,
              ...businessDoc.data()
            });
          } else {
            localStorage.removeItem('activeBusinessId');
          }
        }
      } catch (error) {
        console.error('Error fetching active business:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchActiveBusiness();
  }, [currentUser, isDemoMode]);
  
  // Set a different active business
  const setActiveBusinessById = async (businessId) => {
    if (isDemoMode) {
      setActiveBusiness(null);
      localStorage.removeItem('activeBusinessId');
      return;
    }
    
    if (!businessId) {
      setActiveBusiness(null);
      localStorage.removeItem('activeBusinessId');
      return;
    }
    
    try {
      const businessRef = doc(db, 'businesses', businessId);
      const businessDoc = await getDoc(businessRef);
      
      if (businessDoc.exists()) {
        setActiveBusiness({
          id: businessId,
          ...businessDoc.data()
        });
        localStorage.setItem('activeBusinessId', businessId);
      }
    } catch (error) {
      console.error('Error setting active business:', error);
    }
  };
  
  return (
    <AppContext.Provider value={{
      activeBusiness,
      setActiveBusinessById,
      loading
    }}>
      {children}
    </AppContext.Provider>
  );
};
